import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

function MsfUploader(props) {
  const onDrop = useCallback((file) => {
    if (!file[0].name.toLowerCase().includes(".msf")) {
      alert("Please upload an MSF file.");
    } else {
      console.log("Reading msf");
      props.setFileName(file[0].name);
      //something to make a json from the msf
      var msf = "";
      var data = {};
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        msf = reader.result;
        data = {
          state: props.stateName,
          authority: props.authority,
          msf: msf,
        };
        props.setMsfData(data);
      };
      reader.readAsText(file[0]);
      //send data to sails
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <div
          style={{
            borderStyle: "dashed",
            padding: "5px",
            backgroundColor: "#ffffcc",
          }}
        >
          <p>
            Drag and drop an MSF file here, or{" "}
            <b>
              <u>click here</u>
            </b>{" "}
            🎯 to select files.
          </p>
        </div>
      ) : (
        <div
          style={{
            borderStyle: "dashed",
            borderWidth: "1px",
            padding: "5px",
            backgroundColor: "#f0fff0",
          }}
        >
          <p>
            Drag and drop an MSF file here, or{" "}
            <b>
              <u>click here</u>
            </b>{" "}
            🎯 to select files.
          </p>
        </div>
      )}
    </div>
  );
}

export default MsfUploader;
