import React from "react";
// react-bootstrap
import Accordion from "react-bootstrap/Accordion";
import { Button, Card } from "react-bootstrap";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "./CouncilRuleWarning.css"
// mock data
import councilRules from "./councilRules";

/*
* This component is used to render council rules in Accordion
* dataType is the sections like OSD(osd) or Water Quality(wsud)
*/

const CouncilRuleWarning = ({ dataType }) => {
    return (
        <Accordion defaultActiveKey="0">
            <p className="council-rule-note glowing-text"> &darr;Click here for council rules</p>
            <Accordion.Toggle
                variant="link"
                eventKey="1"
            >
                <Button className="council-rule-button">
                    <p className="council-rule-button-label">Council&nbsp;Rules</p>
                </Button>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
                <Card>
                    <Card.Body>
                        <ol className="list-container">
                            {councilRules[dataType].length !== 0 ? (
                                <div className="list-item">
                                    {councilRules[dataType].map((rule, key) => {
                                        return (
                                            <li key={key}>{rule}</li>
                                        )
                                    })}
                                </div>
                            ) : (
                                <div style={{ marginLeft: "5%" }}>No council rule provided.</div>
                            )}

                        </ol>
                    </Card.Body>
                </Card>
            </Accordion.Collapse>
        </Accordion >
    );
}

export default CouncilRuleWarning;
