const CouncilsList = {
    VIC: [
        {
            name: "Colac Otway Shire Council",
            website: "https://www.colacotway.vic.gov.au/Home"
        },
        {
            name: "Glenelg Shire Council",
            website: "http://www.glenelg.vic.gov.au/"
        },
        {
            name: "City of Ballarat",
            website: "https://www.ballarat.vic.gov.au/"
        },
        {
            name: "City of Casey",
            website: "https://www.casey.vic.gov.au/"
        },
        {
            name: "City of Wangaratta",
            website: "https://www.wangaratta.vic.gov.au/Home"
        },
        {
            name: "City of Baw Baw",
            website: "https://www.bawbawshire.vic.gov.au/Home"
        },
        {
            name: "City of Greater Bendigo",
            website: "https://www.bendigo.vic.gov.au/"
        },
        {
            name: "Mitchell Shire Council",
            website: "https://www.mitchellshire.vic.gov.au/"
        },
        {
            name: "City of Whitehorse",
            website: "https://www.whitehorse.vic.gov.au/"
        },
        {
            name: "Mount Alexander Shire Council",
            website: "http://www.mountalexander.vic.gov.au/"
        },
        {
            name: "Moorabool Shire Council",
            website: "https://www.moorabool.vic.gov.au/Home"
        },
        {
            name: "Ararat Rural City Council",
            website: "https://www.ararat.vic.gov.au/"
        },
        {
            name: "Hepburn Shire Council",
            website: "https://www.hepburn.vic.gov.au/"
        },
        {
            name: "Cardinia Shire Council",
            website: "https://www.cardinia.vic.gov.au/"
        },
        {
            name: "Murrindindi Shire Council",
            website: "https://www.murrindindi.vic.gov.au/Home"
        },
        {
            name: "Moyne Shire Council",
            website: "https://www.moyne.vic.gov.au/Home"
        },
        {
            name: "City of Wodonga",
            website: "https://www.wodonga.vic.gov.au/"
        },
        {
            name: "Manningham Council",
            website: "https://www.manningham.vic.gov.au/"
        },
        {
            name: "Banyule City Council",
            website: "https://www.banyule.vic.gov.au/Home"
        },
        {
            name: "Moonee Vallee City Council",
            website: "https://mvcc.vic.gov.au/"
        },
        {
            name: "Greater Shepparton City Council",
            website: "https://greatershepparton.com.au/"
        },
        {
            name: "Benalla Rural City Council",
            website: "https://www.benalla.vic.gov.au/Home"
        },
        {
            name: "City of Greater Geelong",
            website: "https://www.geelongaustralia.com.au/"
        },
        {
            name: "City of Melbourne",
            website: "https://www.melbourne.vic.gov.au/"
        },
        {
            name: "Hume City Council",
            website: "https://www.hume.vic.gov.au/Home"
        },
        {
            name: "Southern Grampians Shire Council",
            website: "https://www.sthgrampians.vic.gov.au/"
        },
        {
            name: "Pyrenees Shire Council",
            website: "https://www.pyrenees.vic.gov.au/Home"
        },
        {
            name: "Corangamite Shire Council",
            website: "https://www.corangamite.vic.gov.au/Home"
        },
        {
            name: "Northern Grampians Shire Council",
            website: "https://www.ngshire.vic.gov.au/Home"
        },
        {
            name: "Gannawarra Shire Council",
            website: "https://www.gannawarra.vic.gov.au/Home"
        },
        {
            name: "Surf Coast Shire Council",
            website: "https://www.surfcoast.vic.gov.au/Home"
        }
    ],

    NSW: [
        {
            name: "Northern Beaches Council",
            website: "https://www.northernbeaches.nsw.gov.au/"
        },
        {
            name: "Wagga City Council",
            website: "https://wagga.nsw.gov.au/"
        },
        {
            name: "Richmond Valley Council",
            website: "https://richmondvalley.nsw.gov.au/"
        },
        {
            name: "Muswellbrook Shire Council",
            website: "https://www.muswellbrook.nsw.gov.au/"
        },
        {
            name: "Clarence Valley Council",
            website: "https://www.clarence.nsw.gov.au/Home"
        },
        {
            name: "Port Stephens Council",
            website: "http://www.portstephens.nsw.gov.au/"
        },
        {
            name: "Byron Shire Council",
            website: "https://www.byron.nsw.gov.au/Home"
        },
        {
            name: "City of Parramatta",
            website: "https://www.cityofparramatta.nsw.gov.au/"
        },
        // Kuringai does not want to be advertised as accepting storm reports
        // {
        //     name: "Ku-ring-gai Council",
        //     website: "https://www.krg.nsw.gov.au/Home"
        // },
        {
            name: "Federation Council",
            website: "https://www.federationcouncil.nsw.gov.au/Home"
        },
        // {
        //     name: "Port Macquarie Hastings Council",
        //     website: "https://www.pmhc.nsw.gov.au/Home"
        // },
        {
            name: "Lake Macquarie City Council",
            website: "https://www.lakemac.com.au/Home"
        },
        {
            name: "Liverpool City Council",
            website: "https://www.liverpool.nsw.gov.au/"
        }
    ],

    TAS: [
        {
            name: "Glenorchy City Council",
            website: "https://www.gcc.tas.gov.au/"
        },
        {
            name: "Kingborough Council",
            website: "https://www.kingborough.tas.gov.au/"
        },
        {
            name: "Burnie City Council",
            website: "https://www.burnie.net/Home"
        },
        {
            name: "Latrobe Council",
            website: "https://www.latrobe.tas.gov.au/"
        },
        {
            name: "City of Hobart",
            website: "https://www.hobartcity.com.au/Home"
        },
        {
            name: "Brighton Council",
            website: "https://www.brighton.tas.gov.au/"
        },
        {
            name: "Devonport City Council",
            website: "https://www.devonport.tas.gov.au/"
        },
        {
            name: "City of Clarence",
            website: "https://www.ccc.tas.gov.au/"
        },
    ],

    QLD: [
        {
            name: "Fraser Coast Council",
            website: "https://www.frasercoast.qld.gov.au/"
        },
        {
            name: "Sunshine Coast Council",
            website: "https://www.sunshinecoast.qld.gov.au/"
        },
        {
            name: "City of Ipswich",
            website: "https://www.ipswich.qld.gov.au/"
        },
        {
            name: "Moreton Bay Regional Council",
            website: "https://www.moretonbay.qld.gov.au/Home"
        },
        {
            name: "Townsville City Council",
            website: "https://www.townsville.qld.gov.au/"
        },
        {
            name: "Toowoomba Regional Council",
            website: "http://www.tr.qld.gov.au/"
        },
        {
            name: "Rockhampton Regional Council",
            website: "https://www.rockhamptonregion.qld.gov.au/Home"
        },
        {
            name: "Central Highlands Regional Council",
            website: "https://www.chrc.qld.gov.au/"
        },
        {
            name: "Tablelands Regional Council",
            website: "https://www.trc.qld.gov.au/"
        },
        {
            name: "Somerset Regional Council",
            website: "https://www.somerset.qld.gov.au/"
        },
        {
            name: "Noosa Shire Council",
            website: "https://www.noosa.qld.gov.au/"
        },
        {
            name: "Cairns Regional Council",
            website: "https://www.cairns.qld.gov.au/"
        },
        {
            name: "Cassowary Coast Regional Council",
            website: "https://www.cassowarycoast.qld.gov.au/" 	
        },
        {
            name: "Logan City Council",
            website: "https://www.logan.qld.gov.au/"
        },
        {
            name: "Maranoa Regional Council",
            website: "https://www.maranoa.qld.gov.au/"
        },
        {
            name: "Whitsunday Regional Council",
            website: "https://www.whitsundayrc.qld.gov.au/"
        },
        {
            name: "Banana Shire Council",
            website: "https://www.banana.qld.gov.au/"
        }
    ],

    SA: [
        {
            name: "City of Onkaparinga",
            website: "https://www.onkaparingacity.com/Home"
        },
        {
            name: "City of Mitcham",
            website: "https://www.mitchamcouncil.sa.gov.au/"
        },
    ]
}

export default CouncilsList; 	