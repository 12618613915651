import React from "react";
import { Alert } from "react-bootstrap";
import DorriesResultsTable from "./DorriesResultsTable";

class OverallResultsTable extends React.Component {
  constructor(props) {
    super(props);
  };
    
  render() {  
    return (
      <>
        <h4>Live Results</h4>
        <p>
          Total Water Quality Treatment Results for the above model. Feel free
          to add as many lines as you want 😉
        </p>
        {this.props.data.targetReductions !== null &&
        this.props.data.cityCouncil !== "" ? (
          <div>
            {!this.props.data.wqMethod.includes("DorriesNorBE") ? (
              <>
                {!this.props.data.wqMethod.includes("NorBE") ? (
                  <>
                    <h6 style={{ fontWeight: 700 }}>Overall STORM Rating</h6>
                    <table className="table table-sm col-sm-8 table-bordered">
                      <thead
                        style={{
                          backgroundColor: "#508bc9",
                          color: "white",
                          border: "1.5px solid #c7c7ce",
                        }}
                      >
                        <tr>
                          <th> TN Rating (%) </th>
                          <th> TP Rating (%) </th>
                          <th> TSS Rating (%) </th>
                          <th> GP Rating (%) </th>
                        </tr>
                      </thead>
                      <tbody style={{ border: "1.5px solid #c7c7ce" }}>
                        <tr>
                          <td style={{ border: "1.5px solid #c7c7ce" }}>
                            {this.props.data.overallResults.overallRatings
                              .tnRate >= 100 ? (
                              <span style={{ color: "green" }}>
                                {
                                  this.props.data.overallResults.overallRatings
                                    .tnRate
                                }
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                {
                                  this.props.data.overallResults.overallRatings
                                    .tnRate
                                }
                              </span>
                            )}
                          </td>
                          <td style={{ border: "1.5px solid #c7c7ce" }}>
                            {this.props.data.overallResults.overallRatings
                              .tpRate >= 100 ? (
                              <span style={{ color: "green" }}>
                                {
                                  this.props.data.overallResults.overallRatings
                                    .tpRate
                                }
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                {
                                  this.props.data.overallResults.overallRatings
                                    .tpRate
                                }
                              </span>
                            )}
                          </td>
                          <td style={{ border: "1.5px solid #c7c7ce" }}>
                            {this.props.data.overallResults.overallRatings
                              .tssRate >= 100 ? (
                              <span style={{ color: "green" }}>
                                {
                                  this.props.data.overallResults.overallRatings
                                    .tssRate
                                }
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                {
                                  this.props.data.overallResults.overallRatings
                                    .tssRate
                                }
                              </span>
                            )}
                          </td>
                          <td style={{ border: "1.5px solid #c7c7ce" }}>
                            {this.props.data.overallResults.overallRatings
                              .gpRate >= 100 ? (
                              <span style={{ color: "green" }}>
                                {
                                  this.props.data.overallResults.overallRatings
                                    .gpRate
                                }
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                {
                                  this.props.data.overallResults.overallRatings
                                    .gpRate
                                }
                              </span>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                ) : null}
                <h6 style={{ fontWeight: 700 }}>Overall Reductions</h6>
                <table
                  className="table table-sm col-sm-6 table-bordered"
                  style={{ borderColor: "black" }}
                >
                  <caption>
                    Required target reductions for council:{" "}
                    {this.props.data.cityCouncil}
                  </caption>
                  <thead
                    style={{
                      backgroundColor: "#508bc9",
                      color: "white",
                      border: "1.5px solid #c7c7ce",
                    }}
                  >
                    {!this.props.data.wqMethod.includes("NorBE") ? (
                      <tr>
                        <th></th>
                        <th> TN (%) </th>
                        <th> TP (%) </th>
                        <th> TSS (%) </th>
                        <th> GP (%) </th>
                        <th> Flow (% of volume)</th>
                      </tr>
                    ) : (
                      <tr>
                        <th></th>
                        <th> TN</th>
                        <th> TP</th>
                        <th> TSS</th>
                        <th> GP</th>
                        <th> Flow</th>
                      </tr>
                    )}
                  </thead>
                  <tbody style={{ border: "1.5px solid #c7c7ce" }}>
                    <tr>
                      {this.props.data.wqMethod !== "NorBE" ? (
                        <>
                          <td style={{ border: "1.5px solid #c7c7ce" }}>
                            Achieved Reductions
                          </td>
                          <td style={{ border: "1.5px solid #c7c7ce" }}>
                            {
                              this.props.data.overallResults.overallReductions
                                .tnReduction
                            }
                          </td>
                          <td style={{ border: "1.5px solid #c7c7ce" }}>
                            {
                              this.props.data.overallResults.overallReductions
                                .tpReduction
                            }
                          </td>
                          <td style={{ border: "1.5px solid #c7c7ce" }}>
                            {
                              this.props.data.overallResults.overallReductions
                                .tssReduction
                            }
                          </td>
                          <td style={{ border: "1.5px solid #c7c7ce" }}>
                            {
                              this.props.data.overallResults.overallReductions
                                .gpReduction
                            }
                          </td>
                          <td style={{ border: "1.5px solid #c7c7ce" }}>
                            {
                              this.props.data.overallResults.overallReductions
                                .flowReduction
                            }
                          </td>
                        </>
                      ) : (
                        <>
                          <td style={{ border: "1.5px solid #c7c7ce" }}>
                            Achieved Reductions (% from existing)
                          </td>
                          <td style={{ border: "1.5px solid #c7c7ce" }}>
                            {this.props.data.overallResults.overallReductions
                              .tnReduction >
                            this.props.data.targetReductions.tn ? (
                              <span style={{ color: "green" }}>
                                {
                                  this.props.data.overallResults
                                    .overallReductions.tnReduction
                                }
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                {
                                  this.props.data.overallResults
                                    .overallReductions.tnReduction
                                }
                              </span>
                            )}
                          </td>
                          <td style={{ border: "1.5px solid #c7c7ce" }}>
                            {this.props.data.overallResults.overallReductions
                              .tpReduction >
                            this.props.data.targetReductions.tp ? (
                              <span style={{ color: "green" }}>
                                {
                                  this.props.data.overallResults
                                    .overallReductions.tpReduction
                                }{" "}
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                {
                                  this.props.data.overallResults
                                    .overallReductions.tpReduction
                                }{" "}
                              </span>
                            )}
                          </td>
                          <td style={{ border: "1.5px solid #c7c7ce" }}>
                            {this.props.data.overallResults.overallReductions
                              .tssReduction >
                            this.props.data.targetReductions.tss ? (
                              <span style={{ color: "green" }}>
                                {
                                  this.props.data.overallResults
                                    .overallReductions.tssReduction
                                }{" "}
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                {
                                  this.props.data.overallResults
                                    .overallReductions.tssReduction
                                }{" "}
                              </span>
                            )}
                          </td>
                          <td style={{ border: "1.5px solid #c7c7ce" }}>
                            {this.props.data.overallResults.overallReductions
                              .gpReduction >
                            this.props.data.targetReductions.gp ? (
                              <span style={{ color: "green" }}>
                                {
                                  this.props.data.overallResults
                                    .overallReductions.gpReduction
                                }{" "}
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                {
                                  this.props.data.overallResults
                                    .overallReductions.gpReduction
                                }{" "}
                              </span>
                            )}
                          </td>
                          <td style={{ border: "1.5px solid #c7c7ce" }}>
                            {
                              this.props.data.overallResults.overallReductions
                                .flowReduction
                            }
                          </td>
                        </>
                      )}
                    </tr>
                    <tr>
                      {!this.props.data.wqMethod.includes("NorBE") ? (
                        <td style={{ border: "1.5px solid #c7c7ce" }}>
                          Target Reductions
                        </td>
                      ) : (
                        <td style={{ border: "1.5px solid #c7c7ce" }}>
                          Target Reductions (% from existing)
                        </td>
                      )}
                      <td style={{ border: "1.5px solid #c7c7ce" }}>
                        {this.props.data.targetReductions.tn}
                      </td>
                      <td style={{ border: "1.5px solid #c7c7ce" }}>
                        {this.props.data.targetReductions.tp}
                      </td>
                      <td style={{ border: "1.5px solid #c7c7ce" }}>
                        {this.props.data.targetReductions.tss}
                      </td>
                      <td style={{ border: "1.5px solid #c7c7ce" }}>
                        {this.props.data.targetReductions.gp}
                      </td>
                      <td style={{ border: "1.5px solid #c7c7ce" }}>N/A</td>
                    </tr>
                  </tbody>
                </table>

                {
                  //Pollutant table for WaterNSW
                  this.props.data.wqMethod.includes("NorBE") ? (
                    <>
                      <h6 style={{ fontWeight: 700 }}>Pollutant Generation</h6>
                      <table
                        className="table table-sm col-sm-6 table-bordered"
                        style={{ borderColor: "black" }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#508bc9",
                            color: "white",
                            border: "1.5px solid #c7c7ce",
                          }}
                        >
                          <tr>
                            <th></th>
                            <th> TN (kg/yr) </th>
                            <th> TP (kg/yr) </th>
                            <th> TSS (kg/yr) </th>
                            <th> GP (kg/yr) </th>
                            <th> Flow (ML/yr)</th>
                          </tr>
                        </thead>
                        <tbody style={{ border: "1.5px solid #c7c7ce" }}>
                          <tr>
                            <td style={{ border: "1.5px solid #c7c7ce" }}>
                              Existing Site
                            </td>
                            <td style={{ border: "1.5px solid #c7c7ce" }}>
                              {
                                this.props.data.overallResults
                                  .overallPollutantsPreDev.tnPollutantPreDev
                              }
                            </td>
                            <td style={{ border: "1.5px solid #c7c7ce" }}>
                              {
                                this.props.data.overallResults
                                  .overallPollutantsPreDev.tpPollutantPreDev
                              }
                            </td>
                            <td style={{ border: "1.5px solid #c7c7ce" }}>
                              {
                                this.props.data.overallResults
                                  .overallPollutantsPreDev.tssPollutantPreDev
                              }
                            </td>
                            <td style={{ border: "1.5px solid #c7c7ce" }}>
                              {
                                this.props.data.overallResults
                                  .overallPollutantsPreDev.gpPollutantPreDev
                              }
                            </td>
                            <td style={{ border: "1.5px solid #c7c7ce" }}>
                              {
                                this.props.data.overallResults
                                  .overallPollutantsPreDev.flowPollutantPreDev
                              }
                            </td>
                          </tr>
                          <tr>
                            <td style={{ border: "1.5px solid #c7c7ce" }}>
                              Developed Site
                            </td>
                            <td style={{ border: "1.5px solid #c7c7ce" }}>
                              {
                                this.props.data.overallResults
                                  .overallPollutantsPostDev.tnPollutantPostDev
                              }
                            </td>
                            <td style={{ border: "1.5px solid #c7c7ce" }}>
                              {
                                this.props.data.overallResults
                                  .overallPollutantsPostDev.tpPollutantPostDev
                              }
                            </td>
                            <td style={{ border: "1.5px solid #c7c7ce" }}>
                              {
                                this.props.data.overallResults
                                  .overallPollutantsPostDev.tssPollutantPostDev
                              }
                            </td>
                            <td style={{ border: "1.5px solid #c7c7ce" }}>
                              {
                                this.props.data.overallResults
                                  .overallPollutantsPostDev.gpPollutantPostDev
                              }
                            </td>
                            <td style={{ border: "1.5px solid #c7c7ce" }}>
                              {
                                this.props.data.overallResults
                                  .overallPollutantsPostDev.flowPollutantPostDev
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ) : null
                }
              </>
            ) : (
              <>
                {this.props.data.wqMethod.includes("NorBE") ? (
                  <DorriesResultsTable data={this.props.data} />
                ) : null}
              </>
            )}
          </div>
        ) : null}

        {this.props.data.cityCouncil === "" ? (
          <Alert variant="info">
            Target Reductions are hidden as no city council has been selected.
            To view the target reductions please select a location in the
            council you are designing your model.
          </Alert>
        ) : null}
      </>
    );
  }
}

export default OverallResultsTable;
