import React, { Component } from 'react'

import { Alert, Button, Spinner } from "react-bootstrap";
import "../../assets/css/Uprct.css";
import "bootstrap/dist/css/bootstrap.min.css";
import OsdResultsTable from "../../OsdResultsTable";
import ReactTooltip from "react-tooltip";
import { printLog } from '../../common';

export default class Uprct extends Component {
    constructor(props) {
        super(props)
        console.log("================================================================================")
        console.log(props)
        this.state = {
            rwtCreditsCB: false,
            dedicatedAirspaceProvided: false,
            valueChanged: false,
        }
    }
    checkZeroArr = ["totalSiteArea", "numOfDwellings", "siteDrainingToOSDArea", "topWaterLevelED", "orifaceCentreED", "estimatedFloodLevelED", "numOfOrificeED", "topWaterLevelD", "orifaceCentreD", "estimatedFloodLevelD", "numOfOrificeDet", "lengthOfOverFlowWeir", "rlOfMinGarageFL", "TotalRwtVolume", "dailyDemand"]
    cannot_be_negative = ["totalSiteArea", "dedicatedAirspace", "maxHeadCentreOrifice", "numOfDwellings", "siteDrainingToOSDArea", "lengthOfOverFlowWeir", "totalRoofArea"]
    special_Check = ["mintriggeringTopUp", "roofDrainingRWTPercent", "siteRunoffCoff", "siteDrainingToOSDArea"]//totalRoofArea to be included
    invalidInputs = ["totalSiteArea", "numOfDwellings", "siteDrainingToOSDArea", "topWaterLevelED", "orifaceCentreED", "estimatedFloodLevelED", "topWaterLevelD", "orifaceCentreD", "estimatedFloodLevelD", "lengthOfOverFlowWeir", "siteRunoffCoff"]
    rwtCreditFields = ["roofDrainingRWTPercent", "mintriggeringTopUp", "TotalRwtVolume", "dailyDemand"]
    dedicatedAirspaceProvidedCB = ["dedicatedAirspace", "maxHeadCentreOrifice"]
    /**
     * function to change the state on checkbox toggle
     * @param {Obj} e stores the target name and current value 
     */
    handleCheckboxStatusChange = (e) => {
        //printLog(param)
        console.log("clicked")
        const { name, value } = e.target;
        //this.setState({[name]:value})
        this.props.setList(name, value)
        //this.props.data.osd[e.target.name] = e.target.value
        this.addOrDeleteFromInvalidInputsArr(name, value)
    }

    addOrDeleteFromInvalidInputsArr = (name, value) => {
        if (name == "rwtCreditsCB") {
            if (value) {
                this.invalidInputs.push(...this.rwtCreditFields)
            } else {
                for (let i = 0; i < this.rwtCreditFields.length; i++) {
                    this.invalidInputs.splice(this.invalidInputs.indexOf(this.rwtCreditFields[i]), 1)
                }
            }
        } else {
            if (value) {
                this.invalidInputs.push(...this.dedicatedAirspaceProvidedCB)
            } else {
                for (let i = 0; i < this.dedicatedAirspaceProvidedCB.length; i++) {
                    this.invalidInputs.splice(this.dedicatedAirspaceProvidedCB.indexOf(this.dedicatedAirspaceProvidedCB[i]), 1)
                }
            }
        }
        return;
    }

    changeValuechangeState = (e) => {
        if (this.props.data.osd.hasOwnProperty(e.target.id)) {
            this.props.data.osd[e.target.id] = e.target.value
            console.log(e.target.value)
            console.log(e.target.id)
        }
        this.setState({ valueChanged: true })
    }
    /**
     * Function to perform the custom validations for all the input fields
     * @param {Object} e element object of the react component
     */
    handleInputChange = (e) => {
        // e.stopPropagation()
        // console.log(this.props)
        this.setState({ valueChanged: false })
        // e.preventDefault()
        // console.log(e.target.name)
        if (this.checkZeroArr.includes(e.target.id)) {
            if (!this.checkZeroValue(e))
                return;
        } else if (this.cannot_be_negative.includes(e.target.id)) {
            if (!this.checkNegative(e))
                return;
        } else if (this.special_Check.includes(e.target.id)) {
            if (!this.performSpecialChecks(e))
                return;
        }
        this.props.data.osd[e.target.id] = e.target.value
        return;
    }
    /**
     * Checks if the value is 0 in the input field and returns a custom message
     * @param {Object} e element object of the react component 
     */
    checkZeroValue = (e) => {
        if (e.target.value == 0) {
            alert(
                `${e.target.name} cannot be 0.`
            );

            this.addToInvalidInputsArray(e.target.id)
            return false;
        } else {
            this.removeFromInvalidInputsArray(e.target.id)
            return true;
        }
    }
    /**
     * Checks if the value is negative in the input field and returns a custom message
     * @param {Object} e element object of the react component 
     */
    checkNegative = (e) => {
        if (e.target.value < 0) {
            alert(
                `${e.target.id} cannot be less than 0.`
            );
            this.addToInvalidInputsArray(e.target.id)
            return false;
        } else {
            this.removeFromInvalidInputsArray(e.target.id)
            return true;
        }
    }
    addToInvalidInputsArray = (id) => {
        if (this.invalidInputs.indexOf(id) < 0) {
            this.invalidInputs.push(id)
        }
        return;
    }
    removeFromInvalidInputsArray = (id) => {
        let index = this.invalidInputs.indexOf(id)
        if (index >= 0) {
            this.invalidInputs.splice(index, 1)
        }
    }
    /**
     * Checks if the value is adhering to validationchecks for the input field and returns a custom message
     * @param {Object} e element object of the react component 
     */
    performSpecialChecks = (e) => {
        if (e.target.id === "mintriggeringTopUp") {
            if (e.target.value > 0.1 * this.props.data.osd.TotalRwtVolume) {
                alert(
                    `${e.target.id} should be less than 10% of the tank volume`
                );
                this.addToInvalidInputsArray(e.target.id)
                return false;
            } else {
                this.removeFromInvalidInputsArray(e.target.id)
                return true;
            }
        } else if (e.target.id === "roofDrainingRWTPercent") {
            if (e.target.value < 44.2 || e.target.value >= 100) {
                alert(
                    `${e.target.name} should be between 44.2 and 100`
                );
                this.addToInvalidInputsArray(e.target.id)
                return false;
            } else {
                this.removeFromInvalidInputsArray(e.target.id)
                return true;
            }
        } else if (e.target.id === "siteRunoffCoff") {
            if (e.target.value < 0 || e.target.value >= 1) {
                alert(
                    `${e.target.name} should be between 0 and 1`
                );
                this.addToInvalidInputsArray(e.target.id)
                return false;
            } /* else if (e.target.id == "siteDrainingToOSDArea") {
              if(this.getAreaByPassing(e.target.value) < 0.3){
                  alert(
                      `Percentage area bypassing OSD too large, Please increase area of site draining to OSD`
                  );
                  this.addToInvalidInputsArray(e.target.id)
                  return false;
              }
          } */ else {
                this.removeFromInvalidInputsArray(e.target.id)
                return true;
            }
        } /* else if(e.target.name === "totalRoofArea") {
            if(e.target.value < 0 || e.target.value > 1) {
                alert(
                    "site runoff coff should be between 0 and 1"
                )
            }
        } */
    }
    checkAreaByPassing = () => {
        let residualSiteArea = this.props.data.osd.totalSiteArea - this.props.data.osd.totalRoofArea
        let areaBypassingStorage = this.props.data.osd.totalSiteArea - this.props.data.osd.siteDrainingToOSDArea
        let areaByPassing = areaBypassingStorage / residualSiteArea
        if (areaByPassing < .3) {
            return true
        }
        return false;
    }

    validateAllInputs = () => {
        if (this.invalidInputs.length === 0 && this.performAfterInputsCheck()) {
            if (this.props.data.hasWQ) {
                if (this.wqInputsValid()) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        } else {
            alert(
                `validateAllInputs error`
            );
            console.log(this.invalidInputs)
            return false
        }
    }

    performAfterInputsCheck = () => {
        if (!this.checkAreaByPassing()) {
            alert(
                `Percentage area bypassing OSD too large, Please increase area of site draining to OSD`
            );
            //this.addToInvalidInputsArray("AreaByPassing")
            return false
        }
        //this.removeFromInvalidInputsArray("AreaByPassing")
        return true;
    }

    wqInputsValid = () => {
        let wqRoofArea = 0
        let wqAreaExceptRoof = 0
        for (let nodes in this.props.data.imperviousNodes) {
            let nodeData = this.props.data.imperviousNodes[nodes]
            if (this.emptyWQFieldsPresent(nodeData)) {
                return false
            } else {
                if (nodeData.impAreaType.toLocaleLowerCase().includes('roof')) {
                    wqRoofArea = wqRoofArea + Number(nodeData.impAreaSize)
                } else {
                    wqAreaExceptRoof = wqAreaExceptRoof + Number(nodeData.impAreaSize)
                }
            }
        }
        if (this.sumTotalMatching(wqRoofArea, wqAreaExceptRoof)) {
            return true
        } else {
            alert(
                `site area should be equal to sum of impervious`
            );
            return false
        }
    }

    emptyWQFieldsPresent = (nodeData) => {
        if (!nodeData.impAreaName || !nodeData.impAreaSize || !nodeData.impAreaType) {
            alert(
                `Check WQ inputs`
            );
            return true
        } else {

            return false
        }

    }

    sumTotalMatching = (wqRoofArea, wqAreaExceptRoof) => {
        if (wqRoofArea == this.props.data.osd.totalRoofArea || wqAreaExceptRoof == this.props.data.osd.totalSiteArea) {
            return true
        } else {
            return false
        }
    }

    async computeOSDResults() {
        if (this.props.data.osd.rwtCreditsCB) {
            this.invalidInputs.push(...this.rwtCreditFields) 
            if (this.props.data.osd.dedicatedAirspace) {
                this.invalidInputs.push(...this.dedicatedAirspaceProvidedCB)
            }
        } 
        let copyInvalidInputs =  [
            ...this.invalidInputs
        ]
        copyInvalidInputs.forEach(input => {
            let e = {
                target: {
                    id: input,
                    value: this.props.data.osd[input]
                }
            }
            console.log(this.invalidInputs)
            this.handleInputChange(e)
        });

        if (this.validateAllInputs()) {
            // if (this.state.osdInputsValid) {
            // printLog("/OSD/osdResultsAllStorageTypes" );
            /* this.setState({ osdResultLoading: true }); */
            await this.props.setOSD("osdCalculating", true);
            await this.props.sails_api
                .post("/OSD/osdResults/" + this.props.data.osdMethod, {
                    latitude: -37.37233644475312,
                    longitude: 147.90951226562504,
                    osd: this.props.data.osd,
                    council: this.props.data.cityCouncil
                    // totalImpArea: this.props.data.totalImpArea,
                    // totalPArea: this.props.data.totalPArea,
                })
                .then(async (res) => {
                    printLog(res.data);

                    // q_pre message and alert cancel and bring osd loading spinner away
                    if (res.data.message) {
                        /* this.setState({
                          osdResultLoading: false,
                          q_pre_alert: true,
                          q_pre_message: res.data.message,
                        }); */
                        await this.props.setOSD("osdResultLoading", false);
                        // show osd_table after clicking if no error message shows
                        /*  this.setState({ osd_table: false }); */
                        /* printLog("osd_table" + this.props.state.osd_table); */

                        return;
                    }

                    await this.props.setOSD("osdResult", res.data);
                    /* this.setState({ osdResultLoading: false }); */
                    await this.props.setOSD("osdCalculating", false);

                    // show osd_table after clicking if no error message shows
                    /* this.setState({ osd_table: true }); */
                    /* printLog("osd_table" + this.props.state.osd_table); */
                })
                .catch(async (err) => {
                    console.error(err);
                });
        } else {

        }
        // }
    }
    render() {
        return (
            <div className='outerBox'>
                <table>
                    <thead>
                        <tr>
                            <th>
                                OSD
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Total Site Area (m<sup>2</sup>):</td>
                            <td>
                                <input
                                    type='number'
                                    min='0'
                                    step="any"
                                    name="Total Site Area"
                                    className="input_area"
                                    value={this.props.data.osd.totalSiteArea}
                                    id='totalSiteArea'
                                    onChange={(e) => { this.changeValuechangeState(e) }}
                                    onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}
                                >
                                </input>
                            </td>
                            <td>&nbsp;</td>
                            <td>Area of site Draining to OSD (m<sup>2</sup>):</td>
                            <td>
                                <input
                                    type='number'
                                    step="any"
                                    min='0'
                                    name="Area of site Draining to OSD"
                                    id='siteDrainingToOSDArea'
                                    value={this.props.data.osd.siteDrainingToOSDArea}
                                    className="input_area"
                                    onChange={(e) => { this.changeValuechangeState(e) }}
                                    onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}
                                >
                                </input>
                            </td>
                        </tr>
                        <tr>
                            <td>Total Roof Area (m<sup>2</sup>):</td>
                            <td>
                                <input
                                    type='number'
                                    min='0'
                                    required="true"
                                    name="Total Roof Area"
                                    id="totalRoofArea"
                                    className="input_area"
                                    value={this.props.data.osd.totalRoofArea}
                                    onChange={(e) => { this.changeValuechangeState(e) }}
                                    onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}
                                >
                                </input>
                            </td>
                            <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                            <td>Number of Dwellings:</td>
                            <td>
                                <input
                                    type='number'
                                    step="any"
                                    min='0'
                                    name="Number of Dwellings"
                                    id='numOfDwellings'
                                    className="input_area"
                                    value={this.props.data.osd.numOfDwellings}
                                    onChange={(e) => { this.changeValuechangeState(e) }}
                                    onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}
                                >
                                </input>
                            </td>
                        </tr>
                        <tr></tr>
                        <tr></tr>
                    </tbody>
                </table>
                <br />
                <div className='innerBox'>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ textAlign: "centre", paddingBottom: "10px" }} colSpan={'5'}>Orifice Calculations</th>
                            </tr>
                            <tr>
                                <th></th>
                                <td className='thWordWrap'>RL of Top Water Level of Storage (m)</td>
                                <td className='thWordWrap'>RL of Orifice Centre Line(m)</td>
                                <td className='thWordWrap'>Estimated Downstream Flood Level(m)</td>
                                <td className='thWordWrap'>no. of orifice</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Extended Detention:</td>
                                <td>
                                    <input
                                        type='number'
                                        step="any"
                                        min={0}
                                        name="RL of Top Water Level of Storage ED"
                                        id="topWaterLevelED"
                                        className="input_area"
                                        value={this.props.data.osd.topWaterLevelED}
                                        onChange={(e) => { this.changeValuechangeState(e) }}
                                        onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                    </input>
                                </td>
                                <td>
                                    <input
                                        type='number'
                                        step="any"
                                        min={0}
                                        name='RL of Orifice Centre Line ED'
                                        id="orifaceCentreED"
                                        className="input_area"
                                        value={this.props.data.osd.orifaceCentreED}
                                        onChange={(e) => { this.changeValuechangeState(e) }}
                                        onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                    </input>
                                </td>
                                <td>
                                    <input
                                        type='number'
                                        step="any"
                                        min={0}
                                        name="Estimated Downstream Flood Level ED"
                                        id="estimatedFloodLevelED"
                                        className="input_area"
                                        value={this.props.data.osd.estimatedFloodLevelED}
                                        onChange={(e) => { this.changeValuechangeState(e) }}
                                        onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                    </input><span>Based on 50% AEP</span>
                                    <span
                                        data-tip="1.5 yr ARI."
                                        data-multiline="true"
                                        data-place="bottom"
                                    >❓</span>
                                    <ReactTooltip />
                                </td>
                                <td>
                                    <input
                                        disabled={true}
                                        type='number'
                                        min={0}
                                        step={1}
                                        name="no. of orifice ED"
                                        id="numOfOrificeED"
                                        className="input_area"
                                        value={this.props.data.osd.numOfOrificeED}
                                        onChange={(e) => { this.changeValuechangeState(e) }}
                                        onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                    </input>
                                </td>
                            </tr>
                            <tr>
                                <td>Detention:</td>
                                <td>
                                    <input
                                        type='number'
                                        step="any"
                                        min={0}
                                        name="RL of Top Water Level of Storage D"
                                        id="topWaterLevelD"
                                        className="input_area"
                                        value={this.props.data.osd.topWaterLevelD}
                                        onChange={(e) => { this.changeValuechangeState(e) }}
                                        onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                    </input>
                                </td>
                                <td>
                                    <input
                                        type='number'
                                        step="any"
                                        min={0}
                                        name="RL of Orifice Centre Line D"
                                        id="orifaceCentreD"
                                        className="input_area"
                                        value={this.props.data.osd.orifaceCentreD}
                                        onChange={(e) => { this.changeValuechangeState(e) }}
                                        onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                    </input>
                                </td>
                                <td>
                                    <input type='number'
                                        step="any"
                                        min={0}
                                        name="Estimated Downstream Flood Level D"
                                        id="estimatedFloodLevelD"
                                        className="input_area"
                                        value={this.props.data.osd.estimatedFloodLevelD}
                                        onChange={(e) => { this.changeValuechangeState(e) }}
                                        onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                    </input><span>Based on 1% AEP</span>
                                    <span
                                        data-tip="100 yr ARI."
                                        data-multiline="true"
                                        data-place="bottom"
                                    >❓</span>
                                    <ReactTooltip />
                                </td>
                                <td>
                                    <input
                                        disabled={true}
                                        type='number'
                                        min={0}
                                        step={1}
                                        name="no. of orifice"
                                        id="numOfOrificeDet"
                                        className="input_area"
                                        value={this.props.data.osd.numOfOrificeDet}
                                        onChange={(e) => { this.changeValuechangeState(e) }}
                                        onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                    </input>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                <div className='innerBox'>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ textAlign: "centre", paddingBottom: "10px" }} colSpan={4}>
                                    Overflow Weir and Freeboard Calculations
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>RL of Minimum Habitable Floor Level (m):</td>
                                <td>
                                    <input
                                        type='number'
                                        step="any"
                                        min={0}
                                        name="RL of Minimum Habitable Floor Level"
                                        id="rlOfMinHabitableFL"
                                        className="input_area"
                                        value={this.props.data.osd.rlOfMinHabitableFL}
                                        onChange={(e) => { this.changeValuechangeState(e) }}
                                        onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                    </input>
                                </td>
                                <td>Length of Overflow Weir (m):</td>
                                <td>
                                    <input
                                        type='number'
                                        step="any"
                                        min={0}
                                        name='Length of Overflow Weir'
                                        id="lengthOfOverFlowWeir"
                                        className="input_area"
                                        value={this.props.data.osd.lengthOfOverFlowWeir}
                                        onChange={(e) => { this.changeValuechangeState(e) }}
                                        onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                    </input>
                                </td>
                            </tr>
                            <tr>
                                <td>RL of Minimum Garage Floor Level (m):</td>
                                <td>
                                    <input
                                        type='number'
                                        step="any"
                                        min={0}
                                        name='RL of Minimum Garage Floor Level'
                                        id="rlOfMinGarageFL"
                                        className="input_area"
                                        value={this.props.data.osd.rlOfMinGarageFL}
                                        onChange={(e) => { this.changeValuechangeState(e) }}
                                        onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                    </input>
                                </td>
                                <td>Site Runoff Coefficient</td>
                                <td>
                                    <input
                                        type='number'
                                        step="any"
                                        min={0}
                                        max={1}
                                        name='Site Runoff Coefficient'
                                        id="siteRunoffCoff"
                                        className="input_area"
                                        value={this.props.data.osd.siteRunoffCoff}
                                        onChange={(e) => { this.changeValuechangeState(e) }}
                                        onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                    </input>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                <br />
                <div>
                    <label style={{ marginRight: "20px" }}>Claim rainwater tank credits for site storage requirements<input style={{ width: "auto" }} onChange={(e) => {
                        this.handleCheckboxStatusChange({
                            target: {
                                name: e.target.name,
                                value: e.target.checked,
                            },
                        });
                    }} type="checkbox" checked={this.props.data.osd.rwtCreditsCB} id="rwtCreditsCB" name="rwtCreditsCB"></input></label>
                    <br />
                    {this.props.data.osd.rwtCreditsCB ? <div className='innerBox'>
                        <h4 style={{ textAlign: "center" }}>
                            Rainwater Tank Credits
                        </h4>
                        <label style={{ fontWeight: "bold" }}>*Please enter details below per dwelling, assume all dwellings are equal in</label>
                        <div>
                            <label>
                                % Roof Draining to RWT: <input
                                    type='number'
                                    step="any"
                                    min={44.2}
                                    max={100}
                                    name="% Roof Draining to RWT:"
                                    id="roofDrainingRWTPercent"
                                    className="input_area"
                                    value={this.props.data.osd.roofDrainingRWTPercent}
                                    onChange={(e) => { this.changeValuechangeState(e) }}
                                    onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                </input>
                            </label>
                            <label style={{ marginLeft: "25%" }}>
                                Minimum Volume Trigger Top-up (KL): <input
                                    type='number'
                                    step="any"
                                    min={0}
                                    name='Minimum Volume Trigger Top-up'
                                    id="mintriggeringTopUp"
                                    className="input_area"
                                    value={this.props.data.osd.mintriggeringTopUp}
                                    onChange={(e) => { this.changeValuechangeState(e) }}
                                    onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                </input>
                            </label>
                            <label>
                                Total Rainwater Tank Volume (KL): <input
                                    type='number'
                                    step="any"
                                    name='Total Rainwater Tank Volume'
                                    id="TotalRwtVolume"
                                    className="input_area"
                                    value={this.props.data.osd.TotalRwtVolume}
                                    onChange={(e) => { this.changeValuechangeState(e) }}
                                    onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                </input>
                            </label>
                        </div>
                        <br />
                        <div className='innerBoxGreen'>
                            <label style={{ marginRight: "20px" }}>Dedicated Airspace Provided<input style={{ width: "auto" }} type="checkbox" onChange={(e) => {
                                this.handleCheckboxStatusChange({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.checked,
                                    },
                                });
                            }} checked={this.props.data.osd.dedicatedAirspaceProvided} id="dedicatedAirspaceProvided" name="dedicatedAirspaceProvided"></input></label>
                            <br />
                            {this.props.data.osd.dedicatedAirspaceProvided ? <div>
                                <label>
                                    Dedicated Airspace (KL):<input
                                        type='number'
                                        step="any"
                                        min={0}
                                        name='Dedicated Airspace'
                                        id="dedicatedAirspace"
                                        className="input_area"
                                        value={this.props.data.osd.dedicatedAirspace}
                                        onChange={(e) => { this.changeValuechangeState(e) }}
                                        onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                    </input>
                                </label>
                                <label style={{ marginLeft: "25%" }}>
                                    Max Head to Centre of Orifice(m): <input
                                        type='number'
                                        step="any"
                                        min={0}
                                        name='Max Head to Centre of Orifice'
                                        id="maxHeadCentreOrifice"
                                        className="input_area"
                                        value={this.props.data.osd.maxHeadCentreOrifice}
                                        onChange={(e) => { this.changeValuechangeState(e) }}
                                        onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                    </input>
                                </label>
                            </div> : null}
                        </div>
                        <br />
                        <div className='innerBoxYellow'>
                            <label>Dynamic Airspace</label>
                            <br />
                            <div>
                                <label>
                                    Daily Demand (KL/day):<input
                                        name='Daily Demand'
                                        id="dailyDemand"
                                        className="input_area"
                                        value={this.props.data.osd.dailyDemand || 0}
                                        onChange={(e) => { this.changeValuechangeState(e) }}
                                        onBlur={this.state.valueChanged ? (e) => { this.handleInputChange(e) } : null}>
                                    </input>
                                </label>
                            </div>
                        </div>
                    </div> : null}
                    <br />
                    <div>
                        <img style={{ width: '50%' }} src={require('../img/UPRCT_image_interface.png').default} />
                    </div>
                    <Button
                        variant="primary"
                        className="btn btn-sm btn-grad"
                        style={{ marginTop: "20px" }}
                        /* disabled={!this.state.osdInputsValid} */
                        onClick={(e) => this.computeOSDResults()}
                    >
                        CALCULATE OSD
                    </Button>
                </div>
                <OsdResultsTable
                    setList={this.props.setList}
                    setState={this.props.setState}
                    companyDetails={this.props.companyDetails}
                    data={this.props.data}
                    osdInputsValid={true}
                    psd={""}
                    setOSD={this.props.setOSD}
                    climateAdjustment={this.props.data.osd.climateAdjustment}
                />
            </div>
        )
    }
}
