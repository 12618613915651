import React from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import * as _ from "lodash";
import ReactTooltip from "react-tooltip";
import OsdResultsTable from "../../OsdResultsTable";

import "../css/osd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { printLog } from "../../common";
import CouncilRuleWarning from "../../Calculator/warning_message/CouncilRuleWarning";

// import paved from "../img/surfaceType/Paved.png"
// import bare from "../img/surfaceType/Bare Soil.png"
// import poorly from "../img/surfaceType/Poorly Grassed.png"
// import avg from "../img/surfaceType/Average Grassed.png"
// import dense from "../img/surfaceType/Densely Grassed.png"

class Rational extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      psd: false,
      uncontrolledAreasFlag: false,
      surfaceTypes: [
        "Paved",
        "Bare Soil",
        "Poorly Grassed",
        "Average Grassed",
        "Densely Grassed",
      ],
      errors: [],

      metaMissing: true,
      postdevMissing: false,
      predevMissing: false,
      osdResult: null,
      osdInputsValid: false,
      osdResultLoading: false,

      predevAreasOK: true,
      predevAreasMessage: "",
      postdevAreasOK: true,
      postdevAreasMessage: "",
      postdevImpAreasOK: true,
      postdevImpAreasMessage: "",


      enteredPreDevArea: 0,
      enteredPostDevImpArea: 0,
      enteredPostDevPArea: 0,

      alert1: false,

      climateDisplay: 0,

      // this q_pre value will only be change if q_pre is calculated less than 0 and warning message send back from sails.
      q_pre_alert: false, // boolean value, either show q_pre is less than 0 or not
      q_pre_message: "", // error message will be set if q_pre is less than 0

      // show osdTable or not
      osd_table: false // false for not show, true for show
    };
    // Add 0 to uncontrolled area since begin
    this.props.data.osd.postDevAreas.ucFlowLength = '0';
    this.props.data.osd.postDevAreas.ucSlope = '0';



  }

  async componentDidMount() {
    //printLog("did mount rat WQ");

    var predevAreasOK = true;
    var predevAreasMessage = "";
    var postdevAreasOK = true;
    var postdevAreasMessage = "";
    var postdevImpAreasOK = true;
    var postdevImpAreasMessage = "";

    // variables for missing values
    var metaNotMissing = true;
    var preDevNotMissing = true;
    var postDevNotMissing = true;
    var noWarnings = true;

    // initialise designStorm to the default value
    await this.props.setList("designStorm", 1);

    // For pre-dev
    var listPre = [...this.props.data.osd.preDevAreas.areas];
    var enteredPreDevArea = 0;
    listPre.forEach((item) => {
      enteredPreDevArea +=
        item.surfaceArea !== "" ? parseFloat(item.surfaceArea) : 0;

      // for missing info in pre-dev
      if (item.areaName === "" || item.surfaceArea === "") {
        preDevNotMissing = false;
      }
    });

    if (
      parseFloat(enteredPreDevArea).toFixed(2) !==
      parseFloat(this.props.data.totalCatchmentArea).toFixed(2)
    ) {
      predevAreasOK = false;
    }

    // For post-dev
    var listPost = [...this.props.data.osd.postDevAreas.areas];
    var enteredPostDevImpArea = 0;
    var enteredPostDevPArea = 0;
    listPost.forEach((item, i) => {
      if (item.surfaceType === "Paved") {
        enteredPostDevImpArea +=
          item.surfaceArea !== "" ? parseFloat(item.surfaceArea) : 0;
      } else {
        enteredPostDevPArea +=
          item.surfaceArea !== "" ? parseFloat(item.surfaceArea) : 0;
      }

      // For warning info. if uncontrolled > surface area
      if (item.uncontrolled > item.surfaceArea) {
        noWarnings = false;
      }

      // For missing info in post dev
      if (
        item.areaName === "" ||
        item.surfaceArea === "" ||
        item.uncontrolled === ""
      ) {
        postDevNotMissing = false;
      }
    });

    if (
      parseFloat(enteredPostDevImpArea).toFixed(2) !==
      parseFloat(this.props.data.totalImpArea).toFixed(2)
    ) {
      postdevImpAreasOK = false;
    }

    if (
      parseFloat(enteredPostDevPArea).toFixed(2) !==
      parseFloat(this.props.data.totalPArea).toFixed(2)
    ) {
      postdevAreasOK = false;
    }

    // For missing values in metadata
    if (
      // this.props.data.osd.storageType === "" ||
      (this.props.data.osd.psd && this.props.data.osd.councilPSD === "")
    ) {
      metaNotMissing = false;
    }

    this.setState({
      metaMissing: !metaNotMissing,
      predevMissing: !preDevNotMissing,
      postdevMissing: !postDevNotMissing,
      osdInputsValid:
        metaNotMissing &&
        preDevNotMissing &&
        postDevNotMissing &&
        noWarnings &&
        predevAreasOK &&
        postdevAreasOK &&
        postdevImpAreasOK,
      predevAreasOK: predevAreasOK,
      predevAreasMessage: predevAreasMessage,
      postdevAreasOK: postdevAreasOK,
      postdevAreasMessage: postdevAreasMessage,
      postdevImpAreasMessage: postdevImpAreasMessage,
      postdevImpAreasOK: postdevImpAreasOK,
      enteredPostDevImpArea: enteredPostDevImpArea,
      enteredPostDevPArea: enteredPostDevPArea,
      enteredPreDevArea: enteredPreDevArea,
    });
  }

  async componentDidUpdate() {
    if (this.props.data.osdReset) {
      this.props.data.osdReset = false;
      this.setState({ osdInputsValid: false });
      this.setState({ climateDisplay: 0 });
      this.setState({ psd: false });
      await this.props.setList("climateAdjustment", 1);
    }
  }

  async handleClimateChange(e) {
    const { name, value } = e.target;
    printLog(name, value);

    if (name === "climateAdjustment") {
      if (value >= 0 && value <= 1000) {
        this.setState({ climateDisplay: value.trim() });
        await this.props.setList("climateAdjustment", 1 + value / 100);
      }
      else if (value === "") {
        this.setState({ climateDisplay: "" });
        await this.props.setList("climateAdjustment", 1);
      }
    }
  }

  //handle input for slope and length of flow
  async handleInputChange(e) {

    // clean q_pre negative message once input changed
    this.setState({ q_pre_alert: false, q_pre_message: "" });

    // clean results after change
    this.setState({ osd_table: false });

    let { name, value } = e.target;
    const pattern = /^\d+(?:\.\d{0,2})?$/i;
    var obj = {};

    if (name === "psd") {
      await this.setState({ psd: value });
      this.props.data.osd.psd = value;
      await this.props.setList("psd", value);

      //resetting Uncontrolled when Council PSD is changed
      printLog("Resetting uncontrolled...")
      var list = [...this.props.data.osd.postDevAreas.areas];
      //Check whether psd check box is checked if "not checked" replace uncontrolled area as 0 otherwise blank
      if (!value) {
        for (var i = 0; i < list.length; i++) {
          list[i]["uncontrolled"] = "0";
        }
        await this.props.setList("postDevAreas", {
          slope: this.props.data.osd.postDevAreas.slope,
          flowLength: this.props.data.osd.postDevAreas.flowLength,
          ucSlope: "0",
          ucFlowLength: "0",
          areas: list,
        });
      }
      else {
        for (var i = 0; i < list.length; i++) {
          list[i]["uncontrolled"] = "";
        }
        await this.props.setList("postDevAreas", {
          slope: this.props.data.osd.postDevAreas.slope,
          flowLength: this.props.data.osd.postDevAreas.flowLength,
          ucSlope: "",
          ucFlowLength: "",
          areas: list,
        });
      }
    }

    if (name === "councilPSD") {
      if (value !== "") {
        if (!pattern.test(value)) {
          return;
        }
      }
      await this.props.setList("councilPSD", value);
    }
    if (name === "storageType") {
      await this.props.setList("storageType", value);
    }
    if (name === "designStorm") {
      await this.props.setList("designStorm", value);
    }
    if (name === "tankHeight") {
      if (value !== "") {
        if (!pattern.test(value)) {
          return;
        }
      }
      await this.props.setList("tankHeight", value);
    }

    if (name === "predevSlope") {
      if (value !== "") {
        if (!pattern.test(value)) {
          return;
        }
      }

      obj = this.props.data.osd.preDevAreas;
      obj.slope = value;
      await this.props.setList("preDevAreas", obj);
    }
    if (name === "predevFlowLength") {
      if (value !== "") {
        if (!pattern.test(value)) {
          return;
        }
      }

      obj = this.props.data.osd.preDevAreas;
      obj.flowLength = value;
      await this.props.setList("preDevAreas", obj);
    }
    if (name === "postdevSlope") {
      if (value !== "") {
        if (!pattern.test(value)) {
          return;
        }
      }

      obj = this.props.data.osd.postDevAreas;
      obj.slope = value;
      await this.props.setList("postDevAreas", obj);
    }
    if (name === "postdevFlowLength") {
      if (value !== "") {
        if (!pattern.test(value)) {
          return;
        }
      }

      obj = this.props.data.osd.postDevAreas;
      obj.flowLength = value;
      await this.props.setList("postDevAreas", obj);
    }
    if (name === "postdevUCSlope") {
      if (value !== "") {
        if (!pattern.test(value)) {
          return;
        }
      }

      obj = this.props.data.osd.postDevAreas;
      obj.ucSlope = value;
      await this.props.setList("postDevAreas", obj);
    }
    if (name === "postdevUCFlowLength") {
      if (value !== "") {
        if (!pattern.test(value)) {
          return;
        }
      }

      obj = this.props.data.osd.postDevAreas;
      obj.ucFlowLength = value;
      await this.props.setList("postDevAreas", obj);
    }
    this.setState({ osdResult: null });
    this.checkMissing();
  }

  // handle input in predev
  async handlePreDevInputChange(e, index) {
    let { name, value } = e.target;
    var list = [...this.props.data.osd.preDevAreas.areas];
    if (name === "surfaceArea") {
      // To have only 2 digits
      if (value !== "") {
        var pattern = /^\d+(?:\.\d{0,2})?$/i;
        if (!pattern.test(value)) {
          return;
        }
      }

      list[index][name] = value;

      if (this.props.data.hasWQ) {
        var enteredPreDevArea = 0;
        list.forEach(
          (item) =>
          (enteredPreDevArea +=
            item.surfaceArea !== "" ? parseFloat(item.surfaceArea) : 0)
        );
        // printLog(
        //   enteredPreDevArea,
        //   ", ",
        //   this.props.data.totalCatchmentArea,
        //   ". ",
        //   enteredPreDevArea !== parseFloat(this.props.data.totalCatchmentArea)
        // );
        if (
          parseFloat(enteredPreDevArea).toFixed(2) !==
          parseFloat(this.props.data.totalCatchmentArea).toFixed(2)
        ) {
          this.setState({
            predevAreasOK: false,
            enteredPreDevArea: enteredPreDevArea,
          });
        } else {
          this.setState({ predevAreasOK: true });
        }
      } else {
        if (!this.state.predevAreasOK) {
          this.setState({ predevAreasOK: true });
        }
      }
    } else {
      list[index][name] = value;
    }
    // await this.props.setList("preDevAreas", list);
    await this.props.setList("preDevAreas", {
      slope: this.props.data.osd.preDevAreas.slope,
      flowLength: this.props.data.osd.preDevAreas.flowLength,
      areas: list,
    });

    this.checkMissing();
  }

  // handle click event of the Remove button in predev
  handlePreDevRemoveClick = async (index) => {
    const areas = this.props.data.osd.preDevAreas.areas;
    _.pullAt(areas, index); // See imports above.
    await this.props.setList("preDevAreas", {
      slope: this.props.data.osd.preDevAreas.slope,
      flowLength: this.props.data.osd.preDevAreas.flowLength,
      areas: areas,
    });

    // clear results table when input change
    this.setState({ osd_table: false });

    this.checkMissing();
  };

  // handle click event of the Add button
  handlePreDevAddClick = async () => {
    await this.props.setList("preDevAreas", {
      slope: this.props.data.osd.preDevAreas.slope,
      flowLength: this.props.data.osd.preDevAreas.flowLength,
      areas: [
        ...this.props.data.osd.preDevAreas.areas,
        {
          areaName: "",
          surfaceType: "Paved",
          surfaceArea: "",
        },
      ],
    });

    // clear results table when input change
    this.setState({ osd_table: false });

    this.checkMissing();
  };

  // handle input in postdev
  async handlePostDevInputChange(e, index) {
    let { name, value } = e.target;
    var list = [...this.props.data.osd.postDevAreas.areas];

    // clean q_pre negative message once input changed
    this.setState({ q_pre_alert: false, q_pre_message: "" });

    // clear results table when input change
    this.setState({ osd_table: false });

    printLog(name, value);

    if (name === "surfaceArea") {
      // To have only 2 digits
      if (value !== "") {
        var pattern = /^\d+(?:\.\d{0,2})?$/i;
        if (!pattern.test(value)) {
          return;
        }
      }

      list[index][name] = value;

      var enteredPostDevPArea = 0;
      var enteredPostDevImpArea = 0;

      list.forEach((item, i) => {
        if (item.surfaceType === "Paved") {
          enteredPostDevImpArea +=
            item.surfaceArea !== "" ? parseFloat(item.surfaceArea) : 0;
        } else {
          enteredPostDevPArea +=
            item.surfaceArea !== "" ? parseFloat(item.surfaceArea) : 0;
        }
      });

      if (
        parseFloat(enteredPostDevPArea).toFixed(2) !==
        parseFloat(this.props.data.totalPArea).toFixed(2)
      ) {
        this.setState({
          postdevAreasOK: false,
          enteredPostDevPArea: enteredPostDevPArea,
        });
      } else {
        if (!this.state.postdevAreasOK) {
          this.setState({ postdevAreasOK: true });
        }
      }

      if (
        parseFloat(enteredPostDevImpArea).toFixed(2) !==
        parseFloat(this.props.data.totalImpArea).toFixed(2)
      ) {
        this.setState({
          postdevImpAreasOK: false,
          enteredPostDevImpArea: enteredPostDevImpArea,
        });
      } else {
        if (!this.state.postdevImpAreasOK) {
          this.setState({ postdevImpAreasOK: true });
        }
      }
    }
    if (name === "uncontrolled") {
      // To have only 2 digits
      if (value !== "") {
        var pattern = /^\d+(?:\.\d{0,2})?$/i;
        if (!pattern.test(value)) {
          return;
        }
      }
      list[index][name] = value;
      // if (value >= 0 || value === "") {
      //   list[index][name] = value === "" ? "" : parseFloat(value);
      // }
    } else {
      list[index][name] = value;
    }

    if (
      parseFloat(list[index]["surfaceArea"]) <
      parseFloat(list[index]["uncontrolled"])
    ) {
      list[index]["message"] =
        "'Of which Uncontrolled' areas can only be less than or equal to controlled area";
    } else {
      list[index]["message"] = "";
    }

    await this.props.setList("postDevAreas", {
      slope: this.props.data.osd.postDevAreas.slope,
      flowLength: this.props.data.osd.postDevAreas.flowLength,
      ucSlope: this.props.data.osd.postDevAreas.ucSlope,
      ucFlowLength: this.props.data.osd.postDevAreas.ucFlowLength,
      areas: list,
    });

    this.checkMissing();
  }

  // handle click event of the Remove button in predev
  handlePostDevRemoveClick = async (index) => {
    const areas = this.props.data.osd.postDevAreas.areas;
    _.pullAt(areas, index); // See imports above.
    await this.props.setList("postDevAreas", {
      slope: this.props.data.osd.postDevAreas.slope,
      flowLength: this.props.data.osd.postDevAreas.flowLength,
      areas: areas,
    });

    // clear results table when input change
    this.setState({ osd_table: false });

    this.checkMissing();
  };

  // handle click event of the Add button
  handlePostDevAddClick = async () => {
    await this.props.setList("postDevAreas", {
      slope: this.props.data.osd.postDevAreas.slope,
      flowLength: this.props.data.osd.postDevAreas.flowLength,
      ucSlope: this.props.data.osd.postDevAreas.ucSlope,
      ucFlowLength: this.props.data.osd.postDevAreas.ucFlowLength,
      areas: [
        ...this.props.data.osd.postDevAreas.areas,
        {
          areaName: "",
          surfaceType: "Paved",
          surfaceArea: "",
          uncontrolled: "",
          message: "",
        },
      ],
    });

    // clear results table when input change
    this.setState({ osd_table: false });

    this.checkMissing();
  };

  // handle click event of the Delete button in uncontrolled area section
  handleUncontrolledRemoveClick = async (index) => {
    const uncontrolledAreas = this.props.data.osd.uncontrolledAreas;
    _.pullAt(uncontrolledAreas, index); // See imports above.
    await this.props.setList("uncontrolledAreas", uncontrolledAreas);
    this.checkMissing();
  };

  // Check if the pre-dev and post-dev areas match
  checkAreasMatch = () => {
    var predevSum = 0;
    var postdevSum = 0;

    [...this.props.data.osd.preDevAreas.areas].forEach(
      (area) => (predevSum += parseFloat(area.surfaceArea))
    );
    [...this.props.data.osd.postDevAreas.areas].forEach(
      (area) => (postdevSum += parseFloat(area.surfaceArea))
    );

    //printLog(predevSum, postdevSum, this.state.alert1);

    if (predevSum !== postdevSum) {
      if (!this.state.alert1) {
        this.setState({ alert1: true });
      }
      return false;
    } else {
      if (this.state.alert1) {
        this.setState({ alert1: false });
      }
      return true;
    }
  };

  //check if all values are filled
  checkMissing = async () => {
    const areasMatch = this.checkAreasMatch();

    var metaNotMissing = true;
    var preDevNotMissing = true;
    var postDevNotMissing = true;
    var noWarnings = true;
    if (
      // this.props.data.osd.storageType === "" ||
      (this.props.data.osd.psd && this.props.data.osd.councilPSD === "") ||
      parseFloat(this.props.data.osd.tankHeight) <= 0
    ) {
      metaNotMissing = false;
    }

    let areas = this.props.data.osd.preDevAreas.areas;
    for (const index in areas) {
      if (
        areas[index].areaName === "" ||
        areas[index].surfaceType === "" ||
        areas[index].surfaceArea === ""
      ) {
        preDevNotMissing = false;
        break;
      }
    }

    if (
      this.props.data.osd.preDevAreas.slope === "" ||
      this.props.data.osd.preDevAreas.flowLength === ""
    ) {
      preDevNotMissing = false;
    }

    areas = this.props.data.osd.postDevAreas.areas;
    for (const index in areas) {
      if (
        areas[index].areaName === "" ||
        areas[index].surfaceType === "" ||
        areas[index].surfaceArea === "" ||
        areas[index].uncontrolled === ""
      ) {
        postDevNotMissing = false;
        break;
      }
      if (areas[index].message !== "") {
        noWarnings = false;
      }
    }

    //printLog(
    //   "psd is: ",
    //   this.props.data.osd.psd,
    //   ", slope = ",
    //   this.props.data.osd.postDevAreas.slope,
    //   ", flow len = ",
    //   this.props.data.osd.postDevAreas.flowLength,
    //   ", uc slope = ",
    //   this.props.data.osd.postDevAreas.ucSlope,
    //   ", uc flow length = ",
    //   this.props.data.osd.postDevAreas.ucFlowLength
    // );

    if (this.props.data.osd.psd) {
      if (
        this.props.data.osd.postDevAreas.slope === "" ||
        this.props.data.osd.postDevAreas.flowLength === ""
      ) {
        postDevNotMissing = false;
      }
    } else {
      if (
        this.props.data.osd.postDevAreas.slope === "" ||
        this.props.data.osd.postDevAreas.flowLength === "" ||
        this.props.data.osd.postDevAreas.ucSlope === "" ||
        this.props.data.osd.postDevAreas.ucFlowLength === ""
      ) {
        postDevNotMissing = false;
      }
    }

    // if (
    //   this.props.data.osd.postDevAreas.slope === "" ||
    //   this.props.data.osd.postDevAreas.flowLength === ""
    // ) {
    //   if (!this.props.data.osd.psd) {
    //     if (
    //       this.props.data.osd.postDevAreas.ucSlope === "" ||
    //       this.props.data.osd.postDevAreas.ucFlowLength === ""
    //     ) {
    //       postDevNotMissing = false;
    //     }
    //   }
    // }

    this.setState({
      metaMissing: !metaNotMissing,
      predevMissing: !preDevNotMissing,
      postdevMissing: !postDevNotMissing,
      osdInputsValid:
        metaNotMissing &&
        preDevNotMissing &&
        postDevNotMissing &&
        noWarnings &&
        this.state.predevAreasOK &&
        this.state.postdevAreasOK &&
        this.state.postdevImpAreasOK &&
        areasMatch,
    });

    // this.props.data.osdOK =
    //   metaNotMissing &&
    //   preDevNotMissing &&
    //   postDevNotMissing &&
    //   noWarnings &&
    //   this.state.predevAreasOK &&
    //   this.state.postdevAreasOK &&
    //   this.state.postdevImpAreasOK &&
    //   areasMatch;

    await this.props.setOSD(
      "osdOK",
      this.props.data.hasOsd ||
      (
        metaNotMissing &&
        preDevNotMissing &&
        postDevNotMissing &&
        noWarnings &&
        this.state.predevAreasOK &&
        this.state.postdevAreasOK &&
        this.state.postdevImpAreasOK &&
        areasMatch
      )

    );

    await this.props.setOSD(
      "activateButtons",
      metaNotMissing &&
      preDevNotMissing &&
      postDevNotMissing &&
      noWarnings &&
      this.state.predevAreasOK &&
      this.state.postdevAreasOK &&
      this.state.postdevImpAreasOK &&
      areasMatch
    );
  };

  async computeOSDResults() {

    if (this.state.osdInputsValid) {
      printLog("OSD data passed: --", this.props.data.osd);
      this.setState({ osdResultLoading: true });
      await this.props.setOSD("osdCalculating", true);
      await this.props.sails_api
        .post("/OSD/osdResults/rational", {
          latitude: this.props.data.latitude,
          longitude: this.props.data.longitude,
          osd: this.props.data.osd,
        })
        .then(async (res) => {
          printLog(res.data);
          // q_pre message and alert cancel and bring osd loading spinner away
          if (res.data.message) {
            this.setState({ osdResultLoading: false, q_pre_alert: true, q_pre_message: res.data.message });
            await this.props.setOSD("osdResultLoading", false);

            // show osd_table after clicking if no error message shows
            this.setState({ osd_table: false });
            printLog("osd_table" + this.props.state.osd_table);

            return;
          }

          await this.props.setOSD("osdResult", res.data);
          this.setState({ osdResultLoading: false });
          await this.props.setOSD("osdCalculating", false);

          // show osd_table after clicking if no error message shows
          this.setState({ osd_table: true });
          printLog("osd_table" + this.props.state.osd_table);

        })
        .catch((err) => console.error(err));
    }
  }
  // // Mapping surfacetype to corresponding image
  // surfaceImageMap(surfaceType) {
  //   var surfaceImage = paved
  //   switch (surfaceType) {

  //     case "Paved":
  //       surfaceImage = paved;
  //       break;
  //     case "Bare Soil":
  //       surfaceImage = bare;
  //       break;
  //     case "Poorly Grassed":
  //       surfaceImage = poorly;
  //       break;
  //     case "Average Grassed":
  //       surfaceImage = avg;
  //       break;
  //     case "Densely Grassed":
  //       surfaceImage = dense;
  //       break;
  //     default:
  //       surfaceImage = paved;
  //       break;
  //   }
  //   return surfaceImage
  // }
  render() {
    if (this.props.data.hasWQ) {
      return (
        <div className="panNode_osd">
          <h5>OSD*</h5>
          {/* render council rules */}
          {this.props.cityCouncil.includes("Monash City")? (
            <CouncilRuleWarning dataType="osd" />
          ) : (null)}
          <label className="checkboxLabel_Red">
            {" "}
            *OSD results are produced using the {this.props.data.osdMethod}{" "}
            Method.
          </label>
          <div style={{ marginBottom: "12px" }}>
            <table className="table-responsive">
              <tbody>
                <tr>
                  <td>
                    {
                      <div className="checkboxOSDPad">
                        <label className="checkboxLabel">
                          <input
                            name="psd"
                            type="checkbox"
                            checked={this.state.psd}
                            onChange={async (e) => {
                              this.handleInputChange({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                              this.setState({ psd: e.target.checked });
                            }}
                            className="checkbox"
                          />
                          I have Council-provided PSD:
                          <span
                            data-tip="Uncontrolled areas are assumed to be accounted for in the calculation of PSD when PSD is provided by Council."
                            data-multiline="true"
                            data-place="bottom"
                          >
                            ❓
                          </span>
                          <ReactTooltip />
                        </label>
                      </div>
                    }
                  </td>
                  <td>
                    <input
                      name="councilPSD"
                      className="input_area"
                      value={this.props.data.osd.councilPSD}
                      disabled={this.state.psd === false}
                      required={this.state.psd}
                      onChange={(e) => this.handleInputChange(e)}
                    />
                    (L/s)
                  </td>
                  <td>
                    <div className="small_spacer"></div>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <div className="spacer"></div>
                  </td>
                  {/* <td> Storage Type: </td>
                  <td>
                    <select
                      name="storageType"
                      // className="select_area_OSD"
                      value={this.props.data.osd.storageType}
                      required
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value="" hidden>
                        Select
                      </option>
                      <option key="Above Ground Storage">
                        Above ground storage
                      </option>
                      <option key="Below Ground Pipe">Below ground pipe</option>
                      <option key="Below Ground Tank">Below ground tank</option>
                    </select>
                  </td> */}
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <div className="small_spacer"></div>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <div className="spacer"></div>
                  </td>
                  <td>Height of Storage above Orifice: </td>
                  <td>
                    <input
                      name="tankHeight"
                      className="select_area_OSD"
                      value={this.props.data.osd.tankHeight}
                      placeholder="optional"
                      // required
                      onChange={(e) => this.handleInputChange(e)}
                    />
                    (m)
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <div className="small_spacer"></div>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <div className="spacer"></div>
                  </td>
                  <td>Design Storm:
                    <span
                      data-tip="Leave at default value of 1% AEP if Design Storm is unknown.<br /> The design storm applies to both PSD and OSD."
                      data-multiline="true"
                      data-place="bottom"
                    >
                      ❓
                    </span>
                    <ReactTooltip />
                  </td>
                  <td>
                    {/* {" "} */}
                    <select
                      name="designStorm"
                      className="input_area"
                      value={this.props.data.osd.designStorm}
                      // required
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value={10} hidden>
                        10
                      </option>
                      {this.props.data.aeps.map((aep) => (
                        <option key={aep}>{aep}</option>
                      ))}
                    </select>
                    (% AEP)
                  </td>

                  <td></td>
                </tr>
                <tr>
                  <td colSpan={12}>
                    <Alert
                      variant="warning"
                      show={
                        // this.props.data.osd.storageType === "" ||
                        (this.props.data.osd.psd &&
                          this.props.data.osd.councilPSD === "")
                      }
                      // show={this.state.metaMissing}
                      style={{ marginTop: "5px" }}
                    >
                      ⚠️ Please enter missing data in the fields above.
                    </Alert>
                  </td>
                </tr>
                <tr>
                  <td colSpan={12}>
                    <Alert
                      variant="danger"
                      show={parseFloat(this.props.data.osd.tankHeight) <= 0}
                      // show={this.state.metaMissing}
                      style={{ marginTop: "5px" }}
                    >
                      ❗ Height of Storage above Orifice should be greater than
                      0. Leave it blank if you do not wish to model Orifice
                      Diameter.
                    </Alert>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <table
            className="col-7"
            style={{
              backgroundColor: "rgb(149, 186, 209, 0.2)",
              borderRadius: "10px",
            }}
          >
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>
                  <h5 style={{ margin: "10px" }}> Pre-development Areas</h5>
                  <hr />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td>Slope </td>
                        <td>
                          <input
                            name="predevSlope"
                            value={this.props.data.osd.preDevAreas.slope}
                            placeholder="Slope"
                            onChange={(e) => this.handleInputChange(e)}
                            style={{ width: "100px" }}
                          ></input>
                          (%)
                        </td>
                      </tr>
                      <tr>
                        <td>Length of Flow Path</td>
                        <td>
                          <input
                            name="predevFlowLength"
                            value={this.props.data.osd.preDevAreas.flowLength}
                            placeholder="Length"
                            onChange={(e) => this.handleInputChange(e)}
                            style={{ width: "100px" }}
                          ></input>
                          (m)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr style={{ height: "10px" }}></tr>
              <tr>
                {/* for predev areas */}
                <td style={{ verticalAlign: "top" }}>
                  <table>
                    <thead>
                      <tr></tr>
                      <tr>
                        <th></th>
                        <th>Surface Type</th>
                        <th>Surface Area (m²)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.data.osd.preDevAreas.areas.map((area, i) => {
                        return (
                          <tr
                            key={i}
                            style={{
                              borderTop: "1px solid rgb(149, 186, 209)",
                            }}
                          >
                            <td>
                              <input
                                placeholder={"Pre-dev. area " + (i + 1)}
                                name="areaName"
                                value={area.areaName}
                                // disabled={i === 0}
                                onChange={(e) =>
                                  this.handlePreDevInputChange(e, i)
                                }
                              ></input>
                            </td>

                            <td>
                              <select
                                placeholder="User Input"
                                name="surfaceType"
                                // disabled={i === 0}
                                value={area.surfaceType}
                                onChange={(e) =>
                                  this.handlePreDevInputChange(e, i)
                                }
                                style={{ width: "100px" }}
                              >
                                {this.state.surfaceTypes.map((type) => (
                                  <option value={type} key={type}>
                                    {type}
                                  </option>
                                ))}
                              </select>
                              <a data-tip data-for={'pre_image' + i}> ❓ </a>
                              <ReactTooltip id={'pre_image' + i} place="right">
                                <span>
                                  <img
                                    alt={require("../img/surfaceType/" + area.surfaceType + ".png").default}
                                    src={require("../img/surfaceType/" + area.surfaceType + ".png").default}
                                    // width="400"
                                    height="300"
                                    className="d-inline-block align-top"
                                  />
                                </span>
                              </ReactTooltip>
                            </td>
                            <td>
                              <input
                                placeholder="Area"
                                name="surfaceArea"
                                value={area.surfaceArea}
                                style={{ width: "100px" }}
                                onChange={(e) =>
                                  this.handlePreDevInputChange(e, i)
                                }
                              ></input>
                            </td>
                            <td>
                              <Button
                                variant="primary"
                                className="btn btn-sm btn-grad"
                                disabled={i === 0}
                                onClick={() => this.handlePreDevRemoveClick(i)}
                                style={{ marginRight: "2px" }}
                              >
                                🗑
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <Button
                    variant="primary"
                    className="btn btn-sm btn-grad"
                    onClick={this.handlePreDevAddClick}
                    style={{ margin: "2px 8px" }}
                  >
                    + Add Area
                  </Button>
                  <Alert
                    variant="danger"
                    show={!this.state.predevAreasOK}
                    style={{ margin: "10px" }}
                  >
                    Please increase/decrease Pre-Development Areas (currently{" "}
                    {parseFloat(this.state.enteredPreDevArea).toFixed(2)}m²) by{" "}
                    {parseFloat(
                      this.props.data.totalCatchmentArea -
                      this.state.enteredPreDevArea
                    ).toFixed(2)}
                    m² to match the Catchment Area (
                    {parseFloat(this.props.data.totalCatchmentArea).toFixed(2)}
                    m²) in Water Quality.
                    {/* {this.state.predevAreasMessage} */}
                  </Alert>
                </td>
              </tr>
              <tr>
                <td>
                  <Alert
                    variant="warning"
                    show={this.state.predevMissing}
                    style={{ marginTop: "5px" }}
                  >
                    ⚠️ Please enter missing data in the fields above.
                  </Alert>
                </td>
              </tr>
            </tbody>
          </table>

          <br />

          <table
            className="col-9"
            style={{
              backgroundColor: "rgb(149, 186, 209, 0.2)",
              borderRadius: "10px",
            }}
          >
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>
                  <h5 style={{ margin: "10px" }}> Post-development Areas</h5>
                  <hr />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td>Slope </td>
                        <td>
                          <input
                            name="postdevSlope"
                            value={this.props.data.osd.postDevAreas.slope}
                            placeholder="Slope"
                            onChange={(e) => this.handleInputChange(e)}
                            style={{ width: "100px" }}
                          ></input>
                          (%)
                        </td>
                        <td style={{ width: "50px" }}></td>
                        <td>Slope of Uncontrolled Areas</td>
                        <td>
                          <input
                            name="postdevUCSlope"
                            value={
                              this.props.data.osd.psd
                                ? ""
                                : this.props.data.osd.postDevAreas.ucSlope
                            }
                            placeholder="Slope"
                            onChange={(e) => this.handleInputChange(e)}
                            style={{ width: "100px" }}
                            disabled={this.props.data.osd.psd}
                          ></input>{" "}
                          (%)
                        </td>
                      </tr>
                      <tr>
                        <td>Length of Flow Path</td>
                        <td>
                          <input
                            name="postdevFlowLength"
                            value={this.props.data.osd.postDevAreas.flowLength}
                            placeholder="Length"
                            onChange={(e) => this.handleInputChange(e)}
                            style={{ width: "100px" }}
                          ></input>
                          (m)
                        </td>
                        <td style={{ width: "50px" }}></td>
                        <td>Length of Flow Path - Uncontrolled</td>
                        <td>
                          <input
                            name="postdevUCFlowLength"
                            value={
                              this.props.data.osd.psd
                                ? ""
                                : this.props.data.osd.postDevAreas.ucFlowLength
                            }
                            placeholder="Length"
                            onChange={(e) => this.handleInputChange(e)}
                            style={{ width: "100px" }}
                            disabled={this.props.data.osd.psd}
                          ></input>{" "}
                          (m)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr style={{ height: "10px" }}></tr>
              <tr>
                {/* for postdev areas */}
                <td style={{ verticalAlign: "top" }}>
                  <table>
                    <thead>
                      <tr></tr>
                      <tr>
                        <th></th>
                        <th>Surface Type</th>
                        <th>Surface Area (m²)</th>
                        <th style={{ width: "30px" }}></th>
                        <th style={{ textAlign: "center" }}>
                          Of which <br /> uncontrolled (m²)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.data.osd.postDevAreas.areas.map((area, i) => {
                        return (
                          <>
                            <tr
                              key={i}
                              style={{
                                borderTop: "1px solid rgb(149, 186, 209)",
                              }}
                            >
                              <td>
                                <input
                                  placeholder={"Post-dev. area " + (i + 1)}
                                  name="areaName"
                                  value={area.areaName}
                                  // disabled={i === 0}
                                  onChange={(e) =>
                                    this.handlePostDevInputChange(e, i)
                                  }
                                ></input>
                              </td>

                              <td>
                                <select
                                  placeholder="Select"
                                  name="surfaceType"
                                  // disabled={i === 0}
                                  value={area.surfaceType}
                                  onChange={(e) =>
                                    this.handlePostDevInputChange(e, i)
                                  }
                                  style={{ width: "100px" }}
                                >
                                  {this.state.surfaceTypes.map((type) => (
                                    <option value={type} key={type}>
                                      {type}
                                    </option>
                                  ))}
                                </select>
                                <a data-tip data-for={'post_image' + i}> ❓ </a>
                                <ReactTooltip id={'post_image' + i} place="right">
                                  <span>
                                    <img
                                      alt={require("../img/surfaceType/" + area.surfaceType + ".png").default}
                                      src={require("../img/surfaceType/" + area.surfaceType + ".png").default}
                                      // width="400"
                                      height="300"
                                      className="d-inline-block align-top"
                                    />
                                  </span>
                                </ReactTooltip>
                              </td>
                              <td>
                                <input
                                  placeholder="Area"
                                  name="surfaceArea"
                                  value={area.surfaceArea}
                                  style={{ width: "100px" }}
                                  onChange={(e) =>
                                    this.handlePostDevInputChange(e, i)
                                  }
                                ></input>
                              </td>
                              <td style={{ width: "30px" }}></td>
                              <td>
                                <input
                                  placeholder="Uncontrolled"
                                  name="uncontrolled"
                                  value={
                                    this.props.data.osd.psd
                                      ? ""
                                      : area.uncontrolled
                                  }
                                  style={{ width: "100px" }}
                                  onChange={(e) =>
                                    this.handlePostDevInputChange(e, i)
                                  }
                                  disabled={this.props.data.osd.psd}
                                ></input>
                              </td>
                              <td>
                                <Button
                                  variant="primary"
                                  className="btn btn-sm btn-grad"
                                  disabled={i === 0}
                                  onClick={() =>
                                    this.handlePostDevRemoveClick(i)
                                  }
                                  style={{ marginRight: "2px" }}
                                >
                                  🗑
                                </Button>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={6}>
                                <Alert
                                  variant="danger"
                                  show={area.message !== ""}
                                  style={{
                                    // marginTop: "5px",
                                    padding: "0.2rem 1.25rem",
                                    // marginBottom: "0.3rem",
                                    margin: "5px",
                                  }}
                                >
                                  {area.message}
                                </Alert>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                  <Button
                    variant="primary"
                    className="btn btn-sm btn-grad"
                    onClick={this.handlePostDevAddClick}
                    style={{ margin: "2px 8px" }}
                  >
                    + Add Area
                  </Button>
                  <Alert
                    variant="danger"
                    show={!this.state.postdevImpAreasOK}
                    style={{ margin: "10px" }}
                  >
                    Please increase/decrease Post-Development Impervious Areas
                    (currently {this.state.enteredPostDevImpArea.toFixed(2)}m²)
                    by{" "}
                    {parseFloat(
                      this.props.data.totalImpArea -
                      this.state.enteredPostDevImpArea
                    ).toFixed(2)}
                    m² to match the Impervious Area (
                    {this.props.data.totalImpArea}
                    m²) in Water Quality.
                  </Alert>
                  <Alert
                    variant="danger"
                    show={!this.state.postdevAreasOK}
                    style={{ margin: "10px" }}
                  >
                    Please increase/decrease Post-Development Pervious Areas
                    (currently {this.state.enteredPostDevPArea.toFixed(2)}m²) by{" "}
                    {parseFloat(
                      this.props.data.totalPArea -
                      this.state.enteredPostDevPArea
                    ).toFixed(2)}
                    m² to match the Pervious Area ({this.props.data.totalPArea}
                    m²) in Water Quality.
                  </Alert>
                </td>
              </tr>
              <tr>
                <td>
                  <Alert
                    variant="warning"
                    show={this.state.postdevMissing}
                    style={{ marginTop: "5px" }}
                  >
                    ⚠️ Please enter missing data in the fields above.
                  </Alert>
                </td>
              </tr>
            </tbody>
          </table>
          {/* </div> */}
          <div>
            <br />
            Increase OSD storage volume for Climate Change variation by:
            <input
              name="climateAdjustment"
              className="input_area"
              value={this.state.climateDisplay}
              onChange={(e) => this.handleClimateChange(e)}
            />
            % from calculated results.
            <span
              data-tip="Default value as per Council rules. Please consult Council Engineer if a lower value is desired.<br/> 0% indicates no climate change adjustment."
              data-multiline="true"
              data-place="bottom"
            >
              ❓
            </span>
            <ReactTooltip />
          </div>

          <Alert variant="danger" show={this.state.alert1}>
            ❗ Sum of Pre-dev Areas must equal the sum of Post-dev Areas.
          </Alert>

          <Alert variant="danger" show={this.state.q_pre_alert}>
            {this.state.q_pre_message}
          </Alert>

          {this.state.osdResultLoading ? (
            <Button
              variant="primary"
              className="btn btn-sm btn-grad"
              style={{ marginTop: "20px" }}
              disabled
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="sr-only">Loading...</span>
            </Button>
          ) : (
            <Button
              variant="primary"
              className="btn btn-sm btn-grad"
              style={{ marginTop: "20px" }}
              disabled={!this.state.osdInputsValid}
              onClick={(e) => this.computeOSDResults()}
            >
              CALCULATE OSD
            </Button>
          )}

          {this.state.osd_table ? (
            <OsdResultsTable
              setList={this.props.setList}
              setState={this.props.setState}
              companyDetails={this.props.companyDetails}
              data={this.props.data}
              osdInputsValid={this.state.osdInputsValid}
              psd={this.state.psd}
              setOSD={this.props.setOSD}
              climateAdjustment={this.props.data.osd.climateAdjustment}
            />
          ) : null}
        </div>
      );
    }
  }
}

export default Rational;
