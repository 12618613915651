import React from "react";

function TitleLine() {
    return (
        <>
           <div className="item item-1">
              <h4>
                Welcome to STORM Calculator
                <b>
                  <a
                    href="https://www.stormwatervictoria.com.au/awards"
                    style={{
                      color: "#FF0000",
                      float: "right",
                      fontSize: "90%",
                    }}
                  >
                    <i className="bi bi-award" />
                    <i>WINNER of SIA VIC 2022 Research and Innovation Award</i>
                    <i className="bi bi-award" />
                  </a>
                  <br />
                  <a
                    href="https://www.linkedin.com/feed/update/urn:li:activity:6875896841125396480"
                    style={{
                      color: "#FF0000",
                      float: "right",
                      fontSize: "90%",
                    }}
                  >
                    <i className="bi bi-award" />
                    <i>WINNER of SIA NSW 2021 Research and Innovation Award</i>
                    <i className="bi bi-award" />
                  </a>
                </b>
              </h4>
            </div>
        </>
    );
}

export default TitleLine;
