import React from "react";

function Brochure() {
    return (
        <>
            <p>
                80 Councils across Australia accept our reports and also
                STORMupdated is a deemed to comply tool for TAS Stormwater
                Policy Guidance and Standards for Development 2021
                <br />
                <a href="Brochure.pdf" download>
                    Download the Brochure here
                </a>
            </p>
        </>
    );
}

export default Brochure;
