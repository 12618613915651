import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/results.css";
import { Col, Row, Container, Button } from "react-bootstrap";
import { printLog } from "./common";
// import Pdf from "./Pdf";

const ref = React.createRef();

class Result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tnRate: 0,
      tnReduction: 0,
      tpRate: 0,
      tpReduction: 0,
      tssRate: 0,
      tssReduction: 0,
      fr: 0,
    };
  }

  async componentDidMount() {
    if (this.props.data.hasWQ) {
      var data = [];
      if (this.props.data.pervious) {
        data = this.props.data.imperviousNodes.concat(
          this.props.data.perviousNodes
        );
      } else {
        data = this.props.data.imperviousNodes;
      }
      await this.calculate(data);
    }
  }

  async calculate(data) {
    let sumSourceTN = 0;
    let sumResidualTN = 0;
    let sumSourceTP = 0;
    let sumResidualTP = 0;
    let sumSourceTSS = 0;
    let sumResidualTSS = 0;
    let sumSourceFR = 0;
    let sumResidualFR = 0;
    for (const treatment of data) {
      sumSourceTN += treatment.results.sourceTN;
      sumSourceTP += treatment.results.sourceTP;
      sumSourceTSS += treatment.results.sourceTSS;
      sumSourceFR += treatment.results.sourceFlow;
      sumResidualTN += treatment.results.residualTN;
      sumResidualTP += treatment.results.residualTP;
      sumResidualTSS += treatment.results.residualTSS;
      sumResidualFR += treatment.results.residualFlow;
    }

    // let reduction = 0;
    if (sumSourceTN !== 0) {
      // reduction = (1-(sumResidualTN/sumSourceTN)) * 100
      await this.setState({
        tnReduction: ((1 - sumResidualTN / sumSourceTN) * 100).toFixed(1),
      });
      this.setState({
        tnRate: (
          (this.state.tnReduction / this.props.targetReductions.tn) *
          100
        ).toFixed(1),
      });
    } else {
      this.setState({ tnRate: 0 });
    }

    if (sumSourceTP !== 0) {
      // reduction = (1-(sumResidualTP/sumSourceTP)) * 100
      await this.setState({
        tpReduction: ((1 - sumResidualTP / sumSourceTP) * 100).toFixed(1),
      });
      this.setState({
        tpRate: (
          (this.state.tpReduction / this.props.targetReductions.tp) *
          100
        ).toFixed(1),
      });
    } else {
      this.setState({ tpRate: 0 });
    }

    if (sumSourceTSS !== 0) {
      // reduction = (1-(sumResidualTSS/sumSourceTSS)) * 100
      await this.setState({
        tssReduction: ((1 - sumResidualTSS / sumSourceTSS) * 100).toFixed(1),
      });
      this.setState({
        tssRate: (
          (this.state.tssReduction / this.props.targetReductions.tss) *
          100
        ).toFixed(1),
      });
    } else {
      this.setState({ tssRate: 0 });
    }

    if (sumSourceFR !== 0) {
      await this.setState({
        fr: ((1 - sumResidualFR / sumSourceFR) * 100).toFixed(1),
      });
    } else {
      this.setState({ fr: 0 });
    }

    this.setState({
      reductionsTableData: [
        {
          heading: "Achieved Reductions",
          tn: this.state.tnReduction,
          tp: this.state.tpReduction,
          tss: this.state.tssReduction,
          fr: this.state.fr,
        },
        {
          heading: "Target Reductions",
          tn: this.props.targetReductions.tn,
          tp: this.props.targetReductions.tp,
          tss: this.props.targetReductions.tss,
          fr: "N/A",
        },
      ],
    });
  }

  // async downloadPDF() {
  //   const MyDoc = await pdf(
  //     <Pdf
  //       data={this.props.data}
  //       overallRatings={this.state}
  //       reductionsTableData={this.state.reductionsTableData}
  //     />
  //   ).toBlob();

  //   this.props.sails_api
  //     .post("/saveReport", { metadata: this.props, overallRatings: this.state })
  //     .then((res) => printLog("Report: " + res.data));

  //   let url = window.URL.createObjectURL(MyDoc);
  //   let a = document.createElement("a");
  //   a.href = url;
  //   a.download = "Storm_Report.pdf";
  //   a.click();
  // }

  async downloadMSF() {
    this.props.sails_api.post("/getMSF", this.props.data).then((res) => {
      const blob = new Blob([res.data], { type: "text/plain;" });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = "Model.msf";
      a.click();
    });
  }

  async downloadMLB() {
    let mlbDownloadName = this.props.data.rainfallStation + ".mlb";
    var mlburl = null;
    await this.props.sails_api
      .post("/downloadMLB", this.props.data)
      .then((res) => {
        mlburl = res.data.mlbUrl;
      });

    if (mlburl) {
      var element = document.createElement("a");
      element.setAttribute("href", mlburl);
      element.setAttribute("download", mlbDownloadName);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }

  render() {
    return (
      <>
        <Container ref={ref}>
          {this.props.data.hasWQ ? (
            <div className="stormRatingMainWQ">
              <h4>Water Quality</h4>
              <Row>
                <Col>
                  <div className="stormRating">
                    <h5>
                      Storm Rating (TN): &nbsp;
                      {this.state.tnRate !== "" ? this.state.tnRate : 0}%
                    </h5>
                    <h5>
                      Storm Rating (TP): &nbsp;
                      {this.state.tpRate !== "" ? this.state.tpRate : 0}%
                    </h5>
                    <h5>
                      Storm Rating (TSS): &nbsp;
                      {this.state.tssRate !== "" ? this.state.tssRate : 0}%
                    </h5>
                    {this.state.tnRate >= 100 ? (
                      <p>Required Water Quality objectives achieved</p>
                    ) : (
                      <p>
                        {" "}
                        An additional {(100 - this.state.tnRate).toFixed(1)}% of
                        treatment is required to achieve Water Quality
                        objectives{" "}
                      </p>
                    )}
                  </div>
                </Col>
                <Col>
                  <div className="exprotPDF">
                    <Button
                      variant="success"
                      className="btn btn-sm btn-result"
                      style={{ width: "240px" }}
                      // onClick={this.downloadPDF.bind(this)}
                    >
                      📃 Export Result to PDF
                    </Button>
                    <Button
                      variant="info"
                      className="btn btn-sm btn-result"
                      style={{ width: "240px" }}
                      onClick={this.downloadMSF.bind(this)}
                    >
                      📥 Download MUSIC Model
                    </Button>
                    <Button
                      variant="secondary"
                      className="btn btn-sm btn-result"
                      style={{ width: "240px" }}
                      onClick={this.downloadMLB.bind(this)}
                    >
                      📥 Download Meteo Template
                    </Button>
                  </div>
                </Col>
              </Row>

              {/* For Impervious Nodes */}
              <Row>
                <Col>
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr className="table-info">
                        <th className="text-center align-middle">
                          Impervious Area Name
                        </th>
                        <th className="text-center align-middle">
                          Impervious Area Type
                        </th>
                        <th className="text-center align-middle">
                          Impervious Area (m²)
                        </th>
                        <th className="text-center align-middle">
                          Treatment Type
                        </th>
                        <th className="text-center align-middle">
                          Treatment Size <br />
                          (m²/L/items)
                        </th>
                        <th className="text-center align-middle">
                          Number of{" "}
                          {this.props.data.developmentType.includes(
                            "Residential"
                          )
                            ? "Bedrooms"
                            : "Occupants"}
                        </th>
                        {/* <th className='text-center align-middle'>Storm Rating <br/>(%)</th> */}
                      </tr>
                    </thead>
                    <tbody align="center">
                      {this.props.data.imperviousNodes.map((res, i) => (
                        <tr key={i}>
                          <td>{res.impAreaName}</td>
                          <td>{res.impAreaType}</td>
                          <td>{res.impAreaSize}</td>
                          <td>{res.impAreaTreatType}</td>
                          <td>
                            {res.impAreaTreatSize === ""
                              ? "N/A"
                              : res.impAreaTreatSize}
                          </td>
                          <td>
                            {res.impAreaBrooms === ""
                              ? "N/A"
                              : res.impAreaBrooms}
                          </td>
                          {/* <td>{res.results.stormRatingPercTN}</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Col>
              </Row>

              {/* Fpr pervious nodes */}
              {this.props.data.pervious === true ? (
                <Row>
                  <Col>
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="table-info">
                          <th className="text-center align-middle">
                            Pervious Area Name
                          </th>
                          <th className="text-center align-middle">
                            Pervious Area Type
                          </th>
                          <th className="text-center align-middle">
                            Pervious Area (m²)
                          </th>
                          <th className="text-center align-middle">
                            Treatment Type
                          </th>
                          <th className="text-center align-middle">
                            Treatment Size <br />
                            (m²/L)
                          </th>
                          {/* <th className='text-center align-middle'>Storm Rating <br/>(%)</th> */}
                        </tr>
                      </thead>
                      <tbody align="center">
                        {this.props.data.perviousNodes.map((res, i) => (
                          <tr key={i}>
                            <td>{res.pAreaName}</td>
                            <td>{res.pAreaType}</td>
                            <td>{res.pAreaSize}</td>
                            <td>{res.pAreaTreatType}</td>
                            <td>
                              {res.pAreaTreatSize === ""
                                ? "N/A"
                                : res.pAreaTreatSize}
                            </td>
                            {/* <td>{res.results.stormRatingPercTN}</td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              ) : null}
            </div>
          ) : null}

          {this.props.data.hasOsd ? (
            <div className="stormRatingMainOSD">
              <h4>On-site Detention</h4>
              <Row>
                <Col>
                  <div className="stormRating">
                    <h5>
                      Permissible Discharge: &nbsp;{" "}
                      {parseFloat(this.props.data.osdResult.PSD).toFixed(2)} L/s
                    </h5>
                    <h5>
                      On-site Detention Vol.: &nbsp;{" "}
                      {parseFloat(
                        this.props.data.osdResult.onSiteDetentionVolume
                      ).toFixed(2)}{" "}
                      m³
                    </h5>
                  </div>
                </Col>
                {!this.props.data.hasWQ ? (
                  <Col>
                    <div className="exprotPDF">
                      <Button
                        variant="success"
                        className="btn btn-sm btn-result"
                        style={{ width: "240px" }}
                        // onClick={this.downloadPDF.bind(this)}
                      >
                        📃 Export Result to PDF
                      </Button>
                      {/* <Button variant="info" className="btn btn-sm btn-result" style={{width: "240px"}} onClick={this.downloadMSF.bind(this)}>📥 Download MUSIC Model</Button> */}
                      {/* <Button variant="secondary" className="btn btn-sm btn-result" style={{width: "240px"}} onClick={this.downloadMLB.bind(this)}>📥 Download Meteo Template</Button> */}
                    </div>
                  </Col>
                ) : null}
              </Row>
            </div>
          ) : null}
        </Container>
      </>
    );
  }
}

export default Result;
