import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Alert, Dropdown, DropdownButton } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import * as _ from "lodash";
import { mapListToDic } from "./common";
import "./assets/css/perviousSection.css";

class PostDevPerviousNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMessage: "",
      showAlert: false,
      alertVariant: "",
      TreatmentTypesList: [],
    };
    if (props.postDevPerviousNodes.length > 0) {
      let l = props.postDevPerviousNodes[0].treatmentTypes.map((node) => {
        return node.tnodename;
      });
      props.postDevPerviousNodes[0].treatmentTypesList = mapListToDic(l);
    }

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  async componentDidMount() {}

  async componentDidUpdate(prevProps) {
    // when catchment type is changed
    // change all treatment types to None and treatment areas to ""
    if (prevProps.catchmentType !== this.props.catchmentType) {
      const list = this.props.postDevPerviousNodes;
      var perviousAreaTypes = [];

      // find the new source nodes for the new catchment type
      await this.props.sails_api
        .get(
          "perviousAreaTypes/" +
            this.props.rainfallStation +
            "/" +
            this.props.catchmentType
        )
        .then((res) => {
          perviousAreaTypes = res.data;
        })
        .catch((error) => {
          perviousAreaTypes = undefined;
        });

      list.forEach(async (value, index) => {
        await this.handleInputChange(
          {
            target: {
              name: "pAreaType",
              value: perviousAreaTypes[0].snodetype,
            },
          },
          index
        );
        await this.handleInputChange(
          {
            target: {
              name: "pAreaTreatType",
              value: "None",
            },
          },
          index
        );
        await this.handleInputChange(
          {
            target: {
              name: "pAreaTreatSize",
              value: "",
            },
          },
          index
        );
      });
      this.props.calculateOverallResults();
    }
  }

  // handle input change
  async handleInputChange(e, index) {
    var { name, value } = e.target;
    var list = [...this.props.postDevPerviousNodes];

    if (name === "pAreaType") {
      await this.props.sails_api
        .get(
          "/perviousAreaTreatType/" +
            this.props.rainfallStation +
            "/" +
            this.props.catchmentType +
            "/" +
            value
        )
        .then((res) => {
          list[index]["treatmentTypes"] = res.data;
          let l = list[index].treatmentTypes.map((node) => {
            return node.tnodename;
          });
          this.props.postDevPerviousNodes[index].treatmentTypesList =
            mapListToDic(l);
        }); // Loading the treatment types from DB
      list[index]["pAreaTreatType"] = "None"; // reset treatment type when source area type changes
      // this.setState({showAlert: false})
      list[index]["showAlert"] = false;
    }

    if (name === "pAreaSize") {
      // To have only 2 digits
      if (value !== "") {
        var pattern = /^\d+(?:\.\d{0,2})?$/i;
        if (!pattern.test(value)) {
          return;
        }
      }
      // reset osd pre dev & uncontrolled areas fields when site area changes.
      if (this.props.hasOsd) {
        this.props.osd.preDevPArea = "";
        this.props.osd.preDevImpArea = "";
        this.props.osd.postDevPArea = "";
        this.props.osd.postDevImpArea = "";
        this.props.osd.uncontrolledImpArea = "";
        this.props.osd.uncontrolledPArea = "";
      }

      list[index]["pAreaSize"] = value;
      list[index]["pAreaTreatSize"] = "";
    }

    if (name === "pAreaTreatType") {
      // To show or hide occupants/bedrooms depending on the treatment type
      list[index]["showAlert"] = false;
      if (value === "None") {
        list[index]["pAreaTreatSize"] = "";
        list[index]["placeholderUnit"] = "";
      } else {
        list[index]["placeholderUnit"] = "Size (m² / L)";
        await this.props.sails_api
          .get(
            "/maxRatioPervious/" +
              this.props.rainfallStation +
              "/" +
              this.props.catchmentType +
              "/" +
              list[index]["pAreaType"] +
              "/" +
              value
          )
          .then((res) => {
            list[index]["boundsValueOrRatio"] = res.data.maxRatio;
          }); // for the bounds for RWT and Bio-Retention
        list[index]["pAreaTreatSize"] = "";
      }
      list[index]["pAreaTreatType"] = value;
    }

    if (name === "pAreaTreatSize") {
      // To have only 2 digits
      if (value !== "") {
        var pattern = /^\d+(?:\.\d{0,2})?$/i;
        if (!pattern.test(value)) {
          return;
        }
      }

      if (
        parseFloat(value) <= parseFloat(list[index]["pAreaSize"]) ||
        value === ""
      ) {
        list[index]["pAreaTreatSize"] = value;
        list[index]["showAlert"] = false;
      } else {
        list[index]["showAlert"] = true;
        list[index]["alertMessage"] =
          "⛔ Treatment size cannot be greater than the pervious area size — (" +
          list[index]["pAreaSize"] +
          "m²)";
        list[index]["alertVariant"] = "danger";
      }
      if (
        parseFloat(value) / parseFloat(list[index]["pAreaSize"]) >
        list[index]["boundsValueOrRatio"]
      ) {
        list[index]["showAlert"] = true;
        list[index]["alertMessage"] =
          "⚠️ Treatment size greater than " +
          (
            parseFloat(list[index]["pAreaSize"]) *
            list[index]["boundsValueOrRatio"]
          )
            .toFixed(3)
            .replace(/\.?0+$/, "") +
          "m² will not result in significant additional treatment.";
        list[index]["alertVariant"] = "warning";
      }
    }

    //💥💥💥💥💥💥💥💥💥💥Insta Result💥💥💥💥💥💥💥💥💥

    list = await this.computeLiveResults(list, index);

    list[index][name] = value;
    // this.props.setPerviousNodes(list);
    this.props.setVariable("postDevPerviousNodes", list);
    this.props.calculateOverallResults();

    if (this.props.wqMethod.includes("NorBE")) {
      this.props.osd.postDevPArea = parseFloat(this.props.totalPostDevPArea);
      this.props.osd.postDevImpArea = parseFloat(
        this.props.totalPostDevImpArea
      );
      this.props.osd.preDevPArea = parseFloat(this.props.totalPArea);
      this.props.osd.preDevImpArea = parseFloat(this.props.totalImpArea);
    }
  }

  async computeLiveResults(list, index) {
    if (list[index]["pAreaType"] !== "" && list[index]["pAreaSize"] !== "") {
      // calling results only when treatsize is available if not none
      var bassRoot = list[index]["apiBaseRoute"];
      var toipe = list[index]["pAreaTreatType"];
      var atoipe = list[index]["pAreaType"];
      var tsoize = list[index]["pAreaTreatSize"];

      if (
        list[index]["pAreaTreatType"] === "None" ||
        list[index]["pAreaTreatType"] === ""
      ) {
        toipe = "None";
        bassRoot = "NoPerviousTreatmentResults";
        tsoize = "";
      } else {
        // Since we only have Bioretentions for now. Later, we may need an API to decide the apiBaseRoute
        bassRoot = "PerviousBioretentionResults";
      }
      if (
        list[index]["pAreaTreatSize"] === "0" ||
        list[index]["pAreaTreatSize"] === ""
      ) {
        toipe = "None";
        bassRoot = "NoPerviousTreatmentResults";
        tsoize = "";
      } else {
        // Since we only have Bioretentions for now. Later, we may need an API to decide the apiBaseRoute
        bassRoot = "PerviousBioretentionResults";
      }

      if (bassRoot !== "NoPerviousTreatmentResults" && tsoize !== "") {
        await this.props.sails_api
          .get(
            bassRoot +
              "/" +
              this.props.state +
              "/" +
              this.props.cityCouncil +
              "/" +
              this.props.rainfallStation +
              "/" +
              this.props.catchmentType +
              "/" +
              atoipe +
              "/" +
              list[index]["pAreaSize"] +
              "/" +
              toipe +
              "/" +
              tsoize +
              "/"
          )
          .then((response) => {
            if (this.props.wqMethod === "Standard") {
              list[index]["tn"] = response.data.stormRatingPercTN.toFixed(1);
              list[index]["tp"] = response.data.stormRatingPercTP.toFixed(1);
              list[index]["tss"] = response.data.stormRatingPercTSS.toFixed(1);
              list[index]["gp"] = response.data.stormRatingPercGP.toFixed(1);
              list[index]["fr"] = response.data.reductionPercFlow.toFixed(1);
              list[index]["results"] = response.data;
            } else if (this.props.wqMethod.includes("NorBE")) {
              list[index]["tn"] = response.data.residualTN.toFixed(3);
              list[index]["tp"] = response.data.residualTP.toFixed(3);
              list[index]["tss"] = response.data.residualTSS.toFixed(3);
              list[index]["gp"] = response.data.residualGP.toFixed(3);
              list[index]["fr"] = response.data.residualFlow.toFixed(3);
              list[index]["results"] = response.data;
            }
          })
          .catch((err) => {
            console.log(err);
            list[index]["tn"] = "M/A";
            list[index]["tp"] = "N/A";
            list[index]["gp"] = "N/A";
            list[index]["tss"] = "N/A";
            list[index]["fr"] = "N/A";
            list[index]["results"] = undefined;
          });
      } else {
        await this.props.sails_api
          .get(
            bassRoot +
              "/" +
              this.props.state +
              "/" +
              this.props.cityCouncil +
              "/" +
              this.props.rainfallStation +
              "/" +
              this.props.catchmentType +
              "/" +
              atoipe +
              "/" +
              list[index]["pAreaSize"] +
              "/"
          )
          .then((response) => {
            if (this.props.wqMethod === "Standard") {
              list[index]["tn"] = response.data.stormRatingPercTN.toFixed(1);
              list[index]["tp"] = response.data.stormRatingPercTP.toFixed(1);
              list[index]["tss"] = response.data.stormRatingPercTSS.toFixed(1);
              list[index]["gp"] = response.data.stormRatingPercGP.toFixed(1);
              list[index]["fr"] = response.data.reductionPercFlow.toFixed(1);
              list[index]["results"] = response.data;
            } else if (this.props.wqMethod.includes("NorBE")) {
              list[index]["tn"] = response.data.residualTN.toFixed(3);
              list[index]["tp"] = response.data.residualTP.toFixed(3);
              list[index]["tss"] = response.data.residualTSS.toFixed(3);
              list[index]["gp"] = response.data.residualGP.toFixed(3);
              list[index]["fr"] = response.data.residualFlow.toFixed(3);
              list[index]["results"] = response.data;
            }
          })
          .catch((err) => {
            list[index]["tn"] = "N/A";
            list[index]["tp"] = "N/A";
            list[index]["gp"] = "N/A";
            list[index]["tss"] = "N/A";
            list[index]["fr"] = "N/A";
            list[index]["results"] = undefined;
          });
      }
    }

    return list;
  }

  // handle click event of the Remove button
  handleRemoveClick = (index) => {
    const nodes = this.props.postDevPerviousNodes;
    _.pullAt(nodes, index); // See imports above.
    // this.props.setPerviousNodes(nodes);
    this.props.setVariable("postDevPerviousNodes", nodes);
    this.props.calculateOverallResults();
  };

  // handle click event of the Add button
  handleAddClick = async (data) => {
    //Fetch for  pAreaType and pAreaTreatTypes and pass it in the setter below.

    var pAreaType = data.perviousAreaTypes[0].snodetype;
    var pAreaTreatmentTypes = [];
    var pAreaTreatType = "";

    await data.sails_api
      .get(
        "/perviousAreaTreatType/" +
          data.rainfallStation +
          "/" +
          data.catchmentType +
          "/" +
          pAreaType
      )
      .then((res) => {
        pAreaTreatmentTypes = res.data;
        pAreaTreatType = res.data[0].tnodename;
      });

    data.setVariable("postDevPerviousNodes", [
      ...data.postDevPerviousNodes,
      {
        pAreaName: "",
        pAreaType: pAreaType,
        pAreaSize: "",
        pAreaTreatType: pAreaTreatType,
        pAreaTreatSize: "",
        minSourceArea: NaN,
        maxSourceArea: NaN,
        treatmentTypes: pAreaTreatmentTypes,
        apiBaseRoute: "",
        placeholderUnit: "Size (m² / L)",
        alertMessage: "",
        showAlert: false,
        alertVariant: "",
        tn: "N/A",
        tss: "N/A",
        tp: "N/A",
        gp: "N/A",
        fr: "N/A",
        results: undefined,
      },
    ]);

    let l = pAreaTreatmentTypes.map((node) => {
      return node.tnodename;
    });
    console.log(l);
    let n = this.props.postDevPerviousNodes.length;
    this.props.postDevPerviousNodes[n - 1].treatmentTypesList = mapListToDic(l);
    this.props.postDevPerviousNodes[n - 1].isLoading = false;
  };

  render() {
    return (
      <div className="panNode_perviousPostDev">
        {this.props.noData ? (
          <div className="checkboxPad">
            <label className="checkboxLabel_Red">
              {" "}
              We're sorry, there is no pervious area data available for this
              location.
            </label>
            <label className="checkboxLabel_Red">
              {" "}
              Please contact Mircea at mircea@cleanstormwater.com.au for
              details.
            </label>
          </div>
        ) : null}

        <h5>Pervious Areas</h5>
        <table>
          <tbody>
            <tr>
              <td>
                <table>
                  <thead>
                    <tr>
                      <th className="p_node_name_header">
                        <p className="header_margin_Pervious">
                          {" "}
                          Pervious Area <br /> Name{" "}
                        </p>
                      </th>
                      <th className="p_node_type_header">
                        <p className="header_margin_Pervious">
                          {" "}
                          Pervious Area Type{" "}
                        </p>
                      </th>
                      <th className="p_node_area_header">
                        <p className="header_margin_Pervious">
                          {" "}
                          Pervious <br /> Area (m²){" "}
                        </p>
                      </th>
                      <th className="p_node_type_header">
                        <p className="header_margin_Pervious">
                          {" "}
                          Treatment Type{" "}
                        </p>
                      </th>
                      <th>
                        <p className="header_margin_Pervious">
                          {" "}
                          Treatment <br /> Size (m²){" "}
                        </p>
                      </th>
                    </tr>
                  </thead>
                </table>
              </td>
            </tr>
            {this.props.postDevPerviousNodes.map((x, i) => {
              return (
                <tr key={i}>
                  <td>
                    <table>
                      <tbody className="table_line_pervious">
                        <tr>
                          <td>
                            <input
                              name="pAreaName"
                              value={x.pAreaName}
                              onChange={(e) => this.handleInputChange(e, i)}
                              placeholder={"Pervious Area " + (i + 1)}
                              className="select_area_pervious"
                              required={this.props.hasPervious}
                              disabled={
                                !this.props.hasPervious || this.props.noData
                              }
                            />{" "}
                          </td>

                          <td>
                            <select
                              name="pAreaType"
                              value={x.pAreaType}
                              onChange={(e) => this.handleInputChange(e, i)}
                              className="select_area"
                              required={this.props.hasPervious}
                              disabled={
                                !this.props.hasPervious ||
                                this.props.noData ||
                                this.props.perviousAreaTypes.length === 1
                              }
                            >
                              {this.props.perviousAreaTypes.map((type) => (
                                <option
                                  value={type.snodetype}
                                  key={type.snodetype}
                                >
                                  {type.snodetype}
                                </option>
                              ))}
                            </select>
                          </td>

                          <td>
                            <input
                              name="pAreaSize"
                              value={x.pAreaSize}
                              onChange={(e) => this.handleInputChange(e, i)}
                              className="input_area"
                              placeholder="Area (m²)"
                              disabled={
                                this.props.postDevPerviousNodes[i].pAreaType ===
                                  "" ||
                                !this.props.hasPervious ||
                                this.props.noData
                              }
                              required={this.props.hasPervious}
                              step="any"
                            />{" "}
                          </td>
                          <td>
                            <DropdownButton
                              id="bootstrap-dropdown"
                              variant="light"
                              drop="down"
                              title={x.pAreaTreatType}
                              disabled={
                                this.props.postDevPerviousNodes[i].pAreaSize ===
                                  "" ||
                                this.props.postDevPerviousNodes[i].pAreaType ===
                                  "" ||
                                !this.props.hasPervious ||
                                this.props.noData
                              }
                            >
                              {typeof this.props.postDevPerviousNodes[i]
                                .treatmentTypesList !== "undefined" &&
                                Object.keys(
                                  this.props.postDevPerviousNodes[i]
                                    .treatmentTypesList
                                ).map((key) => {
                                  let options =
                                    this.props.postDevPerviousNodes[i]
                                      .treatmentTypesList[key];
                                  if (options.length > 0) {
                                    return (
                                      <DropdownButton
                                        id="bootstrap-dropdown"
                                        drop="down"
                                        variant="light"
                                        title={key}
                                      >
                                        {options.map((value) => {
                                          return (
                                            <Dropdown.Item
                                              variant="light"
                                              id="bootstrap-dropdown"
                                              onClick={() =>
                                                this.handleInputChange(
                                                  {
                                                    target: {
                                                      name: "pAreaTreatType",
                                                      value: key + value,
                                                    },
                                                  },
                                                  i
                                                )
                                              }
                                            >
                                              {value}
                                            </Dropdown.Item>
                                          );
                                        })}
                                      </DropdownButton>
                                    );
                                  }

                                  return (
                                    <Dropdown.Item
                                      variant="light"
                                      id="bootstrap-dropdown"
                                      onClick={() =>
                                        this.handleInputChange(
                                          {
                                            target: {
                                              name: "pAreaTreatType",
                                              value: key,
                                            },
                                          },
                                          i
                                        )
                                      }
                                    >
                                      {key}
                                    </Dropdown.Item>
                                  );
                                })}
                            </DropdownButton>
                          </td>
                          {/* <td>
                            <select
                              name="pAreaTreatType"
                              value={x.pAreaTreatType}
                              onChange={(e) => this.handleInputChange(e, i)}
                              disabled={
                                this.props.postDevPerviousNodes[i].pAreaSize === "" ||
                                this.props.postDevPerviousNodes[i].pAreaType === "" ||
                                !this.props.hasPervious ||
                                this.props.noData
                              }
                              className="select_area"
                            >
                              {this.props.postDevPerviousNodes[i].treatmentTypes.map(
                                (type) => (
                                  <option
                                    value={type.tnodename}
                                    key={type.tnodename}
                                  >
                                    {type.tnodename}
                                  </option>
                                )
                              )}
                            </select>
                          </td> */}

                          <td>
                            <input
                              name="pAreaTreatSize"
                              value={x.pAreaTreatSize}
                              onChange={(e) => this.handleInputChange(e, i)}
                              className="input_area"
                              placeholder={
                                this.props.postDevPerviousNodes[i]
                                  .placeholderUnit
                              }
                              disabled={
                                this.props.postDevPerviousNodes[i]
                                  .pAreaTreatType === "" ||
                                this.props.postDevPerviousNodes[i]
                                  .pAreaTreatType === "None" ||
                                this.props.postDevPerviousNodes[i].pAreaSize ===
                                  "" ||
                                !this.props.hasPervious ||
                                this.props.noData
                              }
                              required
                              step="any"
                            />{" "}
                          </td>

                          <td>
                            {this.props.postDevPerviousNodes.length !== 1 && (
                              <Button
                                variant="primary"
                                className="btn btn-sm btn-grad"
                                onClick={() => this.handleRemoveClick(i)}
                              >
                                🗑
                              </Button>
                            )}
                            {this.props.postDevPerviousNodes.length === 1 && (
                              <Button
                                variant="primary"
                                className="btn btn-sm btn-grad"
                                disabled
                                onClick={() => this.handleRemoveClick(i)}
                              >
                                🗑
                              </Button>
                            )}
                          </td>
                        </tr>

                        {this.props.cityCouncil === "" ? null : (
                          <tr>
                            {this.props.wqMethod.includes("NorBE") ? (
                              <>
                                <td colSpan="5">
                                  <table className="table-sm col-sm-12">
                                    <tbody>
                                      <tr>
                                        <td className="col-sm-3">
                                          <p
                                            className="resultSpray_margins"
                                            data-tip="These results correspond to the individual catchment above. <br/>They can be used to fine-tune treatment."
                                            data-multiline="true"
                                            data-place="bottom"
                                          >
                                            {" "}
                                            LIVE Result <br />
                                            (Residuals,kg/year) ❓
                                          </p>
                                          <ReactTooltip />
                                        </td>

                                        <td colSpan="2">
                                          <br />
                                          TN:
                                          <span className="header_margin_Pervious">
                                            {
                                              this.props.postDevPerviousNodes[i]
                                                .tn
                                            }
                                          </span>
                                        </td>

                                        <td colSpan="2">
                                          <br />
                                          TP:
                                          <span className="header_margin_Pervious">
                                            {
                                              this.props.postDevPerviousNodes[i]
                                                .tp
                                            }
                                          </span>
                                        </td>

                                        <td colSpan="2">
                                          <br />
                                          TSS:
                                          <span className="header_margin_Pervious">
                                            {
                                              this.props.postDevPerviousNodes[i]
                                                .tss
                                            }
                                          </span>
                                        </td>

                                        <td colSpan="2">
                                          <br />
                                          GP:
                                          <span className="header_margin_Pervious">
                                            {
                                              this.props.postDevPerviousNodes[i]
                                                .gp
                                            }
                                          </span>
                                        </td>

                                        <td colSpan="2">
                                          Flow <br />
                                          (ML/year):
                                          <span className="header_margin_Pervious">
                                            {
                                              this.props.postDevPerviousNodes[i]
                                                .fr
                                            }
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </>
                            ) : (
                              <>
                                <td colSpan="5">
                                  <table className="table-sm col-sm-12">
                                    <tbody>
                                      <tr>
                                        <td className="p_node_ResultSpray">
                                          <p
                                            className="resultSpray_margins"
                                            data-tip="These results correspond to the individual catchment above. <br/>They can be used to fine-tune treatment."
                                            data-multiline="true"
                                            data-place="bottom"
                                          >
                                            {" "}
                                            LIVE STORM Result ❓
                                          </p>
                                          <ReactTooltip />
                                        </td>

                                        <td>
                                          TN:
                                          {this.props.postDevPerviousNodes[i]
                                            .tn >= 100 ? (
                                            <span
                                              className="header_margin_Pervious"
                                              style={{ color: "green" }}
                                            >
                                              {
                                                this.props.postDevPerviousNodes[
                                                  i
                                                ].tn
                                              }
                                            </span>
                                          ) : (
                                            <span
                                              className="header_margin_Pervious"
                                              style={{ color: "red" }}
                                            >
                                              {
                                                this.props.postDevPerviousNodes[
                                                  i
                                                ].tn
                                              }
                                            </span>
                                          )}
                                        </td>

                                        <td>
                                          TP:
                                          {this.props.postDevPerviousNodes[i]
                                            .tp >= 100 ? (
                                            <span
                                              className="header_margin_Pervious"
                                              style={{ color: "green" }}
                                            >
                                              {
                                                this.props.postDevPerviousNodes[
                                                  i
                                                ].tp
                                              }
                                            </span>
                                          ) : (
                                            <span
                                              className="header_margin_Pervious"
                                              style={{ color: "red" }}
                                            >
                                              {
                                                this.props.postDevPerviousNodes[
                                                  i
                                                ].tp
                                              }
                                            </span>
                                          )}
                                        </td>

                                        <td>
                                          TSS:
                                          {this.props.postDevPerviousNodes[i]
                                            .tss >= 100 ? (
                                            <span
                                              className="header_margin_Pervious"
                                              style={{ color: "green" }}
                                            >
                                              {
                                                this.props.postDevPerviousNodes[
                                                  i
                                                ].tss
                                              }
                                            </span>
                                          ) : (
                                            <span
                                              className="header_margin_Pervious"
                                              style={{ color: "red" }}
                                            >
                                              {
                                                this.props.postDevPerviousNodes[
                                                  i
                                                ].tss
                                              }
                                            </span>
                                          )}
                                        </td>

                                        <td>
                                          GP:
                                          {this.props.postDevPerviousNodes[i]
                                            .gp >= 100 ? (
                                            <span
                                              className="header_margin_Pervious"
                                              style={{ color: "green" }}
                                            >
                                              {
                                                this.props.postDevPerviousNodes[
                                                  i
                                                ].gp
                                              }
                                            </span>
                                          ) : (
                                            <span
                                              className="header_margin_Pervious"
                                              style={{ color: "red" }}
                                            >
                                              {
                                                this.props.postDevPerviousNodes[
                                                  i
                                                ].gp
                                              }
                                            </span>
                                          )}
                                        </td>

                                        {/* <td>
                                    Abs. Flow Reduction:
                                    {this.props.postDevPerviousNodes[i].fr > this.props.targetReductions.fr ? (
                                      <span className="header_margin_Pervious" style={{color:'green'}}>
                                        {this.props.postDevPerviousNodes[i].fr}
                                      </span>
                                    ):
                                    <span className="header_margin_Pervious" style={{color:'red'}}>
                                      {this.props.postDevPerviousNodes[i].fr}
                                    </span>
                                    }
                                  </td> */}

                                        <td>
                                          Flow Reduction:
                                          <span className="header_margin_Pervious">
                                            {
                                              this.props.postDevPerviousNodes[i]
                                                .fr
                                            }
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </>
                            )}
                          </tr>
                        )}

                        <tr>
                          <td colSpan="5">
                            {this.props.postDevPerviousNodes[i].showAlert ? (
                              <Alert
                                className="alert_set_pervious"
                                variant={
                                  this.props.postDevPerviousNodes[i]
                                    .alertVariant
                                }
                                show={
                                  this.props.postDevPerviousNodes[i].showAlert
                                }
                              >
                                {
                                  this.props.postDevPerviousNodes[i]
                                    .alertMessage
                                }
                              </Alert>
                            ) : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              );
            })}
          </tbody>

          <tfoot>
            {/* Below tr for the gap between the rows and the add treatment row button */}
            <tr className="blank_table_row">
              <td colSpan="3"></td>
            </tr>
          </tfoot>
        </table>
        <Button
          id="addPostDevPArea"
          variant="primary"
          className="btn btn-sm btn-grad"
          onClick={(e) => this.handleAddClick(this.props)}
          disabled={!this.props.hasPervious || this.props.noData}
        >
          + Add Pervious Area
        </Button>
      </div>
    );
  }
}

export default PostDevPerviousNode;
