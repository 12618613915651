import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Alert } from "react-bootstrap";
import { printLog } from "../../common";

function StateUploader(props) {
    const onDrop = useCallback((file) => {
        if (!file[0].name.toLowerCase().includes(".sus")) {
            alert("Please upload a SUS file.");
        } else {
            printLog("Reading SUS File");
            //read input file and set file as calc's new state
            var inputstr = ""
            var data = {}
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onload = () => {
                inputstr = reader.result;
                data = JSON.parse(inputstr)
                printLog(data);
                props.loadState(data);
            }
            reader.readAsText(file[0])

        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <>
                    <div style={{padding: '10px', color: "white", backgroundColor: '#F565E2', textAlign: "center", maxHeight: "50px",width:"138px"}}>
                    Upload Project
                    </div>
               
                </>
            ) : (
                <>
                <div style={{ padding: '10px', color: "white", backgroundColor: '#F565E2', textAlign: "center", maxHeight: "50px",width:"138px"}}>
                    Upload Project
                </div>
                </>
            )}
        </div>
    );
}

export default StateUploader;
