import React from "react";
import "./assets/css/map.css";
import "@reach/combobox/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

const Search = (props) => {
  return (
    <div>
      <Combobox onSelect={props.handleSelect} className="comboboxWidth">
        <ComboboxInput
          value={props.value}
          onChange={props.handleInput}
          disabled={!props.ready || props.isLoading}
          placeholder="Search your location"
          className="comboboxWidth"
        />
        <ComboboxPopover>
          <ComboboxList>
            {props.status === "OK" &&
              props.data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
            {/* {props.status === "OK" &&
											props.data.map(({place_id, description }) => (
													<ComboboxOption key = {place_id} value={description} />
											))} */}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
};

export default Search;
