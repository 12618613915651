/** This file contains all the common methods that cann be used by various modules */
import * as constants from "./constants.js";
import React from "react";

/**
 * THis function creates an image component for the image requested
 * @param {String} imageName Name of the image to be fetched from the image folder
 * @returns the image component that was requested by the calling function
 */
export function createImagecomponent(imageName, cssClass) {
  let imageElement = React.createElement(
    "img",
    {
      src: require(constants.IMAGE_PATH + imageName + "").default,
      className: cssClass,
    },
    null
  );
  return imageElement;
}

export function printLog(logText, logVariable = "") {
  if (constants.LOGGER_LEVEL === 2) {
    console.log(logText, logVariable);
  }
}

// group the list by the preceding substring
export function mapListToDic(l) {
  let l2 = l.map((item) => {
    return item.split(" ");
  });

  let ans = {};
  let prefix = "";
  for (let i = 0; i < l2.length; ++i) {
    let item1 = l2[i];

    if (i === l2.length - 1) {
      if (!(prefix && l[i].startsWith(prefix))) {
        ans[l[i]] = [];
      }
      continue;
    }

    let item2 = l2[i + 1];
    let j = 0;
    prefix = "";
    let nameList = [];

    while (item1[j] == item2[j]) {
      prefix += item1[j] + " ";
      ++j;
    }

    if (prefix === "") {
      ans[l[i]] = [];
      continue;
    }

    nameList = l
      .filter((item) => {
        return item.startsWith(prefix);
      })
      .map((item) => {
        let end = item.indexOf(prefix) + prefix.length;
        return item.substring(end);
      });
    ans[prefix] = nameList;
    while (i < l2.length && l[i].includes(prefix)) {
      i++;
    }
    if (i !== l2.length) {
      i -= 1;
    }
  }

  return ans;
}
