import React from "react";
import TheMap from "./TheMap";
import { useLoadScript } from "@react-google-maps/api";
const libraries = ["places"];

const Midware = React.memo(function (props) {
  // const API_KEY = "AIzaSyBwK7XOusBENQ_kdcTyKc0duRBqSfdilvI";
  // const API_KEY = "AIzaSyAPxTUHngz19jCTz-Gf_hUKq96mnsts1UQ"; //old api
  // const API_KEY = "AIzaSyD1I0YbdC7Q6xG5CGmzfkJoV92TPSGmSJo";
  const API_KEY = "AIzaSyBXWqbpWjUJpXbknlMwWDhwN0IEBzRWxCE";

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: libraries,
  });

  if (loadError) return "Error Loading Map";

  return !isLoaded ? (
    "Loading Map"
  ) : (
    <TheMap
      ip={props.ip}
      source={props.source}

      setAddress={props.setAddress}
      auditorInterface={props.auditorInterface}
      setRainfallStation={props.setRainfallStation}
      sails_api={props.sails_api}
      basicInfo={props.basicInfo}

      // For Map CMS
      companyDetails={props.companyDetails}
      councilDetails={props.councilDetails}

      targetReductions={props.targetReductions}

      showMusicAuditor={props.showMusicAuditor}
      setShowMusicAuditor={props.setShowMusicAuditor}
      setDisableAuditButton={props.setDisableAuditButton}
      
      disableWQ={props.disableWQ}
      cityCouncil={props.cityCouncil}
    />
  );
  // return "LOADINGG>>>>>";
});

export default Midware;
