import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/calculator.css";
import "./Home/thehome.css";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import { isAlpha, isInt } from "validator";
import ReactTooltip from "react-tooltip";
import { downloader } from "./assets/js/Download";
import { CatchmentChange } from "./assets/js/CatchmentChange";
import { OsdStateObject } from "./assets/js/OsdStateObject";
import ImperviousNode from "./ImperviousNodes";
import PerviousNode from "./PerviousNodes";
import PostDevImperviousNode from "./PostDevImperviousNodes";
import PostDevPerviousNode from "./PostDevPerviousNodes";
import Osd from "./Osd";
import LocationAccordion from "./LocationAccordion";
import DownloadButtons from "./DownloadButtons";
import OverallResultsTable from "./OverallResultsTable";
import OsdsAds from "./OsdsAds";
import StateUploadDownloadButtons from "./StateUploadDownloadButtons";
import AdsPanel from "./AdsPanel";
import { printLog } from "./common";
import CouncilRuleWarning from "./Calculator/warning_message/CouncilRuleWarning";

const sails_api = axios.create({
  baseURL: window.location.href.includes("localhost")
    ? "https://su-stage.ml/sails"
    : window.location.origin + "/sails",
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCouncilRules: false,
      showOSDsAdsDiv: false,
      deviceType: "",
      companyDetails: [],
      manufacturerImgLink: "",
      OSDMaterial: "",
      OSDCompany: "",
      // Loading data (select options etc.)
      rainfallStations: [], // List of all rainfall stations

      //below two might need an API to ensure maintainability (to add or remove without touching code)
      developmentTypes: [],
      catchmentTypes: ["Mixed", "Split"], // this is hardcoded but get overwritten by the values from the DB

      //To hide the modal initially. Initially null; will change to true or false as needed
      show: null,

      //For enabling and disabling water quality
      hasWQ: false,
      disableWQ: true,
      greyOutCatchmentSelection: false,

      // flag to indicate whether a location on map is selected or not.
      locationSelected: false,

      //Metadata (user provided initial information)
      rainfallStation: "",
      cityCouncil: "",
      siteArea: "",
      postDevSiteArea: "",
      addressMain: "",
      addressSub: "",
      suburb: "",
      zipcode: "",
      state: "",
      assessorName: "",
      assessorEmail: "",
      developmentType: "",
      catchmentType: "Mixed",
      ip: "",
      planningPermitNo: "",

      // Total impervious Area
      totalImpArea: 0,
      totalImpNodes: 0,

      totalPostDevImpArea: 0,
      totalPostDevImpNodes: 0,

      //Dynamic Data for Impervious Nodes component (for the drop downs)
      imperviousAreaTypes: [],

      // To enable and disable checking for pervious. Default assume has pervious
      pervious: true,
      postDevPervious: true,
      noData: false,

      //To enable and disable checking for impervious (NorBE existing site only)
      impervious: true,

      // Total pervious Area
      totalPArea: 0,
      totalPNodes: 0,

      totalPostDevPArea: 0,
      totalPostDevPNodes: 0,

      //Dynamic Data for Impervious Nodes component (for the drop downs)
      perviousAreaTypes: [],
      perviousAreaTreatmentTypes: [],

      totalCatchmentArea: 0,
      totalTreatmentNodes: 0,
      totalNoneNodes: 0,

      totalPostDevCatchmentArea: 0,
      totalPostDevTreatmentNodes: 0,
      totalPostDevNoneNodes: 0,

      // To show or hide the alert and the alert message
      showAlert: true,
      alertMessage:
        "⚠️ Please enter all the required fields above before adding catchments below",
      alertVariant: "warning",

      // To show or hide the alert and the alert message
      showAlert2: false,
      alert2Message:
        "⚠️ Total Site Area should be greater than Total Catchment Area.",
      alert2Variant: "warning",

      // To show or hide the alert and the alert message
      showAlert3: false,
      alert3Message:
        "⚠️ Total Site Area for Existing Site and Developed Site must be equal.",
      alert3Variant: "warning",

      // OSD data
      hasOsd: false,

      //diasbling OSD if method specified for the council is not supported
      osdMethodUnsupported: true,

      aeps: [],
      latitude: "",
      longitude: "",
      osdOK: true, // To check for the danger warning in OSD. Cannot calculate if there is a warning
      osdResult: null,
      osdCalculating: false,
      osdUncontrolledIssue: "",

      targetReductions: { tn: 0, tp: 0, tss: 0, gp: 0 },

      activateButtons: false,
      // spinner when exporting report/model/mlb
      exportingReport: false,
      exportingModel: false,
      exportingMlb: false,

      //message when report or model has been exported
      reportSent: "",

      //initialiising default overallResults values
      overallResults: {
        overallRatings: {
          gpRate: 0,
          tnRate: 0,
          tpRate: 0,
          tssRate: 0,
        },
        overallReductions: {
          gpReduction: 0,
          tnReduction: 0,
          tpReduction: 0,
          tssReduction: 0,
        },
        overallPollutantsPreDev: {
          gpPollutantPreDev: 0,
          tnPollutantPreDev: 0,
          tpPollutantPreDev: 0,
          tssPollutantPreDev: 0,
          flowPollutantPreDev: 0,
        },
        overallPollutantsPostDev: {
          gpPollutantPostDev: 0,
          tnPollutantPostDev: 0,
          tpPollutantPostDev: 0,
          tssPollutantPostDev: 0,
          flowPollutantPostDev: 0,
        },
        eolResults: {
          residualFlow: 0,
          residualGP: 0,
          residualTN: 0,
          residualTP: 0,
          residualTSS: 0,

          sourceFlow: 0,
          sourceGP: 0,
          sourceTN: 0,
          sourceTP: 0,
          sourceTSS: 0,
        },
      },
      dorriesResults: {
        alerts: [],
        detailed_wsud_table: {
          dev_impact: {
            fr: {
              color: "",
              value: "",
            },
            tn: {
              color: "",
              value: "",
            },
            tp: {
              color: "",
              value: "",
            },
            tss: {
              color: "",
              value: "",
            },
            gp: {
              color: "",
              value: "",
            },
          },
          developedResiduals: {
            fr: {
              color: "",
              value: "",
            },
            tn: {
              color: "",
              value: "",
            },
            tp: {
              color: "",
              value: "",
            },
            tss: {
              color: "",
              value: "",
            },
            gp: {
              color: "",
              value: "",
            },
          },
          achievedReductions: {
            flow: {
              color: "",
              value: "",
            },
            tn: {
              color: "",
              value: "",
            },
            tp: {
              color: "",
              value: "",
            },
            tss: {
              color: "",
              value: "",
            },
            gp: {
              color: "",
              value: "",
            },
          },
          developedSources: {
            flow: "",
            tn: "",
            tp: "",
            tss: "",
            gp: "",
          },
          existingResiduals: {
            flow: "",
            tn: "",
            tp: "",
            tss: "",
            gp: "",
          },
          targetReductions: {
            fr: "",
            tn: "",
            tp: "",
            tss: "",
            gp: "",
          },
        },
        targetReductionsTable: {
          fr: {
            color: "",
            value: "",
            status: "",
          },
          tn: {
            color: "",
            value: "",
            status: "",
          },
          tp: {
            color: "",
            value: "",
            status: "",
          },
          tss: {
            color: "",
            value: "",
            status: "",
          },
          gp: {
            color: "",
            value: "",
            status: "",
          },
        },
      },
      wqMethod: "",

      imperviousNodes: [],
      perviousNodes: [],
      postDevImperviousNodes: [],
      postDevPerviousNodes: [],

      manufacturerLink: "",
      postDevmanufacturerLink: "",

      eolTreatment: "No end-of-line treatment",
      eolTreatmentSize: "",
      eolTypes: [],

      // for Map CMS
      companyDetails: [],
      councilDetails: [],
      queryCMS: "",
    };
  }

  // update the list inside a state object. ex: to update children list state={ osd: {name: "raj", children: ["Naj", "Baj"]}}
  setListinObject = async (name, value) => {
    await this.setState({
      osd: {
        ...this.state.osd,
        [name]: value,
      },
    });
  };

  resetOSD = async (method, cityCouncil) => {
    await this.setState({
      osd: OsdStateObject(method, cityCouncil),
      osdReset: true,
      reportSent: "",
      showOSDsAdsDiv: false,
      osdCalculating: false,
    });
    // console.log("reset osd", OsdStateObject(method));
  };

  // Set osd
  setStateVariable = async (name, value) => {
    if (typeof name === "object") {
      this.setState(name);
      this.checkAllFilled();
    } else {
      this.setState({ [name]: value });
      this.checkAllFilled();
    }
    if (name === "imperviousNodes" || name === "perviousNodes") {
      this.updateTotalArea();
      await this.resetOSD(this.state.osdMethod, this.state.osdCityCouncil);
    }
    if (name === "postDevImperviousNodes" || name === "postDevPerviousNodes") {
      this.updateTotalArea();
      if (this.state.wqMethod.includes("NorBE")) {
        this.updatePostDevTotalArea();
      }
      await this.resetOSD(this.state.osdMethod, this.state.osdCityCouncil);
    }
  };

  async componentDidMount() {
    this.setState({});
    await sails_api.get("/RainfallStation").then(async (response) => {
      await sails_api.get("/DevelopmentTypes").then((res) =>
        this.setState({
          developmentTypes: res.data,
          rainfallStations: response.data,
          sessionID: this.props.sessionID,
          ip: this.props.ip,
          source: this.props.source,
          assessorEmail: this.props.assessorEmail,
        })
      ); // Loading the development types from DB;
    }); // Loading the rainfall stations from DB
    this.getCompanyDetails();
    this.checkAllFilled();
  }

  companyDetails = async (name) => {
    const response = await fetch("https://su-map-cms.ga/mapcms/companyinfos/")
      .then((response) => response.json())
      .then((response) => response.data)

      .then((response) => {
        var companyList = [];

        for (let i = 0; i < response.length; i++) {
          if (response[i].device) {
            if (response[i].device.includes(name)) {
              companyList.push(response[i]);
            }
          }
        }

        this.setStateVariable("companyDetails", companyList);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  getCompanyDetails = async () => {
    const response = await fetch("https://su-map-cms.ga/mapcms/companyinfos/")
      .then((response) => response.json())
      .then((response) => response.data)

      .then((response) => {
        var companyList = [];
        var councilList = [];

        for (let i = 0; i < response.length; i++) {
          if (response[i].authority) {
            councilList.push(response[i]);
          } else {
            companyList.push(response[i]);
          }
        }

        this.setState({
          companyDetails: companyList,
          councilDetails: councilList,
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  async updateTotalArea() {
    var impArea = 0;
    var pArea = 0;
    var nones = 0;
    var impNodes = 0;
    var pNodes = 0;
    // Loop through each of the impervious treatments to calculate the total treatment area
    for (const impnode of this.state.imperviousNodes) {
      if (impnode.impAreaSize !== "") {
        impArea += parseFloat(impnode.impAreaSize);
        impNodes += 1;
        if (
          impnode.impAreaTreatType === "" ||
          impnode.impAreaTreatType === "None"
        ) {
          nones += 1;
        }
      }
    }

    if (this.state.pervious) {
      // Loop through each of the pervious treatments to calculate the total treatment area
      for (const pnode of this.state.perviousNodes) {
        if (pnode.pAreaSize !== "") {
          pArea += parseFloat(pnode.pAreaSize);
          pNodes += 1;
          if (pnode.pAreaTreatType === "" || pnode.pAreaTreatType === "None") {
            nones += 1;
          }
        }
      }
    }

    await this.setState({
      siteArea: (impArea + pArea).toFixed(2),
      totalImpArea: impArea.toFixed(2),
      totalPArea: pArea.toFixed(2),
      totalCatchmentArea: (impArea + pArea).toFixed(2),
      totalImpNodes: impNodes,
      totalPNodes: pNodes,
      totalNoneNodes: nones,
    });

    await this.checkAreaSizeMatches();
  }

  async updatePostDevTotalArea() {
    var postDevImpArea = 0;
    var postDevPArea = 0;
    var postDevNones = 0;
    var postDevImpNodes = 0;
    var postDevPNodes = 0;
    // Loop through each of the impervious treatments to calculate the total treatment area
    for (const impnode of this.state.postDevImperviousNodes) {
      if (impnode.impAreaSize !== "") {
        postDevImpArea += parseFloat(impnode.impAreaSize);
        postDevImpNodes += 1;
        if (
          impnode.impAreaTreatType === "" ||
          impnode.impAreaTreatType === "None"
        ) {
          postDevNones += 1;
        }
      }
    }

    if (this.state.postDevPervious) {
      // Loop through each of the pervious treatments to calculate the total treatment area
      for (const pnode of this.state.postDevPerviousNodes) {
        if (pnode.pAreaSize !== "") {
          postDevPArea += parseFloat(pnode.pAreaSize);
          postDevPNodes += 1;
          if (pnode.pAreaTreatType === "" || pnode.pAreaTreatType === "None") {
            postDevNones += 1;
          }
        }
      }
    }

    await this.setState({
      postDevSiteArea: (postDevImpArea + postDevPArea).toFixed(2),
      totalPostDevImpArea: postDevImpArea.toFixed(2),
      totalPostDevPArea: postDevPArea.toFixed(2),
      totalPostDevCatchmentArea: (postDevImpArea + postDevPArea).toFixed(2),
      totalPostDevImpNodes: postDevImpNodes,
      totalPostDevPNodes: postDevPNodes,
      totalPostDevNoneNodes: postDevNones,
    });

    await this.checkAreaSizeMatches();
  }

  async checkAreaSizeMatches() {
    if (this.state.wqMethod.includes("NorBE")) {
      if (this.state.postDevSiteArea != this.state.siteArea) {
        await this.setState({ showAlert3: true });
      } else {
        await this.setState({ showAlert3: false });
      }
    }
  }

  // To handle the impervious and pervious checkbox change
  async handleImperviousPerviousCheckboxChange() {
    if (!this.state.impervious) {
      this.setState({ imperviousNodes: [] });
    }
    if (!this.state.pervious) {
      this.setState({ perviousNodes: [] });
    }

    // =====================================
    //
    //  Coppied from calculate results
    //
    // =====================================
    var data = {};
    var baseWQMethod = this.state.wqMethod.replace("EOL", "");
    if (this.state.wqMethod.includes("Standard")) {
      data = {
        wqMethod: baseWQMethod,
        pervious: this.state.pervious,
        targetReductions: this.state.targetReductions,
        imperviousNodes: this.state.imperviousNodes,
        perviousNodes: this.state.perviousNodes,
      };
    } else if (this.state.wqMethod.includes("NorBE")) {
      data = {
        wqMethod: baseWQMethod,
        pervious: this.state.pervious,
        targetReductions: this.state.targetReductions,
        imperviousNodes: this.state.imperviousNodes,
        perviousNodes: this.state.perviousNodes,
        postDevPervious: this.state.postDevPervious,
        postDevImperviousNodes: this.state.postDevImperviousNodes,
        postDevPerviousNodes: this.state.postDevPerviousNodes,
      };
    }

    if (
      this.state.wqMethod.includes("EOL") &&
      this.state.eolTreatment !== "No end-of-line treatment" &&
      this.state.eolTreatmentSize > 0
    ) {
      data = {
        ...data,
        wqMethod: this.state.wqMethod,
        eolDeviceName: this.state.eolTreatment,
        eolTreatSize: this.state.eolTreatmentSize,
        state: this.state.state,
        mtemplate: this.state.rainfallStation,
      };
    }

    if (data.wqMethod.includes("Dorries")) {
      await sails_api.post("/OverallResults", data).then((res) => {
        printLog("Dorries results", res.data);
        this.setState({ dorriesResults: res.data });
      });
    } else {
      await sails_api.post("/OverallResults", data).then((res) => {
        this.setState({ overallResults: res.data });
      });
    }
    // =======================================
    // =======================================
  }

  // To handle the input changes
  async handleInputChange(e) {
    this.setState({ started: false });
    const { name, value } = e.target;
    if (name === "assessorEmail") {
      await this.setState({ assessorEmail: e.target.value });
      // this.updateTotalArea();
      this.checkAllFilled();
      return;
    }

    if (name === "assessorName") {
      // to accept only alphabets for state and assessor name
      if (value === "" || isAlpha(value, "en-US", { ignore: " " })) {
        await this.setState({ [name]: value });
        // this.updateTotalArea();
        this.checkAllFilled();
        return;
      } else {
        // this.updateTotalArea();
        this.checkAllFilled();
        return;
      }
    }

    if (name === "planningPermitNo") {
      await this.setState({ [name]: value });
      this.checkAllFilled();
      return;
    }

    if (name === "developmentType") {
      await this.setState({ [name]: value });
      if (!this.state.disableWQ) {
        this.state.greyOutCatchmentSelection = false;
        this.setCatchmentType(value);
      }
      // this.updateTotalArea();
      this.checkAllFilled();
      return;
    }

    if (name === "catchmentType") {
      // fetch the impervious source node types
      const data = await CatchmentChange(this.state, value, sails_api);
      await this.setState(data);
      // this.updateTotalArea();
      return;
    }

    if (name === "hasWQ") {
      // To reset the WQ section stuff
      const data = await CatchmentChange(
        this.state,
        this.state.catchmentType,
        sails_api
      );
      data[name] = value;
      await this.setState(data);
      // this.updateTotalArea();
      return;
    } else {
      await this.setState({ [name]: value });
    }

    if (name === "impervious") {
      this.setState({ imperviousNodes: [] });
      window.document.getElementById("addPreDevImpArea").click();
      this.updateTotalArea();
    }

    if (name === "pervious") {
      this.setState({ perviousNodes: [] });
      window.document.getElementById("addPreDevPArea").click();
      this.updateTotalArea();
    }

    if (name === "postDevPervious") {
      this.setState({ postDevPerviousNodes: [] });
      window.document.getElementById("addPostDevPArea").click();
      this.updatePostDevTotalArea();
    }

    if (this.state.wqMethod.includes("NorBE")) {
      if (this.state.postDevSiteArea != this.state.siteArea) {
        this.setState({ showAlert3: true });
      } else {
        this.setState({ showAlert3: false });
      }
    }
    this.checkAllFilled();

    // Change the node data when based on the checkbox;
    this.handleImperviousPerviousCheckboxChange();
  }
  /**
   * sets the parameter of catchment type on selection of development type
   * @param {String} developmentType
   */
  setCatchmentType(developmentType) {
    let matchText = "";
    if (
      developmentType.indexOf("-") >= 0 ||
      developmentType.indexOf("/") >= 0
    ) {
      matchText = developmentType.split(/[/-]+/)[0];
    } else {
      matchText = developmentType;
    }
    for (let catchmentType in this.state.catchmentTypes) {
      if (
        this.state.catchmentTypes[catchmentType]
          .toLowerCase()
          .includes(matchText.toLowerCase())
      ) {
        this.state.catchmentType = this.state.catchmentTypes[catchmentType];
        this.state.greyOutCatchmentSelection = true;
      }
    }
  }

  // To check all required input fields are filled (for the alert box)
  async checkAllFilled() {
    var showAlert = false;
    var assessorNameValid = false;
    var devTypeValid = false;
    var assessorEmailValid = false;
    var planningPermitNoValid = false;
    if (
      this.state.assessorEmail !== "" &&
      this.state.assessorName !== "" &&
      this.state.rainfallStation !== "" &&
      this.state.addressMain !== "" &&
      this.state.suburb !== "" &&
      this.state.zipcode !== "" &&
      this.state.developmentType !== "" &&
      this.state.state !== "" &&
      this.state.planningPermitNo !== ""
    ) {
      showAlert = false;
    } else {
      showAlert = true;
    }
    if (this.state.assessorName === "") {
      assessorNameValid = false;
    } else {
      assessorNameValid = true;
    }

    if (this.state.developmentType === "") {
      devTypeValid = false;
    } else {
      devTypeValid = true;
    }
    if (this.state.assessorEmail === "") {
      assessorEmailValid = false;
    } else {
      assessorEmailValid = true;
    }
    if (this.state.planningPermitNo === "") {
      planningPermitNoValid = false;
    } else {
      planningPermitNoValid = true;
    }

    this.setState({
      showAlert: showAlert,
      assessorNameValid: assessorNameValid,
      devTypeValid: devTypeValid,
      assessorEmailValid: assessorEmailValid,
      planningPermitNoValid: planningPermitNoValid,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    if (
      (this.state.hasOsd && !this.state.osdOK) ||
      !this.state.assessorNameValid ||
      !this.state.assessorEmailValid ||
      this.state.developmentType === "" ||
      !this.state.planningPermitNoValid
    ) {
      printLog("Check for warnings");
    } else if (
      this.state.hasOsd &&
      this.state.hasOwnProperty("osd") &&
      this.state.osd.hasOwnProperty("storageType") &&
      this.state.osd.storageType === ""
    ) {
      alert("Please press calculate OSD and select a storage type");
    } else if (
      this.state.manufacturerImgLink === "" &&
      this.state.showOSDsAdsDiv
    ) {
      alert(
        "Please select a manufacturer by clicking on one of the displayed logos!"
      );
    } else {
      this.setState({ showAlert: false });
      printLog("state before downloader is: ", this.state);
      downloader(
        this.state,
        sails_api,
        event.nativeEvent.submitter.name,
        this.setStateVariable
      );
    }
  };

  calculateOverallResults = async () => {
    var data = {};
    var baseWQMethod = this.state.wqMethod.replace("EOL", "");
    if (this.state.wqMethod.includes("Standard")) {
      data = {
        wqMethod: baseWQMethod,
        pervious: this.state.pervious,
        targetReductions: this.state.targetReductions,
        imperviousNodes: this.state.imperviousNodes,
        perviousNodes: this.state.perviousNodes,
      };
    } else if (this.state.wqMethod.includes("NorBE")) {
      data = {
        wqMethod: baseWQMethod,
        pervious: this.state.pervious,
        targetReductions: this.state.targetReductions,
        imperviousNodes: this.state.imperviousNodes,
        perviousNodes: this.state.perviousNodes,
        postDevPervious: this.state.postDevPervious,
        postDevImperviousNodes: this.state.postDevImperviousNodes,
        postDevPerviousNodes: this.state.postDevPerviousNodes,
      };
    }

    if (
      this.state.wqMethod.includes("EOL") &&
      this.state.eolTreatment !== "No end-of-line treatment" &&
      this.state.eolTreatmentSize > 0
    ) {
      data = {
        ...data,
        wqMethod: this.state.wqMethod,
        eolDeviceName: this.state.eolTreatment,
        eolTreatSize: this.state.eolTreatmentSize,
        state: this.state.state,
        mtemplate: this.state.rainfallStation,
      };
    }

    if (data.wqMethod.includes("Dorries")) {
      await sails_api.post("/OverallResults", data).then((res) => {
        this.setState({ dorriesResults: res.data });
      });
    } else {
      await sails_api.post("/OverallResults", data).then((res) => {
        this.setState({ overallResults: res.data });
      });
    }
  };

  render() {
    let test = false;
    if (window.location.href === "http://localhost:3000/") {
      test = true;
    }

    return (
      <div style={{ display: "flex" }}>
        {/* <div flexDirection="col"></div> */}
        <div>
          <AdsPanel dataForadsPanel={"Left"} />
        </div>

        <Container>
          <div className="panOuterCalc">
            <h3>The STORM Calculator</h3>

            <div style={{ paddingTop: "70px", paddingLeft: "10px" }}>
              Welcome to the STORM Calculator.
              <br />
              You now need to submit details relating to your development site.
              <br />
              <br />
              Questions? Have a look at the help page or contact your Council's
              Planning or Environmentally Sustainable Design department as part
              of the Council permit requirements.
              <br />
              <br />
            </div>
            <div className="panInnerCalc">
              <StateUploadDownloadButtons
                data={this.state}
                loadState={this.setStateVariable}
              />

              {/* select location */}
              <LocationAccordion
                ip={this.state.ip}
                source={this.state.source}
                state={this.state.fullAddress}
                auditorInterface={false}
                setRainfallStation={this.setRainfallStation}
                setAddress={this.setStateVariable}
                sails_api={sails_api}
                rainfallStations={this.state.rainfallStations}
                userDetails={this.state.assessorEmail}
                companyDetails={this.state.companyDetails}
                councilDetails={this.state.councilDetails}
                showCouncilRules={this.state.showCouncilRules}
                cityCouncil={this.state.cityCouncil}
                disableWQ={this.state.disableWQ}
              />
              <h5 className="checkboxLabel">What would you like to model?</h5>
              {/* Water Quality and On-Site Detentino buttons */}
              <table className="table-responsive tableChoice">
                <thead>
                  <tr>
                    <th>
                      <div className="checkboxPad_hasWQ">
                        <label className="checkboxLabel">
                          <input
                            type="checkbox"
                            name="hasWQ"
                            disabled={this.state.disableWQ}
                            checked={this.state.hasWQ}
                            onChange={(e) => {
                              this.handleInputChange({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            className="checkbox"
                          />
                          Water Quality
                        </label>
                      </div>
                    </th>
                    <th>
                      <div className="checkboxPad_hasOsd">
                        <label className="checkboxLabel">
                          <input
                            type="checkbox"
                            name="hasOsd"
                            checked={this.state.hasOsd}
                            disabled={this.state.osdMethodUnsupported}
                            onChange={(e) => {
                              this.handleInputChange({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            className="checkbox"
                          />
                          On-Site Detention
                        </label>
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>

              {/* warning message */}
              {this.state.locationSelected ? null : (
                <Alert variant="info">
                  Please select a location from the map above and click{" "}
                  <b>Start modelling</b> to begin.
                </Alert>
              )}
              {/* disable Water Quality */}
              {this.state.locationSelected && this.state.disableWQ ? (
                <Alert variant="info">
                  There is no WSUD data available for the selected
                  location yet. Therefore, Water Quality modeling is disabled.
                  {!this.state.osdMethodUnsupported ? (
                    <span>
                      &nbsp;You can still model On-Site Detention 😃.&nbsp;
                    </span>
                  ) : null}
                  Please reach us on mircea@cleanstormwater.com.au or 0433030044
                  if you'd like to nominate this area for Water Quality modeling
                  too.
                </Alert>
              ) : null}
              {/* disable OSD */}
              {this.state.locationSelected &&
                this.state.osdMethodUnsupported ? (
                <Alert variant="info">
                  The OSD Method required by the council is not supported on
                  STORMupdated yet. Rest assured, it will be available soon.
                  On-Site Detention calculation for this council is disabled.
                  {!this.state.disableWQ ? (
                    <span>
                      &nbsp;Meanwhile, you can still model Water Quality 😃.{" "}
                    </span>
                  ) : null}
                </Alert>
              ) : null}

              {/* The main form */}
              <h5>Basic Information</h5>
              <Form onSubmit={this.handleSubmit}>
                {/* Basic information */}
                <table width="100%">
                  <tbody>
                    <tr>
                      <td>Assessor</td>
                      <td></td>
                      <td>
                        <input
                          name="assessorName"
                          style={
                            this.state.assessorNameValid
                              ? null
                              : { border: "1px solid red" }
                          }
                          value={this.state.assessorName}
                          onChange={(e) => this.handleInputChange(e)}
                          placeholder="Assessor Name"
                          required={!test}
                        />
                      </td>
                      <td style={{ width: "50px" }}></td>
                      <td>City Council</td>
                      <td>
                        <input value={this.state.cityCouncil} disabled></input>
                      </td>
                    </tr>

                    <tr>
                      <td>Assessor Email</td>
                      <td></td>
                      <td>
                        <input
                          name="assessorEmail"
                          value={this.state.assessorEmail}
                          style={
                            this.state.assessorEmailValid
                              ? null
                              : { border: "1px solid red" }
                          }
                          onChange={(e) => this.handleInputChange(e)}
                          pattern="[A-za-z0-9._%+-]+@[A-za-z0-9.-]+\.[A-za-z]{2,}$"
                          placeholder="Assessor Email"
                          required={!test}
                        />
                      </td>
                      <td></td>
                      <td>Address</td>
                      <td>
                        <input value={this.state.addressMain} disabled></input>
                      </td>
                    </tr>

                    <tr>
                      <td>Development Type</td>
                      <td></td>
                      <td>
                        <select
                          name="developmentType"
                          value={this.state.developmentType}
                          onChange={(e) => this.handleInputChange(e)}
                          id="devType"
                          required={!test}
                          style={
                            this.state.devTypeValid
                              ? null
                              : { border: "1px solid red" }
                          }
                        >
                          <option value="" hidden>
                            Select Development Type
                          </option>
                          {this.state.developmentTypes.map((type) => (
                            <option key={type.name}>{type.name}</option>
                          ))}
                        </select>
                      </td>
                      <td></td>
                      <td>Suburb</td>
                      <td>
                        <input value={this.state.suburb} disabled></input>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Postcode</td>
                      <td>
                        <input value={this.state.zipcode} disabled></input>
                      </td>
                    </tr>

                    <tr>
                      <td>Catchment Type</td>
                      <td></td>
                      {this.state.disableWQ ? (
                        <td>
                          <select value={this.state.catchmentType} disabled>
                            {this.state.catchmentTypes.map((type) => (
                              <option key={type}>{type}</option>
                            ))}
                          </select>
                        </td>
                      ) : (
                        <td>
                          <select
                            name="catchmentType"
                            value={this.state.catchmentType}
                            onChange={(e) => this.handleInputChange(e)}
                            disabled={
                              this.state.rainfallStation === "" ||
                              this.state.catchmentTypes.length === 1 ||
                              this.state.greyOutCatchmentSelection
                            }
                            required
                          >
                            {this.state.catchmentTypes.map((type) => (
                              <option key={type}>{type}</option>
                            ))}
                          </select>
                          <span
                            data-tip="Split: Catchments will generate pollutants in accordance with their use (roof, road etc.) as per Authority Guidelines. <br/>
              Mixed: Pollution generation is as per the original MUSIC node which does not separate the catchments by their use. <br/> Other: Industrial, UrbanResidential, and Commercial may appear as the catchment types. These describe the development type and follow the 'Split' catchment modelling approach. <br />
               When only working on OSD, catchment type can be ignored as it only applies to water quality modelling. "
                            data-multiline="true"
                            data-place="bottom"
                          >
                            ❓
                          </span>
                          <ReactTooltip />
                        </td>
                      )}
                      <td></td>
                      <td>State</td>
                      <td>
                        <input value={this.state.state} disabled></input>
                      </td>
                    </tr>
                    <tr>
                      <td>Planning Permit No.</td>
                      <td></td>
                      <td>
                        <input
                          name="planningPermitNo"
                          style={
                            this.state.planningPermitNoValid
                              ? null
                              : { border: "1px solid red" }
                          }
                          value={this.state.planningPermitNo}
                          onChange={(e) => this.handleInputChange(e)}
                          placeholder="Permit Number"
                          required={!test}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                {this.state.hasWQ ? (
                  <>
                    {/* introduction */}
                    <div>
                      You now need to list every impervious area (Hard surfaces
                      e.g. roof, road) and pervious area (e.g. landscape) on
                      your site and detail your planned treatment measures. All
                      surface areas must be listed with their area - if there is
                      no treatment choose NONE in the treatment field box.
                      <ul>
                        <li>
                          You can add or delete rows by selecting the Add
                          Impervious Area or Add Pervious Area and 🗑 buttons.
                        </li>
                        <li>
                          Water Quality results are shown live (when we change
                          the size of a treatment type, the reductions will
                          change accordingly).
                        </li>
                      </ul>
                    </div>

                    {/* Alert message */}
                    <Alert
                      variant={this.state.alertVariant}
                      show={this.state.showAlert}
                    >
                      {this.state.alertMessage}
                    </Alert>
                    <hr />
                    <div
                      className={
                        this.state.wqMethod.includes("NorBE")
                          ? "panNodeExisting"
                          : "panNodeWQ"
                      }
                    >
                      {/* if the weMethod is NorBE, render Existing Site Model */}
                      {this.state.wqMethod.includes("NorBE") ? (
                        <>
                          <h4>Existing Site Model</h4>
                          {this.state.noData ? (
                            <div className="checkboxPad"></div>
                          ) : (
                            <div className="checkboxPad">
                              <label className="checkboxLabel">
                                <input
                                  type="checkbox"
                                  name="impervious"
                                  checked={this.state.impervious}
                                  disabled={!this.state.pervious}
                                  onChange={(e) => {
                                    this.handleInputChange({
                                      target: {
                                        name: e.target.name,
                                        value: e.target.checked,
                                      },
                                    });
                                  }}
                                  calculateOverallResults={
                                    this.calculateOverallResults
                                  }
                                  className="checkbox"
                                />
                                This site has impervious sections
                              </label>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {/* no NorBE wqMethod */}
                          <h5>Water Quality</h5>
                          <div>
                            {/* render council rules when the location is Monash City */}
                            {this.state.cityCouncil.includes("Monash City") ? (
                              <CouncilRuleWarning dataType="wsud" />
                            ) : (null)}
                          </div>
                        </>
                      )}

                      {/* Impervious node section */}
                      <div className="imperviousSection">
                        <ImperviousNode
                          sails_api={sails_api}
                          imperviousNodes={this.state.imperviousNodes}
                          impervious={this.state.impervious}
                          bedroomsOrOccupants={
                            this.state.developmentType.includes("Residential")
                              ? "Bedrooms"
                              : "Occupants"
                          }
                          imperviousAreaTypes={this.state.imperviousAreaTypes}
                          catchmentType={this.state.catchmentType}
                          rainfallStation={this.state.rainfallStation}
                          targetReductions={this.state.targetReductions}
                          calculateOverallResults={this.calculateOverallResults}
                          setVariable={this.setStateVariable}
                          cityCouncil={this.state.cityCouncil}
                          state={this.state.state}
                          hasOsd={this.state.hasOsd}
                          osd={this.state.osd}
                          wqMethod={this.state.wqMethod}
                          manufacturerLink={this.state.manufacturerLink}
                          totalImpArea={this.state.totalImpArea}
                          totalPArea={this.state.totalPArea}
                          totalPostDevImpArea={this.state.totalPostDevImpArea}
                          totalPostDevPArea={this.state.totalPostDevPArea}
                          eolTreatment={this.state.eolTreatment}
                          eolTypes={this.state.eolTreatments}
                          eolTreatmentSize={this.state.eolTreatmentSize}
                          eolResults={this.state.overallResults.eolResults}
                          overallRatings={
                            this.state.overallResults.overallRatings
                          }
                        />

                        {/* checkbox for having pervious node */}
                        {this.state.noData ? (
                          <div className="checkboxPad"></div>
                        ) : (
                          <div className="checkboxPad">
                            <label className="checkboxLabel">
                              <input
                                type="checkbox"
                                name="pervious"
                                checked={this.state.pervious}
                                disabled={!this.state.impervious}
                                onChange={(e) => {
                                  this.handleInputChange({
                                    target: {
                                      name: e.target.name,
                                      value: e.target.checked,
                                    },
                                  });
                                }}
                                /*  calculateOverallResults={
                                  this.calculateOverallResults
                                } */
                                className="checkbox"
                              />
                              This site has pervious sections
                            </label>
                          </div>
                        )}
                      </div>
                      {/* Pervious node section */}
                      <div className="flex-horizontal perviousSection">
                        <PerviousNode
                          className="item-left"
                          noData={this.state.noData}
                          hasPervious={this.state.pervious}
                          sails_api={sails_api}
                          perviousNodes={this.state.perviousNodes}
                          setPerviousNodes={this.setPerviousNodes}
                          perviousAreaTypes={this.state.perviousAreaTypes}
                          catchmentType={this.state.catchmentType}
                          rainfallStation={this.state.rainfallStation}
                          targetReductions={this.state.targetReductions}
                          calculateOverallResults={this.calculateOverallResults}
                          cityCouncil={this.state.cityCouncil}
                          state={this.state.state}
                          setVariable={this.setStateVariable}
                          hasOsd={this.state.hasOsd}
                          osd={this.state.osd}
                          wqMethod={this.state.wqMethod}
                          totalPArea={this.state.totalPArea}
                          totalImpArea={this.state.totalImpArea}
                          totalPostDevImpArea={this.state.totalPostDevImpArea}
                          totalPostDevPArea={this.state.totalPostDevPArea}
                        />

                        {/* Total result area */}
                        <div
                          className={
                            this.state.wqMethod.includes("NorBE")
                              ? "panNode_dataPreDev"
                              : "panNode_data"
                          }
                        >
                          <table width="100%">
                            <tbody>
                              <tr>
                                <td align="left">Total Site Area</td>
                                <td align="right">
                                  <input
                                    name="siteArea"
                                    value={this.state.siteArea}
                                    onChange={(e) => this.handleInputChange(e)}
                                    className="input_area"
                                    placeholder="Site Area"
                                    required
                                  />
                                </td>
                                <td align="right">m²</td>
                              </tr>
                              <tr>
                                <td align="left">Total Catchment Area</td>
                                <td align="right">
                                  {this.state.totalCatchmentArea}
                                </td>
                                <td align="right">m²</td>
                              </tr>
                              <tr>
                                <td align="left">Impervious Area</td>
                                <td align="right">{this.state.totalImpArea}</td>
                                <td align="right">m²</td>
                              </tr>
                              <tr>
                                <td align="left">Pervious Area</td>
                                <td align="right">{this.state.totalPArea}</td>
                                <td align="right">m²</td>
                              </tr>
                              <tr>
                                <td align="left">No. of Catchments</td>
                                <td align="right">
                                  {this.state.totalImpNodes +
                                    this.state.totalPNodes}
                                </td>
                              </tr>
                              <tr>
                                <td align="left">No. of Pervious Areas</td>
                                <td align="right">{this.state.totalPNodes}</td>
                              </tr>
                              <tr>
                                <td align="left">No. of Impervious Areas</td>
                                <td align="right">
                                  {this.state.totalImpNodes}
                                </td>
                              </tr>
                              <tr>
                                <td align="left">No. of Treatment Nodes</td>
                                <td align="right">
                                  {this.state.totalImpNodes +
                                    this.state.totalPNodes -
                                    this.state.totalNoneNodes}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* alert message */}
                      <Alert
                        variant={this.state.alert2Variant}
                        show={this.state.showAlert2}
                      >
                        {this.state.alert2Message}
                      </Alert>
                    </div>
                    <br />

                    {/* if the wqMethod is NorBE, show Developed Site Model. */}
                    {!this.state.wqMethod.includes("NorBE") ? null : (
                      <>
                        <div className="panNodeDeveloped">
                          <h4>Developed Site Model</h4>
                          <PostDevImperviousNode
                            sails_api={sails_api}
                            postDevImperviousNodes={
                              this.state.postDevImperviousNodes
                            }
                            bedroomsOrOccupants={
                              this.state.developmentType.includes("Residential")
                                ? "Bedrooms"
                                : "Occupants"
                            }
                            imperviousAreaTypes={this.state.imperviousAreaTypes}
                            catchmentType={this.state.catchmentType}
                            rainfallStation={this.state.rainfallStation}
                            targetReductions={this.state.targetReductions}
                            calculateOverallResults={
                              this.calculateOverallResults
                            }
                            setVariable={this.setStateVariable}
                            cityCouncil={this.state.cityCouncil}
                            state={this.state.state}
                            hasOsd={this.state.hasOsd}
                            osd={this.state.osd}
                            wqMethod={this.state.wqMethod}
                            postDevmanufacturerLink={
                              this.state.postDevmanufacturerLink
                            }
                            totalImpArea={this.state.totalImpArea}
                            totalPArea={this.state.totalPArea}
                            totalPostDevImpArea={this.state.totalPostDevImpArea}
                            totalPostDevPArea={this.state.totalPostDevPArea}
                            eolTreatment={this.state.eolTreatment}
                            eolTypes={this.state.eolTreatments}
                            eolTreatmentSize={this.state.eolTreatmentSize}
                            eolResults={this.state.overallResults.eolResults}
                            overallRatings={
                              this.state.overallResults.overallRatings
                            }
                          />

                          {this.state.noData ? (
                            <div className="checkboxPad"></div>
                          ) : (
                            <div className="checkboxPad">
                              <label className="checkboxLabel">
                                <input
                                  type="checkbox"
                                  name="postDevPervious"
                                  checked={this.state.postDevPervious}
                                  onChange={(e) => {
                                    this.handleInputChange({
                                      target: {
                                        name: e.target.name,
                                        value: e.target.checked,
                                      },
                                    });
                                  }}
                                  className="checkbox"
                                />
                                This site has pervious sections
                              </label>
                            </div>
                          )}

                          <div className="flex-horizontal perviousSection">
                            <PostDevPerviousNode
                              className="item-left"
                              noData={this.state.noData}
                              hasPervious={this.state.postDevPervious}
                              sails_api={sails_api}
                              postDevPerviousNodes={
                                this.state.postDevPerviousNodes
                              }
                              setPerviousNodes={this.setPerviousNodes}
                              perviousAreaTypes={this.state.perviousAreaTypes}
                              catchmentType={this.state.catchmentType}
                              rainfallStation={this.state.rainfallStation}
                              targetReductions={this.state.targetReductions}
                              calculateOverallResults={
                                this.calculateOverallResults
                              }
                              cityCouncil={this.state.cityCouncil}
                              state={this.state.state}
                              setVariable={this.setStateVariable}
                              hasOsd={this.state.hasOsd}
                              osd={this.state.osd}
                              wqMethod={this.state.wqMethod}
                              totalImpArea={this.state.totalImpArea}
                              totalPArea={this.state.totalPArea}
                              totalPostDevImpArea={
                                this.state.totalPostDevImpArea
                              }
                              totalPostDevPArea={this.state.totalPostDevPArea}
                            />
                            <div className="panNode_dataPostDev">
                              <table width="100%">
                                <tbody>
                                  <tr>
                                    <td align="left">Total Site Area</td>
                                    <td align="right">
                                      <input
                                        name="postDevSiteArea"
                                        value={this.state.postDevSiteArea}
                                        onChange={(e) =>
                                          this.handleInputChange(e)
                                        }
                                        className="input_area"
                                        placeholder="Site Area"
                                        required
                                      />
                                    </td>
                                    <td align="right">m²</td>
                                  </tr>
                                  <tr>
                                    <td align="left">Total Catchment Area</td>
                                    <td align="right">
                                      {this.state.totalPostDevCatchmentArea}
                                    </td>
                                    <td align="right">m²</td>
                                  </tr>
                                  <tr>
                                    <td align="left">Impervious Area</td>
                                    <td align="right">
                                      {this.state.totalPostDevImpArea}
                                    </td>
                                    <td align="right">m²</td>
                                  </tr>
                                  <tr>
                                    <td align="left">Pervious Area</td>
                                    <td align="right">
                                      {this.state.totalPostDevPArea}
                                    </td>
                                    <td align="right">m²</td>
                                  </tr>
                                  <tr>
                                    <td align="left">No. of Catchments</td>
                                    <td align="right">
                                      {this.state.totalPostDevImpNodes +
                                        this.state.totalPostDevPNodes}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td align="left">No. of Pervious Areas</td>
                                    <td align="right">
                                      {this.state.totalPostDevPNodes}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td align="left">
                                      No. of Impervious Areas
                                    </td>
                                    <td align="right">
                                      {this.state.totalPostDevImpNodes}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td align="left">No. of Treatment Nodes</td>
                                    <td align="right">
                                      {this.state.totalPostDevImpNodes +
                                        this.state.totalPostDevPNodes -
                                        this.state.totalPostDevNoneNodes}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <Alert
                            variant={this.state.alert2Variant}
                            show={this.state.showAlert2}
                          >
                            {this.state.alert2Message}
                          </Alert>
                          <Alert
                            variant={this.state.alert3Variant}
                            show={this.state.showAlert3}
                          >
                            {this.state.alert3Message}
                          </Alert>
                        </div>
                      </>
                    )}
                  </>
                ) : null}

                {this.state.hasWQ ? (
                  <>
                    <br />
                    <div className="panNodeResult">
                      <OverallResultsTable data={this.state} />
                    </div>
                  </>
                ) : null}
                <br />
                {this.state.hasOsd ? (
                  <Osd
                    setState={this.setState}
                    companyDetails={this.companyDetails}
                    showOSDsAdsDiv={this.state.showOSDsAdsDiv}
                    sails_api={sails_api}
                    data={this.state}
                    setOSD={this.setStateVariable}
                    setListinObject={this.setListinObject}
                    exportingReport={this.state.exportingReport}
                    cityCouncil={this.state.cityCouncil}
                  />
                ) : null}

                {this.state.showOSDsAdsDiv ? (
                  <OsdsAds
                    companyDetails={this.state.companyDetails}
                    manufacturerImgLink={this.state.manufacturerImgLink}
                    setOSD={this.setStateVariable}
                  />
                ) : null}
                <br />
                {/* Current guidelines for City of Hume and City of Moonee Valley require this message */}
                {!this.state.hasWQ && this.state.hasOsd ? null : (
                  <Alert
                    variant="info"
                    show={
                      this.state.cityCouncil.includes("City of Hume") ||
                      this.state.cityCouncil.includes("Moonee Valley") ||
                      this.state.cityCouncil.includes("Cambelltown")
                    }
                  >
                    {this.state.cityCouncil} requires you to submit a MUSIC
                    model along with your report. Please ensure you download
                    both.
                  </Alert>
                )}

                <Alert
                  variant="info"
                  show={this.state.manufacturerImgLink !== ""}
                >
                  You selected {this.state.OSDCompany} {this.state.OSDMaterial}{" "}
                  {this.state.hasOwnProperty("osd")
                    ? this.state.osd.hasOwnProperty("storageType")
                      ? this.state.osd.storageType
                      : null
                    : null}
                  .
                </Alert>
                <div className="calculate_button">
                  <DownloadButtons
                    sails_api={sails_api}
                    data={this.state}
                    targetReductions={this.state.targetReductions}
                    overallResults={this.state.overallResults}
                    disableButtons={
                      this.state.hasWQ === false &&
                      this.state.hasOsd === false &&
                      this.state.activateButtons
                    }
                    activateFunctions={this.state.activateButtons}
                    osdCalculating={this.state.osdCalculating}
                    exportingReport={this.state.exportingReport}
                    exportingModel={this.state.exportingModel}
                    exportingMlb={this.state.exportingMlb}
                  />
                </div>
                <div>
                  <br />
                  <Alert variant="info" show={this.state.reportSent !== ""}>
                    Done! Your {this.state.reportSent} has been sent to{" "}
                    <b>{this.state.assessorEmail}</b>.
                  </Alert>
                </div>
              </Form>
            </div>
          </div>

          <div></div>
        </Container>

        <div>
          <AdsPanel dataForadsPanel={"Right"} />
        </div>
      </div>
    );
  }
}

export default App;
