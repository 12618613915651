import React from "react";
import "./assets/css/osd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Swinburne from "./assets/osd_methods/Swinburne";
import SwinburneWQ from "./assets/osd_methods/SwinburneWQ";
import Boyds from "./assets/osd_methods/Boyds";
import Rational from "./assets/osd_methods/Rational";
import RationalWQ from "./assets/osd_methods/RationalWQ";
import Rational_SunshineCoast from "./assets/osd_methods/Rational_SunshineCoast";
import { printLog } from "./common";
import Uprct from "./assets/osd_methods/Uprct";

const Osd = (props) => { 
  if (props.data.osd.method === "Swinburne Method") {
  if (props.data.hasWQ) {
    return (
      <SwinburneWQ
        setState={props.setState}
        companyDetails={props.companyDetails}
        showOSDsAdsDiv={props.showOSDsAdsDiv}
        sails_api={props.sails_api}
        data={props.data}
        setOSD={props.setOSD}
        setList={props.setListinObject}
        exportingReport={props.exportingReport}
          cityCouncil={props.cityCouncil}
      />
    );
  } else {
    return (
      <Swinburne
        setState={props.setState}
        companyDetails={props.companyDetails}
        showOSDsAdsDiv={props.showOSDsAdsDiv}
        sails_api={props.sails_api}
        data={props.data}
        setOSD={props.setOSD}
        setList={props.setListinObject}
        exportingReport={props.exportingReport}
          cityCouncil={props.cityCouncil}
      />
    );
  }
}
  if (props.data.osd.method === "UPRCT") {
      return (
        <Uprct 
        setState={props.setState}
        companyDetails={props.companyDetails}
        showOSDsAdsDiv={props.showOSDsAdsDiv}
        sails_api={props.sails_api}
        data={props.data}
        setOSD={props.setOSD}
        setList={props.setListinObject}
        exportingReport={props.exportingReport}
        />
      );
  }
  if (
    props.data.osd.method === "Rational Method" ||
    props.data.osd.method === "Boyds Method"
  ) {
    if (props.data.hasWQ) {
      return (
        <RationalWQ
          setState={props.setState}
          companyDetails={props.companyDetails}
          showOSDsAdsDiv={props.showOSDsAdsDiv}
          sails_api={props.sails_api}
          data={props.data}
          setOSD={props.setOSD}
          setList={props.setListinObject}
          exportingReport={props.exportingReport}
          cityCouncil={props.cityCouncil}
        />
      );
    } else {
      return (
        <Rational
          setState={props.setState}
          companyDetails={props.companyDetails}
          showOSDsAdsDiv={props.showOSDsAdsDiv}
          sails_api={props.sails_api}
          data={props.data}
          setOSD={props.setOSD}
          setList={props.setListinObject}
          exportingReport={props.exportingReport}
          cityCouncil={props.cityCouncil}
        />
      );
    }
  }
  // if (props.data.osd.method === "Boyds Method") {
  //   return (
  //     <Boyds
  //       sails_api={props.sails_api}
  //       data={props.data}
  //       setOSD={props.setOSD}
  //       setList={props.setListinObject}
  //       exportingReport={props.exportingReport}
  //     />
  //   );
  // }
  if (props.data.osd.method === "Sunshine Coast Modified Rational Method") {
    return (
      <Rational_SunshineCoast
        setState={props.setState}
        companyDetails={props.companyDetails}
        showOSDsAdsDiv={props.showOSDsAdsDiv}
        sails_api={props.sails_api}
        data={props.data}
        setOSD={props.setOSD}
        setList={props.setListinObject}
        exportingReport={props.exportingReport}
        cityCouncil={props.cityCouncil}
      />
    );
  }
};

export default Osd;
