export const OsdStateObject = (method, cityCouncil) => {
  if (method === "Swinburne" && cityCouncil !== 'Monash City') {
    const osd = {
      shortname: "swinburne",
      method: "Swinburne Method",
      preDevImpArea: "",
      preDevImpAreaRoC: 0.9,
      preDevPArea: "",
      preDevPAreaRoC: 0.3,
      councilPSD: "",
      postDevImpArea: "",
      postDevImpAreaRoC: 1,
      postDevPArea: "",
      postDevPAreaRoC: 0.3,
      catchmentTimeTc: 20,
      catchmentTimeTso: 10,
      catchmentTimeTcs: 10,
      uncontrolledImpArea: 0,
      uncontrolledPArea: 0,
      tankHeight: "",
      aepPSD: 20,
      aepOSD: 10,
      psd: false,
      storageType: "",
      climateAdjustment: 1,
      OSDCompany:"",
      OSDMaterial:"", 
      showOSDsAdsDiv:false,
      qaMultiplicationFactor: 2, // when osd4w is disabled, this becomes 1 
    };

    return osd;
  } else if (method === "Swinburne" && cityCouncil === 'Monash City') {
    const osd = {
      shortname: "swinburne",
      method: "Swinburne Method",
      preDevImpArea: "",
      preDevImpAreaRoC: 0.9,
      preDevPArea: "",
      preDevPAreaRoC: 0.3,
      councilPSD: "",
      postDevImpArea: "",
      postDevImpAreaRoC: 0.9,
      postDevPArea: "",
      postDevPAreaRoC: 0.3,
      catchmentTimeTc: 20,
      catchmentTimeTso: 10,
      catchmentTimeTcs: 10,
      uncontrolledImpArea: 0,
      uncontrolledPArea: 0,
      tankHeight: "",
      aepPSD: 20,
      aepOSD: 10,
      psd: false,
      storageType: "",
      climateAdjustment: 1,
      OSDCompany:"",
      OSDMaterial:"", 
      showOSDsAdsDiv:false,
      qaMultiplicationFactor: 2, // when osd4w is disabled, this becomes 1 
    };

    return osd;
  }
  if (method === "UPRCT") {
    const osd = {
      shortname: "UPRCT",
      method: "UPRCT",
      totalSiteArea:"",
      siteDrainingToOSDArea:"",
      totalRoofArea:"",
      numOfDwellings:"",
      topWaterLevelED:"",
      orifaceCentreED:"",
      estimatedFloodLevelED:"",
      numOfOrificeED:1,
      topWaterLevelD:"",
      orifaceCentreD:"",
      rwtCreditsCB:false,
      dedicatedAirspaceProvided:false,
      estimatedFloodLevelD:"",
      numOfOrificeDet:1,
      estimatedFloodLevelD:"",
      rlOfMinHabitableFL:"",
      lengthOfOverFlowWeir:"",
      rlOfMinGarageFL:"",
      siteRunoffCoff:"",
      roofDrainingRWTPercent:0,
      mintriggeringTopUp:0,
      TotalRwtVolume:0,
      dedicatedAirspace:0,
      maxHeadCentreOrifice:0,
      dailyDemand:0 
    };

    return osd;
  }
  if (method === "Rational") {
    const osd = {
      method: "Rational Method",
      tankHeight: "",
      designStorm: 1,
      psd: false,
      councilPSD: "",
      storageType: "",
      slope: "",
      flowLength: "",
      preDevAreas: {
        slope: "",
        flowLength: "",
        areas: [
          {
            areaName: "",
            surfaceType: "Paved",
            surfaceArea: "",
          },
        ],
      },
      postDevAreas: {
        slope: "",
        flowLength: "",
        ucSlope: "",
        ucFlowLength: "",
        areas: [
          {
            areaName: "",
            surfaceType: "Paved",
            surfaceArea: "",
            uncontrolled: 0,
            message: "",
          },
        ],
      },
      climateAdjustment: 1,
      OSDCompany:"",
      OSDMaterial:"",
      showOSDsAdsDiv:false,
      qaMultiplicationFactor: 2
    };

    return osd;
  }
  if (method === "Boyds") {
    const osd = {
      shortname: "boyds",
      method: "Boyds Method",
      tankHeight: "",
      designStorm: 1,
      psd: false,
      councilPSD: "",
      storageType: "",
      slope: 0, // hard coded. delete this
      flowLength: 0, // hard coded. delete this
      preDevAreas: {
        slope: 0,
        flowLength: 0,
        areas: [
          {
            areaName: "Pre-dev. area 1",
            surfaceType: "Paved",
            surfaceArea: "",
          },
        ],
      },
      postDevAreas: {
        slope: 0,
        flowLength: 0,
        ucSlope: 0,
        ucFlowLength: 0,
        areas: [
          {
            areaName: "Post-dev. area 1",
            surfaceType: "Paved",
            surfaceArea: "",
            uncontrolled: 0,
            message: "",
          },
        ],
      },
      climateAdjustment: 1,
      OSDCompany:"",
      OSDMaterial:"",
      showOSDsAdsDiv:false
    };

    return osd;
  }

  if (method === "Sunshine Coast Modified Rational") {
    const osd = {
      method: "Sunshine Coast Modified Rational Method", //ask V how she wants it
      area: "",
      flowType: "Channelised/Concentrated",
      slope: 0,
      hasSubareas: false,
      flowLength: 0,
      uncontrolledArea: 0,
      uncontrolledAreaRoC: 0.12,
      preDevAreas: [
        {
          areaName: "Pre-dev. area 1",
          surfaceType: "Paved",
          surfaceArea: "",
        },
      ],
      postDevAreas: [
        {
          areaName: "Post-dev. area 1",
          surfaceType: "Paved",
          surfaceArea: "",
        },
      ],
      climateAdjustment: 1
    };

    return osd;
  }

  // If all fail
  return false;
};
