import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import MsfUploader from "./assets/js/MsfUploader";
import axios from "axios";
import "./assets/css/auditor.css";
import { printLog } from "./common";
// component
import ErrorNode from "./Auditor/ErrorNode";

function MusicAuditor(props) {
  const [fullErrors, setFullErrors] = React.useState([]);
  const [useStateErrors, setUseStateErrors] = React.useState(false);

  const [emailSent, setEmailSent] = React.useState(false);

  const [runningSarah, setRunningSarah] = React.useState();
  const [sarahEmailSent, setSarahEmailSent] = React.useState(false);

  function parseMsfErrors(msfErrors) {
    var errorsList = [];
    msfErrors
      .trim()
      .split("\n")
      .map((node) => {
        var id = node.split(",")[1];
        var text = node.split(",")[0];
        text = text.split("$");
        var item = {
          nodeName: text[0],
          nodeMessage: text[1],
          nodeID: id,
          standard: "",
        };
        errorsList.push(item);
      });

    // console.log("Error list:", errorsList);
    return errorsList;
  }

  function parseMsfPaths(msfPaths) {
    var pathsList = [];
    msfPaths
      .trim()
      .split("\n")
      .map((line) => {
        var id = line.split(",")[0];
        var path = line.split(",")[1];
        var temp = path.split(".")[0].split("/");
        var companey = temp[temp.length - 2];
        if (
          companey.toLowerCase() != "constructed" &&
          companey.toLowerCase() != "mixed" &&
          companey.toLowerCase() != "standard"
        ) {
          var dropdownText = companey + " - " + temp[temp.length - 1];
        } else {
          var dropdownText = temp[temp.length - 1];
        }

        var path = {
          nodeID: id,
          path: path,
          dropdownText: dropdownText,
        };
        pathsList.push(path);
      });

    const fieldSorter = (fields) => (a, b) =>
      fields
        .map((o) => {
          let dir = 1;
          if (o[0] === "-") {
            dir = -1;
            o = o.substring(1);
          }
          return a[o] > b[o] ? dir : a[o] < b[o] ? -dir : 0;
        })
        .reduce((p, n) => (p ? p : n), 0);

    pathsList = pathsList.sort(fieldSorter(["nodeID", "dropdownText"]));
    return pathsList;
  }

  function combineErrorsAndPaths(errorsList, pathsList) {
    if (!useStateErrors) {
      var errorsWithPathsList = [];
      errorsList.forEach((item) => {
        var paths = [];
        pathsList.forEach((path) => {
          if (parseInt(path.nodeID) === parseInt(item.nodeID)) {
            paths.push(path);
          }
        });
        var newItem = { ...item, paths: paths };
        errorsWithPathsList.push(newItem);
      });
      return errorsWithPathsList;
    } else {
      console.log("state errors");
      return fullErrors;
    }
  }

  // Results Table based on response from python script
  const ResultsTable = (msfErrors, msfPaths) => {
    // Check no error, has error and failed operation
    if (msfErrors === "Okay") {
      return (
        <tr>
          <td style={{ color: "green" }}>No errors found in MSF.</td>
        </tr>
      );
    } else if (msfErrors.includes("Traceback")) {
      return (
        <tr>
          <td style={{ color: "blue" }}>
            An error occurred when processing the MSF. <br />
            Please try again or contact Mircea (0433030044) or Greg (0452518727)
            if the error persists.
          </td>
        </tr>
      );
    } else {
      // Parses the full msfErrors string into individual errors in a list
      var errorsList = parseMsfErrors(msfErrors);
      // Parses the full msfPaths string into values for the dropdowns
      var pathsList = parseMsfPaths(msfPaths);
      // Combines the errors with the paths based on the nodeID
      var errorsWithPathsList = combineErrorsAndPaths(errorsList, pathsList);

      return (
        <table>
          <tr>
            <td>
              <h5>The following errors were detected in your MSF:</h5>
            </td>
          </tr>
          {/* Displays each error in a list with a corresponding dropdown */}
          {errorsWithPathsList.map((error, index) => {
            return (
              <ErrorNode
                error={error}
                index={index}
                setMsfCheckerData={props.setMsfCheckerData}
                setSarahEmailSent={setSarahEmailSent}
                setFullErrors={setFullErrors}
                setUseStateErrors={setUseStateErrors}
                errorsWithPathsList={errorsWithPathsList}
              />
            );
          })}
          <tr>
            <td>
              <br />
            </td>
          </tr>
        </table>
      );
    }
  };

  // MLBStatus and
  const MLBStatus = (msfErrors, msfPaths) => {
    <table>
      <tr>
        <td>
          <i>
            <b>MLB status: &nbsp;&nbsp;</b>
          </i>
          {props.mlbOkay}
        </td>
      </tr>
      <br />
      <tr>
        <Button
          variant="success"
          value="download"
          size="sm"
          style={{ float: "right" }}
          onClick={() => getMlb()}
        >
          Download Correct MLB
        </Button>
      </tr>
    </table>;
  };

  // Post request for download MLB file
  async function getMlb() {
    let data = { rainfallStation: props.authority };
    let mlbDownloadName = data.rainfallStation + ".mlb";
    var mlburl = null;

    await props.sails_api
      .post("/downloadMLB", data)
      .then((res) => {
        mlburl = res.data.mlbUrl;
      })
      .catch((err) => {
        console.log(err);
      });

    if (mlburl) {
      var element = document.createElement("a");
      element.setAttribute("href", mlburl);
      element.setAttribute("download", mlbDownloadName);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }

  const getAuditorReport = async (
    saraResults,
    reportData = props.auditorReport,
    nodeInfoData = props.nodeInfo,
    msf = ""
  ) => {
    setEmailSent(true);
    await getTreatmentTrainsImage();
    await props.sails_api
      .post(
        "../report",
        {
          template: {
            name: "auditor-report-main",
          },
          data: {
            newReport: reportData,
            nodeInfo: nodeInfoData,
            basicInfo: props.basicInfo,
            sararesults: saraResults,
            lat: props.markers.current.lat,
            lng: props.markers.current.lng,
            wqMethod: props.wqMethod,
            msf: props.msfData.msf,
            auditedMsf: msf,
            fileName: props.fileName,
          },
        },
        {
          responseType: "blob",
        }
      )
      .catch((err) => {
        alert(
          "The report could not be rendered, please check the msf or contact us for further information."
        );
        console.error(err);
      });
  };

  const getTreatmentTrainsImage = async (msf = props.msfData.msf) => {
    let msfDataPassable = msf.replace(/&/gi, "and");

    await props.flask_api
      .post("/getTreatmentTrainsImage", msfDataPassable)
      .then(() => printLog("Generated Image. Message from frontend."));
  };

  const auditMsf = async (msfData) => {
    var dic = {};
    try {
      // await props.sails_api.post("/AuditMSF", msfData, { timeout: 30000 }).then((res) => {
      await props.flask_api
        .post("/AuditMSF", msfData, { timeout: 30000 })
        .then((res) => {
          // Sending request to jsReport api for auditor report
          // Need to separate from The Map
          if ("newReport" in res.data) {
            dic["auditorData"] = res.data.newReport;
            dic["nodeData"] = res.data.nodeInfo;
          }
        });
    } catch (e) {
      console.log("error from /AuditMSF: ", e);
      alert(
        "The server could not respond. Please try again, or contact Mircea (0433030044) or Greg (0452518727) if the error persists."
      );
    }

    return dic;
  };

  const modifyMsf = async () => {
    var data = {
      msf: props.msfData.msf,
      checkerData: props.msfCheckerData,
      state: props.state,
      authority: props.authority,
    };
    // var result = "";
    var out;
    await props.flask_api
      .post("/ModifyMSF", data, { timeout: 30000 })
      .then((res) => {
        out = res.data.data;
      });
    return out;
  };

  const runSaraConnor = async (link, msf) => {
    console.log(`${link} is available, running sara...`);
    return await axios
      .post(link + "/sara-run-results", {
        msf: msf,
        state: props.state,
        authority: props.authority,
        targetReductions: props.targetReductions,
      })
      .catch((error) => {
        console.log(error.message);
        setRunningSarah(false);
      });
  };

  const checkAvailableSara = async () => {
    const arr = [
      "https://cleanstormwater2.ddns.net:5001",
      "https://cleanstormwater.ddns.net:5000",
    ];

    let result;
    let count = 0;
    while (!result && count++ < 5) {
      for await (let link of arr) {
        await axios
          .get(link + "/check-sara", { timeout: 1000 })
          .then(() => {
            result = link;
          })
          .catch(() => {
            console.log(`${link} is unavailable`);
          });
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  const postSaraConnor = async (original) => {
    // payload: {msf:"", checkerData:[list of modified nodes]}
    // response: {data:msf in string}

    setRunningSarah(true);
    var msf = "";
    var msfBuffer = "";
    var auditorData;
    var nodeInfoData;
    if (original) {
      msf = await props.msfData.msf;
      auditorData = props.auditorReport;
      nodeInfoData = props.nodeInfo;
    } else {
      msfBuffer = await modifyMsf();
      msf = msfBuffer;
      var auditorBuffer = await auditMsf({
        msf: msf,
        state: props.state,
        authority: props.authority,
      });
      auditorData = auditorBuffer["auditorData"];
      nodeInfoData = auditorBuffer["nodeData"];
      console.log(nodeInfoData);
    }

    await getTreatmentTrainsImage(msf);

    const saraApi = await checkAvailableSara();
    if (saraApi) {
      await runSaraConnor(saraApi, msf).then(async (res) => {
        console.log("Sarah Results:", res);
        await deconstructSaraResults(res, msf).then(async (res) => {
          console.log("New Sarah Results", res);

          if (
            await getAuditorReport(
              res.data.saraResults,
              auditorData,
              nodeInfoData,
              msfBuffer
            )
          ) {
            if (original) {
              setEmailSent(true);
            } else {
              setSarahEmailSent(true);
            }
          }
        });
      });
    } else {
      alert("Server busy, please try again later");
    }

    setRunningSarah(false);
  };

  const deconstructSaraResults = async (newSaraResults, msf) => {
    // if the wqMethod is "NorBE", re-pack the saraResults
    // first node in NorBE sara results is Pre Development Node, second one is Post Development Node
    let data = [];
    if (
      props.wqMethod === "NorBE" &&
      msf.includes("Node Type,PreDevelopmentNode") &&
      msf.includes("Node Type,PostDevelopmentNode")
    ) {
      for (let i = 0; i < newSaraResults[0].node.data.length; i++) {
        var theAttribute = newSaraResults[1].node.data[i].Attribute;
        var thePreDevelopmentResidualLoad =
          newSaraResults[0].node.data[i].Residual_Load;
        var thePostDevelopmentResidualLoad =
          newSaraResults[1].node.data[i].Residual_Load;
        var theAchievedReduction = Math.trunc(
          ((thePreDevelopmentResidualLoad - thePostDevelopmentResidualLoad) *
            100) /
            thePreDevelopmentResidualLoad
        );
        var theTargetReduction =
          newSaraResults[1].node.data[i].Target_Reduction;
        data.push({
          attribute: theAttribute,
          preDevResidualLoad: thePreDevelopmentResidualLoad,
          postDevResidualLoad: thePostDevelopmentResidualLoad,
          achievedReduction: theAchievedReduction,
          targetReduction: theTargetReduction,
        });
      }
      newSaraResults = data;
    }
    return newSaraResults;
  };

  return (
    <>
      <Modal
        show={props.showMusicAuditor}
        onHide={() => {
          props.setMsfData(undefined);
          props.setMsfErrors(undefined);
          setUseStateErrors(false);
          setEmailSent(false);
          setSarahEmailSent(false);
          props.setAuditorReport(null);
          props.setShowMusicAuditor(false);
          props.setNorBeError(false);
          props.setOtherMethodError(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>☠️ Audit yer MUSIC Model 🏴‍☠️</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Built a model but not sure if it's compliant with the specific
          guidelines for {props.council}? <br />
          <i>Auditor of Music: The Legend of Ming</i> ⚔️ is here!
          <br />
          <br />
          <p style={{ fontSize: "13px" }}>
            <b>
              <a href="https://youtu.be/ozXJrmICwt4">
                HELP - Teach me what all of this means
              </a>
              <br />
              {props.norBeError ? (
                <span style={{ color: "red" }}>
                  The recieving nodes in the uploaded file,{" "}
                  <b>{props.fileName}</b>, is
                  <b> not valid</b>. Please upload another model, using pre
                  development and post development end nodes to represent
                  existing and proposed site conditions
                </span>
              ) : props.otherMethodError ? (
                <span style={{ color: "red" }}>
                  The end node in the uploaded file, <b>{props.fileName}</b>, is
                  <b> not valid.</b> Please upload another model, using
                  receiving end node to represent proposed site conditions
                </span>
              ) : null}
            </b>
          </p>
          <br />
          <table className="fileUploader" style={{ width: "100%" }}>
            <tbody style={{ width: "100%" }}>
              <tr>
                {props.msfData ? (
                  <>
                    <td>
                      <i>{props.fileName}</i>
                    </td>
                  </>
                ) : (
                  <td>
                    <MsfUploader
                      stateName={props.state}
                      authority={props.authority}
                      setMsfData={props.setMsfData}
                      setFileName={props.setFileName}
                    />
                  </td>
                )}
              </tr>
              <tr>
                <td>
                  <br />
                </td>
              </tr>
              <tr>
                {props.isAuditing ? (
                  <td>
                    <Spinner animation="border" variant="success" />
                  </td>
                ) : (
                  <></>
                )}
              </tr>

              <tr>
                <td>
                  {emailSent ? (
                    <>
                      <div style={{ color: "green" }}>
                        The Auditor Report has been sent to the assessor email.
                      </div>
                    </>
                  ) : props.auditorReport != null ? (
                    <>
                      <p>The Auditor Report is ready.</p>
                      <Button
                        // variant="warning"
                        value="download"
                        size="sm"
                        variant="info"
                        style={{ float: "left", width: "256px" }}
                        onClick={() => getAuditorReport("")}
                      >
                        Email My Auditor Report
                      </Button>
                      <br />
                      <br />
                      {runningSarah ? (
                        <Button variant="success" disabled>
                          <Spinner animation="border" variant="warning" />
                          Loading...
                        </Button>
                      ) : (
                        <Button
                          variant="success"
                          value="download"
                          size="sm"
                          style={{ float: "left" }}
                          onClick={() => postSaraConnor(true)}
                        >
                          Email My Auditor Report with Results for the original
                          Model
                        </Button>
                      )}
                    </>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  {props.msfErrors
                    ? ResultsTable(props.msfErrors, props.msfPaths)
                    : null}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  {props.msfErrors ? (
                    <p style={{ fontSize: "11px" }}>
                      <b>*Note:</b>If the desired treatment device or source
                      node type is not found in the dropdown, contact a council
                      engineer for further information.
                    </p>
                  ) : null}
                </td>
              </tr>

              <tr>
                <td
                  colSpan="2"
                  className="uploadButtons"
                  style={{ display: "flex", justifyContent: "right" }}
                >
                  {/* Condition for checking have msf: Audit Button */}
                  {!props.msfData ? (
                    <>
                      <Button
                        variant="success"
                        value="upload"
                        size="sm"
                        onClick={(e) => {
                          props.handleCloseMusicAuditor(e);
                          setUseStateErrors(false);
                        }}
                        disabled={!props.msfData}
                      >
                        Audit MSF Model
                      </Button>
                      &nbsp;
                    </>
                  ) : null}

                  {/* Condition for checking have msf: Remove Upload Button*/}
                  {!props.msfData ? (
                    <>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                          props.setMsfData(undefined);
                          props.setMsfErrors(undefined);
                          setUseStateErrors(false);
                          setEmailSent(false);
                          setSarahEmailSent(false);
                          props.setAuditorReport(null);
                          props.setNorBeError(false);
                          props.setOtherMethodError(false);
                        }}
                        disabled={!props.msfData}
                      >
                        Upload Another Model
                      </Button>
                    </>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td width={"55%"}>
                  {props.msfData ? (
                    <>
                      {!props.msfErrors ? (
                        <>
                          <Button
                            variant="success"
                            value="upload"
                            size="sm"
                            style={{ width: "100%" }}
                            onClick={(e) => {
                              props.handleCloseMusicAuditor(e);
                              setUseStateErrors(false);
                            }}
                            disabled={!props.msfData}
                          >
                            Audit My MSF Model
                          </Button>
                          <br />
                          <br />

                          <Button
                            variant="danger"
                            size="sm"
                            style={{ width: "100%" }}
                            onClick={() => {
                              props.setMsfData(undefined);
                              props.setMsfErrors(undefined);
                              setUseStateErrors(false);
                              setEmailSent(false);
                              setSarahEmailSent(false);
                              props.setAuditorReport(null);
                              props.setNorBeError(false);
                              props.setOtherMethodError(false);
                            }}
                            disabled={!props.msfData}
                          >
                            Upload Another MUSIC Model
                          </Button>
                        </>
                      ) : (
                        <>
                          {props.msfErrors !== "Okay" &&
                          !props.msfErrors.includes("Traceback") ? (
                            <Button
                              variant="info"
                              size="sm"
                              onClick={async () => {
                                var msf = await modifyMsf();

                                const blob = new Blob([msf], {
                                  type: "text/plain;",
                                });
                                let url = window.URL.createObjectURL(blob);
                                let a = document.createElement("a");
                                a.href = url;
                                a.download = props.fileName.endsWith(
                                  "-audited.msf"
                                )
                                  ? props.fileName
                                  : `${props.fileName.substring(
                                      0,
                                      props.fileName.length - 4
                                    )}-audited.msf`;
                                a.click();
                              }}
                              disabled={!props.msfCheckerData}
                              style={{ width: "100%" }}
                            >
                              <p>
                                Make My MUSIC Model Compliant (does not include
                                the mlb)
                              </p>
                            </Button>
                          ) : null}
                          {runningSarah ? (
                            <Button variant="secondary" disabled>
                              <Spinner animation="border" variant="warning" />
                              Loading...
                            </Button>
                          ) : sarahEmailSent ? (
                            <div style={{ color: "green" }}>
                              Auditor Report for Compliant Model with Results
                              have been sent to the assessor email.
                            </div>
                          ) : (
                            <Button
                              variant="secondary"
                              size="sm"
                              onClick={() => postSaraConnor(false)}
                              disabled={!props.msfCheckerData}
                              style={{ width: "100%" }}
                            >
                              <p>
                                Email Auditor Report for Compliant Model with
                                Results.
                              </p>
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </td>

                {props.msfData ? (
                  <>
                    {props.msfErrors ? (
                      <>
                        <td width={"45%"}>
                          <tbody width={"100%"}>
                            <tr width={"100%"}>
                              <td
                                style={{
                                  width: "90%",
                                  paddingLeft: "10%",
                                  fontSize: "13px",
                                }}
                              >
                                <i>
                                  <b>
                                    MLB status: <br />
                                  </b>
                                </i>
                                {props.mlbOkay}*
                              </td>
                            </tr>

                            <tr width={"100%"}>
                              <td width={"100%"}>
                                <Button
                                  style={{ width: "100%", marginLeft: "5%" }}
                                  variant="success"
                                  value="download"
                                  size="sm"
                                  onClick={() => getMlb()}
                                >
                                  Download Correct MLB
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </td>
                      </>
                    ) : null}
                  </>
                ) : null}
              </tr>

              {props.msfData ? (
                <>
                  {props.msfErrors ? (
                    <>
                      <tr>
                        <td colSpan={2}>
                          <p style={{ fontSize: "11px" }}>
                            <b>*Note: </b>We check the *.msf file that you can
                            export from MUSIC. The *.msf file does not include
                            the *.mlb file (rainfall and evapotranspiration).
                            Please have both the *.msf and the *.mlb file in the
                            same folder in order to run your compliant MUSIC
                            model.
                          </p>
                        </td>
                      </tr>
                      <tr width={"100%"}>
                        <td colSpan={2}>
                          <Button
                            variant="danger"
                            size="sm"
                            style={{ width: "100%" }}
                            onClick={() => {
                              props.setMsfData(undefined);
                              props.setMsfErrors(undefined);
                              setUseStateErrors(false);
                              setEmailSent(false);
                              setSarahEmailSent(false);
                              props.setAuditorReport(null);
                              props.setNorBeError(false);
                              props.setOtherMethodError(false);
                            }}
                            disabled={!props.msfData}
                          >
                            Upload Another MUSIC Model
                          </Button>
                        </td>
                      </tr>
                    </>
                  ) : null}
                </>
              ) : null}

              {/* </tr> */}
            </tbody>
          </table>
        </Modal.Body>
        {/* <Modal.Footer>
          <table>
            <tbody>
              <tr>
                <td>
                  <label>
                    We'll notify you at <b>{props.email}</b> when this feature
                    is ready.{" "}
                  </label>
                </td>
                <td></td>
                <td>
                  <Button
                    variant="info"
                    value="notify"
                    size="sm"
                    onClick={props.handleCloseMusicAuditor}
                  >
                    Close
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default MusicAuditor;
