import React from "react";
import { Row } from "react-bootstrap";
import './thehome.css'

function LatestNews() {
    return (
        <>
            <Row>
                <div className="col-sm-12">
                    <h5>Latest News and Developments:</h5>
                    <ul>
                        <a href="https://www.linkedin.com/feed/update/urn:li:activity:6896181285178494976/">
                            Calculator update: complex treatment train
                        </a>
                    </ul>
                    <ul>
                        <a href="https://www.linkedin.com/posts/mircea-s-02021311b_innovate-modelling-stormwatermanagement-activity-6893649966196244480-FCST">
                            Surface photos available on OSD interface
                        </a>
                    </ul>
                    <ul>
                        <a href="https://www.linkedin.com/feed/update/urn:li:activity:6894845739810344960">
                            New tool launched: OSDs for VIPs
                        </a>
                    </ul>
                    <ul>
                        <a href="https://www.linkedin.com/posts/mircea-s-02021311b_leadership-innovation-wsud-activity-6882255480761274368-hjAR">
                            New tool launched: Auditor of MUSIC
                        </a>
                    </ul>
                    <ul>
                        <a href="https://www.linkedin.com/posts/mircea-s-02021311b_we-launched-our-first-product-in-january-activity-6878549458813112320-OjLR">
                            69 Councils accepting our reports! on 20 Dec 2021!
                        </a>
                    </ul>
                    <ul>
                        <a href="https://www.youtube.com/watch?v=VyYk8eK1BG8">
                            Award Winning Tool: Stormwater NSW 2021 Research
                            and Innovation Award!
                        </a>
                    </ul>
                </div>
            </Row>
        </>
    );
}

export default LatestNews;
