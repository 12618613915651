import axios from "axios";
import { printLog } from "../../common";

const api = axios.create({
  baseURL: window.location.href.includes("localhost")
    ? "https://su-stage.ml"
    : window.location.origin,
});

const flask_api = axios.create({
  baseURL: window.location.href.includes("localhost")
    ? "https://su-stage.ml/flask"
    : window.location.origin + "/flask",
});

export const downloader = async (props, sails_api, what, setExport) => {
  //Use regular wqMethod if no EOL device selected
  let data = Object.create(props);
  data = { ...props };
  if (data.wqMethod.includes("EOL")) {
    data = { ...data, eolDeviceName: data.eolTreatment };
    if (data.eolTreatment === "No end-of-line treatment") {
      data.wqMethod = data.wqMethod.replace("EOL", "");
      printLog("no EOL device selected");
    }
  }
  printLog("Data: ", data);
  printLog("Props: ", props);

  const address =
    data.addressMain +
    " " +
    data.addressSub +
    " " +
    data.suburb +
    " " +
    data.state +
    " " +
    data.zipcode;

  const downloadRecordInfo = {
    name: data.assessorName,
    email: data.assessorEmail,
    address: address,
    downloadButton: what,
    source: window.location.href,
    sessionID: data.sessionID,
  };

  // downloadRecordInfo.downloadButton = what;
  await sails_api.post("/RecordDownload", downloadRecordInfo);

  // If download report is clicked
  if (what === "report") {
    var issue = false;
    setExport("exportingReport", true);
    setExport("reportSent", "");
    if (data.hasOsd) {
      if (parseFloat(data.osd.tankHeight) <= 0) {
        setExport("exportingReport", false);
        issue = true;
        return;
      }
      var osdRoute = "";
      osdRoute = "/OSD/osdResults/" + data.osdMethod;
      await sails_api
        .post(osdRoute, {
          latitude: data.latitude,
          longitude: data.longitude,
          osd: data.osd,
          totalImpArea: data.totalImpArea,
          totalPArea: data.totalPArea,
          wqMethod: data.wqMethod,
        })
        .then(async (res) => {
          if (data.osdMethod === "Swinburne") {
            if (res.data.status === "warning") {
              console.log("In error");
              issue = true;
              await setExport(
                "osdUncontrolledIssue",
                "Uncontrolled flow is too great. More area must be controlled."
              );
              return;
            } else {
              data.osdUncontrolledIssue = "";
            }
          }
          data.osdResult = res.data;
        })
        .catch((err) => {
          console.log(err);
          setExport("exportingReport", true);
        });
    }

    if (issue) {
      setExport("exportingReport", false);
      return;
    }

    if (data.osdOK) {
      let reportNumber = 0;

      await sails_api
        .post("/saveReport", {
          metadata: data,
          type: what,
        })
        .then((res) => (reportNumber = res.data.reportId))
        .catch((err) => console.log(err));

      data = { ...data, emailMSFToggle: false };
      // destructing object. Leave useful data into new object
      // const { activateButtons, councilDetails, companyDetails, developmentTypes, eolTreatments,
      //   OSDCompany, OSDMaterial, addressMain, addressSub, alert2Message, alert2Variant,
      //   alert3Message, alert3Variant, alertMessage, alertVariant, assessorEmailValid,
      //   assessorNameValid, devTypeValid, deviceType, disableWQ, emailMSFToggle, exportingMlb,
      //   exportingModel, exportingReport, greyOutCatchmentSelection, hasOsd, hasWQ,
      //   impervious, ip, locationSelected, manufacturerImgLink, manufacturerLink,
      //   pervious, rainfallStations, catchmentTypes, imperviousAreaTypes, eolTypes, osdOK,
      //   osdReset, planningPermitNoValid, reportSent, show, showAlert, showAlert2, showAlert3,
      //   showOSDsAdsDiv, source, started: _, ...newData } = data;

      // get msf data
      let msfData = "";
      await sails_api
        .post("/getMSF", data)
        .then((res) => (msfData = res.data))
        .catch(() => {
          printLog("msf not available");
        });
      printLog("HERES THE MSF " + msfData);
      let msfDataPassable = msfData.replace(/&/gi, "and");

      // create node
      let name = ""
      let hasBioretentionNode = false
      let hasMediaFiltrationNode = false
      function createNode(msf, line, nodeType) {
        // the number of lines for each node
        const lineNumber = {
          UrbanSourceNode: 55,
          AgriculturalSourceNode: 54,
          ForestSourceNode: 54,
          WetlandNode: 45,
          PondNode: 44,
          SedimentationBasinNode: 44,
          DetentionBasinNode: 43,
          InfiltrationSystemNodeV4: 33,
          BioRetentionNodeV4: 38,
          MediaFiltrationNode: 34,
          BufferNode: 11,
          SwaleNode: 27,
          RainWaterTankNode: 40,
          GPTNode: 122,
          GenericNode: 122,
          ReceivingNode: 6,
          PreDevelopmentNode: 7,
          PostDevelopmentNode: 7,
          JunctionNode: 6
        }
        // save node information into object
        // node type = <different treatment node>
        let node = {}
        if (nodeType.includes("UrbanSourceNode")) {
          for (let j = 0; j < lineNumber[msf[line].split(',')[1]]; j++) {
            if (msf[line + j].includes("Node Name")) {
              name = msf[line + j].split(',')[1]
              return
            }
          }
        }
        else if (nodeType.includes("BioRetentionNodeV4")) {
          hasBioretentionNode = true
          node["nodeName"] = name.split(" ")[0]
          node["nodeType"] = nodeType
          node["eddHeight"] = parseFloat(msf[line + 10].split(',')[1]) * 1000
          node["filterArea"] = msf[line + 12].split(',')[1]
          node["filterPermeability"] = msf[line + 14].split(',')[1]
          node["filterLayerHeight"] = parseFloat(msf[line + 15].split(',')[1]) * 1000
          node["bioHeight"] = parseFloat(node["filterLayerHeight"]) + parseFloat(node["eddHeight"]) + 350
          node["treatmentType"]= msf[line + 1].split(',')[1]
        } else if (nodeType.includes("MediaFiltrationNode")) {
          hasMediaFiltrationNode = true
          node["nodeName"] = name.split(" ")[0]
          node["nodeType"] = nodeType
          node["surfaceArea"] = msf[line + 11].split(',')[1]
          node["filterArea"] = msf[line + 13].split(',')[1]
          node["filterPermeability"] = msf[line + 16].split(',')[1]
          node["filterDepth"] = parseFloat(msf[line + 14].split(',')[1]) * 1000
          node["treatmentType"]= msf[line + 1].split(',')[1]
        } 
        return node
      }

      // reading MSF to get node information
      function msfReader() {
        let msfData_split_eol = msfDataPassable.split("\n")
        let nodeList = []

        for (let i = 0; i < msfData_split_eol.length; i++) {
          if (msfData_split_eol[i].includes("Node Type")) {
            let nodeType = msfData_split_eol[i].split(',')[1]
            let nodeData = createNode(msfData_split_eol, i, nodeType)
            if (!nodeType.includes("UrbanSourceNode") && !nodeType.includes("ReceivingNode")) {
              nodeList.push(nodeData)
            }
          }
        }
        return nodeList
      }

      // get the report table information from MSF
      let nodeList = msfReader()

      // get treatment trains image
      await flask_api
        .post("/getTreatmentTrainsImage", msfDataPassable)
        .then((res) => printLog("Generated Image. Message from frontend."))
        .catch(() => printLog("Error generating Image"));
      // data = {...data, msfData};

      if (hasBioretentionNode || hasMediaFiltrationNode) {
        // send request to get calculator report
        api.post("/report", {
          template: { name: "storm-report-main" },
          data: {
            ...data,
            reportNumber: reportNumber,
            nodeInfo: nodeList
          },
        }, { responseType: "blob" })
          .then(() => {
            setExport("exportingReport", false);
            setExport("reportSent", "report");
          })
          .catch(() => {
            setExport("exportingReport", false);
          });
      } else {
        // send request to get calculator report
        api.post("/report", {
          template: { name: "storm-report-main" },
          data: {
            ...data,
            reportNumber: reportNumber,
            nodeInfo: []
          },
        }, { responseType: "blob" })
          .then(() => {
            setExport("exportingReport", false);
            setExport("reportSent", "report");
          })
          .catch(() => {
            setExport("exportingReport", false);
          });
      }

    }
  }

  // If download report is clicked
  if (what === "simple-report") {
    var issue = false;
    setExport("exportingReport", true);
    setExport("reportSent", "");
    if (data.hasOsd) {
      if (!data.osd.tankHeight === "" && parseFloat(data.osd.tankHeight) <= 0) {
        setExport("exportingReport", false);
        issue = true;
        return;
      }

      var osdRoute = "";
      osdRoute = "/OSD/osdResults/" + data.osdMethod;

      await sails_api
        .post(osdRoute, {
          latitude: data.latitude,
          longitude: data.longitude,
          osd: data.osd,
          totalImpArea: data.totalImpArea,
          totalPArea: data.totalPArea,
        })
        .then(async (res) => {
          printLog(data.osdMethod, res);
          if (data.osdMethod === "Swinburne") {
            if (res.data.Qp < 0 || res.data.Error) {
              console.log("In error");
              issue = true;
              await setExport(
                "osdUncontrolledIssue",
                "Uncontrolled flow is too great. More area must be controlled."
              );
              return;
            } else {
              data.osdUncontrolledIssue = "";
            }
          }
          data.osdResult = res.data;
        })
        .catch((err) => {
          console.log(err);
          console.log("In error, data are ", data);
          setExport("exportingReport", true);
        });
    }

    if (issue) {
      setExport("exportingReport", false);
      return;
    }

    if (data.osdOK) {
      let reportNumber = 0;
      sails_api
        .post("/saveReport", {
          metadata: data,
          overallRatings: data.overallResults.overallRatings,
          type: what,
        })
        .then((res) => {
          reportNumber = res.data.reportId;
        })
        .catch((err) => console.log(err));

      api
        .post(
          "/report",
          {
            template: { name: "storm-report-simple" },
            data: { ...data, reportNumber: reportNumber },
          },
          { responseType: "blob" }
        )
        .then((res) => {
          let url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          let a = document.createElement("a");
          a.href = url;
          a.download = "Storm_Report.pdf";
          setExport("exportingReport", false);
          setExport("reportSent", "report");
        })
        .catch((err) => {
          setExport("exportingReport", false);
        });
    }
  }

  // If download model is clicked
  if (what === "model") {
    setExport("exportingModel", true);
    setExport("reportSent", "");
    data = { ...data, emailMSFToggle: true };
    sails_api
      .post("/getMSF", data)
      .then((res) => {
        // if(data.assessorEmail.includes("@cleanstormwater.com.au") || data.assessorEmail === "1@1.com"){
        //   const blob = new Blob([res.data], { type: "text/plain;" });
        //   let url = window.URL.createObjectURL(blob);
        //   let a = document.createElement("a");
        //   a.href = url;
        //   a.download = "Model.msf";
        //   a.click();
        // }
        setExport("exportingModel", false);
        setExport("reportSent", "model");
      })
      .catch((err) => setExport("exportingModel", false));
  }

  // If download mlb is clicked
  if (what === "mlb") {
    setExport("exportingMlb", true);
    setExport("reportSent", "");
    let mlbDownloadName = data.rainfallStation + ".mlb";
    var mlburl = null;
    await sails_api
      .post("/downloadMLB", data)
      .then((res) => {
        mlburl = res.data.mlbUrl;
      })
      .catch((err) => {
        console.log(err);
        setExport("exportingMlb", false);
      });

    if (mlburl) {
      var element = document.createElement("a");
      element.setAttribute("href", mlburl);
      element.setAttribute("download", mlbDownloadName);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      setExport("exportingMlb", false);
    }
  }
};
