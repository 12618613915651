import React from "react";
import "./assets/css/osd.css";
import { Button, Row, Col } from "react-bootstrap";
import { printLog } from "./common";

const colour1 = "#ccb564";
const colour2 = "#6cb86f";
const colour3 = "#5073c9";

class OsdResultsTable extends React.Component {
  constructor(props) {
    super(props);

    /*NEED TO PASS:
            data
            osdInputsValid
            psd
            climateAdjustment
        */
  }
  isGovEmail = (assessorEmail) => {

    let indexOfGov = assessorEmail.lastIndexOf(".gov");

    let indexOfAt = assessorEmail.lastIndexOf("@");

    if(indexOfGov > 0) {

      if(indexOfGov > indexOfAt){

        return true;

      }

    }

    return false;

  }
  resetManufacturerDetails = () => {
    this.props.setOSD("manufacturerImgLink", "");
    this.props.setOSD("OSDMaterial","")
    this.props.setOSD("OSDCompany","")
  }

  render() {
    return (
      <>
        {true ? (
          <>
            {this.props.data.osdResult !== null && this.props.osdInputsValid ? (
              <>
                <table
                  className="table table-horizontal table-bordered col-sm-6"
                  style={{ marginTop: "20px" }}
                >
                  <thead
                    style={{
                      backgroundColor: "#508bc9",
                      color: "white",
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th
                        colSpan="10"
                        style={{ fontSize: "16px", padding: "4px" }}
                      >
                        OSD Results
                      </th>
                    </tr>
                    <tr>
                      <td>🤖 Says: check out the sewerage treatment and management products built by</td>
                      <th
                        colSpan="3"
                        style={{ padding: "4px", backgroundColor: colour1, verticalAlign: "middle"}}
                      >
                        <Button
                        
                        
                        className="aboveGroundButton"
                        onClick={async() => {
                         await this.props.setList("storageType","Above ground storage");
                         let assessorEmail = this.props.data.assessorEmail;

                         if(!this.isGovEmail(assessorEmail)){

                           this.props.setOSD("showOSDsAdsDiv",true);

                         }
                          //this.setState( this.props.showOSDsAdsDiv= true );
                          
                          this.props.setOSD("deviceType","Above ground Storage");
                          this.props.companyDetails("above_slab_tanks");
                          this.resetManufacturerDetails()
                        

                        }}
                      >
                        Above ground Storage
                      </Button>
                      </th>
                      <th
                        colSpan="3"
                        style={{ padding: "4px", backgroundColor: colour2, verticalAlign: "middle" }}
                      >
                        <Button
                        // style={{backgroundColor:colour2,color:"white",borderColor:"white"}}
                        // name="storageType"
                        // value="Below ground Pipe"
                        className="belowGroundPipeButton"
                        
                        onClick={async() => {
                          
                         await this.props.setList("storageType", "Below ground pipe");
                         let assessorEmail = this.props.data.assessorEmail;

                         if(!this.isGovEmail(assessorEmail)){

                           this.props.setOSD("showOSDsAdsDiv",true);

                         }
                         
                         this.props.setOSD("deviceType","Below ground Pipe");
                         this.props.companyDetails("pipe");
                        this.resetManufacturerDetails()
                           
                          
                        }}
                      >
                        Below ground Pipe
                      </Button>
                      </th>
                      <th
                        colSpan="3"
                        style={{ padding: "4px", backgroundColor: colour3, verticalAlign: "middle" }}
                      >
                                              {" "}
                      <Button
                      className="belowGroundtankButton"
                      // style={{backgroundColor:"#5073c9  ",color:"white",borderColor:"white"}}
                      //   type="button"
                      //   name="storageType"
                      //   value="Below Ground tank"
                        onClick={async () => {
                          await this.props.setList("storageType", "Below ground tank");
                          let assessorEmail = this.props.data.assessorEmail;

                         if(!this.isGovEmail(assessorEmail)){

                           this.props.setOSD("showOSDsAdsDiv",true);

                         }
                          
                          this.props.setOSD("deviceType","Below ground tank");
                          this.props.companyDetails("buried_tanks");
                          this.resetManufacturerDetails()
                          
                        }}
                      >
                        {" "}
                        Below ground Tank
                      </Button>
                      </th>
                    </tr>
                    <tr>
                      <th><img style={{width: "200px"}} src={require("./assets/img/Hydrocon 1.6 ratio.png").default} /></th>
                      <th style={{ padding: "4px", backgroundColor: colour1, verticalAlign: "middle" }}>
                        Permissible Discharge
                      </th>
                      <th style={{ padding: "4px", backgroundColor: colour1, verticalAlign: "middle" }}>
                        On site detention volume
                      </th>
                      <th style={{ padding: "4px", backgroundColor: colour1, verticalAlign: "middle" }}>
                        Orifice Diameter
                      </th>
                      <th style={{ padding: "4px", backgroundColor: colour2, verticalAlign: "middle" }}>
                        Permissible Discharge
                      </th>
                      <th style={{ padding: "4px", backgroundColor: colour2, verticalAlign: "middle" }}>
                        On site detention volume
                      </th>
                      <th style={{ padding: "4px", backgroundColor: colour2, verticalAlign: "middle" }}>
                        Orifice Diameter
                      </th>
                      <th style={{ padding: "4px", backgroundColor: colour3, verticalAlign: "middle" }}>
                        Permissible Discharge
                      </th>
                      <th style={{ padding: "4px", backgroundColor: colour3, verticalAlign: "middle" }}>
                        On site detention volume
                      </th>
                      <th style={{ padding: "4px", backgroundColor: colour3, verticalAlign: "middle" }}>
                        Orifice Diameter
                      </th>
                    </tr>
                  </thead>

                  <tbody
                    style={{
                      border: "1.5px solid #c7c7ce",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    <tr>
                      <td style={{backgroundColor: "#508bc9",color:"white"}}>
                        {this.props.data.osd.method === "UPRCT"?"Extended detention":"As designed minor systems"}
                        </td>
                      <td
                        style={{
                          padding: "4px",
                          backgroundColor: colour1,
                          color: "white",
                          verticalAlign: "middle"
                        }}
                      >
                        {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.OSDDischarge_PO:this.props.data.osd.method === "Swinburne Method" ? (this.props.psd
                          ? this.props.data.osd.councilPSD
                          : parseFloat(
                              this.props.data.osdResult.data
                                .above_ground_storage.PSD
                            ).toFixed(2)) : (this.props.psd
                              ? this.props.data.osd.councilPSD
                              : parseFloat(this.props.data.osdResult.q_pre).toFixed(
                                  2
                                ))}{" "}
                        L/s
                      </td>
                      <td
                        style={{
                          padding: "4px",
                          backgroundColor: colour1,
                          color: "white",
                          verticalAlign: "middle"
                        }}
                      >
                        {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.storageVolED:this.props.data.osd.method === "Swinburne Method" ? ((
                          this.props.data.osdResult.data.above_ground_storage
                            .onSiteDetentionVolume *
                          this.props.climateAdjustment
                        ).toFixed(2)) : ((this.props.data.osdResult.max_volume*this.props.climateAdjustment).toFixed(2))}{" "}
                        m<sup>3</sup>
                      </td>
                      <td
                        style={{
                          padding: "4px",
                          backgroundColor: colour1,
                          color: "white",
                          verticalAlign: "middle"
                        }}
                      >
                        {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.orificeDiameterCalcED:this.props.data.osd.method === "Swinburne Method" ? (this.props.data.osdResult.data.above_ground_storage
                          .orificeDiameter !== "N/A"
                          ? this.props.data.osdResult.data.above_ground_storage.orificeDiameter.toFixed(
                              2
                            ) + " mm"
                          : "N/A") : (this.props.data.osdResult.orificeDiameter === "N/A"
                          ? "N/A"
                          : parseFloat(this.props.data.osdResult.orificeDiameter).toFixed(2) +
                            "mm")}
                      </td>
                      <td
                        style={{
                          padding: "4px",
                          backgroundColor: colour2,
                          color: "white",
                          verticalAlign: "middle"
                        }}
                      >
                        {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.OSDDischarge_PO:this.props.data.osd.method === "Swinburne Method" ? (this.props.psd
                          ? this.props.data.osd.councilPSD
                          : parseFloat(
                              this.props.data.osdResult.data.below_ground_pipe
                                .PSD
                            ).toFixed(2)) : this.props.psd
                            ? this.props.data.osd.councilPSD
                            : parseFloat(this.props.data.osdResult.q_pre).toFixed(
                                2
                              )}{" "}
                        L/s
                      </td>
                      <td
                        style={{
                          padding: "4px",
                          backgroundColor: colour2,
                          color: "white",
                          verticalAlign: "middle"
                        }}
                      >
                        {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.storageVolED:this.props.data.osd.method === "Swinburne Method" ? ((
                          this.props.data.osdResult.data.below_ground_pipe
                            .onSiteDetentionVolume *
                          this.props.climateAdjustment
                        ).toFixed(2)): ((this.props.data.osdResult.max_volume*this.props.climateAdjustment).toFixed(2))}{" "}
                        m<sup>3</sup>
                      </td>
                      <td
                        style={{
                          padding: "4px",
                          backgroundColor: colour2,
                          color: "white",
                          verticalAlign: "middle"
                        }}
                      >
                        {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.orificeDiameterCalcED:this.props.data.osd.method === "Swinburne Method" ? (this.props.data.osdResult.data.below_ground_pipe
                          .orificeDiameter !== "N/A"
                          ? this.props.data.osdResult.data.below_ground_pipe.orificeDiameter.toFixed(
                              2
                            ) + " mm"
                          : "N/A") : this.props.data.osdResult.orificeDiameter === "N/A"
                          ? "N/A"
                          : parseFloat(this.props.data.osdResult.orificeDiameter).toFixed(2) +
                            "mm"}
                      </td>
                      <td
                        style={{
                          padding: "4px",
                          backgroundColor: colour3,
                          color: "white",
                          verticalAlign: "middle"
                        }}
                      >
                        {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.OSDDischarge_PO:this.props.data.osd.method === "Swinburne Method" ? (this.props.psd
                          ? this.props.data.osd.councilPSD
                          : parseFloat(
                              this.props.data.osdResult.data.below_ground_tank
                                .PSD
                            ).toFixed(2)) : this.props.psd
                            ? this.props.data.osd.councilPSD
                            : parseFloat(this.props.data.osdResult.q_pre).toFixed(
                                2
                              )}{" "}
                        L/s
                      </td>
                      <td
                        style={{
                          padding: "4px",
                          backgroundColor: colour3,
                          color: "white",
                          verticalAlign: "middle"
                        }}
                      >
                        {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.storageVolED:this.props.data.osd.method === "Swinburne Method" ? ((
                          this.props.data.osdResult.data.below_ground_tank
                            .onSiteDetentionVolume *
                          this.props.climateAdjustment
                        ).toFixed(2)) :((this.props.data.osdResult.max_volume*this.props.climateAdjustment).toFixed(2)) }{" "}
                        m<sup>3</sup>
                      </td>
                      <td
                        style={{
                          padding: "4px",
                          backgroundColor: colour3,
                          color: "white",
                          verticalAlign: "middle"
                        }}
                      >
                        {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.orificeDiameterCalcED:this.props.data.osd.method === "Swinburne Method" ? (this.props.data.osdResult.data.below_ground_tank
                          .orificeDiameter !== "N/A"
                          ? this.props.data.osdResult.data.below_ground_tank.orificeDiameter.toFixed(
                              2
                            ) + " mm"
                          : "N/A") : (this.props.data.osdResult.orificeDiameter === "N/A"
                          ? "N/A"
                          : parseFloat(this.props.data.osdResult.orificeDiameter).toFixed(2) +
                            "mm")}
                      </td>
                    </tr>
                    {this.props.data.osd.method === "UPRCT"?<><tr>
                      <td style={{backgroundColor: "#508bc9",color:"white"}}>{this.props.data.osd.method === "UPRCT"?"Flood Detention":"Major system req's"}</td>
                      <td style={{ padding: "4px",color:"white", backgroundColor: colour1, verticalAlign: "middle" }}>
                        {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.OSDDischarge_SO:"N/A"}
                      </td>
                      <td style={{ padding: "4px",color:"white", backgroundColor: colour1, verticalAlign: "middle" }}>
                      {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.storageVolDet:"N/A"}
                      </td>
                      <td style={{ padding: "4px",color:"white", backgroundColor: colour1, verticalAlign: "middle" }}>
                      {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.orificeDiameterCalcD:"N/A"}
                      </td>
                      <td style={{ padding: "4px",color:"white", backgroundColor: colour2, verticalAlign: "middle" }}>
                      {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.OSDDischarge_SO:"N/A"}
                      </td>
                      <td style={{ padding: "4px",color:"white", backgroundColor: colour2, verticalAlign: "middle" }}>
                      {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.storageVolDet:"N/A"}
                      </td>
                      <td style={{ padding: "4px",color:"white", backgroundColor: colour2, verticalAlign: "middle" }}>
                      {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.orificeDiameterCalcD:"N/A"}
                      </td>
                      <td style={{ padding: "4px",color:"white", backgroundColor: colour3, verticalAlign: "middle" }}>
                      {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.OSDDischarge_SO:"N/A"}
                      </td>
                      <td style={{ padding: "4px",color:"white", backgroundColor: colour3, verticalAlign: "middle" }}>
                      {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.storageVolDet:"N/A"}
                      </td>
                      <td style={{ padding: "4px",color:"white", backgroundColor: colour3, verticalAlign: "middle" }}>
                      {this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.orificeDiameterCalcD:"N/A"}
                      </td>
                    </tr>
                    <tr>
                    <td style={{backgroundColor: "#508bc9",color:"white"}}>
                        {this.props.data.osd.method === "UPRCT"?"Total Storage":null}
                        </td>
                      <td colSpan="3" style={{ padding: "4px",color:"white", backgroundColor: colour1, verticalAlign: "middle" }}>{this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.totalStorageVol:"N/A"}</td>
                      <td colSpan="3" style={{ padding: "4px",color:"white", backgroundColor: colour2, verticalAlign: "middle" }}>{this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.totalStorageVol:"N/A"}</td>
                      <td colSpan="3" style={{ padding: "4px",color:"white", backgroundColor: colour3, verticalAlign: "middle" }}>{this.props.data.osd.method === "UPRCT"?this.props.data.osdResult.totalStorageVol:"N/A"}</td>
                    </tr></>:null}
                  </tbody>
                </table>
              </>
            ) : null}
          </>
        ) : null}
        {false ? (
          <>
            {this.props.data.osdResult !== null && this.props.osdInputsValid ? (
              <>
                <table
                  className="table table-horizontal table-bordered col-sm-7"
                  style={{ marginTop: "20px" }}
                >
                  <thead
                    style={{
                      backgroundColor: "#508bc9",
                      color: "white",
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th
                        colSpan="3"
                        style={{ fontSize: "16px", padding: "4px" }}
                      >
                        OSD Results
                      </th>
                    </tr>
                    <tr>
                      <th style={{ padding: "4px" }}>Permissible Discharge</th>
                      <th style={{ padding: "4px" }}>
                        On site detention volume
                      </th>
                      <th style={{ padding: "4px" }}>Orifice Diameter</th>
                    </tr>
                  </thead>

                  <tbody
                    style={{
                      border: "1.5px solid #c7c7ce",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    <tr>
                      <td style={{ padding: "4px" }}>
                        {this.props.psd
                          ? this.props.data.osd.councilPSD
                          : parseFloat(this.props.data.osdResult.q_pre).toFixed(
                              2
                            )}{" "}
                        L/s
                      </td>
                      <td style={{ padding: "4px" }}>
                        {(
                          this.props.data.osdResult.max_volume *
                          this.props.climateAdjustment
                        ).toFixed(2)}{" "}
                        m<sup>3</sup>
                      </td>
                      <td style={{ padding: "4px" }}>
                        {this.props.data.osdResult.orificeDiameter === "N/A"
                          ? "N/A"
                          : parseFloat(
                              this.props.data.osdResult.orificeDiameter
                            ).toFixed(2) + "mm"}{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : null}
          </>
        ) : null}
      </>
    );
  }
}

export default OsdResultsTable;
