import React, { Component } from "react";

export default class Tour extends Component {
  componentDidMount() {
    this.props.context.start();
  }

  render() {
    return null;
  }
}