import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  Alert,
  Dropdown,
  DropdownButton,
  Spinner,
} from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import * as _ from "lodash";
import { isInt, isFloat } from "validator";
import { mapListToDic, printLog } from "./common";
import "./assets/css/impervious.css";

// Reference : https://www.cluemediator.com/add-or-remove-input-fields-dynamically-with-reactjs

class ImperviousNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMessage: "",
      showAlert: false,
      alertVariant: "",
    };

    if (props.imperviousNodes.length > 0) {
      let l = props.imperviousNodes[0].treatmentTypes.map((node) => {
        return node.tnodename;
      });
      props.imperviousNodes[0].treatmentTypesList = mapListToDic(l);
      props.imperviousNodes[0].isLoading = false;
      // .push(mapListToDic(l));
    }

    // this.state.isLoading.push(false);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  async componentDidMount() { }

  // map the EOL treatment name for the dropdown list
  // Some name is not the same with Aria stored
  // This step will get rid of the chaneg???
  // Edit by Ming (Just gussing, not yet approved)
  filterUniqueTypes(array) {
    let uniqueTypes = [];
    let newArray = [];
    array.forEach((item) => {
      if (
        !uniqueTypes.includes(item.eol_device) &&
        item.eol_device !== "No end-of-line treatment"
      ) {
        uniqueTypes.push(item.eol_device);
        newArray.push(item);
      }
    });
    var sortedArray = newArray.sort((a, b) => {
      var nameA = a.eol_device.toUpperCase();
      var nameB = b.eol_device.toUpperCase();
      if (nameA < nameB) {
        return -1; //nameA comes first
      }
      if (nameA > nameB) {
        return 1; // nameB comes first
      }
      return 0; // names must be equal
    });
    sortedArray.unshift({ eol_device: "No end-of-line treatment" });
    return sortedArray;
  }

  async componentDidUpdate(prevProps) {
    // when catchment type is changed
    // update the sourcenode types one of the source node types available for the new catchment type
    // change all treatment types to None and treatment areas to ""
    const list = this.props.imperviousNodes;

    if (prevProps.catchmentType !== this.props.catchmentType) {
      list.forEach(async (value, index) => {
        var newsrcType = "";
        if (this.props.catchmentType !== "Mixed") {
          this.props.imperviousAreaTypes.some((value, index) => {
            if (value.snodetype !== "Mixed") {
              newsrcType = value.snodetype;
              return true;
            }
            return false;
          });
        } else if (
          this.props.imperviousAreaTypes.some((value, index) => {
            if (value.snodetype === "Mixed") {
              newsrcType = "Mixed";
              return true;
            }
            return false;
          })
        );

        // trigger change on input fields to reset all dropdowns and make necessary api calls.
        this.handleInputChange(
          {
            target: {
              name: "impAreaType",
              value: newsrcType,
            },
          },
          index
        );
        this.handleInputChange(
          {
            target: {
              name: "impAreaTreatType",
              value: "None",
            },
          },
          index
        );
        this.handleInputChange(
          {
            target: {
              name: "impAreaTreatSize",
              value: "",
            },
          },
          index
        );
        this.handleInputChange(
          {
            target: {
              name: "eolTreatment",
              value: "No end-of-line treatment",
            },
          },
          index
        );
        this.handleInputChange(
          {
            target: {
              name: "eolTreatmentSize",
              value: "",
            },
          },
          index
        );
      });

      this.props.calculateOverallResults();
    }

    // list.forEach(async (node, index) => {
    //   let l = node.treatmentTypes.map((node) => {
    //     return node.tnodename;
    //   });
    //   [index] = mapListToDic(l);
    // });
  }

  // handle input change
  async handleInputChange(e, index) {
    let { name, value } = e.target;
    var list = [...this.props.imperviousNodes];

    if (name === "eolTreatment") {
      await this.props.setVariable("eolTreatment", value);

      list.forEach(async (v, i) => {
        list[i]["impAreaTreatType"] = "None";
        list[i]["impAreaTreatSize"] = "";
        await this.props.sails_api
          .get(
            "/TreatmentNodesLinear/" +
            this.props.state +
            "/" +
            this.props.rainfallStation +
            "/" +
            this.props.catchmentType +
            "/" +
            list[i]["impAreaType"] +
            "/" +
            value
          )
          .then((res) => {
            this.handleInputChange(
              {
                target: {
                  name: "impAreaType",
                  value: list[i]["impAreaType"],
                },
              },
              i
            );
            this.handleInputChange(
              {
                target: {
                  name: "eolTreatmentSize",
                  value: "",
                },
              },
              i
            );
          });
      });
    }
    if (name === "eolTreatmentSize") {
      if (isFloat(value) || value === "") {
        await this.props.setVariable("eolTreatmentSize", value);
      } else {
        return;
      }
    }

    // this.setState({});
    if (name === "impAreaType") {
      if (this.props.wqMethod.includes("EOL")) {
        // Call backend StateController
        // getTreatmentNodesLinear method
        await this.props.sails_api
          .get(
            "/TreatmentNodesLinear/" +
            this.props.state +
            "/" +
            this.props.rainfallStation +
            "/" +
            this.props.catchmentType +
            "/" +
            // value represent eolTreatmentSize
            value +
            "/" +
            this.props.eolTreatment
          )
          .then((res) => {
            list[index]["treatmentTypes"] = res.data;
            let l = list[index].treatmentTypes.map((node) => {
              return node.tnodename;
            });
            this.props.imperviousNodes[index].treatmentTypesList =
              mapListToDic(l);
          });
      } else {
        await this.props.sails_api
          .get(
            "/TreatmentNodesLinear/" +
            this.props.state +
            "/" +
            this.props.rainfallStation +
            "/" +
            this.props.catchmentType +
            "/" +
            value
          )
          .then((res) => {
            list[index]["treatmentTypes"] = res.data;
            let l = list[index].treatmentTypes.map((node) => {
              return node.tnodename;
            });
            this.props.imperviousNodes[index].treatmentTypesList =
              mapListToDic(l);
          }); // Loading the treatment types from DB
      }
      list[index]["impAreaTreatType"] = "None"; // reset treatment type when source area type changes
      list[index]["impAreaBrooms"] = ""; // reset any value in occupant or bedroom field
      list[index]["showAlert"] = false;
    }

    if (name === "impAreaTreatType") {
      // To show or hide occupants/bedrooms depending on the treatment type
      list[index]["showAlert"] = false;

      list[index]["apiBaseRoute"] = list[index]["treatmentTypes"].find(
        (item) => {
          if (item.tnodename === value) {
            return item;
          }
        }
      ).endpoint;

      //set placeholder as default for LinearReduction
      list[index]["placeholderUnit"] = "Hi-flow bypass (L/s)";
      list[index]["impAreaTreatSize"] = "";

      if (list[index]["apiBaseRoute"] !== "LinearReduction") {
        if (list[index]["apiBaseRoute"] !== "getHydrochannelResults") {
          if (list[index]["apiBaseRoute"] !== "getNoTreatmentResults") {
            await this.props.sails_api
              .get(
                "/maxRatio/" +
                this.props.rainfallStation +
                "/" +
                this.props.catchmentType +
                "/" +
                list[index]["impAreaType"] +
                "/" +
                value
              )
              .then(
                (res) => (list[index]["boundsValueOrRatio"] = res.data.maxRatio)
              ); // for the bounds for RWT and Bio-Retention
          }
          list[index]["impAreaTreatSize"] = "";
          if (list[index]["apiBaseRoute"] === "getRainwatertankResults") {
            await this.props.sails_api
              .get(
                "RWTminSourceArea/" +
                this.props.rainfallStation +
                "/" +
                this.props.catchmentType +
                "/" +
                list[index]["impAreaType"] +
                "/" +
                value
              )
              .then(
                (res) => (list[index]["minSourceArea"] = res.data.minSrcArea)
              );
            list[index]["placeholderUnit"] = "Size (L)";
          } else {
            // if it is not rainwatertank i.e. it is bioretention
            list[index]["impAreaBrooms"] = "";
            list[index]["placeholderUnit"] = "Size (m²)";
          }
        } else {
          await this.props.sails_api
            .get(
              "/HFBPminSourceArea/" +
              this.props.rainfallStation +
              "/" +
              this.props.catchmentType +
              "/" +
              list[index]["impAreaType"] +
              "/" +
              value
            )
            .then(
              (res) => (list[index]["minSourceArea"] = res.data.minSrcArea)
            );
          await this.props.sails_api
            .get(
              "/HFBPmaxSourceArea/" +
              this.props.rainfallStation +
              "/" +
              this.props.catchmentType +
              "/" +
              list[index]["impAreaType"] +
              "/" +
              value
            )
            .then(
              (res) => (list[index]["maxSourceArea"] = res.data.maxSrcArea)
            );
          list[index]["impAreaTreatSize"] = "";
          list[index]["placeholderUnit"] = "Hi-flow bypass (L/s)";
          list[index]["impAreaBrooms"] = "";
        }
      }
    }

    if (name === "impAreaSize") {
      // To have only 2 digits
      if (value !== "") {
        var pattern = /^\d+(?:\.\d{0,2})?$/i;
        if (!pattern.test(value)) {
          return;
        }
      }
      list[index]["impAreaSize"] = value;
      list[index]["impAreaTreatSize"] = "";
      list[index]["impAreaBrooms"] = "";

      // reset osd pre dev fields when site area changes.
      if (this.props.hasOsd) {
        this.props.osd.preDevPArea = "";
        this.props.osd.preDevImpArea = "";
        this.props.osd.postDevPArea = "";
        this.props.osd.postDevImpArea = "";
        this.props.osd.uncontrolledImpArea = "";
        this.props.osd.uncontrolledPArea = "";
      }
    }

    if (
      name === "impAreaTreatSize" &&
      list[index]["apiBaseRoute"] !== "getHydrochannelResults" &&
      list[index]["apiBaseRoute"] !== "LinearReduction"
    ) {
      // accept float values for bioretention and roof garden up to 2 decimal places
      if (
        list[index]["impAreaTreatType"].includes("Bioretention") ||
        list[index]["impAreaTreatType"].includes("Roof Garden") ||
        list[index]["impAreaTreatType"].includes("Permeable Pavement")
      ) {
        if (value !== "") {
          // To have only 2 digits
          var pattern = /^\d+(?:\.\d{0,2})?$/i;
          if (!pattern.test(value)) {
            return;
          }
        }
      } else {
        if (value !== "") value = parseInt(value);
      }

      if (list[index]["apiBaseRoute"] === "getBioretentionResults") {
        // If bioretention, treatment area can't be greater than source area
        if (
          parseFloat(value) <= parseFloat(list[index]["impAreaSize"]) ||
          value === ""
        ) {
          list[index]["impAreaTreatSize"] = value;
          list[index]["showAlert"] = false;
        } else {
          list[index]["showAlert"] = true;
          list[index]["alertMessage"] =
            "⛔ Treatment size cannot be greater than the impervious area size — (" +
            list[index]["impAreaSize"] +
            "m²)";
          list[index]["alertVariant"] = "danger";
        }
        if (
          parseFloat(value) / parseFloat(list[index]["impAreaSize"]) >
          list[index]["boundsValueOrRatio"]
        ) {
          list[index]["showAlert"] = true;
          list[index]["alertMessage"] =
            "⚠️ Treatment size greater than " +
            (
              parseFloat(list[index]["impAreaSize"]) *
              list[index]["boundsValueOrRatio"]
            )
              .toFixed(3)
              .replace(/\.?0+$/, "") +
            "m² will not result in significant additional treatment.";
          list[index]["alertVariant"] = "warning";
        }
      } else {
        if (list[index]["apiBaseRoute"] === "getRainwatertankResults") {
          // if rainwatertank, treatment area can't be < 0.1*source area
          if (
            parseFloat(value) <
            0.1 * parseFloat(list[index]["impAreaSize"])
          ) {
            list[index]["showAlert"] = true;
            list[index]["alertMessage"] =
              "⚠️ Treatment Size is too small. Please use a Rainwater tank with at least 1L per 1m² of catchment. Unless increased, a minimum of " +
              (0.1 * parseFloat(list[index]["impAreaSize"]))
                .toFixed(3)
                .replace(/\.?0+$/, "") +
              "L will be used.";
            list[index]["alertVariant"] = "warning";
            list[index]["impAreaTreatSize"] = value;
          } else {
            list[index]["impAreaTreatSize"] = value;
            list[index]["showAlert"] = false;
          }
          if (
            parseFloat(value) / parseFloat(list[index]["impAreaSize"]) >
            list[index]["boundsValueOrRatio"]
          ) {
            list[index]["showAlert"] = true;
            list[index]["alertMessage"] =
              "⚠️ A rainwater tank volume greater than " +
              (
                parseFloat(list[index]["impAreaSize"]) *
                list[index]["boundsValueOrRatio"]
              )
                .toFixed(3)
                .replace(/\.?0+$/, "") +
              "L will not result in significant additional treatment.";
            list[index]["alertVariant"] = "warning";
          }
        } else {
          return;
        }
      }
    }

    if (
      name === "impAreaTreatSize" &&
      list[index]["apiBaseRoute"] === "getHydrochannelResults"
    ) {
      // to accept only whole numbers if treatment type comes from hfbp - getHydroChannelResult
      if (isInt(value) || value === "") {
        if (
          parseFloat(list[index]["impAreaSize"]) / parseFloat(value) >
          parseFloat(list[index]["maxSourceArea"])
        ) {
          list[index]["showAlert"] = true;
          list[index]["alertMessage"] =
            "⚠️ The Treatment Size (No. of items) is too small. Please use at least one item per " +
            list[index]["maxSourceArea"] +
            "m² of catchment. Unless increased, a minimum of " +
            Math.ceil(
              parseFloat(list[index]["impAreaSize"]) /
              parseFloat(list[index]["maxSourceArea"])
            ) +
            " items will be used.";
          list[index]["alertVariant"] = "warning";
        } else {
          if (
            parseFloat(list[index]["impAreaSize"]) / parseFloat(value) <
            parseFloat(list[index]["minSourceArea"])
          ) {
            list[index]["showAlert"] = true;
            list[index]["alertMessage"] =
              "⚠️ A Treatment Size (No. of items) greater than " +
              Math.floor(
                parseFloat(list[index]["impAreaSize"]) /
                parseFloat(list[index]["minSourceArea"])
              ) +
              " items will not result in significant additional treatment.";
            list[index]["alertVariant"] = "warning";
          } else {
            list[index]["showAlert"] = false;
          }
        }

        list[index]["impAreaTreatSize"] = value;
      } else {
        return;
      }
    }

    if (
      name === "impAreaTreatSize" &&
      list[index]["apiBaseRoute"] === "LinearReduction"
    ) {
      list[index]["impAreaTreatSize"] = value;
    }

    if (name === "impAreaBrooms") {
      // to accept only numbers in bedrooms/occupants
      if (isInt(value) || value === "") {
        list[index]["impAreaBrooms"] = value;
        if (
          list[index]["minSourceArea"] >
          (list[index]["impAreaSize"] / value) * 100
        ) {
          list[index]["showAlert"] = true;
          list[index]["alertMessage"] =
            "⚠️ The demand is too high for this source area. A demand greater than " +
            Math.round(
              (list[index]["impAreaSize"] / list[index]["minSourceArea"]) * 100
            ) +
            " bedrooms/occupants will not significantly improve treatment. Unless reduced, a maximum of " +
            Math.round(
              (list[index]["impAreaSize"] / list[index]["minSourceArea"]) * 100
            ) +
            " bedrooms/occupants will be applied for calculation.";
          list[index]["alertVariant"] = "warning";
        } else {
          list[index]["showAlert"] = false;
        }
      } else {
        return;
      }
    }

    list[index][name] = value;
    //💥💥💥💥💥💥💥💥💥💥💥💥 Insta result💥💥💥💥💥💥💥💥💥💥💥💥
    list = await this.computeLiveResults(list, index);

    // await this.props.setImperviousNodes(list);
    await this.props.setVariable("imperviousNodes", list);
    this.props.calculateOverallResults();

    if (this.props.wqMethod.includes("NorBE")) {
      this.props.osd.postDevPArea = parseFloat(this.props.totalPostDevPArea);
      this.props.osd.postDevImpArea = parseFloat(
        this.props.totalPostDevImpArea
      );
      this.props.osd.preDevPArea = parseFloat(this.props.totalPArea);
      this.props.osd.preDevImpArea = parseFloat(this.props.totalImpArea);
    }

    // change manufacturer's tooltip link
    var treatmentLink = "";
    if (list[index]["impAreaTreatType"] === "SQIDEP Hydrochannel (1m)") {
      treatmentLink = "https://cleanstormwater.com.au/products";
    } else if (list[index]["impAreaTreatType"] === "SQIDEP SPELBasin (2Lps)") {
      treatmentLink = "https://cleanstormwater.com.au/products";
    } else if (
      list[index]["impAreaTreatType"] === "SPEL Hydrosystem+Stormsack"
    ) {
      treatmentLink = "https://cleanstormwater.com.au/products";
    } else if (
      list[index]["impAreaTreatType"] === "Ecosol Storm Pit Class 2 (10Lps)"
    ) {
      treatmentLink = "https://cleanstormwater.com.au/products";
    }
    this.props.setVariable("manufacturerLink", treatmentLink);

    //set EOL placeholder values
    if (
      list[index]["impAreaTreatType"].includes("Rainwater") &&
      this.props.eolTreatment !== "No end-of-line treatment" &&
      this.props.eolTreatment !== undefined
    ) {
      list[index]["placeholderUnit"] = "Size (L)";
    } else if (list[index]["impAreaTreatType"].includes("Stormsacks")) {
      list[index]["placeholderUnit"] = "Hi-flow bypass (L/s)";
    } else if (
      this.props.eolTreatment !== "No end-of-line treatment" &&
      this.props.eolTreatment !== undefined
    ) {
      list[index]["placeholderUnit"] = "Hi-flow bypass (L/s)";
    }
  }

  // compute and set live results for the impervious node specified by index.
  async computeLiveResults(list, index) {
    if (
      list[index]["impAreaType"] !== "" &&
      list[index]["impAreaSize"] !== ""
    ) {
      var bRooms = "";
      var fetch = true;

      // when bedrooms is not required
      if (
        list[index]["apiBaseRoute"] !== "getRainwatertankResults" ||
        (list[index]["apiBaseRoute"] === "getRainwatertankResults" &&
          list[index]["impAreaTreatSize"] === "")
      ) {
        bRooms = "";
        fetch = true;
      }
      // when bedrooms is required
      else {
        if (list[index]["impAreaBrooms"] !== "") {
          bRooms = list[index]["impAreaBrooms"];
        } else {
          fetch = false;
          list[index]["tn"] = "N/A";
          list[index]["tp"] = "N/A";
          list[index]["tss"] = "N/A";
          list[index]["gp"] = "N/A";
          list[index]["fr"] = "N/A";
          list[index]["wsr"] = "N/A";
          list[index]["results"] = undefined;
        }
      }

      var bassRoot = list[index]["apiBaseRoute"];
      var toipe = list[index]["impAreaTreatType"];
      var atoipe = list[index]["impAreaType"];
      var tsoize = list[index]["impAreaTreatSize"];

      if (
        list[index]["impAreaTreatSize"] === "0" ||
        list[index]["impAreaTreatSize"] === ""
      ) {
        toipe = "None";
        bRooms = "";
        bassRoot = "getNoTreatmentResults";
        tsoize = "";
      }

      if (
        list[index]["impAreaTreatType"] !== "" &&
        list[index]["impAreaTreatType"] !== "None"
      ) {
        if (list[index]["apiBaseRoute"] === "getRainwatertankResults") {
          if (
            list[index]["impAreaTreatSize"] / list[index]["impAreaSize"] <
            0.1
          ) {
            tsoize = 0.1 * list[index]["impAreaSize"];
          }
          if (
            list[index]["minSourceArea"] >
            (list[index]["impAreaSize"] / list[index]["impAreaBrooms"]) * 100
          ) {
            bRooms = Math.round(
              (list[index]["impAreaSize"] / list[index]["minSourceArea"]) * 100
            );
          }
        }
        if (list[index]["apiBaseRoute"] === "getHydrochannelResults") {
          if (
            parseFloat(list[index]["impAreaSize"]) /
            parseFloat(list[index]["impAreaTreatSize"]) >
            parseFloat(list[index]["maxSourceArea"])
          ) {
            tsoize = Math.ceil(
              parseFloat(list[index]["impAreaSize"]) /
              parseFloat(list[index]["maxSourceArea"])
            );
          }
        }
      } else {
        bassRoot = "getNoTreatmentResults";
        toipe = "None";
      }

      if (list[index]["impAreaType"] === "") {
        atoipe = "Mixed";
      }

      if (fetch) {
        this.props.imperviousNodes[index].isLoading = true;
        this.forceUpdate();
        let url_string;
        if (toipe === "None") {
          url_string =
            bassRoot +
            "/" +
            this.props.state +
            "/" +
            this.props.cityCouncil +
            "/" +
            this.props.rainfallStation +
            "/" +
            this.props.catchmentType +
            "/" +
            atoipe +
            "/" +
            list[index]["impAreaSize"] +
            "/" +
            toipe;
        } else if (bassRoot === "LinearReduction") {
          url_string =
            bassRoot +
            "/" +
            this.props.state +
            "/" +
            this.props.cityCouncil +
            "/" +
            this.props.rainfallStation +
            "/" +
            this.props.catchmentType +
            "/" +
            atoipe +
            "/" +
            list[index]["impAreaSize"] +
            "/" +
            toipe +
            "/" +
            tsoize +
            "/JC";
        } else {
          url_string =
            bassRoot +
            "/" +
            this.props.state +
            "/" +
            this.props.cityCouncil +
            "/" +
            this.props.rainfallStation +
            "/" +
            this.props.catchmentType +
            "/" +
            atoipe +
            "/" +
            list[index]["impAreaSize"] +
            "/" +
            toipe +
            "/" +
            tsoize +
            "/" +
            bRooms;
        }

        await this.props.sails_api
          .get(url_string)
          .then((response) => {
            if (this.props.wqMethod.includes("Standard")) {
              list[index]["tn"] = response.data.stormRatingPercTN.toFixed(1);
              list[index]["tp"] = response.data.stormRatingPercTP.toFixed(1);
              list[index]["tss"] = response.data.stormRatingPercTSS.toFixed(1);
              list[index]["gp"] = response.data.stormRatingPercGP.toFixed(1);
              list[index]["fr"] = response.data.reductionPercFlow.toFixed(1);
              list[index]["wsr"] = response.data.waterSupplyReliability
                ? response.data.waterSupplyReliability.toFixed(1)
                : "N/A";
              list[index]["results"] = response.data;
            } else if (this.props.wqMethod.includes("NorBE")) {
              list[index]["tn"] = response.data.residualTN.toFixed(3);
              list[index]["tp"] = response.data.residualTP.toFixed(3);
              list[index]["tss"] = response.data.residualTSS.toFixed(3);
              list[index]["gp"] = response.data.residualGP.toFixed(3);
              list[index]["fr"] = response.data.residualFlow.toFixed(3);
              list[index]["wsr"] = response.data.waterSupplyReliability
                ? response.data.waterSupplyReliability.toFixed(3)
                : "N/A";
              list[index]["results"] = response.data;
            }
            this.props.imperviousNodes[index].isLoading = false;
          })
          .catch((err) => {
            console.log("in error");
            list[index]["tn"] = "N/A";
            list[index]["tp"] = "N/A";
            list[index]["tss"] = "N/A";
            list[index]["gp"] = "N/A";
            list[index]["fr"] = "N/A";
            list[index]["wsr"] = "N/A";
            list[index]["results"] = undefined;
            this.props.imperviousNodes[index].isLoading = false;
          });
      }
    } else {
      // say N/A
      list[index]["tn"] = "N/A";
      list[index]["tp"] = "N/A";
      list[index]["tss"] = "N/A";
      list[index]["gp"] = "N/A";
      list[index]["fr"] = "N/A";
      list[index]["wsr"] = "N/A";
      list[index]["results"] = undefined;
    }
    return list;
  }

  // handle click event of the Remove button
  handleRemoveClick = (index) => {
    const nodes = this.props.imperviousNodes;
    _.pullAt(nodes, index); // See imports above.
    // this.props.setImperviousNodes(nodes);
    this.props.setVariable("imperviousNodes", nodes);
    this.props.calculateOverallResults();
  };

  // handle click event of the Add button
  handleAddClick = async (data) => {
    // do some fetching for  impAreaType, impAreaTreatType and pass them in the setter below.
    var impAreaType = data.imperviousAreaTypes[0].snodetype;
    var impAreaTreatmentTypes = [];
    var impAreaTreatType = "";
    var endpoint = "";

    if (this.props.wqMethod.includes("EOL")) {
      await this.props.sails_api
        .get(
          "/TreatmentNodesLinear/" +
          data.state +
          "/" +
          data.rainfallStation +
          "/" +
          data.catchmentType +
          "/" +
          impAreaType +
          "/" +
          this.props.eolTreatment
        )
        .then((res) => {
          impAreaTreatmentTypes = res.data;
          impAreaTreatType = res.data[0].tnodename;
          endpoint = res.data[0].endpoint;
        });
    } else {
      await data.sails_api
        .get(
          "/TreatmentNodesLinear/" +
          data.state +
          "/" +
          data.rainfallStation +
          "/" +
          data.catchmentType +
          "/" +
          impAreaType
        )
        .then((res) => {
          impAreaTreatmentTypes = res.data;
          impAreaTreatType = res.data[0].tnodename;
          endpoint = res.data[0].endpoint;
        });
    }

    this.props.setVariable("imperviousNodes", [
      ...this.props.imperviousNodes,
      {
        impAreaName: "",
        impAreaType: impAreaType,
        impAreaSize: "",
        impAreaTreatType: impAreaTreatType,
        impAreaTreatSize: "",
        impAreaBrooms: "",
        minSourceArea: NaN,
        maxSourceArea: NaN,
        treatmentTypes: impAreaTreatmentTypes,
        apiBaseRoute: endpoint,
        placeholderUnit: "Size (m² / L / items)",
        alertMessage: "",
        showAlert: false,
        alertVariant: "",
        tn: "N/A",
        tss: "N/A",
        tp: "N/A",
        gp: "N/A",
        fr: "N/A",
        wsr: "N/A",
        results: undefined,
      },
    ]);

    let l = impAreaTreatmentTypes.map((node) => {
      return node.tnodename;
    });
    let n = this.props.imperviousNodes.length;
    this.props.imperviousNodes[n - 1].treatmentTypesList = mapListToDic(l);
    this.props.imperviousNodes[n - 1].isLoading = false;
  };

  render() {
    return (
      <div
        className={
          this.props.wqMethod.includes("NorBE") ? "panNodePreDev" : "panNode"
        }
      >
        <h5>Impervious Areas</h5>
        {this.props.wqMethod.includes("EOL") &&
          !this.props.wqMethod.includes("NorBE") ? (
          <div className="eolPad">
            {/* Manufactured end-of-line treatment device */}
            <table>
              <tbody>
                <tr>

                  <td className="eol-treatment-device-title"
                    data-tip="<img class='eol_tooltip' src='https://cleanstormwater.com.au/wp-content/uploads/2021/11/MicrosoftTeams-image-11.png' width='462' height='230'/><br /> <br />
                  <img class='eol_tooltip' src='https://cleanstormwater.com.au/wp-content/uploads/2021/11/MicrosoftTeams-image-12.png' width='462' height='230'/><br /> <br />
                          <div class='eol_tooltip'>If you would like to model a manufactured treatment train, 
                          <br />please select an end-of-line treatment device and specify its size.</div>"
                    data-place="right"
                    data-html="true">
                    Manufactured end-of-line treatment device:
                  </td>
                  <td style={{ zIndex: 10 }}>
                    ❗
                  </td>
                  <td>
                    <select
                      name="eolTreatment"
                      value={this.props.eolTreatment}
                      onChange={(e) => this.handleInputChange(e, 0)}
                    >
                      {this.filterUniqueTypes(this.props.eolTypes).map(
                        (type) => (
                          <option value={type.eol_device} key={type.eol_device}>
                            {type.eol_device}
                          </option>
                        )
                      )}
                    </select>
                  </td>
                  <td>
                    <input
                      className="input_eol_size"
                      name="eolTreatmentSize"
                      value={this.props.eolTreatmentSize}
                      onChange={(e) => this.handleInputChange(e, 0)}
                      disabled={
                        this.props.eolTreatment ===
                        "No end-of-line treatment" || !this.props.impervious
                      }
                      placeholder={
                        this.props.eolTreatment.includes("SPEL Filter") ||
                          this.props.eolTreatment.includes("Tsunami")
                          ? "Hi-flow bypass (L/s)"
                          : "Treatment size (L/s)"
                      }
                      required
                    />
                  </td>
                  <td>
                    <span
                      data-tip="Only impervious areas flow through this treatment device."
                      data-multiline="true"
                      data-place="bottom"
                    >
                      ❓
                    </span>
                    <ReactTooltip />
                  </td>
                </tr>
              </tbody>
            </table>

            {this.props.eolTreatment !== "No end-of-line treatment" &&
              this.props.eolTreatmentSize > 0 ? (
              <table>
                <tbody>
                  <tr>
                    <td>LIVE STORM Result:</td>
                    <td>
                      <span className="header_margin_Impervious">TN: </span>
                      <span className="eol_result_margin">
                        {" "}
                        {this.props.eolResults
                          ? (
                            (this.props.eolResults.reductionPercTN /
                              this.props.targetReductions.tn) *
                            100
                          ).toFixed(1)
                          : "N/A"}
                        %
                      </span>
                    </td>
                    <td>
                      <span className="header_margin_Impervious">TP: </span>
                      <span className="eol_result_margin">
                        {this.props.eolResults
                          ? (
                            (this.props.eolResults.reductionPercTP /
                              this.props.targetReductions.tp) *
                            100
                          ).toFixed(1)
                          : "N/A"}
                        %
                      </span>
                    </td>
                    <td>
                      <span className="header_margin_Impervious">TSS: </span>
                      <span className="eol_result_margin">
                        {this.props.eolResults
                          ? (
                            (this.props.eolResults.reductionPercTSS /
                              this.props.targetReductions.tss) *
                            100
                          ).toFixed(1)
                          : "N/A"}
                        %
                      </span>
                    </td>
                    <td>
                      <span className="header_margin_Impervious">GP: </span>
                      <span className="eol_result_margin">
                        {this.props.eolResults
                          ? (
                            (this.props.eolResults.reductionPercGP /
                              this.props.targetReductions.gp) *
                            100
                          ).toFixed(1)
                          : "N/A"}
                        %
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : null}
          </div>
        ) : null}
        <table
          className="table-responsive"
          style={{ overflowX: "hidden", display: "contents" }}
        >
          <tbody>
            {/* The working for adding multiple rows of data */}
            <tr>
              <td>
                <table>
                  <thead>
                    <tr>
                      <th className="imp_node_name_header">
                        {" "}
                        <p className="header_margin_Impervious">
                          {" "}
                          Impervious Area Name{" "}
                          <span
                            className="header_margin_Impervious"
                            data-tip="These results correspond to the individual catchment above. <br/>They can be used to fine-tune treatment."
                            data-multiline="true"
                            data-place="bottom"
                          >
                            ❓
                          </span>
                        </p>{" "}
                      </th>
                      <th className="imp_node_type_header">
                        {" "}
                        <p className="header_margin_Impervious">
                          {" "}
                          Impervious Area <br /> Type{" "}
                          <span
                            data-tip="Please select 'Road' as catchment type to model balconies, <br/>terraces and trafficable roofs in accordance with local modelling guidelines."
                            data-multiline="true"
                            data-place="bottom"
                          >
                            ❓
                          </span>
                        </p>{" "}
                      </th>
                      <th className="imp_node_area_header">
                        {" "}
                        <p className="header_margin_Impervious">
                          {" "}
                          Impervious Area (m²){" "}
                        </p>{" "}
                      </th>
                      <th className="imp_node_ttype_header">
                        {" "}
                        <p className="header_margin_Impervious">
                          {" "}
                          Treatment Type{" "}
                        </p>{" "}
                      </th>
                      <th className="imp_node_tarea_header">
                        {" "}
                        <p className="header_margin_Impervious">
                          {" "}
                          Treatment Size <br /> (m²/L/ items){" "}
                        </p>
                      </th>
                      <th className="imp_node_occupants_header">
                        {" "}
                        <p className="header_margin_Impervious">
                          {" "}
                          Number <br /> of <br />{" "}
                          {this.props.bedroomsOrOccupants}{" "}
                        </p>{" "}
                      </th>
                    </tr>
                  </thead>
                </table>
              </td>
            </tr>
            {this.props.imperviousNodes.map((node, i) => {
              return (
                <tr key={i}>
                  <td>
                    <table>
                      <tbody className="table_line_impervious">
                        <tr>
                          <td>
                            <input
                              name="impAreaName"
                              value={node.impAreaName}
                              onChange={(e) => this.handleInputChange(e, i)}
                              placeholder={
                                "Impervious Area " + (i + 1) + " Name"
                              }
                              required
                              className="imp_node_name"
                              disabled={!this.props.impervious}
                            />{" "}
                          </td>
                          <td>
                            <select
                              name="impAreaType"
                              value={node.impAreaType}
                              onChange={(e) => this.handleInputChange(e, i)}
                              disabled={
                                this.props.imperviousAreaTypes.length === 1 ||
                                !this.props.impervious
                              }
                              className="select_area"
                              required
                            >
                              {this.props.imperviousAreaTypes.map((type) => (
                                <option
                                  value={type.snodetype}
                                  key={type.snodetype}
                                >
                                  {type.snodetype}
                                </option>
                              ))}
                            </select>
                          </td>

                          <td>
                            {/* style={{ width: "18%" }} */}
                            <input
                              name="impAreaSize"
                              value={node.impAreaSize}
                              onChange={(e) => this.handleInputChange(e, i)}
                              className="input_area"
                              placeholder="Area (m²)"
                              disabled={
                                this.props.imperviousNodes[i].impAreaType ===
                                "" || !this.props.impervious
                              }
                              required
                              step="any"
                            />
                          </td>

                          <td style={{ position: "unset" }}>
                            <DropdownButton
                              id="bootstrap-dropdown-impervious"
                              variant="light"
                              drop="down"
                              title={node.impAreaTreatType}
                              disabled={
                                this.props.imperviousNodes[i].impAreaSize ===
                                "" ||
                                this.props.imperviousNodes[i].impAreaType === ""
                              }
                              style={{ position: "unset" }}
                            >
                              {
                                /**
                                 * ! Bug:typeof this.props.imperviousNodes[i].treatmentTypesList !== 'undefined' &&
                                 * ! Object.keys(this.props.imperviousNodes[i].treatmentTypesList)
                                 * ! lack one element.
                                 */
                                typeof this.props.imperviousNodes[i]
                                  .treatmentTypesList !== "undefined" &&
                                Object.keys(
                                  this.props.imperviousNodes[i]
                                    .treatmentTypesList
                                ).map((key) => {
                                  let options =
                                    this.props.imperviousNodes[i]
                                      .treatmentTypesList[key];
                                  if (options.length > 0) {
                                    return (
                                      <DropdownButton
                                        id="bootstrap-dropdown-impervious"
                                        drop="down"
                                        variant="light"
                                        key={key}
                                        title={key}
                                        style={{
                                          position: "unset",
                                          transform: "none",
                                          inset: "none",
                                        }}
                                      >
                                        {options.map((value) => {
                                          return (
                                            <Dropdown.Item
                                              variant="light"
                                              id="bootstrap-dropdown-impervious"
                                              key={value}
                                              onClick={() =>
                                                this.handleInputChange(
                                                  {
                                                    target: {
                                                      name: "impAreaTreatType",
                                                      value: key + value,
                                                    },
                                                  },
                                                  i
                                                )
                                              }
                                              style={{
                                                transform: "none",
                                                inset: "none",
                                              }}
                                            >
                                              {value}
                                            </Dropdown.Item>
                                          );
                                        })}
                                      </DropdownButton>
                                    );
                                  }

                                  return (
                                    <Dropdown.Item
                                      variant="light"
                                      key={key}
                                      id="bootstrap-dropdown-impervious"
                                      onClick={() =>
                                        this.handleInputChange(
                                          {
                                            target: {
                                              name: "impAreaTreatType",
                                              value: key,
                                            },
                                          },
                                          i
                                        )
                                      }
                                    >
                                      {key}
                                    </Dropdown.Item>
                                  );
                                })
                              }
                            </DropdownButton>
                          </td>

                          <td>
                            <input
                              name="impAreaTreatSize"
                              value={node.impAreaTreatSize}
                              onChange={(e) => this.handleInputChange(e, i)}
                              className="select_area"
                              placeholder={
                                this.props.imperviousNodes[i].placeholderUnit
                              }
                              disabled={
                                this.props.imperviousNodes[i]
                                  .impAreaTreatType === "" ||
                                this.props.imperviousNodes[i]
                                  .impAreaTreatType === "None" ||
                                this.props.imperviousNodes[i].impAreaSize === ""
                              }
                              required
                              step="any"
                            />{" "}
                          </td>

                          <td>
                            <input
                              name="impAreaBrooms"
                              value={node.impAreaBrooms}
                              onChange={(e) => this.handleInputChange(e, i)}
                              // onBlur={(e) => this.handleInputChange(e, i)}
                              className="input_area"
                              placeholder={this.props.bedroomsOrOccupants}
                              disabled={
                                this.props.imperviousNodes[i].apiBaseRoute !==
                                "getRainwatertankResults" ||
                                (this.props.imperviousNodes[i].apiBaseRoute ===
                                  "getRainwatertankResults" &&
                                  this.props.imperviousNodes[i]
                                    .impAreaTreatSize === "")
                              }
                              required
                              step="any"
                              min="0"
                              pattern="[0-9]+"
                            />{" "}
                          </td>

                          <td>
                            {this.props.imperviousNodes.length !== 1 && (
                              <Button
                                variant="primary"
                                className="btn btn-sm btn-grad"
                                onClick={() => this.handleRemoveClick(i)}
                              >
                                🗑
                              </Button>
                            )}
                            {this.props.imperviousNodes.length === 1 && (
                              <Button
                                variant="primary"
                                className="btn btn-sm btn-grad"
                                disabled
                                onClick={() => this.handleRemoveClick(i)}
                              >
                                🗑
                              </Button>
                            )}
                          </td>
                        </tr>

                        {this.props.cityCouncil === "" ? null : (
                          <tr>
                            {this.props.wqMethod.includes("NorBE") ? (
                              <>
                                <td colSpan="6">
                                  <table className="table-sm col-sm-12">
                                    <tbody>
                                      <tr>
                                        <td>
                                          {" "}
                                          <span
                                            className="header_margin_Impervious"
                                            data-tip="These results correspond to the individual catchment above. <br/>They can be used to fine-tune treatment."
                                            data-multiline="true"
                                            data-place="bottom"
                                          >
                                            {" "}
                                            LIVE Result (Residuals, kg/year) ❓{" "}
                                          </span>
                                        </td>

                                        <td>
                                          TN:
                                          <span className="header_margin_Impervious">
                                            {this.props.imperviousNodes[i].tn}
                                          </span>
                                        </td>

                                        <td>
                                          TP:
                                          <span className="header_margin_Impervious">
                                            {this.props.imperviousNodes[i].tp}
                                          </span>
                                        </td>

                                        <td>
                                          TSS:
                                          <span className="header_margin_Impervious">
                                            {this.props.imperviousNodes[i].tss}
                                          </span>
                                        </td>

                                        <td>
                                          GP:
                                          <span className="header_margin_Impervious">
                                            {this.props.imperviousNodes[i].gp}
                                          </span>
                                        </td>

                                        <td>
                                          Flow (ML/year):
                                          <span className="header_margin_Impervious">
                                            {this.props.imperviousNodes[i].fr}
                                          </span>
                                        </td>
                                        <td>
                                          Water Supply Reliability (%):
                                          <span className="header_margin_Impervious">
                                            {this.props.imperviousNodes[i].wsr}
                                          </span>
                                        </td>

                                        {this.props.imperviousNodes[i]
                                          .isLoading && (
                                            <td>
                                              <Spinner
                                                animation="border"
                                                variant="warning"
                                              />
                                              Loading...
                                            </td>
                                          )}
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </>
                            ) : (
                              <>
                                <td colSpan="6">
                                  <table className="table-sm col-sm-12">
                                    <tbody>
                                      <tr>
                                        <td className="imp_node_name_header">
                                          LIVE STORM Result
                                        </td>

                                        <td>
                                          TN:
                                          {this.props.imperviousNodes[i].tn >=
                                            100 ? (
                                            <span
                                              className="header_margin_Impervious"
                                              style={{ color: "green" }}
                                            >
                                              {this.props.imperviousNodes[i].tn}
                                            </span>
                                          ) : (
                                            <span
                                              className="header_margin_Impervious"
                                              style={{ color: "red" }}
                                            >
                                              {this.props.imperviousNodes[i].tn}
                                            </span>
                                          )}
                                        </td>

                                        <td>
                                          TP:
                                          {this.props.imperviousNodes[i].tp >=
                                            100 ? (
                                            <span
                                              className="header_margin_Impervious"
                                              style={{ color: "green" }}
                                            >
                                              {this.props.imperviousNodes[i].tp}
                                            </span>
                                          ) : (
                                            <span
                                              className="header_margin_Impervious"
                                              style={{ color: "red" }}
                                            >
                                              {this.props.imperviousNodes[i].tp}
                                            </span>
                                          )}
                                        </td>

                                        <td>
                                          TSS:
                                          {this.props.imperviousNodes[i].tss >=
                                            100 ? (
                                            <span
                                              className="header_margin_Impervious"
                                              style={{ color: "green" }}
                                            >
                                              {
                                                this.props.imperviousNodes[i]
                                                  .tss
                                              }
                                            </span>
                                          ) : (
                                            <span
                                              className="header_margin_Impervious"
                                              style={{ color: "red" }}
                                            >
                                              {
                                                this.props.imperviousNodes[i]
                                                  .tss
                                              }
                                            </span>
                                          )}
                                        </td>

                                        <td>
                                          GP:
                                          {this.props.imperviousNodes[i].gp >=
                                            100 ? (
                                            <span
                                              className="header_margin_Impervious"
                                              style={{ color: "green" }}
                                            >
                                              {this.props.imperviousNodes[i].gp}
                                            </span>
                                          ) : (
                                            <span
                                              className="header_margin_Impervious"
                                              style={{ color: "red" }}
                                            >
                                              {this.props.imperviousNodes[i].gp}
                                            </span>
                                          )}
                                        </td>

                                        <td>
                                          Flow Reduction:
                                          <span className="header_margin_Impervious">
                                            {this.props.imperviousNodes[i].fr}
                                          </span>
                                        </td>

                                        <td>
                                          Water Supply Reliability (%):
                                          <span className="header_margin_Impervious">
                                            {this.props.imperviousNodes[i].wsr}
                                          </span>
                                        </td>
                                        {this.props.imperviousNodes[i]
                                          .isLoading && (
                                            <td>
                                              <Spinner
                                                animation="border"
                                                variant="warning"
                                              />
                                              Loading...
                                            </td>
                                          )}
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </>
                            )}
                          </tr>
                        )}
                        <tr>
                          <td colSpan="6">
                            {this.props.imperviousNodes[i].showAlert ? (
                              <Alert
                                className="alert_set_impervious"
                                variant={
                                  this.props.imperviousNodes[i].alertVariant
                                }
                                show={this.props.imperviousNodes[i].showAlert}
                              >
                                {this.props.imperviousNodes[i].alertMessage}
                              </Alert>
                            ) : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              );
            })}
          </tbody>

          <tfoot>
            {/* Below tr for the gap between the rows and the add treatment row button */}
            <tr className="blank_table_row">
              <td colSpan="3"></td>
            </tr>
          </tfoot>
        </table>
        <Button
          id="addPreDevImpArea"
          variant="primary"
          className="btn btn-sm btn-grad"
          onClick={(e) => this.handleAddClick(this.props)}
          disabled={!this.props.impervious}
        >
          + Add Impervious Area
        </Button>
      </div>
    );
  }
}

export default ImperviousNode;
