import React from "react";
import { Button, Alert } from "react-bootstrap";
import { isInt } from "validator";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/manualaddress.css";

const vicCouncils = [
  "Alpine Shire",
  "Ararat Rural City",
  "Ballarat City",
  "Banyule City",
  "Bass Coast Shire",
  "Baw Baw Shire",
  "Bayside City",
  "Benalla Rural City",
  "Boroondara City",
  "Brimbank City",
  "Buloke Shire",
  "Campaspe Shire",
  "Cardinia Shire",
  "Casey City",
  "Central Goldfields Shire",
  "Colac Otway Shire",
  "Corangamite Shire",
  "Darebin City",
  "East Gippsland Shire",
  "Frankston City",
  "Gannawarra Shire",
  "Glen Eira City",
  "Glenelg Shire",
  "Golden Plains Shire",
  "Greater Bendigo City",
  "Greater Dandenong City",
  "Greater Geelong City",
  "Greater Shepparton City",
  "Hepburn Shire",
  "Hindmarsh Shire",
  "Hobsons Bay City",
  "Horsham Rural City",
  "Hume City",
  "Indigo Shire",
  "Kingston City",
  "Knox City",
  "Latrobe City",
  "Loddon Shire",
  "Macedon Ranges Shire",
  "Manningham City",
  "Mansfield Shire",
  "Maribyrnong City",
  "Maroondah City",
  "Melbourne City",
  "Melton City",
  "Mildura Rural City",
  "Mitchell Shire",
  "Moira Shire",
  "Monash City",
  "Moonee Valley City",
  "Moorabool Shire",
  "Moreland City",
  "Mornington Peninsula Shire",
  "Mount Alexander Shire",
  "Moyne Shire",
  "Murrindindi Shire",
  "Nillumbik Shire",
  "Northern Grampians Shire",
  "Port Phillip City",
  "Pyrenees Shire",
  "Borough of Queenscliffe",
  "South Gippsland Shire",
  "Southern Grampians Shire",
  "Stonnington City",
  "Strathbogie Shire",
  "Surf Coast Shire",
  "Swan Hill Rural City",
  "Towong Shire",
  "Wangaratta Rural City",
  "Warrnambool City",
  "Wellington Shire",
  "West Wimmera Shire",
  "Whitehorse City",
  "Whittlesea City",
  "Wodonga City",
  "Wyndham City",
  "Yarra City",
  "Yarra Ranges Shire",
  "Yarriambiack Shire",
];
const nswCouncils = [
  "Bayside Council",
  "Dubbo Regional Council",
  "Albury City Council",
  "Armidale Regional Council",
  "Ballina Shire Council",
  "Balranald Shire Council",
  "Bathurst Regional Council",
  "The Hills Shire Council",
  "Bega Valley Shire Council",
  "Bellingen Shire Council",
  "Berrigan Shire Council",
  "Blacktown City Council",
  "Bland Shire Council",
  "Blayney Shire Council",
  "Blue Mountains City Council",
  "Bogan Shire Council",
  "Bourke Shire Council",
  "Brewarrina Shire Council",
  "Broken Hill City Council",
  "Burwood Council",
  "Byron Shire Council",
  "Cabonne Council",
  "Camden Council",
  "Campbelltown City Council",
  "City of Canada Bay Council",
  "Canterbury Bankstown Council ",
  "Carrathool Shire Council",
  "Central Coast Council",
  "Central Darling Shire Council",
  "Cessnock City Council",
  "Clarence Valley Council",
  "Cobar Shire Council",
  "Coffs Harbour City Council",
  "Coolamon Shire Council",
  "Coonamble Shire Council",
  "Cowra Shire Council",
  "Cumberland Council",
  "Dungog Shire Council",
  "Edward River Council",
  "Eurobodalla Shire Council",
  "Fairfield City Council",
  "Federation Council",
  "Forbes Shire Council",
  "Georges River Council",
  "Gilgandra Shire Council",
  "Glen Innes Severn Council",
  "Goulburn Mulwaree Council",
  "Greater Hume Shire Council",
  "Griffith City Council",
  "Cootamundra-Gundagai Regional Council",
  "Gunnedah Shire Council",
  "Gwydir Shire Council",
  "Port Macquarie-Hastings Council",
  "Hawkesbury City Council",
  "Hay Shire Council",
  "Hilltops Council",
  "The Council of the Shire of Hornsby",
  "The Council of the Municipality of Hunters Hill",
  "Inner West Council",
  "Inverell Shire Council",
  "Junee Shire Council",
  "Kempsey Shire Council",
  "The Council of the Municipality of Kiama",
  "Ku-ring-gai Council",
  "Kyogle Council",
  "Lachlan Shire Council",
  "Lake Macquarie City Council",
  "Lane Cove Municipal Council",
  "Leeton Shire Council",
  "Lismore City Council",
  "City of Lithgow Council",
  "Liverpool City Council",
  "Liverpool Plains Shire Council",
  "Lockhart Shire Council",
  "Maitland City Council",
  "Mid-Coast Council",
  "Mid-Western Regional Council",
  "Moree Plains Shire Council",
  "Mosman Municipal Council",
  "Murray River Council",
  "Murrumbidgee Council",
  "Muswellbrook Shire Council",
  "Nambucca Valley Council",
  "Narrabri Shire Council",
  "Narrandera Shire Council",
  "Narromine Shire Council",
  "Newcastle City Council",
  "North Sydney Council",
  "Northern Beaches Council",
  "Oberon Council",
  "Orange City Council",
  "Parkes Shire Council",
  "Council of the City of Parramatta",
  "Penrith City Council",
  "Port Stephens Council",
  "Queanbeyan-Palerang Regional Council",
  "Randwick City Council",
  "Richmond Valley Council",
  "Council of the City of Ryde",
  "Shellharbour City Council",
  "Shoalhaven City Council",
  "Singleton Council",
  "Snowy Monaro Regional Council",
  "Snowy Valleys Council",
  "Strathfield Municipal Council",
  "Sutherland Shire Council",
  "Council of the City of Sydney",
  "Tamworth Regional Council",
  "Temora Shire Council",
  "Tenterfield Shire Council",
  "Tweed Shire Council",
  "Upper Hunter Shire Council",
  "Upper Lachlan Shire Council",
  "Uralla Shire Council",
  "Wagga Wagga City Council",
  "Walcha Council",
  "Walgett Shire Council",
  "Warren Shire Council",
  "Warrumbungle Shire Council",
  "Waverley Council",
  "Weddin Shire Council",
  "Wentworth Shire Council",
  "Willoughby City Council",
  "Wingecarribee Shire Council",
  "Wollondilly Shire Council",
  "Wollongong City Council",
  "Woollahra Municipal Council",
  "Yass Valley Council",
];
const qldCouncils = [
  "Banana Shire Council",
  "Barcaldine Regional Council",
  "Barcoo Shire Council",
  "Blackall-Tambo Regional Council",
  "Boulia Shire Council",
  "Central Highlands Regional Council",
  "Diamantina Shire Council",
  "Gladstone Regional Council",
  "Isaac Regional Council",
  "Longreach Regional Council",
  "Mackay Regional Council",
  "Rockhampton Regional Council",
  "Whitsunday Regional Council",
  "Winton Shire Council",
  "Woorabinda Aboriginal Shire Council",
  "Aurukun Shire Council",
  "Burdekin Shire Council",
  "Burke Shire Council",
  "Cairns Regional Council",
  "Carpentaria Shire Council",
  "Cassowary Coast Regional Council",
  "Charters Towers Regional Council",
  "Cloncurry Shire Council",
  "Cook Shire Council",
  "Croydon Shire Council",
  "Doomadgee Aboriginal Shire Council",
  "Etheridge Shire Council",
  "Flinders Shire Council",
  "Hinchinbrook Shire Council",
  "Hope Vale Aboriginal Shire Council",
  "Kowanyama Aboriginal Shire Council",
  "Lockhart River Aboriginal Shire Council",
  "Mapoon Aboriginal Shire Council",
  "McKinlay Shire Council",
  "Mornington Shire Council",
  "Mount Isa City Council",
  "Napranum Aboriginal Shire Council",
  "Northern Peninsula Area Regional Council",
  "Palm Island Aboriginal Shire Council",
  "Pormpuraaw Aboriginal Shire Council",
  "Richmond Shire Council",
  "Tablelands Regional Council",
  "Torres Shire Council",
  "Torres Strait Island Regional Council",
  "Townsville City Council",
  "Wujal Wujal Aboriginal Shire Council",
  "Yarrabah Aboriginal Shire Council",
  "Douglas Shire Council",
  "Mareeba Shire Council",
  "Balonne Shire Council",
  "Brisbane City Council",
  "Bulloo Shire Council",
  "Bundaberg Regional Council",
  "Cherbourg Aboriginal Shire Council",
  "Fraser Coast Regional Council",
  "Gold Coast City Council",
  "Goondiwindi Regional Council",
  "Gympie Regional Council",
  "Ipswich City Council",
  "Lockyer Valley Regional Council",
  "Logan City Council",
  "Maranoa Regional Council",
  "Moreton Bay Regional Council",
  "Murweh Shire Council",
  "North Burnett Regional Council",
  "Paroo Shire Council",
  "Quilpie Shire Council",
  "Redland City Council",
  "Scenic Rim Regional Council",
  "Somerset Regional Council",
  "South Burnett Regional Council",
  "Southern Downs Regional Council",
  "Sunshine Coast Regional Council",
  "Toowoomba Regional Council",
  "Western Downs Regional Council",
  "Livingstone Shire Council",
  "Noosa Shire Council",
];
const tasCouncils = [
  "Break O'Day",
  "Brighton",
  "Burnie",
  "Central Coast",
  "Central Highlands",
  "Circular Head",
  "Clarence",
  "Derwent Valley",
  "Devonport",
  "Dorset",
  "Flinders",
  "George Town",
  "Glamorgan - Spring Bay",
  "Glenorchy",
  "Hobart",
  "Huon Valley",
  "Kentish",
  "Kingborough",
  "King Island",
  "Latrobe",
  "Launceston",
  "Meander Valley",
  "Northern Midlands",
  "Sorell",
  "Southern Midlands",
  "Tasman",
  "Waratah Wynyard",
  "West Coast",
  "West Tamar",
];
const saCouncils = [
  "City of Adelaide",
  "Adelaide Hills Council",
  "Adelaide Plains Council",
  "Alexandrina Council",
  "Anangu Pitjantjatjara Yankunytjatjara",
  "The Barossa Council",
  "Barunga West Council",
  "Berri Barmera Council",
  "City of Burnside",
  "Campbelltown City Council",
  "District Council of Ceduna",
  "City of Charles Sturt",
  "Clare & Gilbert Valleys Council",
  "District Council of Cleve",
  "District Council of Coober Pedy",
  "Coorong District Council",
  "Copper Coast Council",
  "District Council of Elliston",
  "The Flinders Ranges Council",
  "District Council of Franklin Harbour",
  "Town of Gawler",
  "Regional Council of Goyder",
  "District Council of Grant",
  "City of Holdfast Bay",
  "Kangaroo Island Council",
  "District Council of Karoonda East Murray",
  "District Council of Kimba",
  "Kingston District Council",
  "Light Regional Council",
  "District Council of Lower Eyre Peninsula",
  "District Council of Loxton Waikerie",
  "City of Marion",
  "Mid Murray Council",
  "City of Mitcham",
  "Mount Barker District Council",
  "City of Mount Gambier",
  "District Council of Mount Remarkable",
  "The Rural City of Murray Bridge",
  "Naracoorte Lucindale Council",
  "Northern Areas Council",
  "City of Norwood Payneham & St Peters",
  "City of Onkaparinga",
  "District Council of Orroroo Carrieton",
  "District Council of Peterborough",
  "City of Playford",
  "City of Port Adelaide Enfield",
  "Port Augusta City Council",
  "City of Port Lincoln",
  "Port Pirie Regional Council",
  "City of Prospect",
  "Renmark Paringa Council",
  "District Council of Robe",
  "Municipal Council of Roxby Downs",
  "City of Salisbury",
  "Southern Mallee District Council",
  "District Council of Streaky Bay",
  "Tatiara District Council",
  "City of Tea Tree Gully",
  "District Council of Tumby Bay",
  "City of Unley",
  "City of Victor Harbor",
  "Wakefield Regional Council",
  "Corporation of the Town of Walkerville",
  "Wattle Range Council",
  "City of West Torrens ",
  "City of Whyalla",
  "Wudinna District Council",
  "District Council of Yankalilla",
  "Yorke Peninsula Council",
];
const waCouncils = [
  "Albany (City)",
  "Armadale (City)",
  "Ashburton (Shire)",
  "Augusta Margaret River (Shire)",
  "Bassendean (Town)",
  "Bayswater (City)",
  "Belmont (City)",
  "Beverley (Shire)",
  "Boddington (Shire)",
  "Boyup Brook (Shire)",
  "Bridgetown-Greenbushes (Shire)",
  "Brookton (Shire)",
  "Broome (Shire)",
  "Broomehill-Tambellup (Shire)",
  "Bruce Rock (Shire)",
  "Bunbury (City)",
  "Busselton (City)",
  "Cambridge (Town)",
  "Canning (City)",
  "Capel (Shire)",
  "Carnamah (Shire)",
  "Carnarvon (Shire)",
  "Chapman Valley (Shire)",
  "Chittering (Shire)",
  "Christmas Island (Shire)",
  "Claremont (Town)",
  "Cockburn (City)",
  "Cocos (Keeling) Islands (Shire)",
  "Collie (Shire)",
  "Coolgardie (Shire)",
  "Coorow (Shire)",
  "Corrigin (Shire)",
  "Cottesloe (Town)",
  "Cranbrook (Shire)",
  "Cuballing (Shire)",
  "Cue (Shire)",
  "Cunderdin (Shire)",
  "Dalwallinu (Shire)",
  "Dandaragan (Shire)",
  "Dardanup (Shire)",
  "Denmark (Shire)",
  "Derby-West Kimberley (Shire)",
  "Donnybrook-Balingup (Shire)",
  "Dowerin (Shire)",
  "Dumbleyung (Shire)",
  "Dundas (Shire)",
  "East Fremantle (Town)",
  "East Pilbara (Shire)",
  "Esperance (Shire)",
  "Exmouth (Shire)",
  "Fremantle (City)",
  "Gingin (Shire)",
  "Gnowangerup (Shire)",
  "Goomalling (Shire)",
  "Gosnells (City)",
  "Greater Geraldton (City)",
  "Halls Creek (Shire)",
  "Harvey (Shire)",
  "Irwin (Shire)",
  "Jerramungup (Shire)",
  "Joondalup (City)",
  "Kalamunda (City)",
  "Kalgoorlie-Boulder (City)",
  "Karratha (City)",
  "Katanning (Shire)",
  "Kellerberrin (Shire)",
  "Kent (Shire)",
  "Kojonup (Shire)",
  "Kondinin (Shire)",
  "Koorda (Shire)",
  "Kulin (Shire)",
  "Kwinana (City)",
  "Lake Grace (Shire)",
  "Laverton (Shire)",
  "Leonora (Shire)",
  "Mandurah (City)",
  "Manjimup (Shire)",
  "Meekatharra (Shire)",
  "Melville (City)",
  "Menzies (Shire)",
  "Merredin (Shire)",
  "Mingenew (Shire)",
  "Moora (Shire)",
  "Morawa (Shire)",
  "Mosman Park (Town)",
  "Mount Magnet (Shire)",
  "Mount Marshall (Shire)",
  "Mukinbudin (Shire)",
  "Mundaring (Shire)",
  "Murchison (Shire)",
  "Murray (Shire)",
  "Nannup (Shire)",
  "Narembeen (Shire)",
  "Narrogin (Shire)",
  "Nedlands (City)",
  "Ngaanyatjarraku (Shire)",
  "Northam (Shire)",
  "Northampton (Shire)",
  "Nungarin (Shire)",
  "Peppermint Grove (Shire)",
  "Perenjori (Shire)",
  "Perth (City)",
  "Pingelly (Shire)",
  "Plantagenet (Shire)",
  "Port Hedland (Town)",
  "Quairading (Shire)",
  "Ravensthorpe (Shire)",
  "Rockingham (City)",
  "Sandstone (Shire)",
  "Serpentine-Jarrahdale (Shire)",
  "Shark Bay (Shire)",
  "South Perth (City)",
  "Stirling (City)",
  "Subiaco (City)",
  "Swan (City)",
  "Tammin (Shire)",
  "Three Springs (Shire)",
  "Toodyay (Shire)",
  "Trayning (Shire)",
  "Upper Gascoyne (Shire)",
  "Victoria Park (Town)",
  "Victoria Plains (Shire)",
  "Vincent (City)",
  "Wagin (Shire)",
  "Wandering (Shire)",
  "Wanneroo (City)",
  "Waroona (Shire)",
  "West Arthur (Shire)",
  "Westonia (Shire)",
  "Wickepin (Shire)",
  "Williams (Shire)",
  "Wongan-Ballidu (Shire)",
  "Woodanilling (Shire)",
  "Wyalkatchem (Shire)",
  "Wyndham-East Kimberley (Shire)",
  "Yalgoo (Shire)",
  "Yilgarn (Shire)",
  "York (Shire)",
];
const actCouncils = ["ACT"];
const ntCouncils = [
  "Alice Springs Town Council",
  "Alyangula",
  "Barkly Regional Council",
  "Belyuen Community Government Council",
  "Central Desert Regional Council",
  "Coomalie Community Government Council",
  "City of Darwin",
  "East Arnhem Regional Council",
  "Katherine Town Council",
  "Litchfield Council",
  "MacDonnell Regional Council",
  "Nhulunbuy Corporation",
  "City of Palmerston",
  "Roper Gulf Regional Council",
  "Tiwi Islands Regional Council",
  "Victoria Daly Regional Council",
  "Wagait Shire Council",
  "West Arnhem Regional Council",
  "West Daly Regional Council",
];

class ManualAddress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      address1: "",
      suburb: "",
      postcode: "",
      state: "",
      rainfallStation: "",
      alertMessage: "Please fill all the fields above.",
      showAlert: false,
      alertVariant: "Danger",
      council: "",
      councilList: [],
    };
  }

  async handleInputChange(e) {
    const { name, value } = e.target;

    await this.setState({ showAlert: false });

    if (name === "postcode") {
      // to accept only positive value for impervious source area
      if (isInt(value) || value === "") {
        await this.setState({ postcode: value });
      } else {
        return;
      }
    } else {
      await this.setState({ [name]: value });
    }

    if (name === "state") {
      // to accept only positive value for impervious source area
      if (value === "VIC") {
        this.setState({ councilList: vicCouncils });
      }
      if (value === "QLD") {
        this.setState({ councilList: qldCouncils });
      }
      if (value === "NSW") {
        this.setState({ councilList: nswCouncils });
      }
      if (value === "NT") {
        this.setState({ councilList: ntCouncils });
      }
      if (value === "SA") {
        this.setState({ councilList: saCouncils });
      }
      if (value === "WA") {
        this.setState({ councilList: waCouncils });
      }
      if (value === "TAS") {
        this.setState({ councilList: tasCouncils });
      }
      if (value === "ACT") {
        this.setState({ councilList: actCouncils });
      }
    }
  }

  handleOnSubmit = (e) => {
    var allFilled = true;
    Object.entries(this.state).map(([key, value]) => {
      if (value === "") {
        this.setState({ showAlert: true });
        allFilled = false;
      }
      return "";
    });

    if (allFilled) {
      this.props.setRainfallStation(
        "rainfallStation",
        this.state.rainfallStation
      );

      setTimeout(() => {
        this.props.setAddress("addressMain", this.state.address1);
        this.props.setAddress("suburb", this.state.suburb);
        this.props.setAddress("zipcode", this.state.postcode);
        this.props.setAddress("state", this.state.state);
        this.props.setAddress("cityCouncil", this.state.council);
        document.querySelector(".addAddressLinkButton").click(); //close the accordion
      }, 1000);
    }
  };

  render() {
    const states = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];

    return (
      <div className="addressDiv">
        <table>
          <tbody>
            <tr>
              <td>Site and Street: *</td>
              <td>
                <input
                  name="address1"
                  value={this.state.address1}
                  onChange={(e) => this.handleInputChange(e)}
                  placeholder="Address line 1"
                  required
                />
              </td>
            </tr>
            <tr>
              <td>Suburb: *</td>
              <td>
                <input
                  name="suburb"
                  value={this.state.suburb}
                  onChange={(e) => this.handleInputChange(e)}
                  placeholder="Suburb"
                  required
                />
              </td>
            </tr>
            <tr>
              <td>Postcode: *</td>
              <td>
                <input
                  name="postcode"
                  value={this.state.postcode}
                  onChange={(e) => this.handleInputChange(e)}
                  placeholder="Post code"
                  maxLength="4"
                  required
                />
              </td>
            </tr>
            <tr>
              <td>State: *</td>
              <td>
                <select
                  name="state"
                  value={this.state.state}
                  onChange={(e) => this.handleInputChange(e)}
                  required
                >
                  <option value="" hidden>
                    Select State
                  </option>
                  {states.map((type) => (
                    <option key={type}>{type}</option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>City Council: *</td>
              <td>
                {/* <select
                    name="council"
                    value={this.state.council}
                    onChange={e => this.handleInputChange(e)}
                    disabled = {this.state.state === ""}
                    required
                  >
                    <option value="" hidden>
                      Select Council
                    </option>
                    {this.state.councilList.map(council => <option key={council}>{council}</option>)}
                  </select> */}
                <input
                  name="council"
                  value={this.state.council}
                  onChange={(e) => this.handleInputChange(e)}
                  placeholder="Council name"
                  disabled={this.state.state === ""}
                  required
                />
              </td>
            </tr>
            <tr>
              <td>Rainfall Station: *</td>
              <td>
                <select
                  name="rainfallStation"
                  value={this.state.rainfallStation}
                  onChange={(e) => this.handleInputChange(e)}
                  disabled={this.state.state === ""}
                  required
                >
                  <option value="" hidden>
                    Select Rainfall Station
                  </option>
                  {this.props.rainfallStations.map((station) => (
                    <option key={station.mtemplate}>{station.mtemplate}</option>
                  ))}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <Button variant="success" size="sm" onClick={this.handleOnSubmit}>
          Confirm
        </Button>
        <Alert variant={this.state.alertVariant} show={this.state.showAlert}>
          {this.state.alertMessage}
        </Alert>
      </div>
    );
  }
}

export default ManualAddress;
