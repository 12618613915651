import React from "react";
// react-bootstrap
import {
    Container,
    Row,
    Col,
} from "react-bootstrap";
// components
import Announcements from "./Announcements";

function About() {
    return (
        <>
            <Row>
                <div className="item item-1">
                    <h4>About STORM</h4>
                    <p>
                        The original STORM was developed approximately 15 years ago,
                        using MUSIC Version 3.Since then, the MUSIC modelling
                        guidelines for the Greater Melbourne Region have been revised
                        3 times and updated WSUD guidance has been developed. In
                        addition, the MUSIC modelling tool has been through a number
                        of revisions and nodes such as bioretention have been
                        reworked. A new database created in accordance with the
                        current Melbourne Water MUSIC Guidelines was needed. Hence the
                        data implemented in STORMupdated* has been created using the
                        latest version of the guidelines. Although STORMupdated* is
                        based on interpolation of data tables using results generated
                        from MUSIC, the results are remarkably close to those
                        generated by a corresponding MUSIC model. Updating STORM is a
                        much welcomed improvement to a great existing tool.
                        Additionally, the updated tool allows for the implementation
                        of new nodes and in the near future will be extended to
                        support treatments such as passively irrigated tree pits and
                        roofgardens. It can also support all relevant flow and
                        pollutant load objectives as needed.
                        <br />
                        <br />
                        Feedback is welcomed both to:
                        <br />
                    </p>
                    <Container fluid="md">
                        <Row>
                            <Col>
                                <ul>
                                    <li>note errors/bugs</li>
                                    <li>suggest improvements/updates.</li>
                                </ul>
                                <p>
                                    We hope you will enjoy using STORMupdated* to help with
                                    your WSUD implementation.
                                </p>
                                <strong>
                                    Development Team:
                                    <br />
                                </strong>
                                <br />
                                Civil Engineering: Alec Olson, Gregory Chian
                                <br />
                                Software Development: Dipto, Ming, Gautam, Peter, Rishi, Warrant, Sam
                                <br />
                                <br />
                                Review: Mircea Stancu
                            </Col>
                            <Col>
                                <div className="announcement">
                                    <Announcements />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <p>
                        <br />
                        <br />
                        <br />
                        <br />
                        This update has been developed with the technical advice and
                        help of Dr. Dale Browne, Senior Engineer at E2Designlab. Thank
                        you, Dale :)
                        <br />
                        <br />
                        The Swinburne (OSD4-compatible) OSD component has been
                        developed with the kind help of Eng. Faslan Naavi from Matrix
                        Engineering. Thank you Faslan.
                    </p>
                </div>
            </Row>
        </>
    );
}

export default About;
