import React from "react";
import {
    Row,
    Button,
} from "react-bootstrap";

function SecondaryButton() {
    return (
        <>
            <Row className="ml-1 mr-1">
                <Button
                    className="form-control btn btn-sm btn-grad_home_orange col-sm-7 ml-2"
                    name="BTB"
                    type="submit"
                    style={{ fontSize: "13.1px" }}
                >
                    BOM the Builder
                </Button>
                <Button
                    className="form-control btn btn-sm btn-grad_home_orange col-sm-4 ml-1 flashing-button-orange"
                    name="tutorial-orange"
                    href="https://www.youtube.com/watch?v=a8QXIes_y4Y"
                    target="_blank"
                >
                    Tutorial
                </Button>
            </Row>
            <Row className="ml-1 mr-1">
                <Button
                    className="form-control btn btn-sm btn-grad_home_purple col-sm-7 ml-2"
                    name="OSDs4VIPs"
                    type="submit"
                    style={{ fontSize: "14px" }}
                >
                    OSDs For VIPs
                </Button>

                <Button
                    className="form-control btn btn-sm btn-grad_home_purple col-sm-4 ml-1 flashing-button-purple"
                    name="tutorial-purple"
                    href="https://www.youtube.com/watch?v=ua5rTnUix0A"
                    target="_blank"
                >
                    Tutorial
                </Button>
            </Row>
        </>
    );
}

export default SecondaryButton;
