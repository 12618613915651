import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";
import Home from "./Home/TheHome";
import Calculator from "./Calculator";
import AuditorInterface from "./AuditorInterface";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "Home",
      assessorEmail: "",
      ip: "",
      sessionID: "",
      source: "",
    };
  }

  // Set the assessor email
  setAssessorEmail = (email) => {
    this.setState({ assessorEmail: email });
  };
  // Set the page
  setPage = (value) => {
    this.setState({ page: value });
  };

  setIP = (value) => {
    this.setState({ ip: value });
  };

  setSessionID = (value) => {
    this.setState({ sessionID: value });
  };

  setSource = (value) => {
    this.setState({ source: value });
  };

  componentDidMount() { }

  render() {
    return (
      <div>
        <TheHeader />
        {this.state.page === "Home" ? (
          <Home
            setAssessorEmail={this.setAssessorEmail}
            setPage={this.setPage}
            setIP={this.setIP}
            setSessionID={this.setSessionID}
            setSource={this.setSource}
          />
        ) :

          this.state.page === "Calculator" ? (
            <Calculator
              assessorEmail={this.state.assessorEmail}
              ip={this.state.ip}
              sessionID={this.state.sessionID}
              source={this.state.source}
            />
          ) :
            <AuditorInterface
              assessorEmail={this.state.assessorEmail}
              ip={this.state.ip}
              sessionID={this.state.sessionID}
              source={this.state.source}
            />
        }
        <TheFooter />
      </div>
    );
  }
}



export default App;
