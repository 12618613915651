import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/downloadbuttons.css";
import MsfUploader from "./assets/js/MsfUploader";
import StateUploader from "./assets/js/StateUploader";
import { Row, Col } from "react-bootstrap";

class StateUploadDownloadButtons extends React.Component {
  handleStateUploadDownload = async (task) => {
    if (task === "Upload") {
      //Uploading a file
      // let currentSession = this.state.sessionID

      console.log("YOU CLICKED UPLOAD");
    }
    if (task === "Download") {
      //This code downloads the state of the Calculator, as a text file
      const json = JSON.stringify(this.props.data, null, "\t");
      const blob = new Blob([json], { type: "application/json" });
      const href = await URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = "Data.sus";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  render() {
    return (
      <div style={{display:"flex"}}>
        <div
          style={{
            position: "relative",
            borderColor: "white",
            margin: "10px",
          }}
        >
          <StateUploader
            data={this.props.data}
            loadState={this.props.loadState}
          />
        </div>

        <Button
          style={{
            position: "relative",
            padding: "10px",
            borderColor: "white",
            margin: "10px",
            maxHeight: "42px",
            padding: "12px",
            lineHeight: "normal",
            backgroundColor: "#175607",
          }}
          onClick={() => this.handleStateUploadDownload("Download")}
        >
          Save Project
        </Button>
      </div>
    );
  }
}

export default StateUploadDownloadButtons;
