import React from "react";
import {
    Container,
    Row,
    Col,
    Button,
  } from "react-bootstrap";

function MainButton() {
    return (
        <>
            <Container>
                <Row className="d-flex main-btn-row">
                    <Col className="main-btn-col-1">
                        <Button
                            className="form-control btn btn-sm btn-grad_home_blue main-btn-col-1-item"
                            name="storm"
                            type="submit"
                        >
                            Calculator
                        </Button>
                        <Button
                            className="form-control btn btn-sm btn-grad_home_red main-btn-col-1-item"
                            name="auditor"
                            type="submit"
                        >
                            Auditor
                        </Button>
                        <Button
                            className="form-control btn btn-sm btn-grad_home_green main-btn-col-1-item"
                            name="JCDownload"
                            type="submit"
                        >
                            Download John Connor
                        </Button>
                    </Col>
                    <Col className="main-btn-col-2">
                        <Button
                            className="form-control btn btn-sm btn-grad_home_blue main-btn-col-2-item flashing-button-blue"
                            name="tutorial-blue"
                            href="https://youtu.be/rqTl6NbF7Eg"
                            target="_blank"
                        >
                            Tutorial
                        </Button>
                        <Button
                            className="form-control btn btn-sm btn-grad_home_red main-btn-col-2-item flashing-button-red"
                            name="tutorial-blue"
                            href="https://www.youtube.com/watch?v=eN9n8_CjKYc"
                            target="_blank"
                        >
                            Tutorial
                        </Button>
                        <Button
                            className="form-control btn btn-sm btn-grad_home_green main-btn-col-2-item flashing-button-green"
                            name="tutorial-green"
                            href="https://youtu.be/3fqquCV2LJE"
                            target="_blank"
                        >
                            Tutorial
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default MainButton;
