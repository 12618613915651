import React from "react";
import { Form, Alert, Container, Row, Col } from "react-bootstrap";
import { isEmail } from "validator";
import axios from "axios";
// css
import "./thehome.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "shepherd.js/dist/css/shepherd.css";
// components
import LGATicker from "../LGATicker";
import CouncilMaps from "../CouncilMaps";
import AdsPanel from "../AdsPanel";
import TitleLine from "./TitleLine";
import LatestNews from "./LatestNews";
import About from "./About";
import Brochure from "./Brochure";
import MainButton from "./MainButton";
import SecondaryButton from "./SecondaryButtons";
// Shepherd
import { ShepherdTour, TourMethods } from "react-shepherd";
import steps from "./Shepherd/steps"
import Tour from "./Shepherd/tour";

const publicIp = require("public-ip");

const sails_api = axios.create({
  baseURL: window.location.href.includes("localhost")
    ? "https://su-stage.ml/sails"
    : window.location.origin + "/sails",
});

// define Sheperd props
const tourOptions = {
  defaultStepOptions: { showCancelLink: true },
  useModalOverlay: true
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assessorEmail: "",

      // To show or hide the alert and the alert message
      showAlert: false,
      alertMessage: "❗ Enter a valid email",
      alertVariant: "danger",

      //download link in alert for JC
      link: false,

      // local storage user exist
      userExist: false,
    };
  }
  componentWillMount() {
    // set up local storage for user if they haven't been visited in a month.
    // get current time
    const now = new Date()
    const day = now.getDate()
    let month = now.getMonth()+1
    let year = now.getFullYear()

    const userExist = JSON.parse(localStorage.getItem('user'))
    if (userExist) {
      // comparing the expiry date and current date
      const expiryDay = userExist.expiry.split('/')[0]
      const expiryMonth = userExist.expiry.split('/')[1]
      const expiryYear = userExist.expiry.split('/')[2]
      if(expiryYear < year) {
        localStorage.removeItem('user')
        this.setState({ userExist: false })
      } else if (expiryYear === year){
        if(expiryMonth < month) {
          localStorage.removeItem('user')
          this.setState({ userExist: false })
        } else if(expiryMonth === month){
          if(expiryDay <= day) {
            localStorage.removeItem('user')
            this.setState({ userExist: false })
          } else {
            this.setState({ userExist: true })
          }
        } else {
          this.setState({ userExist: true })
        }
      } else {
        this.setState({ userExist: true })
      }
    } else {
      if(month === 12){
        month = 0
        year = year + 1
      }
      const data = {
        value: "user",
        expiry: `${day}/${month+1}/${year}`,
      }
      localStorage.setItem('user', JSON.stringify(data));
      this.setState({ userExist: false })
    }
  }

  async componentDidMount() {
    await publicIp
      .v4({ fallbackUrls: ["https://ifconfig.co/ip"] })
      .then((res) => {
        this.setState({ ip: res });
        this.props.setIP(res);
      })
      .catch((err) => {
        this.props.setIP("0.0.0.0");
        this.setState({ ip: "0.0.0.0" });
      });
  }

  async httpGet(theUrl) {
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", theUrl, false); // false for synchronous request
    xmlHttp.send(null);
    return xmlHttp.responseText;
  }

  // To handle the input changes
  handleSubmit = async (event) => {
    this.setState({ link: false });
    event.preventDefault();
    if (event.nativeEvent.submitter.name === "BTB") {
      await sails_api
        .post("/RecordUserLogin", {
          email: this.state.assessorEmail,
          ip: this.state.ip,
          buttonName: event.nativeEvent.submitter.name,
          source: window.location.href,
        })
        .then((res) => {
          setTimeout(() => {
            window.open(res.data.url, "_blank");
          }, 1000);
        });
    } else if (event.nativeEvent.submitter.name === "OSDs4VIPs") {
      // await sails_api
      // .post("/RecordUserLogin", {
      //   email: this.state.assessorEmail,
      //   ip: this.state.ip,
      //   buttonName: event.nativeEvent.submitter.name,
      //   source: window.location.href,
      // })
      // .then(() => {
      // // printLog(window.location.href.substring(0, -5))
      window.location.href = window.location.href.includes("stormupdated")
        ? "https://stormupdated.com.au/OSDs4VIPs"
        : // test Server URL
        window.location.href.includes("su-test")
          ? "https://su-test.ml/OSDs4VIPs"
          : // stage server URL
          window.location.href.includes("su-stage")
            ? "https://su-stage.ml/OSDs4VIPs"
            : // localhost URL
            "http://su-test.ml/OSDs4VIPs";
      //     // : "http://localhost:1337/OSDs4VIPs/",
      // });
    } else if (isEmail(this.state.assessorEmail)) {
      this.props.setSource(window.location.href);
      this.props.setAssessorEmail(this.state.assessorEmail);
      //Allow login for Cucumber testing
      if (this.state.assessorEmail === "cucumber@cleanstormwater.com.au") {
        this.props.setPage("Map");
        return;
      }
      if (event.nativeEvent.submitter.name === "storm") {
        await sails_api
          .post("/RecordUserLogin", {
            email: this.state.assessorEmail,
            ip: this.state.ip,
            buttonName: event.nativeEvent.submitter.name,
            source: window.location.href,
          })
          .then((res) => {
            this.props.setSessionID(res.data.sessionId);
          });
        this.props.setPage("Calculator");
      } else if (event.nativeEvent.submitter.name === "auditor") {
        // put this back once the sails api is updated
        // await sails_api
        //   .post("/RecordUserLogin", {
        //     email: this.state.assessorEmail,
        //     ip: this.state.ip,
        //     buttonName: event.nativeEvent.submitter.name,
        //     source: window.location.href,
        //   })
        //   .then((res) => {
        //     this.props.setSessionID(res.data.sessionId);
        //   });
        this.props.setPage("Auditor");
      } else if (event.nativeEvent.submitter.name === "JCDownload") {
        await sails_api
          .post("/RecordUserLogin", {
            email: this.state.assessorEmail,
            ip: this.state.ip,
            buttonName: event.nativeEvent.submitter.name,
            source: window.location.href,
          })
          .then((res) => {
            setTimeout(() => {
              window.open(res.data.url, "_blank");
            }, 3000);
          });

        this.setState({
          showAlert: true,
          alertMessage: "💖Your download must begin shortly. If it doesn't, ",
          alertVariant: "info",
          link: true,
        });
      } else {
        if (event.nativeEvent.submitter.name === "BTB") {
          await sails_api
            .post("/RecordUserLogin", {
              email: this.state.assessorEmail,
              ip: this.state.ip,
              buttonName: event.nativeEvent.submitter.name,
              source: window.location.href,
            })
            .then((res) => {
              setTimeout(() => {
                window.open(res.data.url, "_blank");
              }, 1000);
            });
        } else {
          this.setState({
            showAlert: true,
            alertMessage: "❗ Enter a valid email",
            alertVariant: "danger",
          });
        }
      }
    } else {
      this.setState({
        showAlert: true,
        alertMessage: "❗ Enter a valid email",
        alertVariant: "danger",
      });
    }
  };

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ showAlert: false });
  }

  render() {
    return (
      <div style={{ display: "flex" }}>
        {/* advertisement on the left side */}
        <div>
          <AdsPanel dataForadsPanel={"Left"} />
        </div>

        {/* main content in the middle */}
        <Container>
          <div className="panOuterHome">
            <TitleLine />
            <Alert variant="success" className="mt-2 ml-2 mr-2">
              <div>
                <Alert.Heading as="h4" className="ml-2">
                  Accepted by LGAs:{" "}
                </Alert.Heading>
              </div>
              <div
                className="row-sm-12"
                style={{
                  fontSize: "18px",
                  whiteSpace: "nowrap",
                  paddingLeft: "10px",
                }}
              >
                <LGATicker />
              </div>
            </Alert>
            {/* main buttons and forms */}
            <Container className="flex-horizontal">
              <Row className="main-row">
                <Col className="main-col">
                  <div className="panInnerHome" id="email-tools">
                    <Form onSubmit={this.handleSubmit}>
                      <div>
                        <h5>Enter the Calculator</h5>
                        <input
                          name="assessorEmail"
                          className=" form-control inputEmail"
                          placeholder="Enter email-id"
                          value={this.state.assessorEmail}
                          onChange={(e) => this.handleInputChange(e)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              alert(
                                "🤖 says: I don't know what tool you want. Gaaaaah!\n" +
                                "\n" +
                                "Please press on the button click click 🤖"
                              );
                            }
                          }}
                        />
                        <p>
                          *Enter your email address and then click on your
                          selection.
                        </p>
                        <Alert
                          variant={this.state.alertVariant}
                          show={this.state.showAlert}
                        >
                          {this.state.alertMessage}
                          {this.state.link === true ? (
                            <Alert.Link
                              target="_blank"
                              href="https://stormwaterwares.s3-ap-southeast-2.amazonaws.com/John+Connor/JC_installer.exe"
                            >
                              click me
                            </Alert.Link>
                          ) : null}
                        </Alert>
                      </div>
                      <div>
                        <MainButton />
                      </div>
                    </Form>
                  </div>
                </Col>
                <Col className="main-col">
                  <div className="panInnerHome" id="non-email-tools">
                    <Form onSubmit={this.handleSubmit}>
                      <p>Check these awesome tools too!</p>
                      <SecondaryButton />
                    </Form>
                  </div>
                  <div
                    className="panInnerHome"
                    id="brochure"
                  >
                    <Brochure />
                  </div>
                </Col>
                <Col className="main-col">
                  <div className="panInnerHome" id="news">
                    <LatestNews />
                  </div>
                </Col>
              </Row>
            </Container>
            {/* council gallery */}
            <CouncilMaps />
            {/* about */}
            <About />
          </div>
        </Container>

        {/* advertisement on the right side */}
        <div>
          <AdsPanel dataForadsPanel={"Right"} />
        </div>

        {this.state.userExist ?
          null : (
            <>
              {/* using Shepherd */}
              < ShepherdTour steps={steps} tourOptions={tourOptions}>
                <TourMethods>
                  {tourContext => <Tour context={tourContext} />}
                </TourMethods>
              </ShepherdTour>
            </>
          )}
      </div>
    );
  }
}

export default Home;