import React from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import { printLog } from "./common";

import "./assets/css/mapcms.css";
class MapCms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfoWindow: false,
      infoWIndowDetails: null,
    };
  }

  render() {
    return (
      <>
        {this.props.companyDetails.length > 0 &&
          this.props.companyDetails.map((company, index) => {
            var image = {
              url: "https://su-map-cms.ga" + company.image,

              scaledSize: new window.google.maps.Size(32, 32),
              //         : new window.google.maps.Size(73, 32)
            };
            return (
              <div key={index}>
                <Marker
                  options={{ icon: image }}
                  key={"company" + index}
                  position={company}
                  // color="Blue"

                  // icon={"https://su-test.ml//mapcms/media/King.jpg"}
                  onMouseOver={() => {
                    this.setState({
                      showInfoWindow: true,
                      infoWIndowDetails: company,
                    });
                  }}
                  onMouseOut={() => {
                    this.setState({
                      showInfoWindow: false,
                      infoWIndowDetails: null,
                    });
                  }}
                  onClick={async () => {
                    await this.props.sails_api
                      .post("/saveMapCmsClick", {
                        email: this.props.userDetails,
                        ip: this.props.ip,
                        source: this.props.source,
                        companyClicked: company.name,
                      })
                      .then((res) => {
                        printLog("Map Cms saved", res);
                      })
                      .catch((e) => {
                        console.log("Sails is not working");
                      });

                    setTimeout(() => {
                      window.open(company.url, "_blank");
                    }, 1000);
                  }}
                ></Marker>
                {/* <BsBuilding /> */}
              </div>
            );
          })}

        {this.state.showInfoWindow && (
          <InfoWindow
            key={"info"}
            show={false}
            position={{
              lat: this.state.infoWIndowDetails.lat,
              lng: this.state.infoWIndowDetails.lng,
            }}
            options={{ pixelOffset: new window.google.maps.Size(0, -34) }}
          >
            <div className="container">
              <div className="details">
                <h4>{this.state.infoWIndowDetails.name}</h4>
                <p>{this.state.infoWIndowDetails.description}</p>

                {this.state.infoWIndowDetails.authority !== "Council" && (
                  <div className="image-container">
                    <img
                      src={
                        "https://su-map-cms.ga" +
                        this.state.infoWIndowDetails.fullImage
                      }
                      alt={
                        "https://su-map-cms.ga" +
                        this.state.infoWIndowDetails.fullImage
                      }
                      style={{ height: "60px" }}
                    ></img>
                  </div>
                )}
                {/* Insert Picture here */}
              </div>
            </div>
          </InfoWindow>
        )}
        {this.props.showCouncils &&
          this.props.councilDetails.map((company, index) => {
            var image = {
              url: "https://su-map-cms.ga" + company.image,
              scaledSize: new window.google.maps.Size(26, 26),
            };
            return (
              <div key={index}>
                <Marker
                  options={{ icon: image }}
                  key={"company" + index}
                  position={company}
                  onMouseOver={() => {
                    this.setState({
                      showInfoWindow: true,
                      infoWIndowDetails: company,
                    });
                  }}
                  onMouseOut={() => {
                    this.setState({
                      showInfoWindow: false,
                      infoWIndowDetails: null,
                    });
                  }}
                  onClick={() => {
                    // this.setState({
                    //     showInfoWindow: true,
                    //     infoWIndowDetails: company

                    // });
                    setTimeout(() => {
                      window.open(company.url, "_blank");
                    }, 1000);
                  }}
                ></Marker>
                {/* <BsBuilding /> */}
              </div>
            );
          })}
      </>
    );
  }
}

export default MapCms;
