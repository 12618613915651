import React from "react";
import { Button, Accordion, Card, Tabs, Tab, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/locationaccordion.css";
import Midware from "./Midware";

class LocationAccordion extends React.Component {
  render() {
    return (
      <Accordion defaultActiveKey="0" className="accordionSpacing">
        <Card>
          <Card.Header className="addAddressLink">
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="0"
              className="addAddressLinkButton"
            >
              {/* Select Site Location */}
              <Nav variant="pills" activeKey="1">
                <Nav.Link style={{ marginLeft: "20px" }} eventKey="1">
                  Select Site Location
                </Nav.Link>
              </Nav>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Tabs defaultActiveKey="map" className="addAddressLinkTabHeader">
              <Tab eventKey="map" title="Choose from Map">
                <Midware
                  // for storing record
                  ip={this.props.ip}
                  source={this.props.source}

                  setAddress={this.props.setAddress}
                  auditorInterface={this.props.auditorInterface}
                  setRainfallStation={this.props.setRainfallStation}
                  sails_api={this.props.sails_api}
                  basicInfo={this.props.basicInfo}

                  // For Map CMS
                  companyDetails={this.props.companyDetails}
                  councilDetails={this.props.councilDetails}

                  targetReductions={this.props.targetReductions}

                  showMusicAuditor={this.props.showMusicAuditor}
                  setShowMusicAuditor={this.props.setShowMusicAuditor}
                  setDisableAuditButton={this.props.setDisableAuditButton}

                  disableWQ={this.props.disableWQ}
                  cityCouncil={this.props.cityCouncil}
                />
              </Tab>
              {/* <Tab eventKey="manual" title="Enter Manually">
                <ManualAddress
                  setAddress={this.props.setAddress}
                  setRainfallStation={this.props.setRainfallStation}
                  rainfallStations={this.props.rainfallStations}
                />
              </Tab> */}
            </Tabs>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }
}

export default LocationAccordion;
