export default [
    {
      id: "installation",
      text:
        "3 - 4 minutes to know how to use the tools.",
      attachTo: { element: ".main-btn-col-2", on: "top" },
      buttons: [
        {
          type: "cancel",
          classes: "shepherd-button-secondary",
          text: "Click to continue"
        }
      ]
    },
  ];
  