import React from "react";
import "./assets/css/adsPanel.css";
import * as commonfunctions from "./common.js"


class AdsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.setAdsInfoObj(this.props.dataForadsPanel)
  }
  /**
   *Object in which the ad elements must be stored
   */
  adsInfoObj = []

  /**
   * object to store ads to be displayed in the left panel
   */
  leftAdsInfoObj = [ { "image": "SPEL Logo 1.6 ratio.jpg", "link":"https://spel.com.au/"},
   { "image": "Humes 1.6 ratio.png", "link": "https://www.holcim.com.au/humes/precast-concrete-solutions/stormwater-solutions/stormwater-treatment"},
   { "image": "star water1.6 ratio.png", "link": "https://www.starwater.com.au/"},
   { "image": "rocka1.6 ratio.png", "link": "https://www.rocla.com.au/products/"},
   { "image": "Urban Asset Solutions 1.6 ratio.png", "link": "https://www.urbanassetsolutions.com.au/"},
   {"image":"Hydrocon 1.6 ratio.png","link":"https://hydrocon.com.au/"},
   { "image": "WaterPave 1.6 ratio.png", "link": "https://www.waterpave.com.au/"},
   { "image": "ACO 1.6.jpg", "link": "https://www.acostormbrixx.com.au/product-support/project-support/"}];
  /**
   * object to store ads to be displayed in the right panel
   */
  rightAdsInfoObj =[ { "image": "enviro 1.6 ratio.png", "link": "https://enviroaustralis.com.au/"}, 
  { "image": "Frankishe1.6 ratio.png", "link": "https://www.stormwatermanagement.com.au/" },
   { "image": "Vinidex 1.6 ratio (1).png", "link": "https://www.vinidex.com.au/" },
   { "image": "iplex 1.6 ratio.png", "link": "https://www.iplex.com.au/products/" },
   { "image": "STS1.6 ratio.png", "link": "https://stormwatertreatment.com.au/" },
   { "image": "Waterline tanks 1.6 ratio.png", "link": "https://waterline.com.au/" },
   { "image": "Premier Paver 1.6 ratio.jpg", "link": "https://premierpavers.com.au/permeable-pavers/" }];
  /**
   * sets the adsInfo bject based upon the parameters passed from home 
   * @param {String} side sends the parameter for which the ads need to be displayed
   */
  setAdsInfoObj(side) {
    if (side === "Left") {
      this.adsInfoObj = this.leftAdsInfoObj
    } else {
      this.adsInfoObj = this.rightAdsInfoObj
    }
  }
  /**
   * Function to create Ads div that will contain the images and the links
   * @returns Array of JSX components of Ads
   */
  createAdsElement() {
    let adsArr = []
    for (let i = 0; i < this.adsInfoObj.length; i++) {
      let adsDiv = React.createElement("div", { className: "AdsImageDiv", key:i },
        this.adsInfoObj[i].hasOwnProperty("link") ? this.createLinkComponent(i) : this.getImageorCaption(i))
      adsArr.push(adsDiv)
    }
    return adsArr;
  }
  /**
   * Function to check whether the ad has cation or image
   * @param {Number} index index element of the ads object
   * @returns JSX component that contains either image or caption element
   */
  getImageorCaption(index) {
    if (this.adsInfoObj[index].hasOwnProperty("image")) {
      let abv = this.createImageComponentForAds(this.adsInfoObj[index]['image'])
      return abv
    } else {
      return this.createCaptionComponent((this.adsInfoObj[index]['caption']))
    }
  }
  /**
   * Creates the anchor element for ads dom
   * @param {Number} index the index of the ads array object for which ad is being created
   * @returns returns the anchor component of the ad
   */
  createLinkComponent = (index) => {
    return React.createElement('a',
      {target:"_blank", href: this.adsInfoObj[index].hasOwnProperty("link") ? this.adsInfoObj[index]['link'] : "#top" },
      this.getImageorCaption(index))

  }
  /**
   * Creates the caption component for the advertisement
   * @param {String} captionText The text that needs to be added for the advertisement
   */
  createCaptionComponent = (captionText) => {
    <div>{captionText}</div>
  }
  /**
   * Function to create the image component for the ad
   * @param {String} imageName the name of the m=image that needs to be picked up from the folder
   * @returns The image component for the ad
   */
  createImageComponentForAds(imageName) {
    let adsImageComponent = commonfunctions.createImagecomponent(imageName,"AdsImageClass")
    return adsImageComponent
  }


  render() {
    return (
      <div style={{margin:"4%", minWidth:"125px"}}>
        {this.createAdsElement()}
      </div>
    );
  }
}

export default AdsPanel;
