import React from "react";
// css
import '../assets/css/announcements.css';
// react-bootstrap
import { Carousel } from 'react-bootstrap';

class Announcements extends React.Component {
  render()  {
    return  (
      <Carousel style={{height:"340px", width: "420px"}}>
        <Carousel.Item>
        
          <img
            className="d-block w-100 img"
            src="../assets/Announcements/approved.png"
            alt="Approved"
          />
        </Carousel.Item>
        
        <Carousel.Item>
          <img
            className="d-block w-100 img"
            src="../assets/Announcements/JCBanner.png"
            alt="Approved"
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100 img"
            src="../assets/Announcements/BOM-comp-image.png"
            alt="Approved"
          />
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default Announcements;
