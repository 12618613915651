import React from "react";
import * as _ from "lodash";
// react-bootstrap
import { Button, Spinner } from "react-bootstrap";
// css
import "../css/osd.css";
import "bootstrap/dist/css/bootstrap.min.css";
// components
import { printLog } from "../../common";
import CouncilRuleWarning from "../../Calculator/warning_message/CouncilRuleWarning";

class Rational_SunshineCoast extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      psd: false,
      flowTypes: ["Channelised/Concentrated", "Sheet"],
      surfaceTypes: [
        "Paved",
        "Bare Soil",
        "Poorly Grassed",
        "Average Grassed",
        "Densely Grassed",
      ],
      preDevAreas: [
        {
          areaName: "Pre-dev. area 1",
          surfaceType: "Paved",
          surfaceArea: 0,
        },
      ],
      errors: [],
      osdInputsValid: false,
      osdResultLoading: false,
    };
  }

  async componentDidMount() {
    // printLog(this.props.data.osd);
  }

  //handle input for slope and length of flow
  async handleInputChange(e) {
    let { name, value } = e.target;
    if (name === "uncontrolledAreaRoC") {
      if ((value >= 0 && value <= 1) || value === "") {
        this.props.setList(name, value === "" ? "" : value);
      }
      return;
    }

    if (name === "flowType") {
      this.props.setList(name, value);
      return;
    }

    if (value >= 0) {
      this.props.setList(name, value);
      return;
    }
  }

  // handle input in predev
  async handlePreDevInputChange(e, index) {
    let { name, value } = e.target;
    printLog(name, value, index);
    var list = [...this.props.data.osd.preDevAreas];
    printLog(list);
    list[index][name] = value;
    this.props.setList("preDevAreas", list);
  }

  // handle click event of the Remove button in predev
  handlePreDevRemoveClick = (index) => {
    const areas = this.props.data.osd.preDevAreas;
    _.pullAt(areas, index); // See imports above.
    this.props.setList("preDevAreas", areas);
  };

  // handle click event of the Add button
  handlePreDevAddClick = () => {
    this.props.setList("preDevAreas", [
      ...this.props.data.osd.preDevAreas,
      {
        areaName: "",
        surfaceType: "",
        surfaceArea: 0,
      },
    ]);
  };

  // handle input in postdev
  async handlePostDevInputChange(e, index) {
    let { name, value } = e.target;
    var list = [...this.props.data.osd.postDevAreas];
    list[index][name] = value;
    this.props.setList("postDevAreas", list);
  }

  // handle click event of the Remove button in predev
  handlePostDevRemoveClick = (index) => {
    const areas = this.props.data.osd.postDevAreas;
    _.pullAt(areas, index); // See imports above.
    this.props.setList("postDevAreas", areas);
  };

  // handle click event of the Add button
  handlePostDevAddClick = () => {
    this.props.setList("postDevAreas", [
      ...this.props.data.osd.postDevAreas,
      {
        areaName: "",
        surfaceType: "",
        surfaceArea: 0,
      },
    ]);
  };

  async validateInputs() {}

  async computeOSDResults() {
    if (this.state.osdInputsValid) {
      this.setState({ osdResultLoading: true });
      await this.props.setOSD("osdCalculating", true);
      await this.props.sails_api
      .post("/OSD/osdResults", {
        latitude: this.props.data.latitude,
        longitude: this.props.data.longitude,
        osd: this.props.data.osd,
        totalImpArea: this.props.data.totalImpArea,
        totalPArea: this.props.data.totalPArea,
      })
        .then(async (res) => {
          await this.props.setOSD("osdResult", res.data);
          this.setState({ osdResultLoading: false });
          await this.props.setOSD("osdCalculating", false);
        })
        .catch((err) => console.error(err));
    }
  }

  render() {
    return (
      <div className="panNode_osd">
        <h5>OSD rational modified*</h5>
        {/* render council rules */}
        {this.props.cityCouncil.includes("Monash City")? (
          <CouncilRuleWarning dataType="osd" />
        ) : (null)}
        <label className="checkboxLabel_Red">
          {" "}
          *OSD results are produced using the {this.props.data.osdMethod}{" "}
          Method.
        </label>
        <div style={{ marginBottom: "12px" }}>
          <table className="table-responsive">
            <tbody>
              <tr>
                <td>Area (m²):</td>
                <td>
                  <input
                    placeholder="Area"
                    className="input_area"
                    name="area"
                    value={this.props.data.osd.area}
                    onChange={(e) => this.handleInputChange(e)}
                  ></input>
                </td>
                {/* <td style={{ width: "30px" }}></td> */}
                <td>
                  <div className="spacer"></div>
                </td>
                <td>
                  Dominant flow type:
                  <select
                    placeholder="User Input"
                    name="flowType"
                    disabled={
                      this.props.data.osd.area === "" ||
                      parseFloat(this.props.data.osd.area) > 15000
                    }
                    value={this.props.data.osd.flowType}
                    onChange={(e) => this.handleInputChange(e)}
                  >
                    {this.state.flowTypes.map((type) => (
                      <option value={type} key={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  {/* <select
                    placeholder="User Input"
                    name="flowType"
                    disabled={
                      this.props.data.osd.area === "" ||
                      parseFloat(this.props.data.osd.area) > 15000
                    }
                    value={this.props.data.osd.flowType}
                    onChange={(e) => this.handleInputChange(e)}
                  >
                    {this.state.flowTypes.map((type) => (
                      <option value={type} key={type}>
                        {type}
                      </option>
                    ))}
                  </select> */}
                </td>
              </tr>
              <tr>
                <td>Slope (%):</td>
                <td>
                  <input
                    placeholder="User Input"
                    className="input_area"
                    name="slope"
                    value={this.props.data.osd.slope}
                    onChange={(e) => this.handleInputChange(e)}
                  ></input>
                </td>
                {/* <td style={{ width: "30px" }}></td> */}
                <td>
                  <div className="spacer"></div>
                </td>
                <td>
                  <div className="checkboxOSDPad">
                    <label className="checkboxLabel">
                      <input
                        name="hasSubareas"
                        type="checkbox"
                        checked={this.props.data.osd.hasSubareas}
                        disabled={
                          this.props.data.osd.slope > 15 ||
                          this.props.data.osd.flowType === "Sheet"
                        }
                        onChange={async (e) => {
                          this.handleInputChange({
                            target: {
                              name: e.target.name,
                              value: e.target.checked,
                            },
                          });
                          // this.setState({ psd: e.target.checked });
                          this.props.setList("hasSubareas", e.target.checked);
                        }}
                        className="checkbox"
                      />
                      This catchment has subareas where sheet flow dominates
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Length of Flow Path (m):</td>
                <td>
                  <input
                    placeholder="User Input"
                    className="input_area"
                    name="flowLength"
                    value={this.props.data.osd.flowLength}
                    onChange={(e) => this.handleInputChange(e)}
                  ></input>
                </td>
                <td>
                  <div className="spacer"></div>
                </td>
              </tr>
              <tr>
                <td>Uncontrolled Area:</td>
                <td>
                  <input
                    name="uncontrolledArea"
                    className="input_area"
                    value={this.props.data.osd.uncontrolledArea}
                    required
                    onChange={(e) => this.handleInputChange(e)}
                  />
                  (m²)
                </td>
                <td>
                  <div className="spacer"></div>
                </td>
                <td>
                  Runoff Coefficient:
                  <input
                    name="uncontrolledAreaRoC"
                    className="input_area"
                    value={this.props.data.osd.uncontrolledAreaRoC}
                    required
                    onChange={(e) => this.handleInputChange(e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <table
            className="table-responsive"
            style={{ backgroundColor: "rgb(149, 186, 209, 0.2)" }}
          >
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th style={{ borderBottom: "1px solid rgb(149, 186, 209)" }}>
                  <h6> Pre-development areas</h6>
                </th>
                <th
                  style={{
                    width: "30px",
                    backgroundColor: "rgb(234, 241, 255)",
                  }}
                >
                  {" "}
                </th>
                <th style={{ borderBottom: "1px solid rgb(149, 186, 209)" }}>
                  <h6> Post-development areas</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* for predev areas */}
                <td style={{ verticalAlign: "top" }}>
                  <table>
                    <thead>
                      <tr></tr>
                      <tr>
                        <th></th>
                        <th>Surface Type</th>
                        <th>Surface Area (m²)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {printLog(this.props)}
                      {this.props.data.osd.preDevAreas.map((area, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <input
                                placeholder={"Pre-dev. pervious area " + i}
                                name="areaName"
                                value={area.areaName}
                                disabled={i === 0}
                                onChange={(e) =>
                                  this.handlePreDevInputChange(e, i)
                                }
                              ></input>
                            </td>
                            <td>
                              <select
                                placeholder="User Input"
                                name="surfaceType"
                                disabled={i === 0}
                                value={area.surfaceType}
                                onChange={(e) =>
                                  this.handlePreDevInputChange(e, i)
                                }
                                style={{ width: "100px" }}
                              >
                                {this.state.surfaceTypes.map((type) => (
                                  <option value={type} key={type}>
                                    {type}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <input
                                placeholder="User Input"
                                name="surfaceArea"
                                value={area.surfaceArea}
                                style={{ width: "100px" }}
                                onChange={(e) =>
                                  this.handlePreDevInputChange(e, i)
                                }
                              ></input>
                            </td>
                            <td>
                              <Button
                                variant="primary"
                                className="btn btn-sm btn-grad"
                                disabled={i === 0}
                                onClick={() => this.handlePreDevRemoveClick(i)}
                                style={{ marginRight: "2px" }}
                              >
                                🗑
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Button
                    variant="primary"
                    className="btn btn-sm btn-grad"
                    onClick={this.handlePreDevAddClick}
                    style={{ margin: "2px 8px" }}
                  >
                    + Add Pervious Area
                  </Button>
                </td>

                <td style={{ backgroundColor: "rgb(234, 241, 255)" }}></td>

                {/* for postdev areas */}
                <td style={{ verticalAlign: "top" }}>
                  <table>
                    <thead>
                      <tr></tr>
                      <tr>
                        <th></th>
                        <th>Surface Type</th>
                        <th>Surface Area (m²)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.data.osd.postDevAreas.map((area, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <input
                                placeholder={"Post-dev. pervious area " + i}
                                name="areaName"
                                value={area.areaName}
                                disabled={i === 0}
                                onChange={(e) =>
                                  this.handlePostDevInputChange(e, i)
                                }
                              ></input>
                            </td>

                            <td>
                              <select
                                placeholder="User Input"
                                name="surfaceType"
                                disabled={i === 0}
                                value={area.surfaceType}
                                onChange={(e) =>
                                  this.handlePostDevInputChange(e, i)
                                }
                                style={{ width: "100px" }}
                              >
                                {this.state.surfaceTypes.map((type) => (
                                  <option value={type} key={type}>
                                    {type}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <input
                                placeholder="User Input"
                                name="surfaceArea"
                                value={area.surfaceArea}
                                style={{ width: "100px" }}
                                onChange={(e) =>
                                  this.handlePostDevInputChange(e, i)
                                }
                              ></input>
                            </td>
                            <td>
                              <Button
                                variant="primary"
                                className="btn btn-sm btn-grad"
                                disabled={i === 0}
                                onClick={() => this.handlePostDevRemoveClick(i)}
                                style={{ marginRight: "2px" }}
                              >
                                🗑
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Button
                    variant="primary"
                    className="btn btn-sm btn-grad"
                    onClick={this.handlePostDevAddClick}
                    style={{ margin: "2px 8px" }}
                  >
                    + Add Pervious Area
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {this.state.osdResultLoading || this.props.exportingReport ? (
          <Button
            variant="primary"
            className="btn btn-sm btn-grad"
            style={{ marginTop: "20px" }}
            disabled
          >
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="sr-only">Loading...</span>
          </Button>
        ) : (
          <Button
            variant="primary"
            className="btn btn-sm btn-grad"
            style={{ marginTop: "20px" }}
            // onClick={this.computeOSDResults.bind(this)}
            onClick={(e) => printLog(this.props.data.osd)}
          >
            CALCULATE OSD
          </Button>
        )}

        {this.props.data.osdResult !== null && this.state.osdInputsValid ? (
          <>
            <table
              className="table table-horizontal table-bordered col-sm-6"
              style={{ marginTop: "20px" }}
            >
              <thead
                style={{
                  backgroundColor: "#508bc9",
                  color: "white",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                <tr>
                  <th colSpan="2" style={{ fontSize: "16px" }}>
                    OSD Results
                  </th>
                </tr>
                <tr>
                  <th>Permissible Discharge</th>
                  <th>On site detention volume</th>
                </tr>
              </thead>

              <tbody
                style={{
                  border: "1.5px solid #c7c7ce",
                  textAlign: "center",
                  fontSize: "14px",
                }}
              >
                <tr>
                  <td>
                    {this.state.psd
                      ? this.props.data.osd.councilPSD
                      : parseFloat(this.props.data.osdResult.PSD).toFixed(
                          2
                        )}{" "}
                    L/s
                  </td>
                  <td>
                    {this.props.data.osdResult.onSiteDetentionVolume.toFixed(2)}{" "}
                    m<sup>3</sup>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) : null}
      </div>
    );
  }
}

export default Rational_SunshineCoast;
