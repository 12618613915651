import React from "react";
import "./assets/css/thefooter.css";
import { FaLinkedin } from "react-icons/fa";
import LGATabs from "./LGATabs";

class TheFooter extends React.Component {
  render() {
    return (
      <div className="footer-dark">
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-10 item text">
                <h4 className="thefont">STORMupdated - Accepted in</h4>
                <div style={{ height: "310px", marginBottom: "20px" }}>
                  <LGATabs />
                </div>
                <div className="row">
                  <p>
                    Please feel free to submit STORMupdated Reports as part of
                    your Application.
                    <br />
                    <br />
                    {/* This tool is not yet endorsed by Melbourne Water. For
                    further information, please contact us at
                    mircea@cleanstormwater.com.au or call Mircea at 0433030044. */}
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-md-10 item text">
                <h4 className="thefont">Cleanstormwater</h4>
                <div className="row">
                  <div className="col-md-3 item text">
                    <h6>About</h6>
                    <ul>
                      <li>
                        <a href="#">Company</a>
                      </li>
                      <li>
                        <a href="#">Team</a>
                      </li>
                      <li>
                        <a href="#">Careers</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-2 col-md-3 item text">
                    <h6>Services</h6>
                    <ul>
                      <li>
                        <a href="#">Web design</a>
                      </li>
                      <li>
                        <a href="#">Development</a>
                      </li>
                      <li>
                        <a href="#">Hosting</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col item social">
                <a href="#">
                  <FaLinkedin />
                </a>
              </div>
            </div> */}
            <p className="copyright">
              <a
                href="https://www.cleanstormwater.com.au"
                target="_blank"
                style={{ color: "white" }}
              >
                ❤️ Cleanstormwater. 2021
              </a>
            </p>
          </div>
        </footer>
      </div>
    );
  }
}

export default TheFooter;
