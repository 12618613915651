import React from "react";
// react-bootstrap
import { Alert, Button, Spinner } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
// css
import "../css/osd.css";
import "bootstrap/dist/css/bootstrap.min.css";
// components
import OsdResultsTable from "../../OsdResultsTable";
import { printLog } from "../../common";
import CouncilRuleWarning from "../../Calculator/warning_message/CouncilRuleWarning";

class Swinburne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      psd: false,

      errors: [],
      osdInputsValid: false,
      areasImpMatch: true,
      areasPMatch: true,

      alertMessage1: "",
      showAlert1: false,
      alertVariant1: "",
      alertMessage2: "",
      showAlert2: false,
      alertVariant2: "",
      alertMessage3: "",
      showAlert3: false,
      alertVariant3: "",
      alertMessage4:
        "⚠️ The AEP for storage cannot be greater than the AEP for flow. Please check the inputs",
      showAlert4: false,
      alertVariant4: "warning",

      uncontrolledOK: true,

      osdResultLoading: false,

      climateDisplay: 0,
    };
  }

  async componentDidMount() {}

  async componentDidUpdate() {
    if (this.props.data.osdReset) {
      this.props.data.osdReset = false;
      this.setState({ osdInputsValid: false });
      this.setState({ climateDisplay: 0 });
      this.setState({ psd: false });
      await this.props.setList("climateAdjustment", 1);
    }
  }
  async handleClimateChange(e) {
    const { name, value } = e.target;
    printLog(name, value);

    if (name === "climateAdjustment") {
      if (value >= 0 && value <= 1000) {
        this.setState({ climateDisplay: value.trim() });
        await this.props.setList("climateAdjustment", 1 + value / 100);
      } else if (value === "") {
        this.setState({ climateDisplay: "" });
        await this.props.setList("climateAdjustment", 1);
      }
    }
  }

  async handleInputChange(e) {
    const { name, value } = e.target;
    printLog(name, value);
    this.setState({ osdResult: null, uncontrolledOK: true });
    var new_value;

    this.props.setOSD("osdResult", null);
    this.props.setOSD("osdUncontrolledIssue", "");

    if (value === "") {
      await this.props.setOSD("activateButtons", false);
    }

    if (name === "psd") {
      this.props.data.osd.preDevPArea = 0;
      this.props.data.osd.preDevImpArea = 0;
      if (value === true) {
        await this.setState({ [name]: value, showAlert4: false });
      } else {
        if (
          parseFloat(this.props.data.osd.aepPSD) <=
          parseFloat(this.props.data.osd.aepOSD)
        ) {
          this.setState({ [name]: value, showAlert4: true });
        } else {
          this.setState({ [name]: value, showAlert4: false });
        }
      }
      this.props.data.osd.psd = value;
      this.props.data.osd.councilPSD = "";
    }

    if (this.props.data.osd.preDevPArea == "") {
      this.props.data.osd.preDevPArea = 0;
    }

    if (this.props.data.osd.preDevImpArea == "") {
      this.props.data.osd.preDevImpArea = 0;
    }

    if (this.props.data.osd.postDevPArea == "") {
      this.props.data.osd.postDevPArea = 0;
    }

    if (this.props.data.osd.postDevImpArea == "") {
      this.props.data.osd.postDevImpArea = 0;
    }

    if (name === "councilPSD") {
      if (value >= 0 || value === "") {
        this.props.data.osd.councilPSD = value;
      }
    }

    if (name === "storageType") {
      this.props.data.osd.storageType = value;
    }
    /* if (name === "SwinburneMethodVariations") {
      if( value == "OSD4W"){
        this.props.data.osd.qaMultiplicationFactor = 2;
      }
      if (value == "Standard Swinburne"){
        this.props.data.osd.qaMultiplicationFactor = 1;
      } 
    } */

    if (name === "catchmentTimeTc") {
      if (value >= 0 || value === "") {
        this.props.data.osd.catchmentTimeTc = value === "" ? "" : value;
        if (
          this.props.data.osd.catchmentTimeTc < 1 ||
          this.props.data.osd.catchmentTimeTc > 168 * 60
        ) {
          await this.setState({
            alertMessage1:
              "⚠️ Tc is outside the bounds of available BOM data (1min - 168hrs). This may result in calculation inaccuracies.",
            showAlert1: true,
            alertVariant1: "warning",
          });
          this.props.data.osd.catchmentTimeTcs = "N/A";
        } else {
          await this.setState({ showAlert1: false });
          value === ""
            ? (this.props.data.osd.catchmentTimeTcs = "N/A")
            : (this.props.data.osd.catchmentTimeTcs =
                value - this.props.data.osd.catchmentTimeTso).toFixed(2);
        }
        if (
          parseFloat(this.props.data.osd.catchmentTimeTc) <
          parseFloat(this.props.data.osd.catchmentTimeTso)
        ) {
          await this.setState({
            alertMessage3:
              "⚠️ Tc should be greater than Tso. Please check the inputs.",
            showAlert3: true,
            alertVariant3: "warning",
          });
          this.props.data.osd.catchmentTimeTcs = "N/A";
        } else {
          await this.setState({ showAlert3: false });
          value === ""
            ? (this.props.data.osd.catchmentTimeTcs = "N/A")
            : (this.props.data.osd.catchmentTimeTcs = (
                value - this.props.data.osd.catchmentTimeTso
              ).toFixed(2));
        }
      }
    }

    if (name === "catchmentTimeTso") {
      if (value >= 0 || value === "") {
        this.props.data.osd.catchmentTimeTso = value === "" ? "" : value;
        if (
          parseFloat(this.props.data.osd.catchmentTimeTso) >
          parseFloat(this.props.data.osd.catchmentTimeTc)
        ) {
          await this.setState({
            alertMessage3:
              "⚠️ Tc should be greater than Tso. Please check the inputs.",
            showAlert3: true,
            alertVariant3: "warning",
          });
          this.props.data.osd.catchmentTimeTcs = "N/A";
        } else {
          await this.setState({ showAlert3: false });
          if (value === "" || parseFloat(value) == 0) {
            this.props.data.osd.catchmentTimeTcs = "N/A";
          } else {
            this.props.data.osd.catchmentTimeTcs = (
              this.props.data.osd.catchmentTimeTc - value
            ).toFixed(2);
          }
        }
      }
    }

    if (name === "postDevImpArea") {
      if (value !== "") {
        var pattern = /^\d+(?:\.\d{0,2})?$/i;
        if (!pattern.test(value)) {
          return;
        }
      }

      if (this.props.data.osd.psd) {
        if (value >= 0 || value === "") {
          this.props.data.totalImpArea = value === "" ? "" : value;
          parseFloat(value)
            ? (this.props.data.osd.postDevImpArea = parseFloat(value))
            : (this.props.data.osd.postDevImpArea = "");
        }
      } else {
        if (
          (value >= 0 &&
            value <=
              parseFloat(this.props.data.osd.preDevImpArea) +
                parseFloat(this.props.data.osd.preDevPArea)) ||
          value === ""
        ) {
          this.props.data.totalImpArea = value === "" ? "" : value;
          if (!this.state.psd) {
            this.props.data.totalPArea = (
              parseFloat(this.props.data.osd.preDevPArea) +
              parseFloat(this.props.data.osd.preDevImpArea) -
              this.props.data.totalImpArea
            ).toFixed(2);
            this.props.data.osd.postDevPArea = this.props.data.totalPArea;
            this.props.data.osd.postDevImpArea = this.props.data.totalImpArea;
          }
        }
      }
    }

    if (name === "postDevPArea") {
      if (value !== "") {
        var pattern = /^\d+(?:\.\d{0,2})?$/i;
        if (!pattern.test(value)) {
          return;
        }
      }
      if (this.props.data.osd.psd) {
        if (value >= 0 || value === "") {
          this.props.data.totalPArea = value === "" ? "" : value;
          parseFloat(value)
            ? (this.props.data.osd.postDevPArea = parseFloat(value))
            : (this.props.data.osd.postDevPArea = "");
        }
      } else {
        if (
          (value >= 0 &&
            value <=
              parseFloat(this.props.data.osd.preDevImpArea) +
                parseFloat(this.props.data.osd.preDevPArea)) ||
          value === ""
        ) {
          this.props.data.totalPArea = value === "" ? "" : value;
          if (!this.state.psd) {
            this.props.data.totalImpArea = (
              parseFloat(this.props.data.osd.preDevPArea) +
              parseFloat(this.props.data.osd.preDevImpArea) -
              this.props.data.totalPArea
            ).toFixed(2);
            this.props.data.osd.postDevPArea = this.props.data.totalPArea;
            this.props.data.osd.postDevImpArea = this.props.data.totalImpArea;
          }
        }
      }
    }

    if (name === "tankHeight") {
      if (value >= 0 || value === "") {
        this.props.data.osd.tankHeight = value === "" ? "" : value;
      }
    }

    if (name === "preDevImpArea") {
      if (value >= 0 || value === "") {
        if (value !== "" && !value.match(/^\d{1,6}(\.\d{0,2})?$/)) {
          new_value = parseFloat(value).toFixed(2);
        } else {
          new_value = value;
        }
        this.props.data.osd.preDevImpArea = value === "" ? "" : new_value;
      }

      this.props.data.totalImpArea = "";
      this.props.data.totalPArea = "";
    }

    if (name === "preDevPArea") {
      if (value >= 0 || value === "") {
        if (value !== "" && !value.match(/^\d{1,6}(\.\d{0,2})?$/)) {
          new_value = parseFloat(value).toFixed(2);
        } else {
          new_value = value;
        }
        this.props.data.osd.preDevPArea = value === "" ? "" : new_value;
      }

      this.props.data.totalImpArea = "";
      this.props.data.totalPArea = "";
    }

    if (name === "preDevImpAreaRoC") {
      if ((value >= 0 && value <= 1) || value === "") {
        this.props.data.osd.preDevImpAreaRoC = value === "" ? "" : value;
      }
    }

    if (name === "preDevPAreaRoC") {
      if ((value >= 0 && value <= 1) || value === "") {
        this.props.data.osd.preDevPAreaRoC = value === "" ? "" : value;
      }
    }

    if (name === "postDevImpAreaRoC") {
      if ((value >= 0 && value <= 1) || value === "") {
        this.props.data.osd.postDevImpAreaRoC = value === "" ? "" : value;
      }
    }

    if (name === "postDevPAreaRoC") {
      if ((value >= 0 && value <= 1) || value === "") {
        this.props.data.osd.postDevPAreaRoC = value === "" ? "" : value;
      }
    }

    if (name === "uncontrolledImpArea") {
      if (value !== "") {
        var pattern = /^\d+(?:\.\d{0,2})?$/i;
        if (!pattern.test(value)) {
          return;
        }
      }
      if (
        value === "" ||
        (value >= 0 && value <= parseFloat(this.props.data.totalImpArea))
      ) {
        this.props.data.osd.uncontrolledImpArea = value;
      }
    }

    if (name === "uncontrolledPArea") {
      if (value !== "") {
        var pattern = /^\d+(?:\.\d{0,2})?$/i;
        if (!pattern.test(value)) {
          return;
        }
      }
      if (
        (value >= 0 && value <= parseFloat(this.props.data.totalPArea)) ||
        value === ""
      ) {
        this.props.data.osd.uncontrolledPArea = value;
      }
    }

    if (name === "aepPSD") {
      this.props.data.osd.aepPSD = value;
      if (parseFloat(value) < parseFloat(this.props.data.osd.aepOSD)) {
        this.setState({ showAlert4: true });
      } else {
        this.setState({ showAlert4: false });
      }
    }

    if (name === "aepOSD") {
      this.props.data.osd.aepOSD = value;
      if (!this.props.data.osd.psd) {
        if (parseFloat(this.props.data.osd.aepPSD) < parseFloat(value)) {
          this.setState({ showAlert4: true });
        } else {
          this.setState({ showAlert4: false });
        }
      }
    }

    await this.validateInputs();
  }

  async validateInputs() {
    // council psd provided
    var psdVal = true;
    var otherVal = false;

    if (this.state.psd && this.props.data.osd.councilPSD === "") {
      psdVal = false;
    }

    printLog("props.data.osd: ", this.props.data.osd);
    if (
      this.props.data.osd.preDevPArea !== "" &&
      this.props.data.osd.preDevPAreaRoC !== "" &&
      this.props.data.osd.preDevImpArea !== "" &&
      this.props.data.osd.preDevImpAreaRoC !== "" &&
      // this.props.data.osd.storageType !== "" &&
      Number(this.props.data.osd.postDevImpArea) + Number(this.props.data.osd.postDevImpArea) !== 0 &&
      this.props.data.osd.catchmentTimeTc !== "" &&
      this.props.data.osd.catchmentTimeTso !== "" &&
      this.props.data.osd.catchmentTimeTcs !== "" &&
      this.props.data.osd.postDevPAreaRoC !== "" &&
      this.props.data.osd.postDevImpAreaRoC !== "" &&
      this.props.data.osd.uncontrolledImpArea !== "" &&
      this.props.data.osd.uncontrolledPArea !== ""
    ) {
      otherVal = true;
    } else {
      this.setState({ osdInputsValid: false });
      return;
    }

    if (otherVal) {
      let totalImp = parseFloat(this.props.data.totalImpArea);
      let totalP = parseFloat(this.props.data.totalPArea);
      let totalCatchmentArea = totalImp + totalP;

      const predev =
        (this.props.data.osd.preDevImpArea *
          this.props.data.osd.preDevImpAreaRoC +
          this.props.data.osd.preDevPArea *
            this.props.data.osd.preDevPAreaRoC) /
        totalCatchmentArea;
      const postdev =
        (totalImp * this.props.data.osd.postDevImpAreaRoC +
          totalP * this.props.data.osd.postDevPAreaRoC) /
        totalCatchmentArea;

      let imp,
        p = false;
      if (
        parseFloat(this.props.data.osd.uncontrolledImpArea) <=
        parseFloat(this.props.data.totalImpArea)
      ) {
        imp = true;
      } else {
        imp = false;
      }
      if (
        parseFloat(this.props.data.osd.uncontrolledPArea) <=
        parseFloat(this.props.data.totalPArea)
      ) {
        p = true;
      } else {
        p = false;
      }

      let tcsOK = false;
      if (parseFloat(this.props.data.osd.catchmentTimeTcs) >= 2) {
        tcsOK = true;
      }

      printLog("IMP, p = ", imp, ", ", p);
      printLog("predev, postdev, ", predev, postdev);

      if (this.coefficientMatch(predev, postdev)) {
        this.setState({
          showAlert2: false,
          osdInputsValid:
            true && imp && p && psdVal && !this.state.showAlert3 && tcsOK,
          areasImpMatch: imp,
          areasPMatch: p,
        });
        this.props.data.osdOK =
          !this.props.data.hasOsd || (imp && p && psdVal && !this.state.showAlert3 && tcsOK) ;
      } else {
        this.setState({
          alertMessage2:
            "⛔ The weighted runoff coefficient for pre-development is equal to or larger than post-development. Please check the inputs.",
          showAlert2: true,
          alertVariant2: "danger",
          osdInputsValid: false,
          areasImpMatch: imp,
          areasPMatch: p,
        });
        this.props.data.osdOK = !this.props.data.hasOsd || (imp && p && tcsOK);
      }
    }
  }

  coefficientMatch(predev, postdev) {
    if (predev >= postdev) {
      return false;
    } else {
      return true;
    }
  }

  async computeOSDResults() {
    if (
      this.state.osdInputsValid &&
      this.state.areasImpMatch &&
      this.state.areasPMatch
    ) {
      this.setState({ osdResultLoading: true });
      await this.props.setOSD("osdCalculating", true);
      await this.props.sails_api
        .post("/OSD/osdResultsAllStorageTypes", {
          latitude: this.props.data.latitude,
          longitude: this.props.data.longitude,
          osd: this.props.data.osd,
          totalImpArea: parseFloat(this.props.data.totalImpArea),
          totalPArea: parseFloat(this.props.data.totalPArea),
        })
        .then(async (res) => {
          printLog(res.data);

          if (res.data.message) {
            this.setState({ osdResultLoading: false, uncontrolledOK: false });
            await this.props.setOSD("osdCalculating", false);
            await this.props.setOSD("osdUncontrolledIssue", res.data.message);
            return;
          }

          // if (res.data.Qp < 0) {
          //   this.setState({ osdResultLoading: false, uncontrolledOK: false });
          //   await this.props.setOSD("osdCalculating", false);
          //   await this.props.setOSD(
          //     "osdUncontrolledIssue",
          //     "Uncontrolled flow is too great. More area must be controlled."
          //   );
          //   return;
          // }
          await this.props.setOSD("osdResult", res.data);
          this.setState({ osdResultLoading: false, uncontrolledOK: true });
          await this.props.setOSD("osdCalculating", false);
          await this.props.setOSD("osdUncontrolledIssue", "");
        })
        .catch(async (err) => {
          console.error("error received", err.response);
          this.setState({ osdResultLoading: false, uncontrolledOK: false });
          await this.props.setOSD("osdCalculating", false);
          await this.props.setOSD("osdUncontrolledIssue", err.response);
          return;
        });
    }
  }

  render() {
    return (
      <div className="panNode_osd">
        <h5>OSD*</h5>
        {/* render council rules */}
        {this.props.cityCouncil.includes("Monash City")? (
          <CouncilRuleWarning dataType="osd" />
        ) : (null)}
        <label className="checkboxLabel_Red">
          {" "}
          *OSD results are produced using the {this.props.data.osd.method}.
        </label>
        <table className="table-responsive">
          <tbody>
            {/* <tr>
            <td colSpan={4} style= {{textAlign: "left"}}>
             
                Results based on:
                <select
                  name="SwinburneMethodVariations"
                  // value={this.props.data.osd.qaMultiplicationFactor}
                  required
                  onChange={(e) => this.handleInputChange(e)}
                >
                
                  <option key="OSD4W"> OSD4W </option> 
                  <option key="Standard Swinburne">Standard Swinburne</option> 
                </select>
              </td>  
            </tr> */}
            <tr>
              <td style={{ textAlign: "left" }}>
                {
                  <div className="checkboxOSDPad">
                    <label className="checkboxLabel">
                      <input
                        name="psd"
                        type="checkbox"
                        checked={this.state.psd}
                        disabled={this.props.data.cityCouncil==="Monash City"}
                        onChange={async (e) => {
                          this.handleInputChange({
                            target: {
                              name: e.target.name,
                              value: e.target.checked,
                            },
                          });
                          this.setState({ psd: e.target.checked });
                        }}
                        className="checkbox"
                      />
                      I have Council-provided PSD:
                      <span
                        data-tip="Uncontrolled areas are assumed to be accounted for in the calculation of PSD when PSD is provided by Council."
                        data-multiline="true"
                        data-place="bottom"
                      >
                        ❓
                      </span>
                      <ReactTooltip />
                    </label>
                  </div>
                }
              </td>
              <td>
                <input
                  name="councilPSD"
                  className="input_area"
                  value={this.props.data.osd.councilPSD}
                  disabled={this.state.psd === false}
                  required={this.state.psd}
                  onChange={(e) => this.handleInputChange(e)}
                />
                (L/s)
              </td>
              <td></td>
              <td></td>
              <td> </td>
              {/* <td colSpan={2}>
                {" "}
                Storage Type:
                <select
                  name="storageType"
                  // className="select_area_OSD"
                  value={this.props.data.osd.storageType}
                  required
                  onChange={(e) => this.handleInputChange(e)}
                >
                  <option value="" hidden>
                    Select
                  </option>
                  <option key="Above Ground Storage">
                    Above ground storage
                  </option>
                  <option key="Below Ground Pipe">Below ground pipe</option>
                  <option key="Below Ground Tank">Below ground tank</option>
                </select>
              </td> */}
              <td></td>
            </tr>
            <tr>
              <td> </td>
              <td> </td>
              <td></td>
              <td>&nbsp;&nbsp;Runoff Coefficient</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Pre-development Impervious Area:</td>
              <td>
                <input
                  name="preDevImpArea"
                  className="input_area"
                  value={this.props.data.osd.preDevImpArea}
                  disabled={this.state.psd === true}
                  required={!this.state.psd}
                  // required={this.props.data.hasOSD && !this.state.psd}
                  onChange={(e) => this.handleInputChange(e)}
                />
                (m²)
              </td>
              <td>
                <div className="small_spacer"></div>
              </td>
              <td>
                <input
                  name="preDevImpAreaRoC"
                  className="select_area_OSD"
                  value={this.props.data.osd.preDevImpAreaRoC}
                  disabled={this.props.data.cityCouncil==="Monash City" || this.state.psd === true}
                  required={!this.state.psd}
                  onChange={(e) => this.handleInputChange(e)}
                />
             

              </td>
              <td>
                <div className="spacer"></div>
              </td>
              <td>Time of Concentration of Catchment (Tc):</td>
              <td>
                <input
                  name="catchmentTimeTc"
                  className="input_area"
                  value={this.props.data.osd.catchmentTimeTc}
                  required
                  disabled={this.state.psd === true}
                  onChange={(e) => this.handleInputChange(e)}
                ></input>
                (min)
              </td>
            </tr>
            <tr>
              <td>Pre-development Pervious Area:</td>
              <td>
                <input
                  name="preDevPArea"
                  className="input_area"
                  value={this.props.data.osd.preDevPArea}
                  disabled={this.state.psd === true}
                  required={!this.state.psd}
                  onChange={(e) => this.handleInputChange(e)}
                />
                (m²)
              </td>
              <td>
                <div className="small_spacer"></div>
              </td>
              <td>
                <input
                  name="preDevPAreaRoC"
                  className="select_area_OSD"
                  value={this.props.data.osd.preDevPAreaRoC}
                  disabled={this.props.data.cityCouncil==="Monash City" || this.state.psd === true}
                  required={!this.state.psd}
                  onChange={(e) => this.handleInputChange(e)}
                /> 
        
              </td>
              <td>
                <div className="spacer"></div>
              </td>
              <td>Time of Concentration Site to Outlet (Tso):</td>
              <td>
                <input
                  name="catchmentTimeTso"
                  className="input_area"
                  value={this.props.data.osd.catchmentTimeTso}
                  required
                  disabled={this.state.psd === true}
                  onChange={(e) => this.handleInputChange(e)}
                ></input>
                (min)
              </td>
              <td></td>
            </tr>
            <tr>
              <td style={{ textAlign: "left" }}>
                Post-development Impervious Area:
              </td>
              <td>
                <input
                  name="postDevImpArea"
                  className="input_area"
                  value={this.props.data.totalImpArea}                
                  required
                  onChange={(e) => this.handleInputChange(e)}
                />
                (m²)
              </td>
              <td>
                <div className="small_spacer"></div>
              </td>
              <td>
                <input
                  name="postDevImpAreaRoC"
                  className="select_area_OSD"
                  value={this.props.data.osd.postDevImpAreaRoC}
                  disabled={this.props.data.cityCouncil==="Monash City"}
                  required
                  onChange={(e) => this.handleInputChange(e)}
                />
               
              </td>
              <td>
                <div className="spacer"></div>
              </td>
              <td style={{ textAlign: "left" }}>
                Time of Concentration Catchment to Site (Tcs):
              </td>
              <td>
                <label name="catchmentTimeTcs" className="lableFormatterOSD">
                  {this.props.data.osd.catchmentTimeTcs}
                </label>
                (min){" "}
                <span data-tip="Tcs = Tc - Tso" data-place="right">
                  ❓
                </span>
                <ReactTooltip />
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Post-development Pervious Area:</td>
              <td>
                <input
                  name="postDevPArea"
                  className="input_area"
                  value={this.props.data.totalPArea}
                  required
                  onChange={(e) => this.handleInputChange(e)}
                />
                (m²)
              </td>

              <td>
                <div className="small_spacer"></div>
              </td>
              <td>
                <input
                  name="postDevPAreaRoC"
                  className="select_area_OSD"
                  value={this.props.data.osd.postDevPAreaRoC}
                  disabled={this.props.data.cityCouncil==="Monash City"}
                  required
                  onChange={(e) => this.handleInputChange(e)}
                />
              </td>
              <td>
                <div className="spacer"></div>
              </td>
              <td>AEP for PSD:</td>
              <td>
                {" "}
                <select
                  name="aepPSD"
                  className="input_area"
                  value={this.props.data.osd.aepPSD}
                  disabled={this.props.data.cityCouncil==="Monash City" || this.state.psd === true}
                  // required
                  onChange={(e) => this.handleInputChange(e)}
                >
                  <option value="" hidden>
                    Select
                  </option>
                  {this.props.data.aeps.map((aep) => (
                    <option key={aep}>{aep}</option>
                  ))}
                </select>
                (%)
              </td>
            </tr>
            <tr>
              <td>Uncontrolled Impervious Area:</td>
              <td>
                <input
                  name="uncontrolledImpArea"
                  className="input_area"
                  value={this.props.data.osd.uncontrolledImpArea}
                  disabled={this.props.data.osd.psd}
                  required
                  onChange={(e) => this.handleInputChange(e)}
                />
                (m²)
              </td>
              <td>
                <div className="small_spacer"></div>
              </td>
              <td></td>
              <td>
                <div className="spacer"></div>
              </td>
              <td>AEP for OSD: </td>
              <td>
                <select
                  name="aepOSD"
                  className="input_area"
                  value={this.props.data.osd.aepOSD}
                  disabled={this.props.data.cityCouncil==="Monash City"}
                  required
                  onChange={(e) => this.handleInputChange(e)}
                >
                  <option value="" hidden>
                    Select
                  </option>
                  {this.props.data.aeps.map((aep) => (
                    <option key={aep}>{aep}</option>
                  ))}
                </select>
                (%)
              </td>
            </tr>
            <tr>
              <td>Uncontrolled Pervious Area:</td>
              <td>
                <input
                  name="uncontrolledPArea"
                  className="input_area"
                  value={this.props.data.osd.uncontrolledPArea}
                  disabled={this.props.data.osd.psd}
                  required
                  onChange={(e) => this.handleInputChange(e)}
                />
                (m²)
              </td>
              <td>
                <div className="small_spacer"></div>
              </td>
              <td></td>
              <td>
                <div className="spacer"></div>
              </td>
              <td>Height of Storage Above Orifice: </td>
              <td>
                <input
                  name="tankHeight"
                  className="input_area"
                  value={this.props.data.osd.tankHeight}
                  placeholder="Optional"
                  // required
                  onChange={(e) => this.handleInputChange(e)}
                />
                (m)
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <br />
          Increase OSD storage volume for Climate Change variation by:
          <input
            name="climateAdjustment"
            className="input_area"
            value={this.state.climateDisplay}
            onChange={(e) => this.handleClimateChange(e)}
          />
          % from calculated results.
          <span
            data-tip="Default value as per Council rules. Please consult Council Engineer if a lower value is desired.<br/> 0% indicates no climate change adjustment."
            data-multiline="true"
            data-place="bottom"
          >
            ❓
          </span>
          <ReactTooltip />
        </div>
        {/* <br /> */}
        <Alert
          variant="danger"
          show={parseFloat(this.props.data.osd.tankHeight) <= 0}
          style={{ marginTop: "5px" }}
        >
          ❗ Height of Storage above Orifice should be greater than 0. Leave it
          blank if you do not wish to model Orifice Diameter.
        </Alert>

        <Alert
          variant="danger"
          show={parseFloat(this.props.data.osd.catchmentTimeTcs) < 2}
          style={{ marginTop: "5px" }}
        >
          ❗ Tcs is too low to use the Swinburne Method.
        </Alert>

        <Alert
          variant={this.state.alertVariant2}
          show={this.state.showAlert2}
          style={{ marginTop: "20px" }}
        >
          {this.state.alertMessage2}
        </Alert>
        <Alert
          variant={this.state.alertVariant1}
          show={this.state.showAlert1}
          style={{ marginTop: "20px" }}
        >
          {this.state.alertMessage1}
        </Alert>
        <Alert
          variant={this.state.alertVariant3}
          show={this.state.showAlert3}
          style={{ marginTop: "20px" }}
        >
          {this.state.alertMessage3}
        </Alert>
        <Alert
          variant={this.state.alertVariant4}
          show={this.state.showAlert4}
          style={{ marginTop: "20px" }}
        >
          {this.state.alertMessage4}
        </Alert>
        <Alert
          variant="warning"
          show={!this.state.osdInputsValid}
          style={{ marginTop: "20px" }}
        >
          1. Please enter all fields to calculate OSD.<br /> 
          2. Please ensure total catchment area is not 0
        </Alert>
        <Alert
          variant="danger"
          show={!this.state.areasImpMatch}
          style={{ marginTop: "20px" }}
        >
          Uncontrolled Impervious Area (
          {this.props.data.osd.uncontrolledImpArea}m²) cannot be greater than
          Post-development Impervious Area ({this.props.data.totalImpArea}m²)
        </Alert>
        <Alert
          variant="danger"
          show={!this.state.areasPMatch}
          style={{ marginTop: "20px" }}
        >
          Uncontrolled Pervious Area ({this.props.data.osd.uncontrolledPArea}m²)
          cannot be greater than Post-development Pervious Area (
          {this.props.data.totalPArea}m²)
        </Alert>
        <Alert
          variant="danger"
          // show={!this.state.uncontrolledOK}
          show={
            // !this.state.uncontrolledOK ||
            this.props.data.osdUncontrolledIssue !== ""
          }
          style={{ marginTop: "20px" }}
        >
          {this.props.data.osdUncontrolledIssue}
        </Alert>
        {this.state.osdResultLoading || this.props.exportingReport ? (
          <Button
            variant="primary"
            className="btn btn-sm btn-grad"
            style={{ marginTop: "20px" }}
            disabled
          >
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="sr-only">Loading...</span>
          </Button>
        ) : (
          <Button
            variant="primary"
            className="btn btn-sm btn-grad"
            style={{ marginTop: "20px" }}
            onClick={this.computeOSDResults.bind(this)}
            disabled={
              !this.state.osdInputsValid ||
              !this.state.areasImpMatch ||
              !this.state.areasPMatch ||
              (this.state.showAlert4 && !this.state.psd) ||
              parseFloat(this.props.data.osd.tankHeight) <= 0
            }
          >
            CALCULATE OSD
          </Button>
        )}

        <OsdResultsTable
          setList={this.props.setList}
          setState={this.props.setState}
          companyDetails={this.props.companyDetails}
          data={this.props.data}
          osdInputsValid={this.state.osdInputsValid}
          psd={this.state.psd}
          setOSD={this.props.setOSD}
          climateAdjustment={this.props.data.osd.climateAdjustment}
        />
      </div>
    );
  }
}

export default Swinburne;
