import React from "react";
import "./assets/css/thefooter.css";
import { Tab, Tabs } from "react-bootstrap";
import CouncilsList from "./assets/js/CouncilsList";

const LGATabs = () => {
  return (
    <Tabs
      defaultActiveKey="VIC"
      id="footer-tabs"
      className="mb-12"
      variant="pills"
      style={{ width: "100%" }}
    >
      <Tab eventKey="VIC" title="Victoria">
        <ul style={{ display: "flex", flexWrap: "wrap" }}>
          {CouncilsList.VIC.map((council) => {
            return (
              <li style={{ padding: "10px" }} key={council.name}>
                <a href={council.website} target="_blank" rel="noreferrer">
                  {council.name}
                </a>
              </li>
            );
          })}
        </ul>
      </Tab>
      <Tab eventKey="NSW" title="New South Wales">
        <ul style={{ display: "flex", flexWrap: "wrap" }}>
          {CouncilsList.NSW.map((council) => {
            return (
              <li style={{ padding: "10px" }} key={council.name}>
                <a href={council.website} target="_blank" rel="noreferrer">
                  {council.name}
                </a>
              </li>
            );
          })}
        </ul>
      </Tab>
      <Tab eventKey="TAS" title="Tasmania">
        <ul style={{ display: "flex", flexWrap: "wrap" }}>
          {CouncilsList.TAS.map((council) => {
            return (
              <li style={{ padding: "10px" }} key={council.name}>
                <a href={council.website} target="_blank" rel="noreferrer">
                  {council.name}
                </a>
              </li>
            );
          })}
        </ul>
      </Tab>
      <Tab eventKey="QLD" title="Queensland">
        <ul style={{ display: "flex", flexWrap: "wrap" }}>
          {CouncilsList.QLD.map((council) => {
            return (
              <li style={{ padding: "10px" }} key={council.name}>
                <a href={council.website} target="_blank" rel="noreferrer">
                  {council.name}
                </a>
              </li>
            );
          })}
        </ul>
      </Tab>
      <Tab eventKey="SA" title="South Australia">
        <ul style={{ display: "flex", flexWrap: "wrap" }}>
          {CouncilsList.SA.map((council) => {
            return (
              <li style={{ padding: "10px" }} key={council.name}>
                <a href={council.website} target="_blank" rel="noreferrer">
                  {council.name}
                </a>
              </li>
            );
          })}
        </ul>
      </Tab>
    </Tabs>
  );
};

export default LGATabs;
