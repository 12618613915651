import React from "react";
import "./assets/css/theheader.css";
import { Navbar, Container, Nav } from "react-bootstrap";
import su_logo from "./assets/img/su_logo.svg";

class TheHeader extends React.Component {
  render() {
    return (
      <div>
        {/* <div className="header-dark">
          <h1>STORMupdated*</h1>
        </div> */}
        <Navbar variant="dark" className="header-navbar">
          <Navbar.Brand href={window.location.origin}>
            <img
              alt=""
              src={su_logo}
              width="240"
              height="60"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>

          <Nav className="ml-auto">
            <Nav.Link href="https://cleanstormwater.com.au/">
              Cleanstormwater
            </Nav.Link>

            <Nav.Link href="https://cleanstormwater.com.au/products">
              Products
            </Nav.Link>

            <Nav.Link href="https://cleanstormwater.com.au/services">
              Services
            </Nav.Link>

            <Nav.Link href="https://cleanstormwater.com.au/about-us">
              About Us
            </Nav.Link>

            <Nav.Link href="https://cleanstormwater.com.au/bookings">
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar>
      </div>
    );
  }
}

export default TheHeader;
