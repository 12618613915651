import { render } from "@testing-library/react";
import { Row, Col } from "react-bootstrap";
import React, { useState } from "react";
import { string } from "prop-types";
import LoadingOverlay from 'react-loading-overlay';
import "./assets/css/calculator.css";

class OsdsAds extends React.Component {
  constructor(props) {
    super(props);
    var isActive = true;
  }
  isActive = true
  displayCompanyDetails = () => {
    this.isActive = true
    let materialsAndCompanyArr = this.getCompanyMaterialObj()
    let imageComponents = this.getUIComponentsForAdsPanel(materialsAndCompanyArr)
    this.isActive = false
    return imageComponents;
  };
  getCompanyMaterialObj = () => {
    let preImageAddress = "https://su-map-cms.ga";
    let materialsAndCompanyArr = {};
    for (let i = 0; i < this.props.companyDetails.length; i++) {
      let listOfMaterials = [];
      let listOFCompanies = [];
      if (this.props.companyDetails[i].material.indexOf(",") > 0) {
        listOfMaterials = this.props.companyDetails[i].material.split(",");
        //listOFCompanies.push(this.props.companyDetails[i].name)
      } else {
        listOfMaterials.push(this.props.companyDetails[i].material);
        //listOFCompanies.push(this.props.companyDetails[i].name)
      }
      for (let itr2 = 0; itr2 < listOfMaterials.length; itr2++) {
        if (!materialsAndCompanyArr.hasOwnProperty(listOfMaterials[itr2].trim())) {
          materialsAndCompanyArr[listOfMaterials[itr2].trim()] = {};
          materialsAndCompanyArr[listOfMaterials[itr2].trim()]["companies"] = [];
          materialsAndCompanyArr[listOfMaterials[itr2].trim()]["images"] = [];
          materialsAndCompanyArr[listOfMaterials[itr2].trim()]["images"].push(
            preImageAddress + this.props.companyDetails[i].fullImage
          );
          materialsAndCompanyArr[listOfMaterials[itr2].trim()]["companies"].push(
            this.props.companyDetails[i].name);
        } else {
          if (
            materialsAndCompanyArr[listOfMaterials[itr2].trim()]["images"].indexOf(
              preImageAddress + this.props.companyDetails[i].fullImage
            ) < 0
          ) {
            materialsAndCompanyArr[listOfMaterials[itr2].trim()]["images"].push(
              preImageAddress + this.props.companyDetails[i].fullImage
            );
            materialsAndCompanyArr[listOfMaterials[itr2].trim()]["companies"].push(
              this.props.companyDetails[i].name)
          }
        }
      }
    }
    return materialsAndCompanyArr;
  }
  getUIComponentsForAdsPanel = (materialsAndCompanyArr) => {
    let ImageCat = [];
    let imageArr = [];
    var counter = 0;
    for (let material in materialsAndCompanyArr) {
      let imageArr = [];
      for (let i = 0; i < materialsAndCompanyArr[material]["images"].length; i++) {
        let image = React.createElement(
          "img",
          { src: materialsAndCompanyArr[material]["images"][i], className: "osdsAdImg", key:materialsAndCompanyArr[material]["companies"][i] },
          null
        );
        let buttonImg = React.createElement('button', {
          className: "buttonClass",
          value: JSON.stringify({"material":material,"Company": materialsAndCompanyArr[material]["companies"][i]}), onClick: this.handleClick.bind(this),
          key:materialsAndCompanyArr[material]["companies"][i]
        },
          image)
        imageArr.push(buttonImg)
      }
      let nameDiv = React.createElement('label', { className: "spanclass", key: Object.keys(materialsAndCompanyArr)[counter]}, (Object.keys(materialsAndCompanyArr)[counter]).toUpperCase())
      counter++;
      ImageCat.push(nameDiv)
      let materialDiv = React.createElement('div', { className: "logoDiv", key:counter}, imageArr,)
      ImageCat.push(materialDiv)

    }
    return ImageCat;
  }
  handleClick = (param) => {
    let companyNameAndMaterialObj = JSON.parse(param.currentTarget.value)
    let material = companyNameAndMaterialObj.material
    //let companyUrl = companyNameAndMaterialObj.Company
    let companyName = companyNameAndMaterialObj.Company
    this.props.setOSD("manufacturerImgLink", companyName);
    this.props.setOSD("OSDMaterial",material)
    this.props.setOSD("OSDCompany",companyName)
  }
  render() {
    return (
      <>
        <Row>
          <Col>
            <div className="ad_box_">
              {/* <LoadMask visible={true} /> */}
              <LoadingOverlay
                active={this.isActive}
                spinner
                text='Loading your content...'
              >
                <div>{this.displayCompanyDetails()}</div>
              </LoadingOverlay>
              {/*  <LoadMask visible={false} /> */}
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default OsdsAds;
