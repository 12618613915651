import { printLog } from "../../common";

export const CatchmentChange = async (data, value, sails_api) => {
  printLog(value);
  printLog(data);

  var catchmentType = value;
  var perviousData = false;
  var perviousAreaTypes = [];
  var imperviousAreaTypes = [];
  var imperviousAreaTreatmentTypes = [];
  var perviousAreaTreatmentTypes = [];
  var noData = false;
  var eolTreatments = [];

  // fetch the impervious source node types
  await sails_api
    .get("imperviousAreaTypes/" + data.rainfallStation + "/" + catchmentType)
    .then((res) => {
      imperviousAreaTypes = res.data;
    });

  // fetch EOL Device types
  if (data.wqMethod.includes("EOL")) {
    await sails_api
      .get("EolDevices/" + data.state + "/" + data.rainfallStation)
      .then((res) => {
        eolTreatments = res.data;
      });
  }
  printLog("EOLTreatments ", eolTreatments);

  // fetch the pervious source node types
  await sails_api
    .get("perviousAreaTypes/" + data.rainfallStation + "/" + catchmentType)
    .then((res) => {
      perviousAreaTypes = res.data;
      noData = false;
      perviousData = true;
    })
    .catch((error) => {
      noData = true;
      perviousData = false;
    });

  // Loading the impervious treatment types from DB
  if (data.state !== "" || data.state !== undefined) {
    await sails_api
      .get(
        "/TreatmentNodesLinear/" +
          data.state +
          "/" +
          data.rainfallStation +
          "/" +
          data.catchmentType +
          "/" +
          data.imperviousAreaTypes[0].snodetype
      )
      .then((res) => {
        imperviousAreaTreatmentTypes = res.data;
      });
  }

  if (perviousData) {
    // Loading the pervious treatment types from DB
    await sails_api
      .get(
        "/perviousAreaTreatType/" +
          data.rainfallStation +
          "/" +
          data.catchmentType +
          "/" +
          data.perviousAreaTypes[0].snodetype
      )
      .then((res) => {
        perviousAreaTreatmentTypes = res.data;
      });
  }

  var imperviousNodes = [...data.imperviousNodes];
  for (const index in imperviousNodes) {
    imperviousNodes[index].impAreaName = "";
    imperviousNodes[index].impAreaType = imperviousAreaTypes[0].snodetype;
    imperviousNodes[index].impAreaSize = "";
    imperviousNodes[index].impAreaTreatType =
      imperviousAreaTreatmentTypes[0].tnodename;
    imperviousNodes[index].impAreaTreatSize = "";
    imperviousNodes[index].impAreaBrooms = "";
    imperviousNodes[index].treatmentTypes = imperviousAreaTreatmentTypes;
    imperviousNodes[index].apiBaseRoute =
      imperviousAreaTreatmentTypes[0].endpoint;
    // For out of bounds warnings..
    imperviousNodes[index].boundsValueOrRatio = NaN;
    // For bedrooms/occupants warning in RWT
    imperviousNodes[index].minSourceArea = NaN;
    imperviousNodes[index].maxSourceArea = NaN;
    // For the placeholder units m^2 or L or items
    imperviousNodes[index].placeholderUnit = "Size (m² / L / items)";
    imperviousNodes[index].alertMessage = "";
    imperviousNodes[index].showAlert = false;
    imperviousNodes[index].alertVariant = "";
    imperviousNodes[index].tn = "N/A";
    imperviousNodes[index].tss = "N/A";
    imperviousNodes[index].tp = "N/A";
    imperviousNodes[index].gp = "N/A";
    imperviousNodes[index].fr = "N/A";
    imperviousNodes[index].results = undefined;
  }

  var perviousNodes = [...data.perviousNodes];
  for (const index in perviousNodes) {
    perviousNodes[index].pAreaName = "";
    perviousNodes[index].pAreaType = noData
      ? ""
      : perviousAreaTypes[0].snodetype;
    perviousNodes[index].pAreaSize = "";
    perviousNodes[index].pAreaTreatType = noData
      ? ""
      : perviousAreaTreatmentTypes[0].tnodename;
    perviousNodes[index].pAreaTreatSize = "";
    perviousNodes[index].treatmentTypes = noData
      ? []
      : perviousAreaTreatmentTypes;
    perviousNodes[index].apiBaseRoute = "";
    // For out of bounds warnings..
    perviousNodes[index].boundsValueOrRatio = NaN;
    // For bedrooms/occupants warning in RWT
    perviousNodes[index].minSourceArea = NaN;
    perviousNodes[index].maxSourceArea = NaN;
    // For the placeholder units m^2 or L or items
    perviousNodes[index].placeholderUnit = "Size (m² / L)";
    perviousNodes[index].alertMessage = "";
    perviousNodes[index].showAlert = false;
    perviousNodes[index].alertVariant = "";
    perviousNodes[index].tn = "N/A";
    perviousNodes[index].tss = "N/A";
    perviousNodes[index].tp = "N/A";
    perviousNodes[index].gp = "N/A";
    perviousNodes[index].fr = "N/A";
    perviousNodes[index].results = undefined;
  }

  var postDevImperviousNodes = [...data.postDevImperviousNodes];
  if (data.wqMethod.includes("NorBE")) {
    for (const index in postDevImperviousNodes) {
      postDevImperviousNodes[index].impAreaName = "";
      postDevImperviousNodes[index].impAreaType =
        imperviousAreaTypes[0].snodetype;
      postDevImperviousNodes[index].impAreaSize = "";
      imperviousNodes[index].impAreaTreatType =
        imperviousAreaTreatmentTypes[0].tnodename;
      postDevImperviousNodes[index].impAreaTreatSize = "";
      postDevImperviousNodes[index].impAreaBrooms = "";
      postDevImperviousNodes[index].treatmentTypes =
        imperviousAreaTreatmentTypes;
      postDevImperviousNodes[index].apiBaseRoute =
        imperviousAreaTreatmentTypes[0].endpoint;
      // For out of bounds warnings..
      postDevImperviousNodes[index].boundsValueOrRatio = NaN;
      // For bedrooms/occupants warning in RWT
      postDevImperviousNodes[index].minSourceArea = NaN;
      postDevImperviousNodes[index].maxSourceArea = NaN;
      // For the placeholder units m^2 or L or items
      postDevImperviousNodes[index].placeholderUnit = "Size (m² / L / items)";
      postDevImperviousNodes[index].alertMessage = "";
      postDevImperviousNodes[index].showAlert = false;
      postDevImperviousNodes[index].alertVariant = "";
      postDevImperviousNodes[index].tn = "N/A";
      postDevImperviousNodes[index].tss = "N/A";
      postDevImperviousNodes[index].tp = "N/A";
      postDevImperviousNodes[index].gp = "N/A";
      postDevImperviousNodes[index].fr = "N/A";
      postDevImperviousNodes[index].results = undefined;
    }
  }
  var postDevPerviousNodes = [...data.postDevPerviousNodes];
  if (data.wqMethod.includes("NorBE")) {
    for (const index in perviousNodes) {
      postDevPerviousNodes[index].pAreaName = "";
      postDevPerviousNodes[index].pAreaType = noData
        ? ""
        : perviousAreaTypes[0].snodetype;
      postDevPerviousNodes[index].pAreaSize = "";
      postDevPerviousNodes[index].pAreaTreatType = noData
        ? ""
        : perviousAreaTreatmentTypes[0].tnodename;
      postDevPerviousNodes[index].pAreaTreatSize = "";
      postDevPerviousNodes[index].treatmentTypes = noData
        ? []
        : perviousAreaTreatmentTypes;
      postDevPerviousNodes[index].apiBaseRoute = "";
      // For out of bounds warnings..
      postDevPerviousNodes[index].boundsValueOrRatio = NaN;
      // For bedrooms/occupants warning in RWT
      postDevPerviousNodes[index].minSourceArea = NaN;
      postDevPerviousNodes[index].maxSourceArea = NaN;
      // For the placeholder units m^2 or L or items
      postDevPerviousNodes[index].placeholderUnit = "Size (m² / L)";
      postDevPerviousNodes[index].alertMessage = "";
      postDevPerviousNodes[index].showAlert = false;
      postDevPerviousNodes[index].alertVariant = "";
      postDevPerviousNodes[index].tn = "N/A";
      postDevPerviousNodes[index].tss = "N/A";
      postDevPerviousNodes[index].tp = "N/A";
      postDevPerviousNodes[index].gp = "N/A";
      postDevPerviousNodes[index].fr = "N/A";
      postDevPerviousNodes[index].results = undefined;
    }
  }

  return {
    imperviousAreaTypes: imperviousAreaTypes,
    perviousAreaTypes: perviousAreaTypes,
    eolTreatments: eolTreatments,
    noData: noData,
    pervious: perviousData,
    imperviousNodes: imperviousNodes,
    perviousNodes: perviousNodes,
    postDevImperviousNodes: data.wqMethod.includes("NorBE")
      ? postDevImperviousNodes
      : "",
    postDevPerviousNodes: data.wqMethod.includes("NorBE")
      ? postDevPerviousNodes
      : "",
    catchmentType: value,
    overallResults: {
      overallRatings: {
        tnRate: 0,
        tpRate: 0,
        tssRate: 0,
        gpRate: 0,
        flowRate: 0,
      },
      overallReductions: {
        tnReduction: 0,
        tpReduction: 0,
        tssReduction: 0,
        gpReduction: 0,
        flowReduction: 0,
      },
      overallPollutantsPreDev: {
        gpPollutantPreDev: 0,
        tnPollutantPreDev: 0,
        tpPollutantPreDev: 0,
        tssPollutantPreDev: 0,
        flowPollutantPreDev: 0,
      },
      overallPollutantsPostDev: {
        gpPollutantPostDev: 0,
        tnPollutantPostDev: 0,
        tpPollutantPostDev: 0,
        tssPollutantPostDev: 0,
        flowPollutantPostDev: 0,
      },
    },
    dorriesResults: {
      alerts: [],
      detailed_wsud_table: {
        dev_impact: {
          fr: {
            color: "",
            value: "",
          },
          tn: {
            color: "",
            value: "",
          },
          tp: {
            color: "",
            value: "",
          },
          tss: {
            color: "",
            value: "",
          },
          gp: {
            color: "",
            value: "",
          },
        },
        developedResiduals: {
          fr: {
            color: "",
            value: "",
          },
          tn: {
            color: "",
            value: "",
          },
          tp: {
            color: "",
            value: "",
          },
          tss: {
            color: "",
            value: "",
          },
          gp: {
            color: "",
            value: "",
          },
        },
        achievedReductions: {
          flow: {
            color: "",
            value: "",
          },
          tn: {
            color: "",
            value: "",
          },
          tp: {
            color: "",
            value: "",
          },
          tss: {
            color: "",
            value: "",
          },
          gp: {
            color: "",
            value: "",
          },
        },
        developedSources: {
          flow: "",
          tn: "",
          tp: "",
          tss: "",
          gp: "",
        },
        existingResiduals: {
          flow: "",
          tn: "",
          tp: "",
          tss: "",
          gp: "",
        },
        targetReductions: {
          fr: "",
          tn: "",
          tp: "",
          tss: "",
          gp: "",
        },
      },
      targetReductionsTable: {
        fr: {
          color: "",
          value: "",
          status: "",
        },
        tn: {
          color: "",
          value: "",
          status: "",
        },
        tp: {
          color: "",
          value: "",
          status: "",
        },
        tss: {
          color: "",
          value: "",
          status: "",
        },
        gp: {
          color: "",
          value: "",
          status: "",
        },
      },
    },
    siteArea: 0,
    totalImpArea: 0,
    totalImpNodes: 0,
    totalPArea: 0,
    totalPNodes: 0,
    totalCatchmentArea: 0,
    totalTreatmentNodes: 0,
    totalNoneNodes: 0,
  };
};

export const CatchmentSet = async (rfStation, state, sails_api, wqMethod) => {
  try {
    printLog("in catchment set");
    // the stuff when rainfall station changes
    var catchmentTypes = [];
    var catchmentType = "";
    var perviousData = false;
    var perviousAreaTypes = [];
    var imperviousAreaTypes = [];
    var imperviousAreaTreatmentTypes = [];
    var perviousAreaTreatmentTypes = [];
    var noData = false;
    var eolTreatments = [];

    await sails_api.get("catchmentTypes/" + rfStation).then((res) => {
      // Switch the order of response from database, so split will rank before mixed
      res.data.catchmentTypes = res.data.catchmentTypes.reverse();
      catchmentTypes = res.data.catchmentTypes;
      catchmentType = res.data.catchmentTypes[0];
    });
    printLog("Catchment type set to = ", catchmentType);

    // fetch EOL Device types
    if (wqMethod.includes("EOL")) {
      await sails_api
        .get("EolDevices/" + state + "/" + rfStation)
        .then((res) => {
          eolTreatments = res.data;
        });
    }
    printLog("EOLTreatments ", eolTreatments);

    // fetch the impervious source node types
    await sails_api
      .get("imperviousAreaTypes/" + rfStation + "/" + catchmentType)
      .then((res) => {
        imperviousAreaTypes = res.data;
      });
    printLog("ImperviousAreaTypes = ", imperviousAreaTypes);

    // fetch the pervious source node types
    await sails_api
      .get("perviousAreaTypes/" + rfStation + "/" + catchmentType)
      .then((res) => {
        perviousAreaTypes = res.data;
        noData = false;
        perviousData = true;
      })
      .catch((error) => {
        console.log(error);
        noData = true;
        perviousData = false;
      });
    printLog("PerviousData = ", perviousData);
    printLog("PerviousAreaTypes = ", perviousAreaTypes);

    // Loading the impervious treatment types from DB
    if (state !== "" || state !== undefined) {
      await sails_api
        .get(
          "/TreatmentNodesLinear/" +
            state +
            "/" +
            rfStation +
            "/" +
            catchmentType +
            "/" +
            imperviousAreaTypes[0].snodetype
        )
        .then((res) => {
          imperviousAreaTreatmentTypes = res.data;
        });
    } else {
      printLog("state loading");
    }

    printLog(
      "Impervious nodes treatment types = ",
      imperviousAreaTreatmentTypes
    );

    if (perviousData) {
      // Loading the pervious treatment types from DB
      await sails_api
        .get(
          "/perviousAreaTreatType/" +
            rfStation +
            "/" +
            catchmentType +
            "/" +
            perviousAreaTypes[0].snodetype
          // "/MixedPervious"
        )
        .then((res) => {
          perviousAreaTreatmentTypes = res.data;
        });
    }

    return {
      catchmentTypes: catchmentTypes,
      catchmentType: catchmentType,
      perviousData: perviousData,
      perviousAreaTypes: perviousAreaTypes,
      imperviousAreaTypes: imperviousAreaTypes,
      imperviousAreaTreatmentTypes: imperviousAreaTreatmentTypes,
      perviousAreaTreatmentTypes: perviousAreaTreatmentTypes,
      eolTreatments: eolTreatments,
      noData: noData,
      imperviousNodes: [
        {
          impAreaName: "",
          impAreaType: imperviousAreaTypes[0].snodetype,
          impAreaSize: "",
          impAreaTreatType: imperviousAreaTreatmentTypes[0].tnodename,
          impAreaTreatSize: "",
          impAreaBrooms: "",
          treatmentTypes: imperviousAreaTreatmentTypes,
          apiBaseRoute: imperviousAreaTreatmentTypes[0].endpoint,
          // For out of bounds warnings..
          boundsValueOrRatio: NaN,
          // For bedrooms/occupants warning in RWT
          minSourceArea: NaN,
          maxSourceArea: NaN,
          // For the placeholder units m^2 or L or items
          placeholderUnit: "Size (m² / L / items)",
          alertMessage: "",
          showAlert: false,
          alertVariant: "",
          tn: "N/A",
          tss: "N/A",
          tp: "N/A",
          gp: "N/A",
          fr: "N/A",
          results: undefined,
        },
      ],
      perviousNodes: [
        {
          pAreaName: "",
          pAreaType: noData ? "" : perviousAreaTypes[0].snodetype,
          pAreaSize: "",
          pAreaTreatType: noData ? "" : perviousAreaTreatmentTypes[0].tnodename,
          pAreaTreatSize: "",
          treatmentTypes: noData ? [] : perviousAreaTreatmentTypes,
          apiBaseRoute: "",
          // For out of bounds warnings..
          boundsValueOrRatio: NaN,
          // For bedrooms/occupants warning in RWT
          minSourceArea: NaN,
          maxSourceArea: NaN,
          // For the placeholder units m^2 or L or items
          placeholderUnit: "Size (m² / L)",
          alertMessage: "",
          showAlert: false,
          alertVariant: "",
          tn: "N/A",
          tss: "N/A",
          tp: "N/A",
          gp: "N/A",
          fr: "N/A",
          results: undefined,
        },
      ],
      postDevImperviousNodes: wqMethod.includes("NorBE")
        ? [
            {
              impAreaName: "",
              impAreaType: imperviousAreaTypes[0].snodetype,
              impAreaSize: "",
              impAreaTreatType: imperviousAreaTreatmentTypes[0].tnodename,
              impAreaTreatSize: "",
              impAreaBrooms: "",
              treatmentTypes: imperviousAreaTreatmentTypes,
              apiBaseRoute: imperviousAreaTreatmentTypes[0].endpoint,
              // For out of bounds warnings..
              boundsValueOrRatio: NaN,
              // For bedrooms/occupants warning in RWT
              minSourceArea: NaN,
              maxSourceArea: NaN,
              // For the placeholder units m^2 or L or items
              placeholderUnit: "Size (m² / L / items)",
              alertMessage: "",
              showAlert: false,
              alertVariant: "",
              tn: "N/A",
              tss: "N/A",
              tp: "N/A",
              gp: "N/A",
              fr: "N/A",
              results: undefined,
            },
          ]
        : "",
      postDevPerviousNodes: wqMethod.includes("NorBE")
        ? [
            {
              pAreaName: "",
              pAreaType: noData ? "" : perviousAreaTypes[0].snodetype,
              pAreaSize: "",
              pAreaTreatType: noData
                ? ""
                : perviousAreaTreatmentTypes[0].tnodename,
              pAreaTreatSize: "",
              treatmentTypes: noData ? [] : perviousAreaTreatmentTypes,
              apiBaseRoute: "",
              // For out of bounds warnings..
              boundsValueOrRatio: NaN,
              // For bedrooms/occupants warning in RWT
              minSourceArea: NaN,
              maxSourceArea: NaN,
              // For the placeholder units m^2 or L or items
              placeholderUnit: "Size (m² / L)",
              alertMessage: "",
              showAlert: false,
              alertVariant: "",
              tn: "N/A",
              tss: "N/A",
              tp: "N/A",
              gp: "N/A",
              fr: "N/A",
              results: undefined,
            },
          ]
        : "",
      overallResults: {
        overallRatings: {
          tnRate: 0,
          tpRate: 0,
          tssRate: 0,
          gpRate: 0,
          flowRate: 0,
        },
        overallReductions: {
          tnReduction: 0,
          tpReduction: 0,
          tssReduction: 0,
          gpReduction: 0,
          flowReduction: 0,
        },
        overallPollutantsPreDev: {
          gpPollutantPreDev: 0,
          tnPollutantPreDev: 0,
          tpPollutantPreDev: 0,
          tssPollutantPreDev: 0,
          flowPollutantPreDev: 0,
        },
        overallPollutantsPostDev: {
          gpPollutantPostDev: 0,
          tnPollutantPostDev: 0,
          tpPollutantPostDev: 0,
          tssPollutantPostDev: 0,
          flowPollutantPostDev: 0,
        },
      },
      dorriesResults: {
        alerts: [],
        detailed_wsud_table: {
          dev_impact: {
            fr: {
              color: "",
              value: "",
            },
            tn: {
              color: "",
              value: "",
            },
            tp: {
              color: "",
              value: "",
            },
            tss: {
              color: "",
              value: "",
            },
            gp: {
              color: "",
              value: "",
            },
          },
          developedResiduals: {
            fr: {
              color: "",
              value: "",
            },
            tn: {
              color: "",
              value: "",
            },
            tp: {
              color: "",
              value: "",
            },
            tss: {
              color: "",
              value: "",
            },
            gp: {
              color: "",
              value: "",
            },
          },
          achievedReductions: {
            flow: {
              color: "",
              value: "",
            },
            tn: {
              color: "",
              value: "",
            },
            tp: {
              color: "",
              value: "",
            },
            tss: {
              color: "",
              value: "",
            },
            gp: {
              color: "",
              value: "",
            },
          },
          developedSources: {
            flow: "",
            tn: "",
            tp: "",
            tss: "",
            gp: "",
          },
          existingResiduals: {
            flow: "",
            tn: "",
            tp: "",
            tss: "",
            gp: "",
          },
          targetReductions: {
            fr: "",
            tn: "",
            tp: "",
            tss: "",
            gp: "",
          },
        },
        targetReductionsTable: {
          fr: {
            color: "",
            value: "",
            status: "",
          },
          tn: {
            color: "",
            value: "",
            status: "",
          },
          tp: {
            color: "",
            value: "",
            status: "",
          },
          tss: {
            color: "",
            value: "",
            status: "",
          },
          gp: {
            color: "",
            value: "",
            status: "",
          },
        },
      },
      siteArea: 0,
      totalImpArea: 0,
      totalImpNodes: 0,
      totalPArea: 0,
      totalPNodes: 0,
      totalCatchmentArea: 0,
      totalTreatmentNodes: 0,
      totalNoneNodes: 0,
    };
  } catch (error) {
    alert(
      "Rainfall Station data unavailable. You can still perform OSD modeling for this site 😎"
    );
  }
};
