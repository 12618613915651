import React from "react";
import { Button, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/downloadbuttons.css";

const DownloadButtons = (props) => {
  if (props.osdCalculating) {
    return (
      <div className="central">
        <span> Please wait. OSD calculation currently in progress..</span>
      </div>
    );
  } else {
    return (
      <div className="central">
        {props.exportingReport ? (
          <Button
            variant="success"
            type="submit"
            className="btn btn-sm btn-result spacer"
            style={{ width: "240px" }}
            disabled
            name="report"
          >
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: "10px" }}
            />
            <span>Generating report...</span>
          </Button>
        ) : (
          <Button
            variant="success"
            type="submit"
            className="btn btn-sm btn-result spacer"
            style={{ width: "240px" }}
            disabled={props.disableButtons}
            name="report"
          >
            📃 STORMupdated Report
          </Button>
        )}
        {props.data.hasWQ ? (
          props.exportingModel ? (
            <Button
              variant="info"
              type="submit"
              className="btn btn-sm btn-result spacer"
              style={{ width: "240px" }}
              disabled
              name="model"
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "10px" }}
              />
              <span>Generating Model...</span>
            </Button>
          ) : (
            <Button
              variant="info"
              type="submit"
              className="btn btn-sm btn-result spacer"
              style={{ width: "240px" }}
              disabled={props.disableButtons}
              name="model"
            >
              ➡️ Generate MUSIC Model
            </Button>
          )
        ) : null}
        {props.data.hasWQ ? (
          props.exportingMlb ? (
            <Button
              variant="secondary"
              type="submit"
              className="btn btn-sm btn-result spacer"
              style={{ width: "240px" }}
              disabled
              name="mlb"
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "10px" }}
              />
              <span>Generating Mlb...</span>
            </Button>
          ) : (
            <Button
              variant="secondary"
              type="submit"
              className="btn btn-sm btn-result spacer"
              style={{ width: "240px" }}
              disabled={props.disableButtons}
              name="mlb"
            >
              📥 Download Meteo Template
            </Button>
          )
        ) : null}

        {props.data.hasWQ ? (
          props.exportingReport ? (
            <Button
              variant="link"
              type="submit"
              className="btn btn-sm btn-result spacer"
              style={{ width: "240px" }}
              disabled
              name="simple-report"
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "10px" }}
              />
              <span>Generating report...</span>
            </Button>
          ) : (
            <Button
              variant="link"
              type="submit"
              className="btn btn-sm btn-result spacer"
              style={{ width: "240px" }}
              disabled={
                props.disableButtons || props.data.wqMethod.includes("NorBE")
              }
              name="simple-report"
            >
              📃 STORMupdated Report Lite
            </Button>
          )
        ) : null}
      </div>
    );
  }
};

export default DownloadButtons;
