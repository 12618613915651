import React from "react";
import "./assets/css/map.css";
import "@reach/combobox/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  ToggleButtonGroup,
  ToggleButton,
  Dropdown,
  ButtonGroup,
  DropdownButton,
  Spinner,
  Button,
} from "react-bootstrap";
import { GoogleMap, Marker, InfoBox } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import Search from "./Search";
import { OsdStateObject } from "./assets/js/OsdStateObject";
import { CatchmentSet } from "./assets/js/CatchmentChange";
import InfoWindowEx from "./InfoWindowEx";
import MusicAuditor from "./MusicAuditor";
import MapCms from "./MapCms";
import axios from "axios";
import Select from "react-select";
import { printLog } from "./common";

const flask_api = axios.create({
  baseURL: window.location.href.includes("localhost")
    ? "https://su-stage.ml/flask"
    : window.location.origin + "/flask",
});

const TheMap = React.memo(function Map(props) {
  const mapContainerStyle = {
    height: "500px",
    width: "100%",
  };

  const options = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  const stateCoordinates = {
    ACT: {
      center: { lat: -35.42995093240622, lng: 149.0282702905103 },
      zoom: 6,
    },
    NSW: {
      center: { lat: -32.186360053101396, lng: 148.77494312266262 },
      zoom: 6,
    },
    NT: {
      center: { lat: -18.398986846262332, lng: 133.72803718845648 },
      zoom: 6,
    },
    QLD: {
      center: { lat: -19.617333739656416, lng: 145.8990016598991 },
      zoom: 6,
    },
    SA: {
      center: { lat: -30.265556751610323, lng: 136.05253052267014 },
      zoom: 6,
    },
    TAS: {
      center: { lat: -41.93069837065246, lng: 146.77890262469748 },
      zoom: 6,
    },
    VIC: {
      center: { lat: -36.89938739551902, lng: 144.85531304687504 },
      zoom: 6,
    },
    WA: {
      center: { lat: -24.91318170386305, lng: 119.52909302267012 },
      zoom: 6,
    },
  };

  const infoboxoptions = { closeBoxURL: "", enableEventPropagation: true };
  const center = React.useRef({ lat: -37.99, lng: 145.3832 });
  const zoom = React.useRef(7);
  const markers = React.useRef();
  const [selected, setSelected] = React.useState(null);
  const [toggle, setToggle] = React.useState([
    "councils",
    "overlays",
    "showCouncils",
  ]);
  const state = React.useRef("");
  const selectedState = React.useRef("VIC");
  const council = React.useRef();
  const address = React.useRef();
  const wqMethod = React.useRef("");
  const confirmButtonStyle = React.useRef();
  const states = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];
  const [isLoading, setIsLoading] = React.useState(false);
  const [infoWindownCenter, setInfoWindownCenter] = React.useState({
    lat: -37.99,
    lng: 145.3832,
  });
  // const [showMusicAuditor, props.setShowMusicAuditor] = React.useState(false);
  const [msfData, setMsfData] = React.useState();
  const [msfErrors, setMsfErrors] = React.useState();
  const [msfPaths, setMsfPaths] = React.useState();
  const [msfCheckerData, setMsfCheckerData] = React.useState();
  const [isAuditing, setIsAuditing] = React.useState(false);
  const mlbOkay = React.useRef();
  const rainfallStation = React.useRef();

  // State for Auditor
  const [fileName, setFileName] = React.useState();
  const [auditorReport, setAuditorReport] = React.useState(null);
  const [nodeInfo, setNodeInfo] = React.useState(null);
  const [norBeError, setNorBeError] = React.useState(false);
  const [otherMethodError, setOtherMethodError] = React.useState(false);

  // Map instance
  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    confirmButtonStyle.current = "success";
    mapRef.current = map;
    navigator.geolocation.getCurrentPosition(async function (position) {
      center.current = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });
    findState(center.current);
  }, []);

  // for mapcms toggle councils
  const [showCouncils, setShowCouncils] = React.useState(false);
  const [filteredCompanies, setFilteredCompanies] = React.useState([]);
  const [materialOptions, setMaterialOptions] = React.useState([]);
  const [deviceTypeOptions, setDeviceTypeOptions] = React.useState([]);

  async function getFilterOptions() {
    fetch("https://su-map-cms.ga/mapcms/columnfilter/material")
      .then(
        (response) => response.json()
        // response.json().data.material
      )
      .then((response) => response.material)
      .then((response) =>
        response.map((val) => ({
          label: val[0].toUpperCase() + val.slice(1).replaceAll("_", " "),
          value: val,
        }))
      )
      .then((response) => {
        setMaterialOptions(response);
        setMaterials(response);
      });
    fetch("https://su-map-cms.ga/mapcms/columnfilter/device")
      .then((response) => response.json())
      .then((response) => response.device)
      .then((response) =>
        response.map((val) => ({
          label: val[0].toUpperCase() + val.slice(1).replaceAll("_", " "),
          value: val,
        }))
      )
      .then((response) => {
        setDeviceTypeOptions(response);
        setDeviceTypes(response);
      });
  }

  const [materials, setMaterials] = React.useState([]);
  const [deviceTypes, setDeviceTypes] = React.useState([]);

  React.useEffect(() => {
    queryCMS();
  }, [materials, deviceTypes]);

  React.useEffect(() => {
    setFilteredCompanies(props.companyDetails);
    getFilterOptions();
  }, [props.companyDetails]);

  const animateConfirmButton = () => {
    confirmButtonStyle.current = "danger";
    setTimeout(() => {
      confirmButtonStyle.current = "success";
    }, 130);
  };

  const findState = async (location) => {
    const result = await getGeocode({ location });

    // FIND STATE FOR THE CURRENT LOCATION
    // ------- Simple cleaner version of the for loop below -------
    var filtered_address = result[0].address_components.filter(
      (address_component) => {
        return address_component.types.includes("administrative_area_level_1");
      }
    );
    var state = filtered_address.length ? filtered_address[0].short_name : "";
    printLog("State Found:", state);
    selectAndLoadState(state);
  };

  const selectAndLoadState = async (val) => {
    selectedState.current = val;
    setIsLoading(true);

    center.current = stateCoordinates[val].center;
    zoom.current = stateCoordinates[val].zoom;

    mapRef.current.data.forEach((feature) =>
      mapRef.current.data.remove(feature)
    );

    let state_val_for_overlay = val;

    printLog("href", window.location.href);
    if (
      window.location.href.includes("localhost") ||
      window.location.href.includes("amplify")
    ) {
      printLog("inside if");
      await mapRef.current.data.loadGeoJson(
        "https://stormwaterwares.s3-ap-southeast-2.amazonaws.com/GeoJSON/LGA_" +
          val +
          ".json",
        null,
        function (features) {
          printLog("features? ", features);
        }
      );

      await mapRef.current.data.loadGeoJson(
        "https://stormwaterwares.s3-ap-southeast-2.amazonaws.com/Overlays/Ovrlay_" +
          state_val_for_overlay +
          ".json",
        null,
        function (features) {
          printLog("in if.. loading spinner");
          checkAndLoad(toggle);
          mapRef.current.data.addListener("click", function (event) {
            onMapClick(event);
          });
          setIsLoading(false);
          // forceUpdate();
          printLog("in if.. stopping spinner");
        }
      );
    } else {
      if (val === "VIC") {
        await mapRef.current.data.loadGeoJson(
          "./assets/geojson/LGA_VIC_2.json"
        );
      } else {
        await mapRef.current.data.loadGeoJson(
          "./assets/geojson/LGA_" + val + ".json"
        );
      }
      await mapRef.current.data.loadGeoJson(
        "./assets/geojsonRainfall/Ovrlay_" + state_val_for_overlay + ".json",
        null,
        function (features) {
          checkAndLoad(toggle);
          mapRef.current.data.addListener("click", function (event) {
            onMapClick(event);
          });
          setIsLoading(false);
        }
      );
    }
  };

  const onMapClick = React.useCallback((e) => {
    const location = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };

    council.current = undefined;
    rainfallStation.current = undefined;

    //checking if feature exists
    if (e.feature) {
      if (e.feature.getProperty("type") === "overlay") {
        e.feature.forEachProperty(function (value, property) {
          printLog(property + ": ", value);
        });
        council.current = e.feature.getProperty("councilName");
        rainfallStation.current = e.feature.getProperty("stationName");
        // printLog(rainfallStation.current);
      } else if (e.feature.getProperty("type") === "Council") {
        // printLog("pulling from Council geojson");
        council.current = e.feature.getProperty("lga__2");
        // printLog("Council ? ", council.current);
      }
    } else {
      council.current = undefined;
    }

    getAddress(location);
    setSelected(location);
  }, []);

  const getAddress = async (location) => {
    const result = await getGeocode({ location });
    address.current = result[0];
    if (!council.current) {
      var council_val = await getCouncilAndState(result[0].address_components);
      council.current = council_val;
      printLog("Council from Google ? ", council.current);
      printLog("Address from Google ? ", result[0].address_components);
    }

    state.current = getStateFromAddress(address.current.address_components);

    markers.current = {
      lat: location.lat,
      lng: location.lng,
    };

    center.current = {
      lat: location.lat,
      lng: location.lng,
    };
    setValue(result[0].formatted_address, false);
  };

  const handleButtonSelection = async (val, e) => {
    checkAndLoad(val);
    setToggle(val);
  };

  const checkAndLoad = (val) => {
    let zIndex = 0;

    // Applies the colours from the overlay geojsons
    if (val.includes("councils") && val.includes("overlays")) {
      mapRef.current.data.setStyle(function (feature) {
        var color = "gray";
        var fillOp = 0;
        var strkOpacity = 0.3;
        var strkWeight = 0.5;
        var strkColor = "black";

        if (feature.getProperty("type") === "overlay") {
          color = feature.getProperty("color");
          strkColor = feature.getProperty("color");
          fillOp = 0.5;
          strkOpacity = 0.5;
          strkWeight = 0;
          zIndex = 1;
        }
        return {
          fillColor: color,
          fillOpacity: fillOp,
          strokeOpacity: strkOpacity,
          strokeWeight: strkWeight,
          strokeColor: strkColor,
          zIndex: zIndex,
        };
      });
    }
    if (val.includes("councils") && !val.includes("overlays")) {
      mapRef.current.data.setStyle(function (feature) {
        var color = "gray";
        var fillOp = 0;
        var strkOpacity = 0.3;
        var strkWeight = 0.5;
        var strkColor = "black";

        if (feature.getProperty("type") === "overlay") {
          color = feature.getProperty("color");
          strkColor = feature.getProperty("color");
          fillOp = 0;
          strkOpacity = 0;
          strkWeight = 0;
          zIndex = 1;
        }
        return {
          fillColor: color,
          fillOpacity: fillOp,
          strokeOpacity: strkOpacity,
          strokeWeight: strkWeight,
          strokeColor: strkColor,
          zIndex: zIndex,
        };
      });
    }

    if (!val.includes("councils") && val.includes("overlays")) {
      mapRef.current.data.setStyle(function (feature) {
        var color = "gray";
        var fillOp = 0;
        var strkOpacity = 0;
        var strkWeight = 0;
        var strkColor = "black";

        if (feature.getProperty("type") === "overlay") {
          color = feature.getProperty("color");
          strkColor = feature.getProperty("color");
          fillOp = 0.5;
          strkOpacity = 0.5;
          strkWeight = 0;
          zIndex = 1;
        }
        return {
          fillColor: color,
          fillOpacity: fillOp,
          strokeOpacity: strkOpacity,
          strokeWeight: strkWeight,
          strokeColor: strkColor,
          zIndex: zIndex,
        };
      });
    }

    if (!val.includes("councils") && !val.includes("overlays")) {
      mapRef.current.data.setStyle(function (feature) {
        var color = "gray";
        var fillOp = 0;
        var strkOpacity = 0;
        var strkWeight = 0;
        var strkColor = "black";

        if (feature.getProperty("type") === "overlay") {
          color = feature.getProperty("color");
          fillOp = 0;
          strkOpacity = 0;
          strkWeight = 0;
          strkColor = feature.getProperty("color");
          zIndex = 1;
        }
        return {
          fillColor: color,
          fillOpacity: fillOp,
          strokeOpacity: strkOpacity,
          strokeWeight: strkWeight,
          strokeColor: strkColor,
          zIndex: zIndex,
        };
      });
    }
    setShowCouncils(val.includes("showCouncils"));
  };

  let osdCityCouncil;
  const confirmLocation = async (calculator) => {
    printLog("confirm location clicked");
    if (!markers.current) {
      alert(
        "Please select a location on the map, or search for a location and select a suggested address."
      );
      return;
    }
    setIsLoading(true);
    try {
      //Fetch rainfall station and council name from Overlay GeoJson
      let rfStationAndCouncil = await getRFStationAndCouncil([
        markers.current.lng,
        markers.current.lat,
      ]);

      let rfStation = "";

      printLog("rfStationAndCouncil ?", rfStationAndCouncil);
      //Set rfStation, and set council name if location is inside any of our GeoJsons
      rfStation = rfStationAndCouncil.stationName;

      if (!rfStation) {
        rfStation = "";
      }
      if (rfStationAndCouncil.status === "ok") {
        council.current = rfStationAndCouncil.councilName;
        osdCityCouncil = rfStationAndCouncil.councilName;
        printLog("council from geojson?", council.current);
      } else {
        //Otherwise, pull council name from Google
        printLog("in getting council from Google");
        var council_val = await getCouncilAndState(
          address.current.address_components
        );
        council.current = council_val;
        printLog("council ?", council.current);
      }

      //Replacing em dash with hyphen, this if statement also prevents a crash
      if (council.current) {
        council.current = council.current.replace("–", "-");
      }
      let targetReductions = await props.sails_api.get(
        "OSD/reductionTargets/" + state.current + "/" + council.current
      );

      let osdMethod;
      let osdObject;

      printLog("\n\n\n Calculator");
      osdMethod = await props.sails_api.get(
        "OSD/osdAndWQMethods/" + state.current + "/" + council.current
      );

      wqMethod.current = osdMethod.data.wq_method;

      osdMethod = osdMethod.data.osd_method;
      var osdMethodUnsupported = false;
      var hasOsd = true;
      var hasWQ = false;
      var disableWQ = true;
      
      osdObject = OsdStateObject(osdMethod, osdCityCouncil);
      if (osdObject === false) {
        osdMethodUnsupported = true;
        hasOsd = false;
      }

      var data = {};
      var aeps = [];
      await props.sails_api
        .get(
          "osd/aep?latitude= " +
            markers.current.lat +
            "&longitude=" +
            markers.current.lng
        )
        .then((res) => {
          aeps = res.data;
        });

      if (rfStation === "") {
        // both water quality and osd not available
        if (osdObject === false) {
          alert(
            "WSUD data unavailable. OSD Method not supported for this council."
          );
        }

        // water quality not available but osd available
        else {
          alert(
            "WSUD data unavailable. You can still perform OSD modeling for this site 😎"
          );
        }
      } else {
        // water quality available but osd not available
        if (osdObject === false) {
          alert(
            "OSD method not yet supported. Water Quality Modelling is available. 😎"
          );
        }
        hasWQ = true;
        disableWQ = false;
        if(rfStationAndCouncil.councilName === "Monash City"){
          disableWQ = true;
          hasWQ =  false;
        }

        data = await CatchmentSet(
          rfStation,
          state.current,
          props.sails_api,
          wqMethod.current
        );
        printLog("state data = ", data);
      }
      printLog("Current address = ", address.current);
      printLog("Water quality method : ", wqMethod.current);

      if (address.current !== undefined) {
        var okAddress = false;
        for (
          let index = 0;
          index < address.current.address_components.length;
          index++
        ) {
          for (
            let x = 0;
            x < address.current.address_components[index].types.length;
            x++
          ) {
            if (
              address.current.address_components[index].types[x] === "route"
            ) {
              okAddress = true;
            }
          }
        }

        printLog("Address is ", okAddress ? "OK" : "Not Ok");

        var addressMain = "";
        var suburb = "";
        var cityCouncil = "";
        var zipcode = "";

        if (okAddress) {
          // if the address is in the required format. Else, find the address for the latlng on markers and use that

          var isACT = false;
          for (let i = 0; i < address.current.address_components.length; i++) {
            for (
              let j = 0;
              j < address.current.address_components[i].types.length;
              j++
            ) {
              if (
                address.current.address_components[i].types[j] ===
                  "street_number" ||
                address.current.address_components[i].types[j] === "route"
              ) {
                addressMain =
                  address.current.address_components[i].long_name + " ";
              }
              if (
                address.current.address_components[i].types[j] === "locality"
              ) {
                suburb = address.current.address_components[i].long_name;
              }
              if (
                address.current.address_components[i].types[j] === "postal_code"
              ) {
                zipcode = address.current.address_components[i].short_name;
              }
            }
          }
          if (isACT) {
            cityCouncil = "Australian Capital Territory";
          }
        } else {
          const location = {
            lat: markers.current.lat,
            lng: markers.current.lng,
          };
          const result = await getGeocode({ location });
          addressMain = "Not provided";
          for (let i = 0; i < result[0].address_components.length; i++) {
            for (
              let j = 0;
              j < result[0].address_components[i].types.length;
              j++
            ) {
              if (result[0].address_components[i].types[j] === "locality") {
                suburb = result[0].address_components[i].long_name;
              }
              if (result[0].address_components[i].types[j] === "postal_code") {
                zipcode = result[0].address_components[i].short_name;
              }
            }
          }
        }

        setIsLoading(false);
        if (calculator) {
          if (wqMethod.current.includes("NorBE")) {
            props.setAddress({
              rainfallStation: rfStation,
              noData: data.noData,
              imperviousAreaTypes: data.imperviousAreaTypes,
              disableWQ: disableWQ,
              catchmentTypes: disableWQ ? ["N/A"] : data.catchmentTypes,
              catchmentType: disableWQ ? "N/A" : data.catchmentType,
              pervious: data.perviousData,
              perviousAreaTypes: data.perviousAreaTypes,
              perviousAreaTreatmentTypes: data.perviousAreaTreatmentTypes,
              targetReductions: targetReductions.data,
              osdMethod: osdMethod,
              locationSelected: true,
              osdMethodUnsupported: osdMethodUnsupported,
              hasOsd: hasOsd,
              hasWQ: hasWQ,
              showOSDsAdsDiv: false,
              latitude: markers.current.lat,
              longitude: markers.current.lng,
              aeps: aeps,
              suburb: suburb,
              state: state.current,
              cityCouncil: council.current,
              zipcode: zipcode,
              addressMain: addressMain,
              osd: osdObject,
              overallResults: {
                overallRatings: {
                  gpRate: 0,
                  tnRate: 0,
                  tpRate: 0,
                  tssRate: 0,
                },
                overallReductions: {
                  gpReduction: 0,
                  tnReduction: 0,
                  tpReduction: 0,
                  tssReduction: 0,
                },
                overallPollutantsPreDev: {
                  gpPollutantPreDev: 0,
                  tnPollutantPreDev: 0,
                  tpPollutantPreDev: 0,
                  tssPollutantPreDev: 0,
                  flowPollutantPreDev: 0,
                },
                overallPollutantsPostDev: {
                  gpPollutantPostDev: 0,
                  tnPollutantPostDev: 0,
                  tpPollutantPostDev: 0,
                  tssPollutantPostDev: 0,
                  flowPollutantPostDev: 0,
                },
              },
              imperviousNodes: data.imperviousNodes,
              perviousNodes: data.perviousNodes,
              postDevImperviousNodes: data.postDevImperviousNodes,
              postDevPerviousNodes: data.postDevPerviousNodes,
              wqMethod: wqMethod.current,
              eolTreatments: data.eolTreatments,
            });
          } else {
            props.setAddress({
              rainfallStation: rfStation,
              noData: data.noData,
              imperviousAreaTypes: data.imperviousAreaTypes,
              disableWQ: disableWQ,
              catchmentTypes: disableWQ ? ["N/A"] : data.catchmentTypes,
              catchmentType: disableWQ ? "N/A" : data.catchmentType,
              pervious: data.perviousData,
              perviousAreaTypes: data.perviousAreaTypes,
              perviousAreaTreatmentTypes: data.perviousAreaTreatmentTypes,
              targetReductions: targetReductions.data,
              osdMethod: osdMethod,
              locationSelected: true,
              osdMethodUnsupported: osdMethodUnsupported,
              hasOsd: hasOsd,
              hasWQ: hasWQ,
              showOSDsAdsDiv: false,
              latitude: markers.current.lat,
              longitude: markers.current.lng,
              aeps: aeps,
              suburb: suburb,
              state: state.current,
              cityCouncil: council.current,
              zipcode: zipcode,
              addressMain: addressMain,
              osd: osdObject,
              overallResults: {
                overallRatings: {
                  gpRate: 0,
                  tnRate: 0,
                  tpRate: 0,
                  tssRate: 0,
                },
                overallReductions: {
                  gpReduction: 0,
                  tnReduction: 0,
                  tpReduction: 0,
                  tssReduction: 0,
                },
              },
              imperviousNodes: data.imperviousNodes,
              perviousNodes: data.perviousNodes,
              wqMethod: wqMethod.current,
              eolTreatments: data.eolTreatments,
            });
          }
        } else {
          props.setAddress({
            rainfallStation: rfStation,
            suburb: suburb,
            state: state.current,
            cityCouncil: council.current,
            zipcode: zipcode,
            addressMain: addressMain,
            targetReductions: targetReductions.data,
          });
        }
      }

      document.querySelector(".addAddressLinkButton").click(); //close the accordion
    } catch (e) {
      console.log("Exception caught: ", e);
      /*Emailing an error report */
      var userEmail = props.userDetails;
      var lat = markers.current.lat;
      var lng = markers.current.lng;

      // For todays date;
      Date.prototype.today = function () {
        return (
          (this.getDate() < 10 ? "0" : "") +
          this.getDate() +
          "/" +
          (this.getMonth() + 1 < 10 ? "0" : "") +
          (this.getMonth() + 1) +
          "/" +
          this.getFullYear()
        );
      };

      // For the time now
      Date.prototype.timeNow = function () {
        return (
          (this.getHours() < 10 ? "0" : "") +
          this.getHours() +
          ":" +
          (this.getMinutes() < 10 ? "0" : "") +
          this.getMinutes() +
          ":" +
          (this.getSeconds() < 10 ? "0" : "") +
          this.getSeconds()
        );
      };

      var time = new Date().timeNow();
      var day = new Date().today();
      printLog("Current details: ", userEmail, lat, lng, time, day);
      if (
        userEmail !== "1@1.com" &&
        userEmail !== "mircea@cleanstormwater.com.au" &&
        userEmail !== "greg@cleanstormwater.com.au" &&
        userEmail !== "alec@cleanstormwater.com.au"
      ) {
        var errorReport = {
          userEmail: userEmail,
          lat: lat,
          lng: lng,
          time: time,
          day: day,
          address: address.current,
        };
        await props.sails_api
          .post("/EmailErrorMessage", errorReport)
          .catch((err) => console.log("Couldn't send error report: ", err));
      }
      alert(
        "This location is unavailable. Please select another location, or contact Mircea (0433030044) or Greg (0452518727) for more information."
      );
      setIsLoading(false);
    }
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => -37.99, lng: () => 145.3832 },
      radius: 200 * 1000,
    },
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (add) => {
    animateConfirmButton();
    clearSuggestions();
    try {
      const result = await getGeocode({ address: add });

      address.current = result[0];

      setValue(result[0].formatted_address, false);
      const { lat, lng } = await getLatLng(result[0]);
      var x = getCouncilAndState(result[0].address_components);
      council.current = x;

      markers.current = {
        lat: lat,
        lng: lng,
      };
      center.current = {
        lat: lat,
        lng: lng,
      };

      setSelected({
        lat: lat,
        lng: lng,
      });

      mapRef.current.setZoom(14);
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };

  async function getRFStationAndCouncil(position) {
    let fetchFrom = "";

    let selectedState = state.current;

    if (
      window.location.href.includes("localhost") ||
      window.location.href.includes("amplify")
    ) {
      fetchFrom =
        "https://stormwaterwares.s3-ap-southeast-2.amazonaws.com/Overlays/Ovrlay_" +
        selectedState +
        ".json";
    } else {
      fetchFrom = "./assets/geojsonRainfall/Ovrlay_" + selectedState + ".json";
    }

    return new Promise(async (resolve, reject) => {
      await fetch(fetchFrom)
        .then((response) => response.json())
        .then((jsonData) => {
          printLog("length", jsonData.features.length);
          for (var i = 0; i < jsonData.features.length; i++) {
            let ps = jsonData.features[i].geometry.coordinates[0];
            if (inside(position, ps)) {
              if (jsonData.features[i].properties.councilName !== undefined) {
                resolve({
                  status: "ok",
                  stationName: jsonData.features[i].properties.stationName,
                  councilName: jsonData.features[i].properties.councilName,
                });
              } else {
                resolve({
                  status: "error",
                  stationName: jsonData.features[i].properties.stationName,
                });
              }
            }
          }
          resolve({
            status: "error",
            message: "location not within any overlay geojsons",
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // check if the point is inside a polygon
  function inside(point, vs) {
    const x = point[0],
      y = point[1];
    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      let xi = vs[i][0],
        yi = vs[i][1];
      let xj = vs[j][0],
        yj = vs[j][1];

      let intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  }

  function getCouncilAndState(address_components) {
    for (let i = 0; i < address_components.length; i++) {
      for (let j = 0; j < address_components[i].types.length; j++) {
        if (address_components[i].types[j] === "administrative_area_level_1") {
          state.current = address_components[i].short_name;
          if (address_components[i].short_name === "ACT") {
            return "Australian Capital Territory";
          }
        }
      }
    }

    for (let i = 0; i < address_components.length; i++) {
      for (let j = 0; j < address_components[i].types.length; j++) {
        if (address_components[i].types[j] === "administrative_area_level_2") {
          return address_components[i].long_name;
        }
      }
    }
    return "";
  }

  function getStateFromAddress(address_components) {
    // FIND STATE FOR THE CURRENT LOCATION
    // ------- Simple cleaner version of the for loop below -------
    var filtered_address = address_components.filter((address_component) => {
      return address_component.types.includes("administrative_area_level_1");
    });
    var state = filtered_address.length ? filtered_address[0].short_name : "";
    printLog("State Found:", state);
    return state;
  }

  // To hold the loading box in place
  const onCenterChange = (center) => {
    setInfoWindownCenter({ lat: center.lat(), lng: center.lng() });
  };

  // post to /AuditMSF to audit the MSF
  const auditMSF = async (loadingData) => {
    console.log("uploading...");
    setIsAuditing(true);
    setMsfErrors(undefined);
    setMsfPaths(undefined);
    var responseString = "";
    try {
      await flask_api
        .post("/AuditMSF", loadingData, { timeout: 30000 })
        .then((res) => {
          // Check response to see if there are any errors
          responseString = res.data.data;
          printLog("res from TheMap:", res);
          // Sending request to jsReport api for auditor report
          // Need to separate from The Map
          if ("newReport" in res.data) {
            setAuditorReport(res.data.newReport);
            setNodeInfo(res.data.nodeInfo);
          }

          if (responseString.includes("No Error")) {
            const okay = "Okay";
            setMsfErrors(okay);
          } else {
            // Parse the response into error array
            responseString = responseString.split("**SEPARATOR**");
            mlbOkay.current = responseString[0];
            setMsfPaths(responseString[1].trim());
            setMsfErrors(responseString[2].trim());
          }
        });
    } catch (e) {
      console.log("error from /AuditMSF: ", e);
      alert(
        "The server could not respond. Please try again, or contact Mircea (0433030044) or Greg (0452518727) if the error persists."
      );
    } finally {
      setIsAuditing(false);
      // setMsfData(undefined);
      return;
    }
  };

  const handleCloseMusicAuditor = async (e) => {
    if (e) {
      var buttonClicked = e.target.value;
      // Sends the MSF to the back end to be checked by the Auditor
      if (buttonClicked === "upload") {
        if (msfData && msfData !== "") {
          console.log(wqMethod.current);
          // if the wqMethod is NorBE
          if (wqMethod.current === "NorBE") {
            //  the nodes are including PreDevelopmentNode or PostDevelopmentNode
            if (
              msfData.msf.includes("Node Type,PreDevelopmentNode") &&
              msfData.msf.includes("Node Type,PostDevelopmentNode")
            ) {
              await auditMSF(msfData);
            } else {
              setNorBeError(true);
              return;
            }
          } else {
            // if the wqMethod is not NorBE
            // if the MSF didn't include pre and post development nodes
            console.log(
              !msfData.msf.includes("Node Type,PreDevelopmentNode") &&
                !msfData.msf.includes("Node Type,PostDevelopmentNode")
            );
            if (
              !msfData.msf.includes("Node Type,PreDevelopmentNode") &&
              !msfData.msf.includes("Node Type,PostDevelopmentNode")
            ) {
              await auditMSF(msfData);
              return;
            } else {
              setOtherMethodError(true);
            }
          }
        } else {
          console.log("error from TheMap.js. The MSF might be empty");
          alert(
            "The MSF could not be uploaded. Please try again, or contact Mircea (0433030044) or Greg (0452518727) if the error persists. "
          );
        }
      }
    }
  };

  //Triggers when "Audit your MUSIC model" is clicked. Shows Auditor Modal component
  const handleShowMusicAuditor = async () => {
    // printLog("=================Locations============\n", props.companyDetails)
    // Adds the user's email to the list we want to notify when the Auditor is ready
    confirmLocation(false);
    await props.sails_api.post("/SaveUserEmail", {
      email: props.basicInfo.assessorEmail,
    });
    if (!markers.current) {
      alert(
        "Please select a location on the map, or search for a location and select a suggested address."
      );
      return;
    }
    try {
      //Fetch rainfall station and council name from Overlay GeoJson
      rainfallStation.current = null;
      let rfStationAndCouncil = await getRFStationAndCouncil([
        markers.current.lng,
        markers.current.lat,
      ]);
      rainfallStation.current = rfStationAndCouncil.stationName;
      printLog("auditing in this authority:", rainfallStation.current);

      if (rainfallStation.current !== undefined) {
        props.setDisableAuditButton(false);
        // props.setShowMusicAuditor(true);
      } else {
        props.setDisableAuditButton(true);
        alert("WSUD data unavailable.");
      }
    } catch (e) {
      console.log("issue with fetching: ", e);
    }
  };

  //V2 of Auditor, modifies the user's MSF based on what they pick in the dropdowns.
  async function handleMusicAuditorChecker(e) {
    //Send MSF data to the back end with desired changes to nodes
    //Returns new MSF as text, downloads automatically
    var data = { msf: msfData.msf, checkerData: msfCheckerData };
    printLog("Data to Ming: ", data);
    await flask_api
      .post("/ModifyMSF", data, { timeout: 30000 })
      .then((res) => {
        const blob = new Blob([res.data.data], { type: "text/plain;" });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "Model.msf";
        a.click();
      })
      .catch((e) => {
        console.log("Error from Ming: ", e);
      });
  }

  const queryCMS = async () => {
    let filteredList = [];
    const mat_list = materials.map((m) => {
      return m.value;
    });
    const dev_list = deviceTypes.map((m) => {
      return m.value;
    });

    if (mat_list.length == 0 && dev_list.length == 0) {
      setFilteredCompanies(props.companyDetails);
      return;
    }

    if (mat_list.length > 0) {
      props.companyDetails.map((company) => {
        company.material.split(",").map((m) => {
          // printLog(m.trim())
          if (mat_list.includes(m.trim())) {
            filteredList.push(company);
            return;
          }
        });
      });
    }
    // printLog(filteredList)

    if (dev_list.length == 0) {
      setFilteredCompanies(filteredList);
      return;
    }

    let filteredList2 = [];
    let companyDetails =
      mat_list.length > 0 ? filteredList : props.companyDetails;
    companyDetails.map((company) => {
      company.device.split(",").map((d) => {
        if (dev_list.includes(d.trim())) {
          filteredList2.push(company);
          return;
        }
      });
    });
    setFilteredCompanies(filteredList2);
  };

  if (!ready) {
    return "Loading search";
  } else {
    return (
      <div className="the-Map-Div">
        <div className="search">
          <ToggleButtonGroup
            type="checkbox"
            value={toggle}
            onChange={handleButtonSelection}
          >
            <Dropdown as={ButtonGroup}>
              <DropdownButton
                as={ButtonGroup}
                variant="secondary"
                title={selectedState.current}
                // title="State"
                id="bg-nested-dropdown"
                disabled={isLoading}
              >
                {states.map((state) => (
                  <Dropdown.Item
                    key={state}
                    eventKey={state}
                    onSelect={selectAndLoadState}
                  >
                    {state}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Dropdown>
            <ToggleButton
              value={"councils"}
              variant="outline-warning"
              className="councilTog"
              disabled={isLoading}
            >
              LGA Boundaries
            </ToggleButton>
            <ToggleButton
              value={"overlays"}
              className="overlayTog"
              disabled={isLoading}
              variant="outline-info"
            >
              Overlays
            </ToggleButton>
            <ToggleButton
              value={"showCouncils"}
              className="overlayTog"
              disabled={isLoading}
              variant="outline-dark"
            >
              Councils
            </ToggleButton>
            <Search
              handleSelect={handleSelect}
              ready={ready}
              handleInput={handleInput}
              value={value}
              status={status}
              data={data}
              isLoading={isLoading}
            />
          </ToggleButtonGroup>
        </div>
        <div className="selector">
          Filter companies by Materials
          <Select
            isMulti
            options={materialOptions}
            value={materials}
            onChange={setMaterials}
            labelledby="Filter By Materials"
          />
          Filter companies by Devices
          <Select
            isMulti
            options={deviceTypeOptions}
            value={deviceTypes}
            onChange={setDeviceTypes}
            labelledby="Filter By Materials"
          />
          {((deviceTypes.length > 0 &&
            deviceTypes.length < deviceTypeOptions.length) ||
            (materials.length > 0 &&
              materials.length < materialOptions.length)) &&
            filteredCompanies.length == 0 && (
              <div className="err-msg">No result found</div>
            )}
        </div>

        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={zoom.current}
          center={center.current}
          options={options}
          onClick={onMapClick}
          onLoad={onMapLoad}
          onCenterChanged={(e) => {
            if (mapRef.current) {
              onCenterChange(mapRef.current.center);
            }
          }}
        >
          {isLoading ? (
            <InfoBox position={infoWindownCenter} options={infoboxoptions}>
              <Button variant="secondary" disabled>
                <Spinner animation="grow" variant="warning" />
                Loading...
              </Button>
            </InfoBox>
          ) : null}

          {/* Rendering Map CMS */}
          {props.companyDetails && (
            <MapCms
              ip={props.ip}
              source={props.source}
              basicInfo={props.basicInfo}
              sails_api={props.sails_api}
              companyDetails={
                materials.length > 0 || deviceTypes.length > 0
                  ? filteredCompanies
                  : props.companyDetails
              }
              councilDetails={props.councilDetails}
              showCouncils={showCouncils}
            ></MapCms>
          )}
          <Marker
            position={markers.current}
            onClick={() => {
              setSelected(markers.current);
            }}
          >
            {selected ? (
              <>
                <InfoWindowEx
                  position={{
                    lat: selected.lat,
                    lng: selected.lng,
                  }}
                  onCloseClick={() => {
                    setSelected(null);
                  }}
                >
                  <div>
                    <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Location Information:
                    </span>
                    <div style={{ fontSize: "12px", marginRight: "10px" }}>
                      <b>Address: </b> {value} <br />
                      <b>Council: </b> {council.current}
                    </div>
                    <br />
                    {props.auditorInterface ? (
                      <Button
                        variant="info"
                        size="sm"
                        style={{ marginBottom: "10px" }}
                        disabled={isLoading}
                        onClick={handleShowMusicAuditor}
                      >
                        Audit yer MUSIC Model
                      </Button>
                    ) : (
                      <Button
                        variant="success"
                        size="sm"
                        style={{ marginBottom: "10px" }}
                        onClick={() => confirmLocation(true)}
                        disabled={isLoading}
                      >
                        Start modelling
                      </Button>
                    )}
                    <br />
                  </div>
                </InfoWindowEx>
                <MusicAuditor
                  showMusicAuditor={props.showMusicAuditor}
                  setShowMusicAuditor={props.setShowMusicAuditor}
                  state={state.current}
                  council={council.current}
                  authority={rainfallStation.current}
                  wqMethod={wqMethod.current}
                  setMsfData={setMsfData}
                  msfData={msfData}
                  handleCloseMusicAuditor={handleCloseMusicAuditor}
                  handleShowMusicAuditor={handleShowMusicAuditor}
                  handleMusicAuditorChecker={handleMusicAuditorChecker}
                  msfErrors={msfErrors}
                  setMsfErrors={setMsfErrors}
                  msfPaths={msfPaths}
                  setMsfPaths={setMsfPaths}
                  msfCheckerData={msfCheckerData}
                  setMsfCheckerData={setMsfCheckerData}
                  isAuditing={isAuditing}
                  mlbOkay={mlbOkay.current}
                  sails_api={props.sails_api}
                  flask_api={flask_api}
                  auditorReport={auditorReport}
                  setAuditorReport={setAuditorReport}
                  nodeInfo={nodeInfo}
                  setNodeinfo={setNodeInfo}
                  basicInfo={props.basicInfo}
                  targetReductions={props.targetReductions}
                  markers={markers}
                  norBeError={norBeError}
                  setNorBeError={setNorBeError}
                  otherMethodError={otherMethodError}
                  setOtherMethodError={setOtherMethodError}
                  fileName={fileName}
                  setFileName={setFileName}
                />
              </>
            ) : null}
          </Marker>
        </GoogleMap>

        <div className="icon-click-prompt">
          <p>Click on the map icons and see how it goes!</p>
        </div>
      </div>
    );
  }
});

export default TheMap;
