import React from 'react'
import Ticker from 'react-ticker'
import CouncilsList from './assets/js/CouncilsList';
 
var councils = () => {
    var councilArray = [];
    CouncilsList.VIC.map((council) => councilArray.push(council.name));
    CouncilsList.NSW.map((council) => councilArray.push(council.name));
    CouncilsList.TAS.map((council) => councilArray.push(council.name));
    CouncilsList.QLD.map((council) => councilArray.push(council.name));
    CouncilsList.SA.map((council) => councilArray.push(council.name));
    return councilArray;
}

const LGATicker = () => (


    <Ticker height={30}>
        {({ index }) => (
            <>
                {councils().join(" - ")}
            </>
        )}
    </Ticker>
)
 
export default LGATicker;