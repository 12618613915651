import React from "react";
import Gallery from "react-grid-gallery";

const IMAGES = [
  {
    src: "./assets/Announcements/NSW_Accepted.png",
    thumbnail: "./assets/Announcements/NSW_Accepted.png",
    thumbnailWidth: 200,
    thumbnailHeight: 174,
    caption: "",
  },
  {
    src: "./assets/Announcements/QLD_Accepted.png",
    thumbnail: "./assets/Announcements/QLD_Accepted.png",
    thumbnailWidth: 200,
    thumbnailHeight: 174,
    caption: "",
  },
  {
    src: "./assets/Announcements/SA_Accepted.png",
    thumbnail: "./assets/Announcements/SA_Accepted.png",
    thumbnailWidth: 200,
    thumbnailHeight: 174,
    caption: "",
  },
  {
    src: "./assets/Announcements/TAS_Accepted.png",
    thumbnail: "./assets/Announcements/TAS_Accepted.png",
    thumbnailWidth: 200,
    thumbnailHeight: 174,
    caption: "",
  },
  {
    src: "./assets/Announcements/VIC_Accepted.png",
    thumbnail: "./assets/Announcements/VIC_Accepted.png",
    thumbnailWidth: 200,
    thumbnailHeight: 174,
    caption: "",
  },
];

const CouncilMaps = () => (
  <div>
    <Gallery images={IMAGES} enableImageSelection={false} />
  </div>
);

export default CouncilMaps;
