import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/calculator.css";
import ReactTooltip from "react-tooltip";
import { Alert } from "react-bootstrap";

class DorriesResultsTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if(this.props.data.showAlert3){
      return (
        <><Alert
        variant="danger"
        show={this.props.data.showAlert3}
      >
        ⚠️ Please ensure Total Site Area for Developed Site and Existing Site matches.
      </Alert></>
      );
    }
    else if (this.props.data.dorriesResults.detailed_wsud_table.dev_impact.fr.color !== "") {
    return (
      <>
      <table><tbody><tr>
      <td>
        <h6 style={{ fontWeight: 700 }}>
          Target Reductions Achieved &nbsp;</h6></td>
          <td style={{fontSize: 14}}>
          <span
            data-tip="NorBe means this development improves or does not worsen the existing condition. <br />STORM Rating of 100% or more means the targets have been achieved."
            data-multiline="true"
            data-place="bottom"
          >
            ❓
          </span>{" "}
          <ReactTooltip />
          <span
            data-tip="Green good! <br />Red baaaaaaad."
            data-multiline="true"
            data-place="bottom"
          >
            🤖
          </span>{" "}
          <ReactTooltip /></td>
        
        </tr></tbody></table>
        <table className="table table-sm col-sm-8 table-bordered">
          <thead
            style={{
              backgroundColor: "#508bc9",
              color: "white",
              border: "1.5px solid #c7c7ce",
            }}
          >
            <tr>
              <th> </th>
              <th> TN </th>
              <th> TP </th>
              <th> TSS </th>
              <th> GP </th>
              <th> Flow </th>
            </tr>
          </thead>
          <tbody style={{ border: "1.5px solid #c7c7ce" }}>
            <tr>
              <td style={{ border: "1.5px solid #c7c7ce", fontWeight: 700 }}>
                Status
              </td>
              <td style={{ border: "1.5px solid #c7c7ce", fontWeight: 700, 
                  color: this.props.data.dorriesResults.targetReductionsTable.tn.color }}>
                {this.props.data.dorriesResults.targetReductionsTable.tn.status}
              </td>
              <td style={{ border: "1.5px solid #c7c7ce", fontWeight: 700,
              color: this.props.data.dorriesResults.targetReductionsTable.tp.color }}>
              {this.props.data.dorriesResults.targetReductionsTable.tp.status}
              </td>
              <td style={{ border: "1.5px solid #c7c7ce", fontWeight: 700,
              color: this.props.data.dorriesResults.targetReductionsTable.tss.color }}>
              {this.props.data.dorriesResults.targetReductionsTable.tss.status}
              </td>
              <td style={{ border: "1.5px solid #c7c7ce", fontWeight: 700,
              color: this.props.data.dorriesResults.targetReductionsTable.gp.color }}>
              {this.props.data.dorriesResults.targetReductionsTable.gp.status}
              </td>
              <td style={{ border: "1.5px solid #c7c7ce", fontWeight: 700,
              color: this.props.data.dorriesResults.targetReductionsTable.fr.color }}>
              {this.props.data.dorriesResults.targetReductionsTable.fr.status}
              </td>
            </tr>
            <tr>
              <td style={{ border: "1.5px solid #c7c7ce", fontWeight: 700 }}>
                STORM Rating (%)
              </td>
              <td style={{ border: "1.5px solid #c7c7ce", fontWeight: 700, 
                  color: this.props.data.dorriesResults.targetReductionsTable.tn.color }}>
                {this.props.data.dorriesResults.targetReductionsTable.tn.value}
              </td>
              <td style={{ border: "1.5px solid #c7c7ce", fontWeight: 700,
              color: this.props.data.dorriesResults.targetReductionsTable.tp.color }}>
              {this.props.data.dorriesResults.targetReductionsTable.tp.value}
              </td>
              <td style={{ border: "1.5px solid #c7c7ce", fontWeight: 700,
              color: this.props.data.dorriesResults.targetReductionsTable.tss.color }}>
              {this.props.data.dorriesResults.targetReductionsTable.tss.value}
              </td>
              <td style={{ border: "1.5px solid #c7c7ce", fontWeight: 700,
              color: this.props.data.dorriesResults.targetReductionsTable.gp.color }}>
              {this.props.data.dorriesResults.targetReductionsTable.gp.value}
              </td>
              <td style={{ border: "1.5px solid #c7c7ce", fontWeight: 700,
              color: this.props.data.dorriesResults.targetReductionsTable.fr.color }}>
              {this.props.data.dorriesResults.targetReductionsTable.fr.value}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <h6 style={{ fontWeight: 700 }}>Detailed WSUD Table</h6>
        <table className="table table-sm col-sm-8 table-bordered">
          <thead
            style={{
              backgroundColor: "#508bc9",
              color: "white",
              border: "1.5px solid #c7c7ce",
            }}
          >
            <tr>
              <th> </th>
              <th> TN (kg/yr) </th>
              <th> TP (kg/yr) </th>
              <th> TSS (kg/yr) </th>
              <th> GP (kg/yr)</th>
              <th> Flow (ML/yr) </th>
            </tr>
          </thead>
          <tbody style={{ border: "1.5px solid #c7c7ce" }}>
            <tr>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                Existing Site Residuals
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.existingResiduals.tn}</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.existingResiduals.tp}</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.existingResiduals.tss}</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.existingResiduals.gp}</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.existingResiduals.flow}</span>
              </td>
            </tr>
            <tr>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                Developed Site Sources
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.developedSources.tn}</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.developedSources.tp}</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.developedSources.tss}</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.developedSources.gp}</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.developedSources.flow}</span>
              </td>
            </tr>
            <tr>
              <td style={{ border: "1.5px solid #c7c7ce", textAlign: "left" }}>
                Developed Site Residuals
              </td>
              <td style={{ border: "1.5px solid #c7c7ce",
                color: this.props.data.dorriesResults.detailed_wsud_table.developedResiduals.tn.color }}>
                <span>{this.props.data.dorriesResults.detailed_wsud_table.developedResiduals.tn.value}</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce",
                color: this.props.data.dorriesResults.detailed_wsud_table.developedResiduals.tp.color }}>
                <span>{this.props.data.dorriesResults.detailed_wsud_table.developedResiduals.tp.value}</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce",
                color: this.props.data.dorriesResults.detailed_wsud_table.developedResiduals.tss.color }}>
                <span >{this.props.data.dorriesResults.detailed_wsud_table.developedResiduals.tss.value}</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce",
                color: this.props.data.dorriesResults.detailed_wsud_table.developedResiduals.gp.color }}>
                <span >{this.props.data.dorriesResults.detailed_wsud_table.developedResiduals.gp.value}</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce",
                color: this.props.data.dorriesResults.detailed_wsud_table.developedResiduals.fr.color }}>
                <span >{this.props.data.dorriesResults.detailed_wsud_table.developedResiduals.fr.value}</span>
              </td>
            </tr>
            <tr>
              <td style={{ border: "1.5px solid #c7c7ce", textAlign: "left" }}>
                Development Impact on Waterway Pollution?
              </td>
              <td style={{ border: "1.5px solid #c7c7ce",
                color: this.props.data.dorriesResults.detailed_wsud_table.dev_impact.tn.color }}>
                <span>{this.props.data.dorriesResults.detailed_wsud_table.dev_impact.tn.value}</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce",
                color: this.props.data.dorriesResults.detailed_wsud_table.dev_impact.tp.color }}>
                <span>{this.props.data.dorriesResults.detailed_wsud_table.dev_impact.tp.value}</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce",
                color: this.props.data.dorriesResults.detailed_wsud_table.dev_impact.tss.color }}>
                <span>{this.props.data.dorriesResults.detailed_wsud_table.dev_impact.tss.value}</span>
              </td>
               <td style={{ border: "1.5px solid #c7c7ce",
                color: this.props.data.dorriesResults.detailed_wsud_table.dev_impact.gp.color }}>
                <span>{this.props.data.dorriesResults.detailed_wsud_table.dev_impact.gp.value}</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce",
                color: this.props.data.dorriesResults.detailed_wsud_table.dev_impact.fr.color }}>
                <span>{this.props.data.dorriesResults.detailed_wsud_table.dev_impact.fr.value}</span>
              </td>
            </tr>
            <tr>
              <td style={{ border: "1.5px solid #c7c7ce", textAlign: "left" }}>
                Achieved Reductions (%)
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.achievedReductions.tn.value}%</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.achievedReductions.tp.value}%</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.achievedReductions.tss.value}%</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.achievedReductions.gp.value}%</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.achievedReductions.flow.value}</span>
              </td>
            </tr>
            <tr>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                Target Reductions (%)
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.targetReductions.tn}%</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.targetReductions.tp}%</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.targetReductions.tss}%</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.targetReductions.gp}%</span>
              </td>
              <td style={{ border: "1.5px solid #c7c7ce" }}>
                <span style={{ color: "black" }}>{this.props.data.dorriesResults.detailed_wsud_table.targetReductions.fr}</span>
              </td>
            </tr>
          </tbody>
          <caption>
            Required target reductions for council: {this.props.data.cityCouncil}
          </caption>
        </table>
        <div>
          {this.props.data.dorriesResults.alerts.map((alert, index) => { 
              return (
                  <Alert key={index} variant={alert.type}>
                    {alert.message}
                  </Alert>
              );
          })}
        </div>
      </>
    );
        }
        else {
          return <i>Live results will appear here once you have entered values above.</i>
        }
  }
}

export default DorriesResultsTable;
